import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

function Row(props) {
  let { row, classes, isFirstRow } = props;
  console.log(row);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          style={{
            borderBottom: 'none',
            paddingLeft: '0px',
            paddingTop: isFirstRow ? '0px' : '10px',
            paddingRight: '12px',
            paddingBottom: '0px',
            verticalAlign: 'top'
          }}
          title={row}
          className={classes.fontSize12}
        >
          <Typography style={{ fontFamily: 'Satoshi', fontSize: '12px' }}>{row}</Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ config }) {
  const { tableAccordianData, classes } = config;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      style={{
        borderRadius: '8px',
        backgroundColor: '#FAFAFA'
      }}
    >
      <Table aria-label="collapsible table">
        <TableBody>
          {tableAccordianData &&
            tableAccordianData.length > 0 &&
            tableAccordianData.map((row, i) => (
              <Row key={i} row={row} classes={classes} isFirstRow={i === 0} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  config: PropTypes.any
};
