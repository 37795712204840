import React from 'react';
import useChatStyles from '../styles';
import ChipComponent from '../../../../atoms/chipComponent';

function FollowUpComponent({ messageData, handleCardQuestionClick, title, disabled }) {
  const classes = useChatStyles();
  return (
    <>
      {messageData && messageData.length > 0 && (
        <div id="followUpMain">
          <div className={classes.headingText}>Suggested questions</div>
          {messageData.map((question, i) => {
            return (
              <ChipComponent
                title={title}
                key={i}
                label={question}
                onClick={(e) => {
                  handleCardQuestionClick(e, question);
                }}
                className={`${classes.suggestedQues}`}
                disabled={disabled}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

export default FollowUpComponent;
