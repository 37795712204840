import { getApi } from '../../../../services/token';
import { useEffect, useState } from 'react';
import Autocomplete from '../../../atoms/searchableAutocomplete';
import { entitesFilterToDisplayMapTheme } from '../../../../constants/tearSheet/tearSheet';

const isDefaultOption = (option) => {
  return (
    option.entity_type === 'No options' &&
    option.entity_name === 'No options' &&
    option.entity_id === 'custom_entity_id' &&
    option.logo === ''
  );
};

const CompanyThemeSearch = ({
  // selectedFilter = '',
  getReports,
  updateParamsInURL,
  navigate,
  location,
  COMPANY_API_URL,
  queryParamName,
  selectedItem,
  setSelectedItem,
  // setLoading,
  // setErrorMessage,
  activeEntityType,
  query_definition_type,
  setOpenThemeDialog,
  getSimilarEntities,
  onEditTheme,
  onDeleteTheme,
  closeCompThemeSearch,
  setCloseCompThemeSearch
}) => {
  const isPresistSearchValue = true;
  const page_size = 15;

  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    if (selectedTab !== query_definition_type) {
      setSelectedTab(query_definition_type || 'pre_defined');
    }
  }, [query_definition_type]);

  const checkAndAssignOptions = (data, page) => {
    const items = data?.data?.items || [];
    const totalRecords = data?.data?.metadata?.total_records || 0;

    if (page === 1 && items.length === 0) {
      return {
        newOptions: [
          {
            entity_type: 'No options',
            entity_name: 'No options',
            entity_id: 'custom_entity_id',
            logo: ''
          }
        ],
        total_records: 1
      };
    }

    return { newOptions: items, total_records: totalRecords };
  };

  const getAutocompleteOptions = async (parameters) => {
    const [input, page, activeEntityType, selectedTab] = parameters;
    const queryParams = new URLSearchParams({
      entity_types: activeEntityType === 'theme' ? 'theme,sub_theme' : activeEntityType || '',
      page_size: page_size || 15,
      page_number: page || 1,
      search_text: input || '',
      definition_type: activeEntityType === 'theme' ? selectedTab || 'pre_defined' : ''
    });

    try {
      // setLoading(true);
      const { data } = await getApi(
        `${COMPANY_API_URL}/v1/entity/search?${queryParams.toString()}`
      );
      // const { items, total_records } = await searchEntities(input);
      const chipsData = await getSimilarEntities(input, activeEntityType);
      const { newOptions, total_records } = checkAndAssignOptions(data, page);

      // setLoading(false);
      return { newOptions, total_records, chipsData };
    } catch (error) {
      console.error(error);
      // setLoading(false);
      // setErrorMessage(
      //   `Error occurred while retrieving options for the ${selectedFilter}. Please try again.`
      // );
    }
  };

  const onAutoCompleteChange = async (selectedOption) => {
    if (selectedOption && isDefaultOption(selectedOption)) {
      return;
    }
    setSelectedItem(selectedOption);
    if (
      selectedOption?.entity_id &&
      (!selectedItem || selectedItem.entity_id != selectedOption.entity_id)
    ) {
      await getReports(selectedOption);
    } else if (selectedOption?.entity_name) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { [queryParamName]: selectedOption.entity_name },
        removeParams: ['report_id']
      });
    }
  };

  const onSelectedTabOption = (value) => {
    if (value) {
      if (value === 'pre_defined' || value === 'user_defined') {
        setSelectedTab(value);
        updateParamsInURL({
          navigate,
          location,
          addParams: { definition_type: value }
        });
      } else if (value === 'add-theme') {
        setOpenThemeDialog(true);
      }
    }
  };

  const handleOnBlur = () => {
    if (!selectedItem) {
      updateParamsInURL({
        navigate,
        location,
        removeParams: ['action', 'entity_id', queryParamName, 'report_id']
      });
      // setSelectedTab('');
    }
  };

  let themeOptions = {};

  if (activeEntityType === 'theme') {
    themeOptions = {
      tabOptions: entitesFilterToDisplayMapTheme,
      selectedTab,
      onSelectedTabOption
    };
  }

  return (
    <>
      <Autocomplete
        searchableProps={{
          getAutocompleteOptions,
          isPresistSearchValue,
          onAutoCompleteChange,
          queryParamName,
          APIDependency: [activeEntityType, selectedTab],
          ...themeOptions,
          handleOnBlur,
          activeEntityType,
          selectedTab,
          showChips: activeEntityType === 'theme',
          onEditTheme: onEditTheme,
          onDeleteTheme: onDeleteTheme,
          closeSearch: closeCompThemeSearch,
          setParentClose: setCloseCompThemeSearch,
          isDefaultOption
        }}
      />
    </>
  );
};

export default CompanyThemeSearch;
