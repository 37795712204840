import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import ContactSupportComponent from './contactSupportComponent';

const useStyles = makeStyles({
  helpCenterContentMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '22px !important',
    fontWeight: '700 !important',
    paddingBottom: '16px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  },
  helpCenterInfoContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#C44545 !important',
    lineHeight: '24px !important'
  },
  helpCenterInfoSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: '#373737 !important',
    lineHeight: '24px !important'
  }
});

function DocIngestHelpCenter() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentMainTxt}>Document Library</Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Document library allows you to upload your proprietary documents including research,
          analyses and updates to a segregated private area for your organisation. These documents
          will then be used alongside the publicly available information in the platform to enrich
          and deliver the best possible answers to your questions. Tag each uploaded document to a
          company, sector or miscellaneous to guide its use.
        </Typography>
      </Grid>
      <Grid style={{ padding: '10px 0px 10px 0px' }} lg={12}>
        <div
          style={{
            padding: '10px 20px',
            backgroundColor: '#FFF9F9',
            borderLeft: '3px solid #C44545'
          }}
        >
          <Typography className={classes.helpCenterInfoContent}>Please note:</Typography>
          <Typography className={classes.helpCenterInfoSubContent}>
            Your proprietary data will never be used to train our models, or answer questions for
            any user outside your organisation.
          </Typography>
        </div>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Let's explore this feature.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            controls
            style={{
              maxWidth: '60%',
              maxHeight: '100%',
              width: '60%',
              height: 'auto'
            }}
          >
            <source
              src="https://public.privateblok.ai/help_center/documentliberary.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </Grid>
      <ContactSupportComponent />
    </Grid>
  );
}

export default DocIngestHelpCenter;
