import React, { useEffect, useState } from 'react';
import TearSheetSearchBar from '../../myTemplate/templateSearchBar';
import { getApi, postApi, deleteApi } from '../../../../services/token';
import TearSheetTemplate from './templateLibrary/templates/templates';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateParamsInURL } from '../../../../helpers';
import { makeStyles } from '@material-ui/core';
import { LinearProgress } from '@mui/material';
import ErrorToast from '../../../atoms/errorToast';
import SuccessToast from '../../../atoms/successToast';
import { Grid } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { setTearSheetKeys } from '../../action';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import TemplateDialog from './templateDialog';
import { ReactComponent as LeftArrowBlack } from '../../../../components/icons/LeftArrowBlack.svg';

const useStyles = makeStyles(() => ({
  templateHeading: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '22px !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '30px !important',
    cursor: 'pointer',
    padding: '10px 0px'
  },
  templateHeadingDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  selected: {
    padding: '6px 8px !important',
    borderRadius: '4px !important',
    border: '1px solid #E4EBFC !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    textTransform: 'none !important'
  },
  unSelected: {
    padding: '6px 8px !important',
    borderRadius: '4px !important',
    border: '1px solid #FFFFFF !important',
    backgroundColor: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  selectedText: {
    fontFamily: 'Satoshi !important',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '700 !important'
  },
  unSelectedText: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '16px',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '700 !important'
  },
  emptyTemplate: {
    display: 'flex',
    height: '44px',
    padding: '10px 32px',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '0px 0px 12px 0px rgba(6, 10, 84, 0.08)',
    alignItems: 'center'
  },
  emptyTemplateTxt: {
    fontFamily: 'Satoshi !important',
    color: '#171717 !important',
    fontSize: '18px',
    lineHeight: '24px !important',
    fontWeight: '500 !important',
    margin: '0 auto'
  },
  createBtnTxt: {
    fontFamily: 'Satoshi !important',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '500 !important'
  }
}));

const TemplateLibaray = () => {
  const classes = useStyles();
  const companyUrl = process.env.REACT_APP_COMPANY_API;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedFilter = searchParams.get('entity_type') || 'company';
  const entityName = searchParams.get('search');
  const entityId = searchParams.get('entity_id');
  const templateId = searchParams.get('template_id');

  const [templateData, setTemplateData] = useState();
  const [initialTemplateData, setInitialTemplateData] = useState();
  const [btnTemplateData, setBtnTemplateData] = useState();
  const [templateValidationErrors, setTemplateValidationErrors] = useState({});
  const [poperSuccessMessage, setPoperSuccessMessage] = useState('');
  const [triggerError, setTriggerErrorMessage] = useState('');
  const [useTemplate, setUseTemplate] = useState(true);
  const [savetemplateBtn, setSavetemplateBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const tearSheetSearchBarProps = {
    t,
    selectedFilter,
    location,
    navigate,
    updateParamsInURL,
    searchParams,
    entityId,
    templateId
  };

  const getTemplateData = async () => {
    if (!templateId) {
      return;
    } else {
      try {
        const res = await getApi(`${companyUrl}/v2/template/${templateId}`);
        const data = res.data.data;
        setSavetemplateBtn(false);
        setUseTemplate(true);
        setBtnTemplateData();
        setTemplateData(data);
        setInitialTemplateData(JSON.parse(JSON.stringify({ ...data })));
      } catch (err) {
        setTemplateData(null);
        setInitialTemplateData(null);
        setApiError(t('tearSheet.apiError'));
        setTimeout(() => setApiError(''), 10000);
      }
    }
  };

  const handleDeleteTemplate = async () => {
    setTriggerErrorMessage('');
    setPoperSuccessMessage('');
    setLoading(true);
    try {
      await deleteApi(`${companyUrl}/v2/template/${templateId}`);
      navigate(`/my-templates`, {
        state: { successMessage: t('tearSheet.tearSheetsDeletion') }
      });
      setPoperSuccessMessage(t('tearSheet.tearSheetsDeletion'));
      setTimeout(() => setPoperSuccessMessage(''), 10000);
    } catch (err) {
      setTriggerErrorMessage(t('tearSheet.triggerFailedDeleteTemplate'));
    }
    setLoading(false);
  };

  const modifyErrors = (modifiedValidationErrors) => {
    let question_groups_errors = modifiedValidationErrors?.question_groups;
    if (question_groups_errors?.length) {
      question_groups_errors.sort((a, b) => a.index - b.index);
      for (let i = 0; i < templateData?.question_groups.length; i++) {
        if (question_groups_errors.length <= i) {
          question_groups_errors.push({});
        } else if (i !== question_groups_errors[i]?.index) {
          question_groups_errors.splice(i, 0, {});
        }
        let questions_errors = question_groups_errors[i]?.questions;
        if (questions_errors) {
          questions_errors.sort((a, b) => a.index - b.index);
          for (let j = 0; j < templateData?.question_groups?.[i]?.questions?.length; j++) {
            if (questions_errors.length <= j) {
              questions_errors.push({});
            } else if (j !== questions_errors?.[j]?.index) {
              questions_errors.splice(j, 0, {});
            }
          }
        }
      }
    }
  };

  const handleDuplicate = () => {
    setPoperSuccessMessage('');
    setUseTemplate(false);
    let duplicatedTemplate = {};
    duplicatedTemplate.report = initialTemplateData.template_name;
    duplicatedTemplate.entity_type = selectedFilter;
    duplicatedTemplate.entity_name = entityName;
    duplicatedTemplate.question_groups = initialTemplateData.question_groups;
    setTemplateData(duplicatedTemplate);
  };

  const handleGenerateReport = async () => {
    setPoperSuccessMessage('');
    setApiError('');
    setLoading(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTemplateValidationErrors(null);

    const payload = {
      report_name: templateData.report,
      entity_list: [
        {
          entity_type: selectedFilter,
          entity_id: entityId
        }
      ],
      ...(isEqual(initialTemplateData.question_groups, templateData.question_groups)
        ? { template_id: templateId }
        : { question_groups: templateData.question_groups })
    };

    try {
      const res = await postApi(`${companyUrl}/v2/report`, payload);

      dispatch(setTearSheetKeys({}));

      if (res?.data?.data?.success === true) {
        const successMessage = 'Report Succesfully Generated';

        dispatch(setTearSheetKeys({}));
        setTemplateValidationErrors({});
        setPoperSuccessMessage(successMessage);
        if (res?.data && res?.data?.data?.report_id?.length > 0 && res?.data?.data?.report_id[0]) {
          setLoading(false);
          updateParamsInURL({
            navigate,
            location,
            addParams: { report_id: res?.data?.data?.report_id[0], success: 'template-library' },
            removeParams: ['entity_id', 'template_id', 'action']
          });
        }
        setTimeout(() => setPoperSuccessMessage(''), 5000);
      }

      if (res?.data?.data?.success === false) {
        if (res?.data?.data?.errors.message) {
          setApiError(res.data.data.errors.message);
          setTimeout(() => setApiError(''), 5000);
        } else {
          let modifiedValidationErrors = res.data?.data?.errors;
          modifyErrors(modifiedValidationErrors);
          setTemplateValidationErrors(modifiedValidationErrors);
          setOpenTemplateDialog(false);
          setApiError(t('tearSheet.templateValidationError'));
          setTimeout(() => setApiError(''), 10000);
          setLoading(false);
          return;
        }
      }
    } catch (err) {
      console.log(err);
      setApiError(t('tearSheet.apiError'));
      setTimeout(() => setApiError(''), 5000);
    }
    setLoading(false);
  };

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const openPopup = () => {
    setOpenTemplateDialog(true);
  };

  const onSaveTemplate = async (templateName, templateDesc, resetTemplateForm) => {
    setTemplateValidationErrors({});
    setPoperSuccessMessage('');
    setTriggerErrorMessage('');
    setApiError('');
    if (templateName && templateDesc) {
      const dataToSend = {
        template_name: templateName,
        entity_type: selectedFilter,
        entity_id: entityId,
        description: templateDesc,
        template_type: 'user_defined', // predefined or userdefined
        question_groups: templateData.question_groups
      };
      try {
        setLoading(true);
        const data = await postApi(`${companyUrl}/v2/template`, dataToSend);

        if (data.data?.data.success === false) {
          console.log(data.data?.data?.errors);
          let modifiedValidationErrors = data.data?.data?.errors;
          modifyErrors(modifiedValidationErrors);
          setTemplateValidationErrors(modifiedValidationErrors);
          if (modifiedValidationErrors.template_name === 'Template with the same name exists') {
            setApiError(modifiedValidationErrors.template_name);
          } else {
            setOpenTemplateDialog(false);
            setApiError(t('tearSheet.templateValidationError'));
          }
          setTimeout(() => setApiError(''), 10000);
          setLoading(false);
          return;
        }

        if (data.data?.data.success === true) {
          dispatch(setTearSheetKeys({}));
          setTemplateValidationErrors({});
          resetTemplateForm();
          setOpenTemplateDialog(false);
          setLoading(false);
          setSavetemplateBtn(true);
          setBtnTemplateData(templateData);
          setPoperSuccessMessage(`${templateName} template successfully generated`);
        }
        setTimeout(() => setPoperSuccessMessage(''), 10000);
      } catch (error) {
        setTriggerErrorMessage(
          'The template creation failed. Please check the data input and try again'
        );
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const initial = () => {
      if (!isEqual(btnTemplateData?.question_groups, templateData?.question_groups)) {
        setSavetemplateBtn(false);
      }
    };
    initial();
  }, [btnTemplateData, templateData]);

  const handleSaveTemplate = async () => {
    setLoading(true);
    setTemplateValidationErrors({});
    setPoperSuccessMessage('');
    setTriggerErrorMessage('');
    setApiError('');

    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTemplateValidationErrors(null);
    setApiError('');
    const endpoint =
      templateId === 'new'
        ? `${companyUrl}/v2/template`
        : `${companyUrl}/v2/template/${templateId}`;

    const method = postApi;
    let payload = { ...templateData, entity_type: selectedFilter, template_type: 'user_defined' };
    delete payload.company_list;
    try {
      const res = await method(endpoint, payload);

      if (res.data?.data.success === false) {
        console.log(res.data?.data?.errors);
        let modifiedValidationErrors = res.data?.data?.errors;
        modifyErrors(modifiedValidationErrors);
        setTemplateValidationErrors(modifiedValidationErrors);
        setApiError(t('tearSheet.templateValidationError'));
        setTimeout(() => setApiError(''), 10000);
        setLoading(false);
        return;
      }

      if (res.data?.data.success === true) {
        dispatch(setTearSheetKeys({}));
        const successMessage =
          templateId === 'new'
            ? t('tearSheet.templateCreateSuccessful')
            : t('tearSheet.templateSaveSuccessful');
        setPoperSuccessMessage(successMessage);
        if (res.data?.data?.template_id) {
          navigate(`/my-templates`);
        }
        setTimeout(() => setPoperSuccessMessage(''), 10000);
      }

      // setPoperSuccessMessage(successMessage);
      // setSuccessMessage(successMessage);
      // setTimeout(() => setPoperSuccessMessage(''), 10000);
      // setTimeout(() => setSuccessMessage(''), 10000);
      // navigate(`/my-templates`);
    } catch (err) {
      console.log(err);
      setApiError(t('tearSheet.apiError'));
      setTimeout(() => setApiError(''), 10000);
    }
    setLoading(false);
  };

  const handleDiscardTemplate = () => {
    navigate(`/my-templates`, { state: { successMessage: t('tearSheet.templateDiscardText') } });
  };

  useEffect(() => {
    const initial = async () => {
      setLoading(true);
      await getTemplateData();
      setLoading(false);
    };
    initial();
  }, [templateId]);

  return (
    <>
      <div style={{ minHeight: 'calc(100vh - 120px)' }}>
        <TemplateDialog
          title="Save as template"
          open={openTemplateDialog}
          save={onSaveTemplate}
          handleClose={() => {
            setOpenTemplateDialog(false);
          }}
        />
        {loading && <LinearProgress />}
        <ErrorToast errorMessage={apiError} />
        <ErrorToast errorMessage={triggerError} />
        <SuccessToast successMessage={poperSuccessMessage} />

        <div style={{ background: '#F7F9FD' }}>
          <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
            <Grid style={{ padding: '16px 100px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => window.history.back()}>
                  <LeftArrowBlack style={{ fontSize: '20px', paddingRight: '20px' }} />
                </div>
                <div className={classes.templateHeadingDiv}>
                  <Typography className={classes.templateHeading}>Template Library</Typography>
                </div>
              </div>
              <div style={{ padding: '10px 0px' }} />
              <div
                style={{ padding: '10px 0px', display: 'flex', justifyContent: 'space-between' }}
              >
                <TearSheetSearchBar {...tearSheetSearchBarProps} />
              </div>
            </Grid>
          </div>
        </div>

        {templateId === null && (
          <Grid style={{ padding: '16px 100px' }}>
            <div className={classes.emptyTemplate}>
              <Typography className={classes.emptyTemplateTxt}>
                Kindly select a template to generate report.
              </Typography>
            </div>
          </Grid>
        )}
        {templateId !== null && templateData && (
          <div style={{ background: '#F7F9FD' }}>
            <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
              <Grid style={{ padding: '16px 100px' }}>
                <div className={classes.tearSheetDiv}>
                  <TearSheetTemplate
                    template={templateData}
                    initialTemplate={initialTemplateData}
                    setTemplate={setTemplateData}
                    handleSaveTemplate={handleSaveTemplate}
                    handleDiscardTemplate={handleDiscardTemplate}
                    handleDeleteTemplate={handleDeleteTemplate}
                    validationErrors={templateValidationErrors}
                    setValidationErrors={setTemplateValidationErrors}
                    disabled={useTemplate}
                    handleDuplicate={handleDuplicate}
                    handleUseTemplate={openPopup}
                    handleGenerateReport={handleGenerateReport}
                    savetemplateBtn={savetemplateBtn}
                  />
                </div>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateLibaray;
