import { makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postApi } from '../../../services/token';

const useStyles = makeStyles(() => ({
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  },
  tryButton: {
    width: '50%',
    height: '46px',
    textTransform: 'none'
  }
}));
const url = process.env.REACT_APP_API;
const WaitList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const intializeContactInfo = {
    name: '',
    email: '',
    message: '',
    type: 'waitlist'
  };
  const [contactInfo, setContactInfo] = useState({ ...intializeContactInfo });
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        saveContact();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  });
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const isValid = () => {
    if (!contactInfo.name) {
      setError(t('validation.Name'));
      return true;
    } else if (!contactInfo.email) {
      setError(t('validation.Email'));
      return true;
    } else if (!validateEmail(contactInfo.email)) {
      setError(t('validation.InvalidEmail'));
      return true;
    }
    return false;
  };

  const saveContact = async () => {
    if (isValid()) return;
    setLoading(true);
    setError('');
    try {
      const getUser = `${url}/contactus`;
      await postApi(getUser, contactInfo);
      setSuccessMessage(true);
    } catch (err) {
      setError(t('waitList.apiFail'));
    }
    setLoading(false);
  };

  return (
    <Box width="100%">
      {successMessage ? (
        <Box width="100%">
          <Typography
            style={{
              fontSize: '24px',
              fontFamily: 'Satoshi',
              marginBottom: '10px'
            }}
          >
            <span>{t('waitList.successHeading')}</span>
          </Typography>
          <Typography style={{ fontSize: '14px' }}>{t('waitList.successStatus')}</Typography>
          <br />
          <br />
        </Box>
      ) : (
        <>
          <Box width="100%">
            <Typography
              style={{
                fontSize: '24px',
                fontFamily: 'Satoshi',
                marginBottom: '2px'
              }}
            >
              <span>{t('waitList.heading')}</span>
            </Typography>
            <Typography style={{ fontSize: '14px' }}>{t('waitList.heading1')}</Typography>
          </Box>
          <br />
          <Box width="100%" style={{ fontFamily: 'Satoshi' }}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('waitList.Name')}
              value={contactInfo.name}
              onChange={(e) => {
                setError('');
                setContactInfo({ ...contactInfo, name: e.target.value });
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                },
                style: {
                  fontFamily: 'Satoshi'
                }
              }}
              InputLabelProps={{
                style: {
                  color: '#08070d',
                  opacity: 0.8
                }
              }}
            />
          </Box>
          <br />
          <Box width="100%">
            <TextField
              fullWidth
              variant="outlined"
              label={t('waitList.emailID')}
              value={contactInfo.email}
              onChange={(e) => {
                setError('');
                setContactInfo({ ...contactInfo, email: e.target.value });
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                },
                style: {
                  fontFamily: 'Satoshi'
                }
              }}
              InputLabelProps={{
                style: {
                  color: '#08070d',
                  opacity: 0.8
                }
              }}
            />
          </Box>
          <br />
          <br />
          <Box width="100%">
            <Button
              fullWidth
              disabled={loading}
              color="primary"
              variant="contained"
              className={classes.tryButton}
              onClick={saveContact}
              style={{
                background: loading
                  ? '#cccccc'
                  : 'transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box'
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#FFFFFF',
                  textTransform: 'none'
                }}
              >
                {t('waitList.btn')}
              </Typography>
            </Button>
          </Box>
          {error && (
            <Box marginTop="-10px">
              <br />
              <br />
              <Box
                style={{
                  background: '#FDF6F6',
                  borderRadius: '5px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <ErrorOutline
                  style={{
                    marginLeft: '13px', // Set margin left to 13px
                    marginRight: '5px', // Set margin right to 1px
                    color: '#EB5B55' // Set color of the icon to #3C962A
                  }}
                />
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#EB5B55',
                    marginRight: '15px',
                    fontFamily: 'Satoshi'
                  }}
                >
                  {error}
                </Typography>
              </Box>
            </Box>
          )}
          {loading && (
            <>
              <br />
              <LinearProgress />
            </>
          )}
          <br />
          <br />
        </>
      )}
    </Box>
  );
};

export default WaitList;
