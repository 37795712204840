import React, { useRef, useEffect } from 'react';
import ChipComponent from '../../../../atoms/chipComponent';
import useStyles from '../styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { Grid, useMediaQuery } from '@mui/material';
import Paper from '@material-ui/core/Paper';
import { LinearProgress } from '@mui/material';
import UserComponent from './userComponent';
// import BotComponent from './botComponent';
import BotComponent from './botComponent';
import FollowUpComponent from './followUp';
import Disclaimer from '../../../askBot/disclaimer';
import { setChatScrollDE, setChatSearchInputDE } from '../../../action';

const Chat = ({ handleQuestionClick }) => {
  const classes = useStyles();
  const allMessages = useSelector((state) => state.tokenReducer.allMessagesDE);
  const loading = useSelector((state) => state.tokenReducer.loadingDE);
  const chatScroll = useSelector((state) => state.tokenReducer.chatScrollDE);
  const deTags = useSelector((state) => state.tokenReducer.deTags);
  const selectedDocExp = useSelector((state) => state.tokenReducer.selectedDocExp);
  const title = selectedDocExp && selectedDocExp.length > 0 ? '' : 'Please select document.';
  console.log(chatScroll);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileLandscape = useMediaQuery('(max-width: 900px) and (orientation:landscape)');
  const messagesEndRef = useRef(null);
  const prevScrollTop = useRef(0);
  const dispatch = useDispatch();

  const handleScroll = () => {
    if (messagesEndRef.current) {
      const scrollTop = messagesEndRef.current.scrollTop;

      const clientHeight = messagesEndRef.current.clientHeight;
      const scrollHeight = messagesEndRef.current.scrollHeight;

      const bufferHeight = Math.round(clientHeight * 0.1);
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - bufferHeight;
      if (scrollTop > prevScrollTop.current && isScrolledToBottom) {
        dispatch(setChatScrollDE(true));
      } else if (scrollTop < prevScrollTop.current) {
        dispatch(setChatScrollDE(false));
      }
      prevScrollTop.current = scrollTop;
    }
  };
  console.log(handleScroll);
  const autoScroll = () => {
    // if (messagesEndRef.current && chatScroll) {
    //   messagesEndRef.current.scrollTo({
    //     top: messagesEndRef.current.scrollHeight,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    autoScroll();
  }, [allMessages]);

  const handleCardQuestionClick = (event, question) => {
    event.preventDefault();
    dispatch(setChatSearchInputDE(question));
    handleQuestionClick(event, question);
  };

  const suggestedQues = [
    'What are the key challenges and opportunities facing the Indian healthcare sector, and how can technology play a role in addressing them?',
    'How does the Indian footwear retail market differ from other major markets, and what are the major drivers of its growth?',
    'How are the changing dynamics of consumer behaviour, the rise of digital channels, and sustainability considerations impacting the global luxury goods market?'
  ];

  const isDocIds = selectedDocExp && selectedDocExp.length > 0;
  return (
    <>
      <div className={classes.chatContainer} ref={messagesEndRef}>
        <div className={classes.chatMain}>
          <div>
            <div className={classes.headingText}>Help me create</div>
            <div className={classes.flexRow}>
              <ChipComponent
                title={title}
                label="Summary"
                onClick={(e) => {
                  handleQuestionClick(e, 'Summary');
                }}
                className={classes.chipLabel}
                disabled={!isDocIds}
              />
              <ChipComponent
                title={title}
                label="Key Insights"
                onClick={(e) => {
                  handleQuestionClick(e, 'Key Insights');
                }}
                className={classes.chipLabel}
                disabled={!isDocIds}
              />
            </div>
          </div>
          <div>
            {deTags && deTags.length > 0 && <div className={classes.headingText}>Tags</div>}
            <div className={classes.flexRow}>
              {deTags &&
                deTags.length > 0 &&
                deTags.map((x, i) => {
                  return (
                    <ChipComponent
                      key={i}
                      label={x}
                      onClick={() => {}}
                      className={`${classes.chipLabel} ${classes.ovverrideChipLbl}`}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        {allMessages.length === 0 ? (
          <div>
            <FollowUpComponent
              title={title}
              messageData={suggestedQues}
              matches={matches}
              handleCardQuestionClick={handleCardQuestionClick}
              disabled={!isDocIds}
            />
          </div>
        ) : (
          <Grid id="askBotItemContianerMain">
            <div id="askBotItemContianer">
              {/* <Grid item xs={0} md={0.3} id="askBotEmpty" /> */}
              <div id="askBotChildMainItem" style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container item xs={12} id="askBotChildItem" md={11}>
                  <Grid
                    container
                    sx={{
                      height: '100%',
                      paddingTop: matches ? '0px' : '20px'
                    }}
                    id="askBotChildItemContainer"
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      style={{ padding: '5px 0px 5px 0px' }}
                    ></Grid>
                    {allMessages.length === 0 ? (
                      <>
                        <Grid
                          container
                          id="askBotMainMessageContainer"
                          style={{
                            paddingTop: matches ? '0px' : '5px',
                            overflowY: 'auto',
                            height: isMobileLandscape
                              ? 'calc(100vh - 107px)'
                              : 'calc(100vh - 360px)'
                          }}
                        ></Grid>
                      </>
                    ) : (
                      <>
                        <Paper
                          id="askBotMainPaper"
                          elevation={0}
                          style={{
                            width: '100%',
                            height: isMobileLandscape
                              ? 'calc(100vh - 161px)'
                              : 'calc(100vh - 383px)'
                          }}
                        >
                          <div
                            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                            id="askBotMainMessageEndRef"
                          >
                            <div
                              id="askBotChildMessageEndRef"
                              // ref={messagesEndRef}
                              // onScroll={() => { }} // handleScroll
                              // style={{ flex: 1, overflowY: 'auto', paddingTop: '20px' }}
                            >
                              {Object.keys(allMessages).map(
                                (key, answer, follow_up_q, disclaimer) => {
                                  if (allMessages[key].creditsExpired) {
                                    return (
                                      <div key={key}>
                                        <UserComponent key={key} messageData={allMessages[key]} />
                                        {/* <CreditsExpiredComponent
                                      key={key}
                                      messageData={allMessages[key]}
                                    /> */}
                                      </div>
                                    );
                                  } else if (
                                    (allMessages[key].follow_up_q &&
                                      allMessages[key].follow_up_q.length > 0) ||
                                    allMessages[key].disclaimer
                                  ) {
                                    return (
                                      <div className={classes.mtBt} key={key}>
                                        <UserComponent key={key} messageData={allMessages[key]} />
                                        <BotComponent
                                          key={answer}
                                          messageData={allMessages[key]}
                                          showMore={false}
                                          isDEChat={true}
                                        />
                                        <Disclaimer
                                          key={disclaimer}
                                          messageData={allMessages[key]}
                                          matches={matches}
                                        />
                                        <FollowUpComponent
                                          key={follow_up_q}
                                          messageData={allMessages[key]?.follow_up_q}
                                          matches={matches}
                                          handleCardQuestionClick={handleCardQuestionClick}
                                        />
                                      </div>
                                    );
                                  } else if (allMessages[key].answer) {
                                    return (
                                      <>
                                        <UserComponent key={key} messageData={allMessages[key]} />
                                        <BotComponent
                                          key={answer}
                                          messageData={allMessages[key]}
                                          showMore={false}
                                        />
                                      </>
                                    );
                                  } else {
                                    return (
                                      <UserComponent key={key} messageData={allMessages[key]} />
                                    );
                                  }
                                }
                              )}
                              {loading && (
                                <>
                                  <LinearProgress />
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                        </Paper>
                      </>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Grid item xs={0} md={0.3} id="askbotGridEmptyChild" />
            </div>
          </Grid>
        )}
      </div>
      <div className={classes.pdBt10}>
        <span className={classes.helpingText}>
          *Answers are for informational purposes only and are not financial advice or opinions.
          Please conduct your own due diligence before making any investment decisions.
        </span>
      </div>
    </>
  );
};

export default Chat;
