import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import ContactSupportComponent from './contactSupportComponent';

const useStyles = makeStyles({
  helpCenterContentMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '22px !important',
    fontWeight: '700 !important',
    paddingBottom: '16px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  }
});

function MyWatchlistHelpCenter() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentMainTxt}>My Watchlist</Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          My watchlist lets you seamlessly craft numerous watchlists tailored to your preferences
          and helps you curate diverse combinations of companies spanning various funding stages,
          industries, inception years, geographical origins, or simply your favorites.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Let's explore this feature.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            controls
            style={{
              maxWidth: '60%',
              maxHeight: '100%',
              width: '60%',
              height: 'auto'
            }}
          >
            <source
              src="https://public.privateblok.ai/help_center/watchlist.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </Grid>
      <ContactSupportComponent />
    </Grid>
  );
}

export default MyWatchlistHelpCenter;
