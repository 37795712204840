import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as DeleteBinIcon } from '../../../components/icons/DeleteBinIcon.svg';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  iconPd: {
    padding: '6px 5px 0 0'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}));

export default function EditDeleteMenu({ currentWatchList }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    navigate(`/watchlist-company?action=edit&watchlist-id=${currentWatchList.watchlist_id}`);
  };

  const handleDelete = () => {
    navigate(`/watchlist-company?action=delete&watchlist-id=${currentWatchList.watchlist_id}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src="/images/icons/3dots.svg" alt="Menu" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={handleEdit}>
          <span className={classes.iconPd}>
            <img src="/images/icons/edit.svg" alt="Edit" />
          </span>
          <span>{t('watchlist.editName')}</span>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <span className={classes.iconPd}>
            <DeleteBinIcon className={classes.cursorPointer} />
          </span>
          <span>{t('list.delete')}</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
