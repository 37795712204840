import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, TextField, makeStyles } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../components/icons/SearchIcon.svg';

// for single select
// const [selectedItems, setSelectedItems] = useState(null);

// for multi select
// const [selectedItems, setSelectedItems] = useState([]);

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      // height: '10px !important',
      fontFamily: 'Satoshi !important'
    }
  },
  searchIcon: {
    marginRight: '4px',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  optionTypo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '4px 0px'
  }
}));

const AutocompleteComponent = ({
  data,
  hasMore,
  setInputValue,
  setPage,
  selectedItems,
  setSelectedItems,
  property = { key: 'key', value: 'value', type: 'entity_type' },
  isSingleSelect = false,
  searchIcon = true,
  label = 'Search Companies',
  placeholder = 'Type to search',
  disabled = false
}) => {
  const classes = useStyles();
  const { key, value, type } = property;
  const [open, setOpen] = useState(false);

  const handleOptionToggle = (e, option) => {
    if (isSingleSelect) {
      setSelectedItems({ [key]: option[key], [value]: option[value], [type]: option[type] });
      setOpen(false);
    } else {
      const currentIndex = selectedItems.findIndex((item) => item[key] === option[key]);
      let newSelectedItems = [];

      if (currentIndex === -1) {
        newSelectedItems = [
          ...selectedItems,
          { [key]: option[key], [value]: option[value], [type]: option[type] }
        ];
      } else {
        newSelectedItems = selectedItems.filter((item, index) => index !== currentIndex);
      }

      setSelectedItems(newSelectedItems);
    }
  };

  const handleScroll = (event) => {
    const target = event.currentTarget;
    if (target.scrollTop + target.clientHeight > target.scrollHeight - 100 && hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple={!isSingleSelect}
      className={classes.root}
      options={data}
      getOptionLabel={(option) => option[value] || ''}
      value={selectedItems}
      disabled={disabled}
      onChange={(event, newValue) => {
        if (isSingleSelect) {
          setSelectedItems(newValue);
        } else {
          setSelectedItems(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: searchIcon && (
              <>
                <SearchIcon className={classes.searchIcon} />
                {params.InputProps.startAdornment}
              </>
            )
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputLabel
            }
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          onClick={(event) => handleOptionToggle(event, option)}
          style={{ backgroundColor: selected ? '#fafafa' : 'transparent' }}
        >
          {!isSingleSelect && (
            <Checkbox checked={selected} style={{ color: '#004CFF', marginRight: 8 }} />
          )}
          {option[value]}
        </li>
      )}
      onInputChange={(event, newValue) => setInputValue(newValue)}
      isOptionEqualToValue={(option, value) => option[key] === value[key]}
      ListboxProps={{
        onMouseDown: (event) => {
          event.preventDefault();
        },
        onScroll: handleScroll
      }}
    />
  );
};

export default AutocompleteComponent;
