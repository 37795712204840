const LoadingDots = () => {
  return (
    <div className="loading-dots-container" id="loadingDotsMain">
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
    </div>
  );
};

export default LoadingDots;
