import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'none',
    padding: '6px 8px',
    backgroundColor: '#F7F9FD',
    border: '1px solid #FE4D4D',
    '&:disabled': {
      background: '#cccccc',
      '& svg': {
        color: '#ffffff'
      },
      '& p': {
        color: '#ffffff'
      }
    }
  },
  startIcon: {
    marginRight: '-4px',
    fontSize: '17px !important',
    color: '#FE4D4D',
    '&:disabled': {
      color: '#666666'
    }
  },
  typo: {
    fontSize: '14px',
    color: '#FE4D4D'
  }
}));

const DeleteButton = ({ handleClick, StartIcon, btnText, disabled }) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      className={classes.root}
      disabled={disabled}
      startIcon={StartIcon ? <StartIcon className={classes.startIcon} /> : null}
    >
      <Typography className={classes.typo}>{btnText}</Typography>
    </Button>
  );
};

export default DeleteButton;
