import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { useValidUsername } from '../../hooks/useAuthHooks';
import { Password, Username } from './authComponents';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './contexts/authContext';
import PropTypes from 'prop-types';

import { useValidPassword } from '../../hooks/useAuthHooks';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { setUuID, setCurrentStepNumber, setShowTourGuide } from '../shared/action';
import { LinearProgress } from '@mui/material';
import { getApi, postApi } from '../../services/token';
import { redirectMainToSubDomain } from '../utils/subDomainUtil';

const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': { cursor: 'pointer' },
    color: '#004CFF'
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  pd: {
    [theme.breakpoints.up('sm')]: {
      padding: '66px'
    },
    [theme.breakpoints.down('sm')]: {}
  },
  signinGrid: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start'
    }
  },
  signinPaper: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  disclaimerButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    fontSize: '13px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px'
    }
  },
  signUpButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px'
    }
  }
}));

const SignIn = ({ prevRoute, isDialog }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const url = process.env.REACT_APP_API;

  const [userVerified, setUserVerified] = useState(true);

  const { username, setUsername, usernameIsValid } = useValidUsername('');

  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const [error, setError] = useState('');
  const [signinLoading, setSigninLoading] = useState(false);
  const storeCurrentStep = useSelector((state) => state.tokenReducer.currentStep);
  const storeShowTourGuide = useSelector((state) => state.tokenReducer.showTourGuide);

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  // const isValid = !usernameIsValid || !passwordIsValid;
  const isValid = !usernameIsValid;

  useEffect(() => {
    if (state?.message) setMessage(state.message);
  }, [state]);

  useEffect(() => {
    if (storeCurrentStep === 1 && storeShowTourGuide && authContext.authStatus === 2) {
      navigate('/home');
    }
  }, [storeCurrentStep, storeShowTourGuide, navigate, prevRoute]);

  const checkWhiteLabel = async () => {
    try {
      const res = await getApi(`${url}/v1/user/user_white_label_details`);
      const labelDetails = res.data.data;
      await redirectMainToSubDomain(labelDetails.sub_domain);
    } catch (err) {
      console.log(err);
    }
  };

  const helpCenterCheckPass = async () => {
    try {
      postApi(`${url}/v1/user/user_initial_onboarding_flag`, {
        first_time_user_flag: false
      });
    } catch (err) {
      console.log(err);
    }
  };

  const signInClicked = async () => {
    setSigninLoading(true);
    setError(null);
    try {
      await authContext.signInWithEmail(username, password);
      const sessionId = uuidv4();
      dispatch(setUuID(sessionId));
      await checkWhiteLabel();
      const respose = await getApi(`${url}/v1/user/user_initial_onboarding_flag`);

      if (respose.data.data === true) {
        dispatch(setCurrentStepNumber(1));
        dispatch(setShowTourGuide(true));
        await helpCenterCheckPass();
      } else {
        prevRoute = prevRoute || '/home';
        if (prevRoute === '/home') {
          prevRoute = '/dashboard';
        }
        navigate(prevRoute);
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setUserVerified(false);
      } else {
        if (err.code === 'NotAuthorizedException') {
          setError(t('signIn.NotAuthorizedException'));
        } else if (err.code === 'InvalidParameterException') {
          setError(t('signIn.InvalidParameterException'));
        }
      }
    }
    setSigninLoading(false);
  };

  const passwordResetClicked = async () => {
    navigate('/requestcode');
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmlMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const paperWidth = isDesktop ? '60%' : '100%';
  const gridHeight = isDesktop
    ? 'calc(100vh - 121px)'
    : isSmlMobile
    ? 'calc(100vh - 155px)'
    : 'calc(100vh - 121px)';

  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        const btn = document.getElementById(isValid ? 'verifyBtn' : 'signInPgBtn');
        btn.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [userVerified]);

  const signin = (
    <>
      {/* Title */}
      {message ? (
        <Box
          marginY={1}
          style={{
            background: '#C7F1BF',
            borderRadius: '5px',
            opacity: 1,
            width: '100%',
            height: '40px',
            display: 'flex', // Set display to flex
            alignItems: 'center' // Vertically center align the content
          }}
        >
          <CheckCircleOutlineIcon
            style={{
              marginLeft: '13px', // Set margin left to 13px
              marginRight: '2px', // Set margin right to 1px
              color: '#3C962A' // Set color of the icon to #3C962A
            }}
          />
          <Typography
            style={{
              fontSize: '14px',
              color: '#206113',
              opacity: 1,
              fontFamily: 'Satoshi',
              fontWeight: 'normal'
            }}
          >
            {message}
          </Typography>
        </Box>
      ) : null}
      <Box width="100%">
        <Typography
          style={{ fontSize: '24px', lineHeight: '70px', letterSpacing: '0px', color: '#08070D' }}
        >
          {t('signIn.signInHeading')}
        </Typography>
      </Box>
      {/* Sign In Form */}
      {/* render Username and Password components here */}
      <Box width="100%" marginY={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box width="100%" marginY={1}>
        <Password
          label={t('signIn.pwdLabel')}
          password={password}
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
          validate={false}
        />
      </Box>
      <Box width="100%">
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Box onClick={passwordResetClicked} mt={2}>
            <Typography className={classes.hover} variant="body2">
              {t('signIn.forgotPwd')}
            </Typography>
          </Box>
        </Grid>
      </Box>
      {/* Buttons */}
      <br />
      <Button
        id="signInPgBtn"
        color="primary"
        variant="contained"
        style={{
          width: '100%',
          background: signinLoading ? '#CCCCCC' : 'linear-gradient(90deg,#004CFF,#06BCC1)',
          height: '50px'
        }}
        onClick={signInClicked}
        disabled={signinLoading}
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 'medium',
            color: '#FFFFFF',
            textTransform: 'none'
          }}
        >
          {t('signIn.signInBtn')}
        </Typography>
      </Button>
      {signinLoading && (
        <>
          <br />
          <LinearProgress style={{ width: '100%' }} />
        </>
      )}
      {error && (
        <>
          <br />
          <Box
            m={1}
            style={{
              background: '#FDF6F6',
              borderRadius: '5px',
              height: '40px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ErrorOutlineIcon
              style={{
                marginLeft: '13px', // Set margin left to 13px
                marginRight: '5px', // Set margin right to 1px
                color: '#EB5B55' // Set color of the icon to #3C962A
              }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: '#EB5B55',
                marginRight: '15px'
              }}
            >
              {error}
            </Typography>
          </Box>
        </>
      )}
      <Typography
        style={{ color: '#0C0C0C', paddingTop: 20, display: 'flex' }}
        className={classes.disclaimerButton}
      >
        {t('signIn.disclaimer')}{' '}
        <Typography
          style={{
            color: '#004CFF',
            textTransform: 'none',
            fontFamily: 'Satoshi',
            cursor: 'pointer',
            paddingLeft: '3px',
            paddingRight: '3px'
          }}
          className={classes.disclaimerButton}
          onClick={() => {
            navigate('/terms-and-conditions');
            // window.open('https://public.privateblok.ai/policies/terms_of_use.html', '_blank');
          }}
        >
          {t('signIn.disclaimerT&C')}
        </Typography>
        and
        <Typography
          style={{
            color: '#004CFF',
            textTransform: 'none',
            fontFamily: 'Satoshi',
            cursor: 'pointer',
            paddingLeft: '3px',
            paddingRight: '3px'
          }}
          className={classes.disclaimerButton}
          onClick={() => {
            navigate('/privacy');
            // window.open('https://public.privateblok.ai/policies/privacy_policy.html', '_blank');
          }}
        >
          {t('signIn.disclaimerPrivacy')}
        </Typography>
      </Typography>
      {/* display is set to none for as we want to prevent signup */}
      <Typography
        style={{ color: '#0C0C0C', paddingTop: 20, display: 'flex' }}
        className={classes.signUpButton}
      >
        {t('signIn.createAccount')}
        <Typography
          style={{
            color: '#004CFF',
            textTransform: 'none',
            paddingLeft: '5px',
            fontFamily: 'Satoshi',
            cursor: 'pointer'
          }}
          onClick={() => navigate('/signup')}
          className={classes.signUpButton}
        >
          {t('signIn.signUpBtn')}
        </Typography>
      </Typography>
    </>
  );

  const verifyAccount = async () => {
    var email = username;
    navigate('/verify', { state: { email } });
    await authContext.resendCode(email);
  };

  const verifyMessage = t('signIn.verifyMessage');

  const verify = (
    <>
      <Box
        marginY={1}
        style={{
          background: '#FDF6F6',
          borderRadius: '5px',
          width: '100%',
          height: '40px',
          display: 'flex', // Set display to flex
          alignItems: 'center' // Vertically center align the content
        }}
      >
        <ErrorOutlineIcon
          style={{
            marginLeft: '13px', // Set margin left to 13px
            marginRight: '5px', // Set margin right to 1px
            color: '#EB5B55' // Set color of the icon to #3C962A
          }}
        />
        <Typography
          style={{
            fontSize: '14px',
            color: '#EB5B55',
            fontFamily: 'Satoshi'
          }}
        >
          {t('signIn.verifyHeading')}
        </Typography>
      </Box>
      <Box
        marginY={1}
        style={{
          width: '100%',
          display: 'flex', // Set display to flex
          alignItems: 'center' // Vertically center align the content
        }}
      >
        <Typography
          style={{
            fontSize: '16px',
            color: '#0C0C0C',
            marginLeft: '13px'
          }}
        >
          {verifyMessage}
        </Typography>
      </Box>
      <br />
      <Button
        disabled={isValid}
        id="verifyBtn"
        color="primary"
        variant="contained"
        style={{
          width: '100%',
          background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
          height: '40px'
        }}
        onClick={verifyAccount}
      >
        <Typography
          style={{
            fontSize: '16px',
            fontWeight: 'medium',
            color: '#FFFFFF',
            textTransform: 'none'
          }}
        >
          {t('signUp.sendCodeBtn')}
        </Typography>
      </Button>

      <Button
        style={{
          color: '#0C0C0C',
          fontSize: '16px',
          textTransform: 'none',
          paddingTop: '25px',
          fontFamily: 'Satoshi'
        }}
        className={classes.button}
        onClick={() => navigate('/home')}
      >
        {t('signIn.cancelBtn')}
      </Button>
    </>
  );

  return (
    <>
      {isDialog ? (
        <Grid container>
          <div className={classes.pd}>{userVerified ? signin : verify}</div>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          style={{
            display: 'flex'
          }}
        >
          {!isMobile && ( // Conditionally render only on non-mobile screens
            <Grid item lg={5}>
              <Box
                sx={{
                  background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
                  borderRadius: '3px',
                  opacity: '1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <img src="/images/PBLogo_White.svg" alt="PrivateBlok-Logo" className="logo" />
              </Box>
            </Grid>
          )}
          <Grid
            xs={12}
            sm={12}
            lg={7}
            container
            item
            className={classes.signinGrid}
            style={{ flex: '1', minHeight: gridHeight }}
          >
            <Paper elevation={0} className={classes.signinPaper} style={{ width: paperWidth }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                {userVerified ? signin : verify}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SignIn;
SignIn.propTypes = {
  prevRoute: PropTypes.any,
  isDialog: PropTypes.bool
};
