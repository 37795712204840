import React, { useState, useEffect } from 'react';
import AutocompleteComponent from '../../../atoms/multiSearchableAutocomplete';
import { getApi } from '../../../../services/token';
import { useDebounce } from '../../../../hooks/useDebounce';
const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;

const MultiCompanyThemeSearch = ({
  isSingleSelect = false,
  entity_types = 'company',
  onSelectedValue = () => {},
  onApiCall = async () => {},
  label,
  placeholder,
  selectedVal = null,
  disabled = false,
  setIsModified
}) => {
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [selectedItems, setSelectedItems] = useState(isSingleSelect ? null : []);

  useEffect(() => {
    if (isSingleSelect) {
      if (selectedItems?.entity_id !== selectedVal?.entity_id) {
        setIsModified(false);
        setSelectedItems(selectedVal);
      }
    } else {
      if (Array.isArray(selectedVal)) {
        const isEqual =
          selectedItems?.length === selectedVal.length &&
          selectedItems.every((item, index) => item.entity_id === selectedVal[index].entity_id);

        if (!isEqual) {
          setIsModified(false);
          setSelectedItems(selectedVal);
        }
      }
    }
  }, [selectedVal]);

  useEffect(() => {
    if (isSingleSelect) {
      if (selectedItems) {
        onSelectedValue(selectedItems);
      } else {
        onSelectedValue(null);
      }
    } else {
      onSelectedValue(selectedItems && selectedItems.length > 0 ? selectedItems : []);
    }
  }, [selectedItems]);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams({
        entity_types: entity_types,
        page_size: 15,
        page_number: page,
        search_text: debouncedInputValue || ''
      });

      try {
        const response = await getApi(
          `${COMPANY_API_URL}/v1/entity/search?${queryParams.toString()}`
        );
        await onApiCall(debouncedInputValue);
        const newOptions = response.data?.data?.items || [];
        const totalRecords = response.data?.data?.metadata?.total_records;

        if (page === 1) {
          setData(newOptions);
        } else {
          setData((prev) => [...prev, ...newOptions]);
        }

        setHasMore(data.length < totalRecords);
      } catch (error) {
        console.error('Fetching data failed', error);
      }
    };

    fetchData();
  }, [debouncedInputValue, page, COMPANY_API_URL]);

  useEffect(() => {
    if (inputValue !== '') {
      setPage(1);
      setData([]);
    }
  }, [debouncedInputValue]);

  const onSelectedItems = (v) => {
    setIsModified(false);
    setSelectedItems(v);
  };
  return (
    <>
      <AutocompleteComponent
        data={data}
        hasMore={hasMore}
        setInputValue={setInputValue}
        setPage={setPage}
        selectedItems={selectedItems}
        setSelectedItems={onSelectedItems}
        property={{ key: 'entity_id', value: 'entity_name', type: 'entity_type' }}
        isSingleSelect={isSingleSelect}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
      />
    </>
  );
};

export default MultiCompanyThemeSearch;
