import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool
} from 'amazon-cognito-identity-js';

const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`
};

const userPool = new CognitoUserPool(poolData);

let currentUser = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

function getCognitoUser(username) {
  const userData = {
    Username: username,
    Pool: userPool
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  if (currentUser)
    return new Promise(function (resolve, reject) {
      currentUser.getSession(function (err, session) {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    }).catch(() => {
      return null;
    });

  return null;
}

export async function getRefreshedSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  if (currentUser) {
    return new Promise(function (resolve, reject) {
      currentUser.getSession(async function (err, session) {
        if (err) {
          reject(err);
        } else {
          currentUser.refreshSession(session.refreshToken, function (refreshErr, refreshedSession) {
            if (refreshErr) {
              reject(refreshErr);
            } else {
              resolve(refreshedSession);
            }
          });
        }
      });
    }).catch(() => {
      return null;
    });
  }

  return null;
}

export async function signUpUserWithEmail(
  name,
  email,
  password,
  terms,
  mobile_number,
  invite_code,
  trial_agreement_accepted
) {
  var phone_number = mobile_number;

  return new Promise(function (resolve, reject) {
    const attributeList = [];

    const fullnameAttribute = new CognitoUserAttribute({
      Name: 'name',
      Value: name
    });

    const emailAttribute = new CognitoUserAttribute({
      Name: 'email',
      Value: email
    });

    const phoneAttribute = new CognitoUserAttribute({
      Name: 'phone_number',
      Value: phone_number
    });

    const inviteCodeAttribute = new CognitoUserAttribute({
      Name: 'custom:invite_code',
      Value: invite_code
    });

    const dataTermsAndCondition = new CognitoUserAttribute({
      Name: 'custom:terms_accepted_yn',
      Value: String(terms)
    });

    const dateTermsAndCondition = new CognitoUserAttribute({
      Name: 'custom:terms_accepted_date',
      Value: String(new Date())
    });

    const dateTrialAgreement = new CognitoUserAttribute({
      Name: 'custom:trial_agreement_date',
      Value: trial_agreement_accepted ? String(new Date()) : null
    });

    attributeList.push(fullnameAttribute);
    attributeList.push(emailAttribute);
    attributeList.push(phoneAttribute);
    attributeList.push(inviteCodeAttribute);
    attributeList.push(dataTermsAndCondition);
    attributeList.push(dateTermsAndCondition);
    attributeList.push(dateTrialAgreement);
    let username = email;

    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username, code) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function resendConfirmationCode(email) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(email);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        reject(err);
      }
      resolve(result);
    });
  }).catch((err) => {
    throw err;
  });
}

export async function checkVerifiedEmail(email) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(email);
    cognitoUser.getSession(function (err, result) {
      if (err) {
        reject(err);
      }
      resolve(result);
    });
  }).catch((err) => {
    throw err;
  });
}

export function getAttributeVerificationCode(email) {
  try {
    currentUser.getAttributeVerificationCode('email', {
      onSuccess: (success) => {
        console.log('Verification code sent successfully:', success);
        console.log(email);
      },
      onFailure: (err) => {
        console.error('Error sending verification code:', err);
      }
    });
  } catch (err) {
    console.error('Error getting attribute verification code:', err);
  }
}

export async function verifyAttributeCode(attribute, code) {
  return new Promise(function (resolve, reject) {
    currentUser.verifyAttribute(attribute, code, {
      onSuccess: (success) => {
        resolve(success);
      },
      onFailure: (err) => {
        reject(err);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(username, password) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signOut() {
  if (currentUser) {
    await currentUser.signOut();
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err, attributes) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttribute(attribute) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    currentUser.updateAttributes(attributeList, (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(username, code, password) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated');
      },
      onFailure: function (err) {
        reject(err);
      }
    });
  });
}

export async function changePassword(oldPassword, newPassword) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}
