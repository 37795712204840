import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postApi } from '../../../services/token';
import { Box, Button, Dialog, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { CheckCircleOutline, Close, ErrorOutline } from '@material-ui/icons';

const url = process.env.REACT_APP_API;
const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  submitCompayGrid: {
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingTop: '72px',
    paddingBottom: '72px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  },
  requestComanyTest1: {
    fontSize: '36px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '26px'
    }
  },
  requestComanyTest2: {
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  lineBreakBlog: {
    height: '2px',
    background: 'linear-gradient(90deg, #004CFF 0%, #06BCC1 100%)',
    opacity: 1,
    width: '100%'
  },
  rootText: {
    '& .MuiInputBase-input': {
      backgroundColor: '#E9F0FD !important'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  },
  tryButton: {
    width: '160px',
    height: '46px',
    textTransform: 'none',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`
  }
}));
const initialBody = {
  company_name: '',
  company_comments: ''
};
const RequestCompanyPopover = ({ displayPopoverSubmitCopany, setDisplayPopoverSubmitCopany }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorRequestCompany, setErrorRequestCompany] = useState('');
  const [loadingSubmitCompany, setLoadingSubmitCompany] = useState(false);
  //   const [comment, setComment] = useState('');
  //   const [companyNameRequest, setCompanyNameRequest] = useState('');
  const [body, setBody] = useState(JSON.parse(JSON.stringify(initialBody)));
  const submitCompanyRequest = async () => {
    if (!body.company_name) {
      setErrorRequestCompany(t('exploreAllCompanies.requestCompanyNameReq'));
      setSuccessMessage(false);
      return;
    }
    setLoadingSubmitCompany(true);
    try {
      await postApi(`${url}/user/request`, body);
      setBody(JSON.parse(JSON.stringify(initialBody)));
      setSuccessMessage(true);
      setErrorRequestCompany('');
    } catch (err) {
      setErrorRequestCompany(t('exploreAllCompanies.requestCompanyApiFail'));
      setSuccessMessage(false);
    }
    setLoadingSubmitCompany(false);
  };
  //   const handlecommentChange = (event) => {
  //     setComment(event.target.value);
  //     setSuccessMessage(false);
  //     setErrorRequestCompany('');
  //   };
  //   const handleCompanyNameChange = (event) => {
  //     setCompanyNameRequest(event.target.value);
  //     setSuccessMessage(false);
  //     setErrorRequestCompany('');
  //   };
  const handleChange = (key, value) => {
    setSuccessMessage(false);
    setErrorRequestCompany('');
    setBody((prev) => ({
      ...prev,
      [key]: value
    }));
  };
  const handleClose = () => {
    setSuccessMessage(false);
    setDisplayPopoverSubmitCopany(false);
    setErrorRequestCompany('');
    setBody(JSON.parse(JSON.stringify(initialBody)));
  };
  return (
    <Dialog
      open={displayPopoverSubmitCopany}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      classes={{ paper: classes.dialogPaper }}
      fullWidth={true}
      maxWidth="lg"
    >
      <Grid
        style={{
          background: '#FFFFFF'
        }}
        className={classes.submitCompayGrid}
      >
        <Grid container spacing={1}>
          <Grid item sm={12} style={{ height: '25px' }}>
            <div
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                fontSize: '20px',
                margin: 0,
                padding: 0,
                cursor: 'pointer'
              }}
            >
              <Close style={{ fontSize: '34px' }} onClick={handleClose} />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <br />
            <Typography
              style={{
                fontWeight: 400,
                fontFamily: 'Satoshi'
              }}
              className={classes.requestComanyTest1}
            >
              <span
                style={{
                  backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Unable to find a company?
              </span>
            </Typography>
            <br />
            <Typography
              style={{
                fontWeight: 400,
                color: '#171717',
                fontFamily: 'Satoshi',
                textAlign: 'left'
              }}
              className={classes.requestComanyTest2}
            >
              Please fill out this form and we’ll look into it.
            </Typography>
            <br />
            <div className={classes.lineBreakBlog}></div>
          </Grid>
          <Grid item sm={12} md={6}>
            {successMessage ? (
              <Box
                style={{
                  background: '#C7F1BF',
                  borderRadius: '5px',
                  opacity: 1,
                  width: '100%',
                  height: '40px',
                  display: 'flex', // Set display to flex
                  alignItems: 'center', // Vertically center align the content
                  marginBottom: '10px'
                }}
              >
                <CheckCircleOutline
                  style={{
                    marginLeft: '13px', // Set margin left to 13px
                    marginRight: '2px', // Set margin right to 1px
                    color: '#3C962A' // Set color of the icon to #3C962A
                  }}
                />
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#206113',
                    opacity: 1,
                    fontFamily: 'Satoshi',
                    fontWeight: 'normal'
                  }}
                >
                  {t('contactus.successStatus')}
                </Typography>
              </Box>
            ) : null}
            <Box width="100%" marginY={1}>
              <TextField
                fullWidth
                variant="outlined"
                label="Company Name"
                value={body.company_name}
                className={classes.rootText}
                onChange={(e) => handleChange('company_name', e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#08070d',
                    opacity: 0.8
                  }
                }}
              />
            </Box>
            <Box width="100%" marginY={1}>
              <TextField
                type="text"
                multiline
                onChange={(e) => handleChange('company_comments', e.target.value)}
                rows={5}
                value={body.company_comments}
                label="Any Comments you may have"
                variant="outlined"
                fullWidth
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#08070d',
                    opacity: 0.8
                  }
                }}
              />
            </Box>
            <Box width="100%" marginY={1}>
              <Button
                color="primary"
                variant="contained"
                className={classes.tryButton}
                disabled={loadingSubmitCompany}
                style={{
                  background: loadingSubmitCompany ? '#ccc' : ''
                }}
                onClick={() => {
                  submitCompanyRequest();
                }}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#FFFFFF',
                    textTransform: 'none'
                  }}
                >
                  {t('homepage.Submit')}
                </Typography>
              </Button>
            </Box>
            {errorRequestCompany && (
              <Box
                style={{
                  background: '#FDF6F6',
                  borderRadius: '5px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '15px'
                }}
              >
                <ErrorOutline
                  style={{
                    marginLeft: '13px', // Set margin left to 13px
                    marginRight: '5px', // Set margin right to 1px
                    color: '#EB5B55' // Set color of the icon to #3C962A
                  }}
                />
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#EB5B55',
                    marginRight: '15px'
                  }}
                >
                  {errorRequestCompany}
                </Typography>
              </Box>
            )}
            {loadingSubmitCompany && <LinearProgress />}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default RequestCompanyPopover;
