export const tagTypes = [
  {
    key: 'company',
    displayKey: 'Companies',
    hasTagsCell: true
  },
  // {
  //   key: 'sector',
  //   displayKey: 'Sector',
  //   hasTagsCell: true
  // },
  {
    key: 'themes',
    displayKey: 'Theme',
    hasTagsCell: true
  },
  {
    key: 'miscellaneous',
    displayKey: 'Miscellaneous'
  }
];
