import React from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import ReactLinkify from 'react-linkify';
// import { Link } from 'react-router-dom';
import Table from '../table';

const AccordianwithNestedTable = ({ title, tableAccordianData, classes, truncateStr }) => {
  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
          {' '}
          {decoratedText}
        </a>
      )}
    >
      <div style={{ paddingTop: '15px' }}>
        <Accordion
          defaultExpanded={true}
          style={{
            backgroundColor: '#FAFAFA',
            borderRadius: '8px',
            border: '1px solid #E8E8E8'
          }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<SeeMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{ height: '56px', minHeight: '56px', display: 'flex' }}
          >
            <Typography className={`${classes.fontSize14} ${classes.fontWeight500}`}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Table config={{ isChild: true, tableAccordianData, classes, truncateStr }} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </ReactLinkify>
  );
};

export default AccordianwithNestedTable;
