export const questionLib = [
  {
    question: 'Give me description of the company.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Give me the valuation details of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Valuation'
  },
  {
    question: 'Give me the annual revenue details of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Annual Revenue'
  },
  {
    question: 'Tell me about business model of the company in detail.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Tell me about product description or product portfolio of the company.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Tell me about various investment stages of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Round Construct'
  },
  {
    question: 'Tell me about founders of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Key People'
  },
  {
    question: 'Tell me about competitors of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Competitors'
  },
  {
    question: 'Give me risks associated with the company in terms of business and investments.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Tell me about traction for business model of company.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Give me the consolidated profit and loss statement for the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Profit and Loss'
  },
  {
    question: 'Give me the total funding raised by the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Total Funding Raised'
  }
];
export const questionTypeToDisplay = {
  companies: 'Company',
  people: 'People',
  sector: 'Sector',
  screener: 'Screener',
  fund: 'Fund'
};
export const questionTypes = [
  {
    key: 'companies',
    value: 'Company'
  },
  {
    key: 'people',
    value: 'People'
  },
  {
    key: 'sector',
    value: 'Theme'
  },
  {
    key: 'screener',
    value: 'Screener'
  },
  {
    key: 'fund',
    value: 'Fund'
  }
];
export const defaultQuestion = {
  question: '',
  type: 'query',
  question_type: '',
  static_field: ''
};

export const defaultSection = {
  name: '',
  questions: [defaultQuestion]
};

export const createTemplate = {
  name: '',
  entity_list: [],
  question_groups: [defaultSection]
};

export const templateActions = [
  { key: 'new', value: 'Create New' },
  { key: 'existing', value: 'Edit Existing' }
];

export const validationErrors = {
  name: '',
  entity_list: 'Entity list cannot be empty.',
  question_groups: [
    {
      index: 0,
      name: '',
      questions: [
        {
          index: 0,
          question: 'Question cant be empty'
        },
        {
          index: 1,
          question_type: 'Question Type cannot be empty'
        }
      ]
    }
  ]
};
