import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  padding: {
    paddingLeft: '36px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5px'
    }
  }
}));

function Disclaimer({ messageData }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.padding} id="disclaimerMain">
        {messageData.disclaimer && (
          <>
            <Typography
              id="disclaimerMainTypo"
              style={{
                paddingLeft: '0px',
                fontSize: '14px',
                color: '#000000'
              }}
              variant="body1"
            >
              <i>{messageData.disclaimer}</i>
            </Typography>
          </>
        )}
      </div>
      <br />
    </>
  );
}

Disclaimer.propTypes = {
  messageData: PropTypes.any
};
export default Disclaimer;
