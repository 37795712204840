import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setCompanyDetails } from './action';
import { useDispatch } from 'react-redux';
// import { json } from 'react-router-dom';
// import { useEffect } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box sx={{ p: 3 }}>
        <Box sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
          <Typography
            style={{
              fontFamily: 'Satoshi',
              fontWeight: 'bold'
            }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function BasicTabs(props) {
  const { config } = props;
  const { tabs, tabPanels } = config;
  const companyDetails = useSelector((state) => state.tokenReducer.companyDetails);
  const dispatch = useDispatch();
  // const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    // setValue(newValue);
    let updatedCompanyDetails = JSON.parse(JSON.stringify(companyDetails));
    const existingIndex = updatedCompanyDetails.findIndex((obj) => obj.active === true);
    updatedCompanyDetails[existingIndex].activeTab = newValue;
    dispatch(setCompanyDetails([...updatedCompanyDetails]));
  };
  const activeTab =
    companyDetails && companyDetails.length > 0
      ? companyDetails.find((x) => x.active === true).activeTab
      : null;
  return (
    <Box sx={{ width: '100%' }} id="tabBoxMain">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} id="tabBoxMainCh">
        <Tabs
          id={`tabBoxMainChTabs-${activeTab}`}
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs &&
            tabs.map((x, i) => {
              return (
                <Tab
                  id={`tabBoxMainChTab-${activeTab}`}
                  key={i}
                  label={x}
                  {...a11yProps(i)}
                  onChange={handleChange}
                  style={{
                    textTransform: 'none',
                    fontFamily: 'Satoshi',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                />
              );
            })}
        </Tabs>
      </Box>
      {tabPanels &&
        tabPanels.map((X, i) => {
          return (
            <TabPanel
              key={i}
              value={activeTab}
              index={i}
              id={`tabBoxMainChTabPanel-${activeTab}-${i}`}
            >
              <X config={{ ...config }} index={i} />
            </TabPanel>
          );
        })}
    </Box>
  );
}

BasicTabs.propTypes = {
  config: PropTypes.object.isRequired
};

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};
