export const formatedTime = (dateFormat, withTime = false) => {
  if (!dateFormat) return;

  let backendDateUTC;
  let userDate;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Checking if the input date format is in "DD Month(long) Year" format
  if (/\d{1,2} \w+ \d{4}/.test(dateFormat)) {
    const [day, month, year] = dateFormat.split(' ');
    backendDateUTC = new Date(Date.UTC(year, getMonthNumber(month), day));
    const options = {
      timeZone: userTimeZone,
      month: 'short',
      year: 'numeric',
      day: '2-digit'
    };
    userDate = new Intl.DateTimeFormat('en-US', options).format(backendDateUTC);
  } else {
    // two formats handeled her "2024-02-26 12:36:50" and "2024:02:26, 12:36:50"
    const [datePart, timePart] = dateFormat.split(/,?\s+/);
    const [year, month, day] = datePart.split(/[-:]/);
    const [hours, minutes] = timePart.split(':');
    backendDateUTC = new Date(Date.UTC(year, month - 1, day, hours, minutes));
    let options = {
      timeZone: userTimeZone,
      month: 'short',
      year: 'numeric',
      day: '2-digit'
    };

    if (withTime) {
      options = {
        ...options,
        hour: 'numeric',
        minute: 'numeric'
      };
    }
    userDate = new Intl.DateTimeFormat('en-US', options).format(backendDateUTC);
  }

  // Adding suffix to the day part
  // const dayWithSuffix = addDaySuffix(backendDateUTC.getUTCDate());
  // const formattedDate = userDate.replace(/\b\d+\b/, dayWithSuffix);

  return userDate;
};

function getMonthNumber(month) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months.indexOf(month);
}

// function addDaySuffix(day) {
//   if (day >= 11 && day <= 13) {
//     return day + 'th';
//   }
//   switch (day % 10) {
//     case 1:
//       return day + 'st';
//     case 2:
//       return day + 'nd';
//     case 3:
//       return day + 'rd';
//     default:
//       return day + 'th';
//   }
// }

export const currentTime = (withTime = true) => {
  const currentDate = new Date();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let options = {
    timeZone: userTimeZone,
    month: 'short',
    year: 'numeric',
    day: '2-digit'
  };

  if (withTime) {
    options = {
      ...options,
      hour: 'numeric',
      minute: 'numeric'
    };
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
  return formattedDate;
};
