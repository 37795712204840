import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

function Row(props) {
  const { row, classes } = props;
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', paddingTop: '0px' } }}>
        {row &&
          Object.keys(row).length > 0 &&
          Object.keys(row)
            .filter((x) => typeof row[x] != 'object')
            .map((x, i) => {
              if (i == 0)
                return (
                  <TableCell
                    style={{
                      borderBottom: 'none',
                      paddingLeft: '0px',
                      paddingRight: '12px',
                      paddingBottom: '0px',
                      verticalAlign: 'top'
                    }}
                    title={row[x]}
                    className={classes.fontSize12}
                    key={i}
                  >
                    <Typography
                      style={{ fontFamily: 'Satoshi', fontSize: '12px', fontWeight: 600 }}
                    >
                      {row[x]}
                    </Typography>
                  </TableCell>
                );

              return (
                <TableCell
                  style={{
                    borderBottom: 'none',
                    paddingLeft: '0px',
                    paddingRight: '12px',
                    paddingBottom: '0px',
                    verticalAlign: 'top'
                  }}
                  title={row[x]}
                  className={classes.fontSize12}
                  key={i}
                >
                  <Typography style={{ fontFamily: 'Satoshi', fontSize: '12px' }}>
                    {row[x]}
                  </Typography>
                </TableCell>
              );
            })}
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ config }) {
  const { tableAccordianData, classes } = config;
  const data =
    tableAccordianData &&
    Object.keys(tableAccordianData).length > 0 &&
    Object.keys(tableAccordianData).map((x) => {
      return {
        column_name: x,
        value: tableAccordianData[x]
      };
    });
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      style={{
        borderRadius: '8px',
        backgroundColor: '#FAFAFA'
      }}
    >
      <Table aria-label="collapsible table">
        <TableBody>
          {data.length > 0 && data.map((row, i) => <Row key={i} row={row} classes={classes} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  config: PropTypes.any
};
