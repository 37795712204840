import { makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import { getDisplayTextFromMap, getNestedValue } from '../utils/utils';
import { entityTypeToDisplayMap } from '../../constants/tearSheet/tearSheet';

const useStyles = makeStyles(() => ({
  entityNameTypo: {
    color: '#004CFF',
    fontSize: '12px',
    fontWeight: 500
  },
  entityTypeTypo: {
    fontSize: '10px',
    fontWeight: 500,
    color: '#767676'
  }
}));

const EntitiesChipLabel = ({ entity }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.entityNameTypo}>
        {getNestedValue(entity, 'entity_name')}
      </Typography>
      <Typography className={classes.entityTypeTypo}>
        {getDisplayTextFromMap(entity, 'entity_type', entityTypeToDisplayMap)}
      </Typography>
    </Box>
  );
};

export default EntitiesChipLabel;
