import React, { useEffect, useState, useContext } from 'react';
import { TextField, Button, makeStyles, Typography, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { postApi } from '../../services/token';
import './addCompany.css';
import { Container } from '@material-ui/core';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useCommonStyles } from '../../common/globalCss';
import MenuItem from '@mui/material/MenuItem';
import { AuthContext } from '../auth/contexts/authContext';
const companyUrl = process.env.REACT_APP_COMPANY_API;
const MAX_FILE_SIZE = 10485760;

const useStyles = makeStyles((theme) => ({
  spanTickSvg: {
    '& svg': {
      height: '100%',
      width: '90%',
      color: '#004CFF'
    }
  },
  dashboardBtn: {
    'padding-left': '24px',
    'padding-right': '24px'
  },
  eastIcon: {
    top: '3px',
    left: '4px',
    position: 'relative'
  },
  centreAlign: {
    'align-items': 'center',
    'justify-content': 'center',
    display: 'flex'
  },
  successDiv: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '43px'
    }
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {}
  },
  formMain: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  heading: {
    'font-size': '32px'
  },
  icon: {
    background: '#EFF3FC',
    '& img': {
      left: '22%',
      height: '50%',
      width: '50%',
      position: 'relative',
      top: '23%'
    }
  },
  iconDiv: {
    background: '#EFF3FC',
    display: 'flex',
    '& img': {
      left: '7%',
      padding: '8%',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        width: '6%'
      },
      [theme.breakpoints.down('sm')]: {
        left: '2%',
        height: '50%',
        width: '9%'
      }
    },
    '& p': {
      padding: '7%',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        top: '27%',
        position: 'relative'
      }
    }
  },
  flex: {
    display: 'flex',
    width: '100%'
  },
  textarea: {
    resize: 'both'
  },
  submitTokens: {
    width: '100%',
    textTransform: 'none',
    background:
      'transparent linear-gradient(90deg, #06BCC1 0%, #004CFF 100%) 0% 0% no-repeat padding-box',
    color: '#FFFFFF'
  },
  spanSubmit: {
    'padding-left': '31px',
    'padding-right': '31px',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('sm')]: {}
  },
  spanDashboard: {
    '& svg': {
      width: '27px',
      height: '20px',
      color: '#FFFFFF'
    }
  },
  btnCancel: {
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      top: '9px'
    }
  },
  desktopLabelBackGround: {
    background: '#F7F9FD',
    padding: '2px',
    [theme.breakpoints.up('sm')]: {
      width: '41.9%'
    }
  },
  placeholderFontSize: {
    [theme.breakpoints.down('sm')]: {
      '& input::placeholder': {
        fontSize: '10px'
      }
    }
  },
  relationSelect: {
    'border-color': '#E3EBFD !important'
  },
  fileCss: {
    padding: '18.5px 14px',
    'border-width': '1px',
    'border-radius': '3px',
    'border-color': '#E3EBFD!important',
    border: '1px solid',
    width: '94%'
  }
}));
function SubmitToken() {
  const navigate = useNavigate();
  if (process.env.REACT_APP_ENABLE_COMPANY_DOCUMENT_UPLOAD === 'false') {
    useEffect(() => {
      navigate('/home');
    }, []);

    return <></>;
  }

  let auth = useContext(AuthContext);
  const [files, setFiles] = useState(null);
  const [errorFile, setFileError] = useState('');
  const [currentUserEmail, setCurrentUSerEmail] = useState('');
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onBlur'
  });
  const { errors } = formState;
  const { t } = useTranslation();

  const [successToken, setSuccessToken] = useState(false);

  const postToken = async (event) => {
    setFileError('');
    let data = new FormData();
    data.append('file', files);
    data.append('cin', event.companyCIN);
    data.append('company_name', event.companyName);
    data.append('phone_number', event.phNumber);
    data.append('company_relation', event.relation);
    data.append('description', event.description);

    if (event.alternativeEmail) data.append('alternative_email', event.alternativeEmail);

    const postTokenURL = `${companyUrl}/company/documents`;
    const resp = await postApi(postTokenURL, data);
    if (resp.status === 200) {
      reset({
        companyCIN: '',
        companyName: '',
        phNumber: '',
        relation: '',
        description: '',
        alternativeEmail: ''
      });
      setFiles(null);
      window.scroll(0, 0);
      setSuccessToken(true);
      setTimeout(() => {
        setSuccessToken(false);
      }, 5000);
    }
  };

  function handleTokenSubmit(event) {
    setSuccessToken(false);
    postToken(event);
  }

  useEffect(() => {
    async function sessionInfo() {
      const session = await auth.getSession();
      const email = session?.idToken?.payload?.email;
      if (email) setCurrentUSerEmail(email);
    }
    sessionInfo();
  }, []);

  const uploadFile = (e) => {
    const currentFiles = e.target.files;
    if (currentFiles && currentFiles.length > 0) {
      const inValidFile = Array.from(Array(currentFiles.length).keys()).findIndex((x) => {
        return MAX_FILE_SIZE <= currentFiles[x].size;
      });
      if (inValidFile > -1) {
        setFileError('Each File must be less than 10 MB.');
        e.target.value = null;
      } else {
        setFileError('');
        setFiles(currentFiles[0]);
      }
    }
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(handleTokenSubmit)}>
          {successToken ? (
            <Box
              style={{
                background: '#C7F1BF',
                borderRadius: '5px',
                opacity: 1,
                width: '100%',
                height: '40px',
                display: 'flex', // Set display to flex
                alignItems: 'center' // Vertically center align the content
              }}
            >
              <CheckCircleOutlineIcon
                style={{
                  marginLeft: '13px', // Set margin left to 13px
                  marginRight: '2px', // Set margin right to 1px
                  color: '#3C962A' // Set color of the icon to #3C962A
                }}
              />
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#206113',
                  opacity: 1,
                  fontFamily: 'Satoshi',
                  fontWeight: 'normal'
                }}
              >
                {t('contactus.successStatus')}
              </Typography>
            </Box>
          ) : null}
          <Grid container spacing={2} className={classes.container}>
            <Grid item sm={10} xs={9}>
              <Typography className={classes.heading}>{t('addCompany.CompanyLabel')}</Typography>
            </Grid>
            <Grid item sm={2} xs={3}>
              <Button
                variant="text"
                className={classes.btnCancel}
                onClick={() => {
                  navigate('home');
                }}
              >
                {t('externalFilter.cancel')}
              </Button>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography className={classes.desktopLabelBackGround}>
                {t('addCompany.Companydetails')}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Grid item sm={5}>
                <TextField
                  type="text"
                  label={t('addCompany.CompanyName') + ' *'}
                  variant="outlined"
                  {...register('companyName', { required: true })}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: commonClasses.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8
                    }
                  }}
                />
                {errors.companyName && (
                  <span className="error-message">{t('validation.required')}</span>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.formMain}>
              <Grid item sm={5} xs={12}>
                <TextField
                  type="text"
                  label={t('addCompany.CompanyCIN')}
                  variant="outlined"
                  {...register('companyCIN', { required: true })}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: commonClasses.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8
                    }
                  }}
                />
                {errors.companyCIN && (
                  <span className="error-message">{t('validation.required')}</span>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.formMain}>
              <Grid item sm={5} xs={12}>
                <TextField
                  disabled
                  type="text"
                  label={t('addCompany.EmailOfUser')}
                  variant="outlined"
                  value={currentUserEmail}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: commonClasses.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formMain}>
              <Grid item sm={5} xs={12}>
                <TextField
                  type="email"
                  label={t('addCompany.AlternativeEmail')}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: commonClasses.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8
                    }
                  }}
                />
                {errors.alternativeEmail && errors?.alternativeEmail?.message && (
                  <span className="error-message">{errors?.alternativeEmail?.message}</span>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.formMain}>
              <Grid item sm={5} xs={12}>
                <TextField
                  type="number"
                  label={t('addCompany.phNumber')}
                  variant="outlined"
                  {...register('phNumber', {
                    required: true,
                    validate: {
                      maxLength: (v) =>
                        v.length <= 10 || 'The phone number can have 10 digits only',
                      matchPattern: (v) => /^[0-9]{10}$/.test(v) || 'Invalid phone number'
                    }
                  })}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: commonClasses.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8
                    }
                  }}
                />
                {errors.phNumber && errors.phNumber.type === 'required' && (
                  <span className="error-message">{t('validation.required')}</span>
                )}
                {errors?.phNumber?.message && (
                  <span className="error-message">{errors?.phNumber?.message}</span>
                )}
              </Grid>
            </Grid>
            {/* <Grid container className={classes.formMain}>
              <Grid item sm={5} xs={12}>
                <TextField
                  type="text"
                  label={t('addCompany.Type')}
                  variant="outlined"
                  {...register('type', { required: true })}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: commonClasses.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8
                    }
                  }}
                />
                {errors.type && <span className="error-message">{t('validation.required')}</span>}
              </Grid>
            </Grid> */}
            <Grid container className={classes.formMain}>
              <Grid item sm={5} xs={12}>
                <TextField
                  classname={classes.root}
                  {...register('relation', { required: true })}
                  select
                  label={t('addCompany.RelationToCompany')}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: commonClasses.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8
                    }
                  }}
                >
                  <MenuItem value="">
                    <em>none</em>
                  </MenuItem>
                  <MenuItem value={'Employee'}>Employee</MenuItem>
                  <MenuItem value={'Investor'}>Investor</MenuItem>
                  <MenuItem value={'Founder'}>Founder</MenuItem>
                  <MenuItem value={'Share holder'}>Share holder</MenuItem>
                </TextField>
                {errors.relation && (
                  <span className="error-message">{t('validation.required')}</span>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.formMain}>
              <Grid item sm={5} xs={12}>
                <input type="file" onChange={uploadFile} multiple className={classes.fileCss} />
                {errorFile && <span className="error-message">{errorFile}</span>}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={5} xs={12}>
              <TextField
                type="text"
                multiline
                rows={3}
                label={t('addCompany.AboutRelation')}
                variant="outlined"
                inputProps={{ className: classes.textarea }}
                {...register('description', { required: true })}
                fullWidth
                InputProps={{
                  classes: {
                    notchedOutline: commonClasses.notchedOutline
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#08070d',
                    opacity: 0.8
                  }
                }}
              />
              {errors.description && (
                <span className="error-message">{t('validation.required')}</span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item sm={5} xs={12}>
              <Button className={classes.submitTokens} type="submit">
                <span className={classes.spanSubmit}>{t('button.submit')}</span>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
export default SubmitToken;
