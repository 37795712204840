import React, { useState, useEffect, useContext } from 'react';
import * as cognito from '../libs/cognito';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { setUuID } from '../../shared/action';

export const AuthStatus = {
  Loading: 1,
  SignedIn: 2,
  SignedOut: 3
};

export const IAuth = {
  sessionInfo: {
    username: '',
    email: '',
    sub: '',
    accessToken: '',
    refreshToken: ''
  },
  attrInfo: null,
  authStatus: AuthStatus,
  signInWithEmail: null,
  signUpWithEmail: null,
  signOut: null,
  verifyCode: null,
  getSession: null,
  sendCode: null,
  forgotPassword: null,
  changePassword: null,
  getAttributes: null,
  setAttribute: null,
  resendCode: null
};

const defaultState = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);
  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

AuthIsSignedIn.propTypes = {
  children: PropTypes.any
};

export const AuthIsNotSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};
AuthIsNotSignedIn.propTypes = {
  children: PropTypes.any
};

const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);
  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token
        });
        await setAttribute({
          Name: 'website',
          Value: window.location.origin
        });
        const attr = await getAttributes();
        setAttrInfo(attr);
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);
  if (authStatus === AuthStatus.Loading) {
    return null;
  }
  async function signInWithEmail(username, password) {
    try {
      await cognito.signInWithEmail(username, password);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function resendCode(email) {
    await cognito.resendConfirmationCode(email);
  }

  async function signUpWithEmail(
    name,
    email,
    password,
    terms,
    gender,
    mobile_number,
    invite_code,
    trial_agreement_accepted
  ) {
    var phone_number = mobile_number;
    await cognito.signUpUserWithEmail(
      name,
      email,
      password,
      terms,
      gender,
      phone_number,
      invite_code,
      trial_agreement_accepted
    );
  }
  const dispatch = useDispatch();
  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
    dispatch(setUuID(null));
  }

  async function verifyCode(username, code) {
    await cognito.verifyCode(username, code);
  }

  async function verifyAttributeCode(attribute, code) {
    await cognito.verifyAttributeCode(attribute, code);
  }

  async function getSession() {
    const session = await cognito.getSession();
    return session;
  }

  async function getRefreshedSession() {
    const session = await cognito.getRefreshedSession();
    return session;
  }

  async function getAttributes() {
    const attr = await cognito.getAttributes();
    return attr;
  }

  async function setAttribute(attr) {
    const res = await cognito.setAttribute(attr);
    return res;
  }

  async function sendCode(username) {
    await cognito.sendCode(username);
  }

  async function forgotPassword(username, code, password) {
    await cognito.forgotPassword(username, code, password);
  }

  async function changePassword(oldPassword, newPassword) {
    await cognito.changePassword(oldPassword, newPassword);
  }

  async function resendConfirmationCode(email) {
    await cognito.resendConfirmationCode(email);
  }

  async function getAttributeVerificationCode(emailVal) {
    await cognito.getAttributeVerificationCode(emailVal);
  }

  const state = {
    authStatus,
    sessionInfo,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    resendCode,
    resendConfirmationCode,
    getAttributeVerificationCode,
    verifyAttributeCode,
    getRefreshedSession
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
AuthProvider.propTypes = {
  children: PropTypes.any
};
export default AuthProvider;
