import React, { useState, useEffect, useContext } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { postApi } from '../../../services/token';
import { Card, CardActionArea, CardContent } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';
import { ReactComponent as RightArrow } from '../../../components/icons/RightArrow.svg';
import { ReactComponent as LeftArrow } from '../../../components/icons/LeftArrow.svg';
import './homePage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setHomeTabs, isShowCompanyPage } from '../action';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { setCompanyMenuClickedStatus, setIsFirstTimeUser } from '../action';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Marquee from 'react-fast-marquee';
import { IconButton } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AuthContext } from '../../auth/contexts/authContext';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent } from '@mui/material';
import FooterMenu from '../../footer';

const useStyles = makeStyles((theme) => ({
  heading2fontsize: {
    fontWeight: 500,
    color: '#171717',
    lineHeight: '36px',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px' // Adjust the font size for screens up to 'sm' (600px)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '20px' // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px' // Adjust the font size for screens above 'md' (960px)
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0px'
    }
  },
  companiesText: {
    textAlign: 'center',
    fontSize: '22px',
    fontWeight: 700,
    paddingLeft: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px', // Adjust the font size for screens up to 'sm' (600px)
      paddingLeft: '10px'
    }
  },
  gird5StyleH2: {
    fontSize: '22px',
    fontWeight: 700,
    // color: '#171717',
    color: '#373737',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px' // Adjust the font size for screens up to 'sm' (600px)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '30px' // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
    }
  },
  gird2item: {
    fontSize: '28px',
    fontWeight: 500,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px' // Adjust the font size for screens up to 'sm' (600px)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '20px'
    }
  },
  gird5Para: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#5E5E5E',
    paddingTop: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      paddingTop: '12px',
      paddingLeft: '0px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingTop: '12px',
      paddingLeft: '0px',
      fontSize: '18px'
    }
  },
  exploreCompanies: {
    marginTop: '10px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
      zIndex: -1,
      transition: '200ms'
    }
  },
  blogBtn: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#E9F0FD',
      zIndex: -1,
      transition: '200ms'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  },
  blogBtnTxt: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#08070D',
    whiteSpace: 'nowrap'
  },
  blogHeading: {
    fontSize: '34px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      fontWeight: 'bold'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px'
    }
  },
  blogPara: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  profileImage: {
    width: '219.436px',
    height: '263px',
    flexShrink: 0,
    borderRadius: '50px',
    boxShadow: '20px 30px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '2px',
      right: '2px',
      bottom: '2px',
      left: '2px',
      borderRadius: '50px',
      background: 'linear-gradient(to right, #06BCC1, #004CFF)',
      zIndex: -1,
      transition: '200ms'
    }
  },
  name: {
    paddingLeft: '52px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingTop: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px',
      paddingTop: '20px'
    }
  },
  gird12StyleH2: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px', // Adjust the font size for screens up to 'sm' (600px)
      paddingTop: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '16px', // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
      paddingTop: '10px'
    }
  },
  gird12StyleH3: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px', // Adjust the font size for screens up to 'sm' (600px)
      paddingTop: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '16px', // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
      paddingTop: '10px'
    }
  },
  arrowIcon: {
    fontSize: '2rem',
    cursor: 'pointer',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'black',
    margin: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.5s ease'
  },
  pd10Bottom: {
    paddingBottom: '10px!important'
  },
  pd5: {
    padding: '5px'
  },
  buttonLink: {
    'font-family': 'Satoshi',
    'font-size': '16px',
    'font-style': 'normal',
    'font-weight': '500',
    padding: '0!important',
    'text-transform': 'none',
    '& span': {
      'align-items': 'start',
      'justify-content': 'start'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  typographyCls: {
    'font-family': 'Satoshi',
    'font-size': '22px',
    'font-style': 'normal',
    'font-weight': '700',
    'line-height': '28px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  heading: {
    color: '#171717',
    fontSize: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  highlightedText: {
    'font-size': '40px',
    'font-family': 'Satoshi',
    'font-style': 'normal',
    'font-weight': 400,
    'line-height': 'normal',
    'padding-top': '23px'
  },
  pdTop: {
    padding: '10px',
    paddingLeft: '0px'
  },
  descText: {
    color: '#171717',
    fontSize: '20px',
    fontFamily: 'Satoshi',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  },
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: '#E9F0FD !important'
    }
  },
  multilineInput: {
    '& .MuiOutlinedInput-multiline': {
      backgroundColor: '#E9F0FD !important'
    }
  },
  betaLogo: {
    fontSize: '17px',
    color: '#06B5BA',
    fontFamily: 'Satoshi',
    marginLeft: '2px',
    fontWeight: 500,
    marginTop: '-7px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-4px',
      fontSize: '14px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  hompePgGradientText: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'normal'
    }
  },
  logo: {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'auto'
    },
    opacity: 1
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E3EBFD',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: '08'
    }
  },
  displayColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  displayFlex: {
    display: 'flex'
  },
  firstDivImgIcon: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center'
  },
  demoBtn: {
    height: '35px',
    width: '200px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    margin: '0px 16px 0px 8px',
    padding: '0px 14px'
  },
  mainContact: {
    [theme.breakpoints.up('md')]: {
      background:
        'linear-gradient(to right, rgba(0, 76, 255, 0.20) 0%,  rgba(6, 188, 193, 0.20) 100%)',
      padding: '42px 0'
    }
  },
  contactSection: {
    [theme.breakpoints.up('md')]: {
      background: 'none!important'
    }
  },
  contactForm: {
    [theme.breakpoints.up('md')]: {
      padding: '56px',
      background: '#ffffff',
      'border-radius': '20px'
    }
  }
}));

function HomePageCopy() {
  const url = process.env.REACT_APP_API;
  const companyUrl = process.env.REACT_APP_COMPANY_API;
  const classes = useStyles();
  const [company, setCompany] = useState([]);
  const [companyMarquee, setCompanyMarquee] = useState([]);
  const [helpPopover, setHelpPopover] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [loadingSaveContact, setLoadingSaveContact] = useState(false);
  const isCompanyMenuClicked = useSelector((state) => state.tokenReducer.isCompanyMenuClicked);
  const intializeContactInfo = {
    name: '',
    email: '',
    message: '',
    companyName: '',
    jobTitle: '',
    companyRole: null,
    phoneNumber: '+91',
    type: 'contact_us',
    country: 'in'
  };
  const [contactInfo, setContactInfo] = useState({ ...intializeContactInfo });
  const [numOfCompanies, setNumOfCompanies] = useState(null);
  const formatNumOfComp = (num) => {
    if (!num) return null;
    return Math.floor(num / 100) * 100;
  };
  const getCompanyData = async () => {
    let listCompanyUrl = authStatus === 3 ? 'v1/company/all' : 'v1/companies';
    const companyListData = await postApi(`${companyUrl}/${listCompanyUrl}`, {
      page_number: 1,
      page_limit: parseInt(process.env.REACT_APP_HOMEPAGE_LIST_COMPANIES_PAGE_SIZE, 10)
    });
    if (companyListData && companyListData.data) {
      const data = companyListData.data.data;
      setCompanyMarquee(data?.company_list);
      let compCnt = data?.total_count;
      setNumOfCompanies(formatNumOfComp(compCnt));
      const parsedData = companyListData.data.data.company_list.slice(0, 15);
      setCompany(parsedData);
    }
  };

  const scrollDownToContact = () => {
    const ele = document.getElementById('contactSection');
    window.scrollTo({ top: ele.offsetTop, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isCompanyMenuClicked) {
      dispatch(setCompanyMenuClickedStatus(false));
      setTimeout(() => {
        scrollDownToContact();
      }, 1000);
    }
  }, [isCompanyMenuClicked]);

  const profiles = [
    {
      pictureUrl: '',
      name1: 'Amanda',
      name2: 'Maxwell',
      designation1: 'Buying & Control',
      designation2: 'Agent at Figma'
    },
    {
      pictureUrl: '',
      name1: 'Amanda2',
      name2: 'Maxwell2',
      designation1: 'Buying & Control2',
      designation2: 'Agent at Figma2'
    },
    {
      pictureUrl: '',
      name1: 'Amanda3',
      name2: 'Maxwell3',
      designation1: 'Buying & Control3',
      designation2: 'Agent at Figma3'
    },
    {
      pictureUrl: '',
      name1: 'Amanda4',
      name2: 'Maxwell4',
      designation1: 'Buying & Control4',
      designation2: 'Agent at Figma4'
    },
    {
      pictureUrl: '',
      name1: 'Amanda5',
      name2: 'Maxwell5',
      designation1: 'Buying & Control5',
      designation2: 'Agent at Figma5'
    }
  ];

  const companyRole = [
    { field: 'Angel Investor' },
    { field: 'Venture Capital' },
    { field: 'Wealth Manager/ Financial Advisor' },
    { field: 'Syndicate' },
    { field: 'Private Equity Firm' },
    { field: 'Family office' },
    { field: 'Others' }
  ];

  useEffect(() => {
    getCompanyData();
  }, []);

  const handleCompanyClick = (cin, name) => {
    if (matches) dispatch(isShowCompanyPage('/home'));

    // dispatch(setHomeTabs(t('tab.AskBot')));
    // eslint-disable-next-line no-unused-vars
    const body = {
      cin: cin,
      name: name
    };
    // dispatch(setCompanyApiContext(body));

    dispatch(setHomeTabs(t('tab.ExploreAllCompanies')));
    navigate('/explore-all-companies');
  };

  const handlePhoneNumberChange = (value) => {
    const formattedPhoneNumber = `+${value}`;
    setContactInfo({ ...contactInfo, phoneNumber: formattedPhoneNumber });
  };

  const { t } = useTranslation();
  const [currentProfile, setCurrentProfile] = useState(0);

  const handlePrevProfile = () => {
    setCurrentProfile((prevProfile) => (prevProfile === 0 ? profiles.length - 1 : prevProfile - 1));
  };

  const handleNextProfile = () => {
    setCurrentProfile((prevProfile) => (prevProfile === profiles.length - 1 ? 0 : prevProfile + 1));
  };

  const handleCircleClick = (index) => {
    setCurrentProfile(index);
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhoneNo = (phoneNumber) => {
    if (
      (phoneNumber.startsWith('+91') && phoneNumber.length === 13) ||
      (phoneNumber.startsWith('+1') && phoneNumber.length === 12)
    ) {
      return true;
    } else if (!phoneNumber.startsWith('+91') && !phoneNumber.startsWith('+1')) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = () => {
    if (!contactInfo.name) {
      setError(t('validation.Name'));
      return true;
    } else if (!contactInfo.email) {
      setError(t('validation.Email'));
      return true;
    } else if (!validateEmail(contactInfo.email)) {
      setError(t('validation.InvalidEmail'));
      return true;
    } else if (!contactInfo.companyRole) {
      setError(t('validation.companyRole'));
      return true;
    } else if (!contactInfo.phoneNumber) {
      setError(t('validation.phoneNumberErrMessage'));
      return true;
    } else if (!validatePhoneNo(contactInfo.phoneNumber)) {
      setError(t('validation.phoneNumberInvalid'));
      return true;
    }
    return false;
  };

  const saveContact = async () => {
    if (isValid()) return;

    setError('');
    setLoadingSaveContact(true);
    try {
      const getUser = `${url}/contactus`;
      await postApi(getUser, contactInfo);
      setContactInfo({ ...intializeContactInfo });
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 5000);
    } catch (err) {
      setError(t('contactus.apiFail'));
    }
    setLoadingSaveContact(false);
  };
  const { authStatus } = useContext(AuthContext);
  const isDesktop = useMediaQuery(theme.breakpoints.up('1200'));
  const isMobile = useMediaQuery(theme.breakpoints.down('600'));
  const isTabForGrid5Img = useMediaQuery(theme.breakpoints.up('1000'));

  const helpCenterCheckPass = async () => {
    try {
      postApi(`${url}/v1/user/user_initial_onboarding_flag`, {
        first_time_user_flag: false
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseHelpPopover = async () => {
    setHelpPopover(false);
  };

  useEffect(() => {
    if (state?.isFirstTimeUser === true) {
      setHelpPopover(false);
      helpCenterCheckPass();
      dispatch(setIsFirstTimeUser(true));
    }
  }, [state]);

  return (
    <>
      <Dialog open={helpPopover} fullWidth={true} maxWidth="sm" onClose={handleCloseHelpPopover}>
        <Box p={0} width="100%">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <div>
              <IconButton
                className={classes.closeBtn}
                onClick={() => {
                  handleCloseHelpPopover();
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
          <DialogContent style={{ paddingTop: '0px' }}>
            <>
              <Box
                style={{
                  borderRadius: '5px',
                  paddingTop: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Typography style={{ fontFamily: 'Satoshi', file_size: '16px', fontWeight: 700 }}>
                  Visit the Help Center to understand how to use PrivateBlok
                </Typography>
                <Typography style={{ fontFamily: 'Satoshi', file_size: '16px', fontWeight: 700 }}>
                  and learn about its features
                </Typography>
                <br />
                <Button
                  type="submit"
                  onClick={() => {
                    navigate('/help-center');
                  }}
                  className={classes.demoBtn}
                >
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#FFFFFF',
                      textTransform: 'none',
                      fontFamily: 'Satoshi'
                    }}
                  >
                    Go to Help Center
                  </Typography>
                </Button>
              </Box>
            </>
          </DialogContent>
        </Box>
      </Dialog>
      <Grid
        style={{
          background: '#FFFFFF',
          display: 'flex',
          justifyContent: 'space-around' /* Center the content horizontally */,
          alignItems: 'center' /* Center the content vertically */,
          maxWidth: '1440px',
          margin: '0 auto' /* Center the grid itself horizontally */
        }}
      >
        <div
          className="home-pg-grid-padding"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            <span
              className="heading-1-fontsize"
              style={{
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              {/* AI Copilot For&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              AI Copilot For
            </span>
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              whiteSpace: 'nowrap'
            }}
          >
            <span
              className="heading-1-fontsize"
              style={{
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Private Markets
              <sup
                className="heading-tm"
                style={{
                  color: '#004CFF'
                }}
              >
                {/* &nbsp;TM */}
                &nbsp;&reg;
              </sup>
            </span>
          </Typography>
        </div>
        {isDesktop && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '675px'
            }}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                maxWidth: '80%',
                maxHeight: '100%',
                pointerEvents: 'none'
              }}
            >
              <source src="https://public.privateblok.ai/assets/homePgVid.mp4" type="video/mp4" />
            </video>
          </div>
        )}
      </Grid>
      {!isDesktop && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              maxWidth: '80%',
              maxHeight: '100%',
              pointerEvents: 'none'
            }}
          >
            <source src="https://public.privateblok.ai/assets/homePgVid.mp4" type="video/mp4" />
          </video>
        </div>
      )}
      <Grid className="gird-2-ticker-style">
        <div
          style={{
            maxWidth: '1440px',
            margin: '0 auto' /* Center the container horizontally */
          }}
        >
          <Marquee pauseOnHover={true}>
            {companyMarquee.map(({ name, cin, images }) => (
              <>
                <Card
                  sx={{
                    maxWidth: '100%',
                    height: '55px',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #F0F0F0',
                    margin: '7.5px',
                    borderRadius: '0.25rem',
                    '&:hover': { backgroundColor: '#EFF3FC' }
                  }}
                  style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                  key={cin}
                  elevation={0}
                  onClick={() => handleCompanyClick(cin, name)}
                >
                  <CardActionArea sx={{ flexGrow: 1, display: 'flex' }}>
                    {images && images.logo ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '50px',
                          height: '50px',
                          paddingLeft: '10px'
                        }}
                      >
                        <img
                          src={images.logo}
                          alt="Logo"
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                          }}
                        />
                      </div>
                    ) : (
                      <BusinessIcon style={{ paddingLeft: '20px' }} />
                    )}
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          color: '#000000B2'
                        }}
                      >
                        {name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </>
            ))}
          </Marquee>
        </div>
      </Grid>
      <Grid
        className="gird-2-padding-style"
        style={{
          background: 'linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box'
        }}
      >
        <div
          style={{
            maxWidth: '1440px',
            margin: '0 auto' /* Center the container horizontally */
          }}
        >
          {/* space around added to remove curated data */}
          <div className="gird-2-style">
            <div className="grid-2-style-ch1">
              <Typography className={classes.gird2item}>
                {/* {numOfCompanies ? `${numOfCompanies} ${t('homepage.plusComp')}` : ''}  Hardcoding no of companies for now */}
                {process.env.REACT_APP_NO_COMPANY_VALUE
                  ? `${process.env.REACT_APP_NO_COMPANY_VALUE}${t('homepage.plusComp')}`
                  : numOfCompanies
                  ? `${numOfCompanies}${t('homepage.plusComp')}`
                  : ''}
              </Typography>
            </div>
            <div className="grid-2-style-ch3">
              <Typography className={classes.gird2item}>{t('homepage.Prompt')}</Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className="gird-3-style">
        <Typography className={classes.companiesText}>{t('homepage.TopCompanies')}</Typography>
        <Grid container>
          {isMobile ? (
            <div className="horizontal-scroll">
              {company.map(({ name, cin, images }) => (
                <Grid
                  item
                  sm={4}
                  md={3}
                  lg={2.4}
                  key={cin}
                  sx={{
                    flex: '0 0 auto',
                    paddingTop: '16px',
                    paddingBottom: '15px'
                  }}
                >
                  <Card
                    sx={{
                      maxWidth: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      border: '1px solid #F0F0F0',
                      margin: '7.5px',
                      borderRadius: '10px',
                      boxShadow: '0px 2px 4px 0px #0000000D',
                      '&:hover': { backgroundColor: '#EFF3FC' }
                    }}
                    style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                    key={name}
                    elevation={0}
                    onClick={() => handleCompanyClick(cin, name)}
                  >
                    <CardActionArea sx={{ flexGrow: 1, display: 'flex' }}>
                      {images && images.logo ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '55px',
                            height: '55px',
                            paddingLeft: '10px'
                          }}
                        >
                          <img
                            src={images.logo}
                            alt="Logo"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                          />
                        </div>
                      ) : (
                        <BusinessIcon style={{ paddingLeft: '20px' }} />
                      )}
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          style={{
                            fontFamily: 'Satoshi',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            color: '#000000B2'
                          }}
                        >
                          {name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </div>
          ) : (
            company.map(({ name, cin, images }) => (
              <Grid
                item
                sm={4}
                md={3}
                lg={2.4}
                key={cin}
                sx={{
                  flexGrow: 1,
                  paddingTop: '26px'
                }}
              >
                <Card
                  sx={{
                    maxWidth: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #F0F0F0',
                    margin: '7.5px',
                    borderRadius: '10px',
                    boxShadow: '0px 2px 4px 0px #0000000D',
                    '&:hover': { backgroundColor: '#EFF3FC' }
                  }}
                  style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                  key={name}
                  elevation={0}
                  onClick={() => handleCompanyClick(cin, name)}
                >
                  <CardActionArea sx={{ flexGrow: 1, display: 'flex' }}>
                    {images && images.logo ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '55px',
                          height: '55px',
                          paddingLeft: '10px'
                        }}
                      >
                        <img
                          src={images.logo}
                          alt="Logo"
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                          }}
                        />
                      </div>
                    ) : (
                      <BusinessIcon style={{ paddingLeft: '20px' }} />
                    )}
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          color: '#000000B2'
                        }}
                      >
                        {name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
        <div style={{ paddingTop: '20px' }}></div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={classes.exploreCompanies}
            onClick={() => {
              dispatch(setHomeTabs(t('tab.ExploreAllCompanies')));
              navigate('/explore-all-companies');
            }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#ffffff'
              }}
            >
              Explore Companies
            </Typography>
          </Button>
        </div>
      </Grid>
      <Grid
        style={{
          background: '#EFF3FC',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '1440px',
            margin: '0 auto' /* Center the grid itself horizontally */
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
            className="gird-5-style"
          >
            <Typography className={classes.hompePgGradientText}>
              <span
                className="heading-5-fontsize"
                style={{
                  backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Comprehensive and Reliable
              </span>
            </Typography>
            <Typography className={classes.gird5StyleH2}>
              Private Company Information and News
            </Typography>
            <Typography className={classes.gird5Para}>
              Curated data from multiple trusted sources, ensuring you have a holistic view of each
              company you evaluate.
            </Typography>
            <br />
            <br />
          </div>
          {isTabForGrid5Img && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10px',
                paddingBottom: '10px'
              }}
            >
              <img
                src={'/images/homePageImgs/PB-1-03.png'}
                style={{ maxWidth: '50%', height: 'auto' }}
              />
            </div>
          )}
        </div>
        {!isTabForGrid5Img && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10px',
              paddingBottom: '10px'
            }}
          >
            <img
              src={'/images/homePageImgs/PB-1-03.png'}
              style={{ maxWidth: '70%', maxHeight: '300px' }}
            />
          </div>
        )}
      </Grid>
      <Grid
        style={{
          background: '#FFFFFF',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '1440px',
            margin: '0 auto'
          }}
        >
          {isTabForGrid5Img && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10px',
                paddingBottom: '10px'
              }}
            >
              <img
                src={'/images/homePageImgs/PB-HomePgAI.jpeg'}
                style={{ maxWidth: '50%', height: 'auto' }}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
            className="gird-6-style"
          >
            <Typography className={classes.hompePgGradientText}>
              <span
                className="heading-5-fontsize"
                style={{
                  backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Generative AI Powered
              </span>
            </Typography>
            <Typography className={classes.gird5StyleH2}>Dynamic Analysis</Typography>
            <Typography className={classes.gird5Para}>
              Our Al algorithms analyze vast amounts of information, providing you with actionable
              insights that help you make well-informed investment decisions.
            </Typography>
            <br />
            <br />
          </div>
        </div>
        {!isTabForGrid5Img && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10px',
              paddingBottom: '10px'
            }}
          >
            <img
              src={'/images/homePageImgs/PB-HomePgAI.jpeg'}
              style={{ maxWidth: '60%', maxHeight: '300px' }}
            />
          </div>
        )}
      </Grid>
      <Grid
        style={{
          background: '#EFF3FC',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '1440px',
            margin: '0 auto'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
            className="gird-5-style"
          >
            <Typography className={classes.hompePgGradientText}>
              <span
                className="heading-5-fontsize"
                style={{
                  backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Cutting - Edge
              </span>
            </Typography>
            <Typography className={classes.gird5StyleH2}>Chat Interface</Typography>
            <Typography className={classes.gird5Para}>
              Whether you're an investor, entrepreneur, or simply curious, our chat interface offers
              an intuitive and interactive way to explore the inner workings of private companies.
            </Typography>
            <br />
            <br />
          </div>
          {isTabForGrid5Img && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10px',
                paddingBottom: '10px'
              }}
            >
              <img
                src={'/images/homePageImgs/PB-1-02-1.png'}
                // src={'/images/homePageImgs/PB-1-02.png'}
                style={{ maxWidth: '50%', height: 'auto' }}
              />
            </div>
          )}
        </div>
        {!isTabForGrid5Img && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10px',
              paddingBottom: '10px'
            }}
          >
            <img
              // src={'/images/homePageImgs/PB-1-02.png'}
              src={'/images/homePageImgs/PB-1-02-1.png'}
              style={{ maxWidth: '70%', maxHeight: '300px' }}
            />
          </div>
        )}
      </Grid>
      <Grid className="gird-8-style">
        <Grid style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <span className="heading-5-fontsize">{t('homepage.sponsorShips')}</span>
          <Grid>
            <div style={{ paddingTop: '22px' }} className="sponser-imageContainer">
              <img
                src={'/images/homePageImgs/MsHomePg.png'}
                className="sponser-image1"
                alt="Microsoft Home"
              />
              <img
                src={'/images/homePageImgs/AwsHomePg.png'}
                className="sponser-image1"
                alt="AWS Home"
              />
              <img
                src={'/images/homePageImgs/GoogleHomePg.png'}
                className="sponser-image"
                alt="Google Home"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="gird-12-style">
        <Grid
          style={{
            maxWidth: '1440px',
            margin: '0 auto',
            display: 'none'
          }}
        >
          <Grid className="gird-12-style-profile">
            <div className="gird-12-style-profile-1">
              <img
                src={profiles[currentProfile].pictureUrl}
                alt="Profile Image"
                className={classes.profileImage}
              />
              <div className={classes.name}>
                <div>
                  <Typography className={classes.gird12StyleH2}>
                    It's all about getting the right information at the right time
                  </Typography>
                  <Typography className={classes.gird12StyleH2}>
                    And Private Block helps you exactly with that
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.gird12StyleH3}>
                    {profiles[currentProfile].name1} {profiles[currentProfile].name2}
                  </Typography>
                  <Typography className={classes.gird12StyleH2}>
                    {profiles[currentProfile].designation1}
                  </Typography>
                  <Typography className={classes.gird12StyleH2}>
                    {profiles[currentProfile].designation2}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '20px'
              }}
            >
              <LeftArrow onClick={handlePrevProfile} className={classes.arrowIcon} />
              {profiles.map((profile, index) => (
                <div
                  key={index}
                  className={classes.circle}
                  onClick={() => handleCircleClick(index)}
                  style={{
                    backgroundColor: currentProfile === index ? 'white' : 'black'
                  }}
                />
              ))}
              <RightArrow onClick={handleNextProfile} className={classes.arrowIcon} />
            </div>
            <br />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          background: '#E9F0FD',
          display: 'none'
        }}
        className="gird-9-style"
      >
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <div style={{ margin: '0 30px' }}>
            <Typography
              style={{
                fontSize: '20px',
                fontWeight: 500,
                color: '#171717'
              }}
            >
              Blog
            </Typography>
          </div>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              overflowX: 'auto',
              overflowY: 'hidden'
            }}
          >
            <div style={{ margin: '0 20px', paddingBottom: '10px', minWidth: '160px' }}>
              <div className="box">
                <img
                  src={'/images/blogImgs/BlogImg.png'}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    maxWidth: '100%'
                  }}
                />
              </div>
              <br />
              <Typography className={classes.blogHeading}>Optimized Reach</Typography>
              <br />
              <Typography className={classes.blogPara}>
                It's all about getting your message in front of the right audience and creating
                those valuable relationships.
              </Typography>
              <br />
              <br />
              <div className="line-break-blog"></div>
              <br />
              <br />
              <Button className={classes.blogBtn}>
                <Typography className={classes.blogBtnTxt}>Learn more</Typography>
              </Button>
            </div>
            <div style={{ margin: '0 20px', paddingBottom: '10px', minWidth: '160px' }}>
              <div className="box">
                <img
                  src={'/images/blogImgs/BlogImg.png'}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    maxWidth: '100%'
                  }}
                />
              </div>
              <br />
              <Typography className={classes.blogHeading}>Optimized Reach</Typography>
              <br />
              <Typography className={classes.blogPara}>
                It's all about getting your message in front of the right audience and creating
                those valuable relationships.
              </Typography>
              <br />
              <br />
              <div className="line-break-blog"></div>
              <br />
              <br />
              <Button className={classes.blogBtn}>
                <Typography className={classes.blogBtnTxt}>Learn more</Typography>
              </Button>
            </div>
            <div style={{ margin: '0 20px', paddingBottom: '10px', minWidth: '160px' }}>
              <div className="box">
                <img
                  src={'/images/blogImgs/BlogImg.png'}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    maxWidth: '100%'
                  }}
                />
              </div>
              <br />
              <Typography className={classes.blogHeading}>Optimized Reach</Typography>
              <br />
              <Typography className={classes.blogPara}>
                It's all about getting your message in front of the right audience and creating
                those valuable relationships.
              </Typography>
              <br />
              <br />
              <div className="line-break-blog"></div>
              <br />
              <br />
              <Button className={classes.blogBtn}>
                <Typography className={classes.blogBtnTxt}>Learn more</Typography>
              </Button>
            </div>
          </div>
        </div>
      </Grid>
      <div className={classes.mainContact}>
        <Grid
          id="contactSection"
          className={`gird-10-style ${classes.contactSection}`}
          style={{
            background: '#FFFFFF',
            maxWidth: '1440px',
            margin: '0 auto'
          }}
        >
          <Grid container className={classes.contactForm}>
            <Grid item lg={12} md={12} sm={12}>
              <Typography>
                <span
                  className="heading-5-fontsize"
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  {t('homepage.weDLoveTo')}
                </span>
              </Typography>
              <div className={classes.pdTop}>
                <Typography className={classes.descText}>
                  {t('homepage.pleaseFillOutThis')}
                </Typography>
              </div>
              <div className="line-break-blog"></div>
              <br />
              {successMessage ? (
                <>
                  <br />
                  <Box
                    style={{
                      background: '#C7F1BF',
                      borderRadius: '5px',
                      opacity: 1,
                      width: '100%',
                      height: '40px',
                      display: 'flex', // Set display to flex
                      alignItems: 'center' // Vertically center align the content
                    }}
                  >
                    <CheckCircleOutlineIcon
                      style={{
                        marginLeft: '13px', // Set margin left to 13px
                        marginRight: '2px', // Set margin right to 1px
                        color: '#3C962A' // Set color of the icon to #3C962A
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#206113',
                        opacity: 1,
                        fontFamily: 'Satoshi',
                        fontWeight: 'normal'
                      }}
                    >
                      {t('contactus.successStatus')}
                    </Typography>
                  </Box>
                </>
              ) : null}
            </Grid>
            <Grid container lg={12} md={12} sm={12} xs={12} style={{ padding: '0px 6px 0px 6px' }}>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('homepage.Name')}
                    value={contactInfo.name}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, name: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('homepage.emailID')}
                    value={contactInfo.email}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, email: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Company Name"
                    value={contactInfo.companyName}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, companyName: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Job Title"
                    value={contactInfo.jobTitle}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, jobTitle: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <Autocomplete
                    options={companyRole}
                    className={classes.autocomplete}
                    getOptionLabel={(option) => option.field || ''}
                    renderInput={(params) => (
                      <form noValidate>
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={t('homepage.companyRole')}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <>{params.InputProps.startAdornment}</>
                          }}
                          autoComplete="off"
                        />
                      </form>
                    )}
                    onChange={(event, value) =>
                      setContactInfo({ ...contactInfo, companyRole: value })
                    }
                    autoHighlight
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <PhoneInput
                    inputStyle={{
                      height: '56px',
                      width: '100%',
                      color: '#08070d',
                      backgroundColor: '#E9F0FD !important',
                      background: '#E9F0FD'
                    }}
                    value={contactInfo?.phoneNumber}
                    country={contactInfo?.country}
                    countryCodeEditable={false}
                    onChange={handlePhoneNumberChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '6px 12px 6px 12px' }}>
              <Box width="100%">
                <TextField
                  type="text"
                  multiline
                  rows={5}
                  label={t('homepage.Message')}
                  variant="outlined"
                  value={contactInfo.message}
                  className={`${classes.root} ${classes.multilineInput}`}
                  onChange={(e) => {
                    setContactInfo({ ...contactInfo, message: e.target.value });
                  }}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8,
                      padding: '0px !important'
                    }
                  }}
                />
              </Box>
              <Box width="100%">
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      className="tryButton"
                      disabled={loadingSaveContact}
                      style={{ background: loadingSaveContact ? '#ccc' : '' }}
                      onClick={saveContact}
                    >
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: '#FFFFFF',
                          textTransform: 'none'
                        }}
                      >
                        {t('homepage.Submit')}
                      </Typography>
                    </Button>
                  </div>
                  <div>
                    {loadingSaveContact && (
                      <>
                        <LinearProgress />
                      </>
                    )}
                    {error && (
                      <>
                        <Box
                          style={{
                            background: '#FDF6F6',
                            borderRadius: '5px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <ErrorOutlineIcon
                            style={{
                              marginLeft: '13px', // Set margin left to 13px
                              marginRight: '5px', // Set margin right to 1px
                              color: '#EB5B55' // Set color of the icon to #3C962A
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#EB5B55',
                              marginRight: '15px'
                            }}
                          >
                            {error}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <FooterMenu />
    </>
  );
}
export default HomePageCopy;
