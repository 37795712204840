import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IconButton } from '@material-ui/core';
import { ReactComponent as PostQuestion } from '../../icons/PrivateBlokLogoChat.svg';

// import RemainingCreditComponent from './ramianingCredit';

const useStyles = makeStyles((theme) => ({
  timeText: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  padding: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5px'
    }
  },
  answerText: {
    fontSize: '18px',
    paddingLeft: '36.2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      paddingLeft: '5px'
    }
  },
  iconPostQuestion: {
    display: 'flex',
    alignContent: 'center'
  }
}));

function CreditsExpiredComponent({ messageData }) {
  const classes = useStyles();
  //   let alternate = false;

  //   const renderTd = (props) => {
  //     const { children } = props;
  //     alternate = !alternate;
  //     const className = alternate ? classes.alternateCell : '';
  //     return <td className={`${classes.td} ${className}`}>{children}</td>;
  //   };

  return (
    <>
      <div className={classes.padding} id="creditExpireMain">
        <div className={classes.iconPostQuestion}>
          <IconButton
            id="botComponentIconButton"
            style={{ paddingTop: '0px', paddingLeft: '0px', paddingBottom: '0px' }}
          >
            <PostQuestion />
          </IconButton>
          <Typography
            style={{
              color: '#1D1D1D',
              fontWeight: 500,
              lineHeight: '18.9px',
              display: 'flex',
              alignContent: 'center',
              flexWrap: 'wrap'
            }}
            className={classes.timeText}
          >
            {messageData.question_time}
          </Typography>
        </div>
        {/* <RemainingCreditComponent messageData={messageData} /> */}
        <Typography
          style={{
            color: '#1D1D1D',
            fontWeight: 500
          }}
          className={classes.answerText}
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            //   components={{
            //     table: ({ children }) => <table className={classes.table}>{children}</table>,
            //     th: ({ children }) => <th className={classes.th}>{children}</th>,
            //     td: renderTd
            //   }}
          >
            {messageData.creditsExpired}
          </ReactMarkdown>
        </Typography>
      </div>
      <br />
    </>
  );
}

CreditsExpiredComponent.propTypes = {
  messageData: PropTypes.any
};

export default CreditsExpiredComponent;
