import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { postApi } from '../../../services/token';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as HomePage1 } from '../../../components/icons/HomePage/HomePage1.svg';
import { ReactComponent as HomePagePlayIcon } from '../../../components/icons/HomePage/HomePagePlayIcon.svg';
import { ReactComponent as ClockIconGradient } from '../../../components/icons/ClockIconGradient.svg';
import './homePage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { setCompanyMenuClickedStatus, setIsFirstTimeUser } from '../action';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { IconButton, CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent } from '@mui/material';
import FooterMenu from '../../footer';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  heading2fontsize: {
    fontWeight: 500,
    color: '#171717',
    lineHeight: '36px',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px' // Adjust the font size for screens up to 'sm' (600px)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '20px' // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px' // Adjust the font size for screens above 'md' (960px)
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0px'
    }
  },
  companiesText: {
    textAlign: 'center',
    fontSize: '22px',
    fontWeight: 700,
    paddingLeft: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px', // Adjust the font size for screens up to 'sm' (600px)
      paddingLeft: '10px'
    }
  },
  gird5StyleH2: {
    fontSize: '22px',
    fontWeight: 700,
    // color: '#171717',
    color: '#373737',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px' // Adjust the font size for screens up to 'sm' (600px)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '30px' // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
    }
  },
  gird2item: {
    fontSize: '28px',
    fontWeight: 500,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px' // Adjust the font size for screens up to 'sm' (600px)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '20px'
    }
  },
  gird5Para: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#5E5E5E',
    paddingTop: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      paddingTop: '12px',
      paddingLeft: '0px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingTop: '12px',
      paddingLeft: '0px',
      fontSize: '18px'
    }
  },
  exploreCompanies: {
    marginTop: '10px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
      zIndex: -1,
      transition: '200ms'
    }
  },
  blogBtn: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#E9F0FD',
      zIndex: -1,
      transition: '200ms'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  },
  blogBtnTxt: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#08070D',
    whiteSpace: 'nowrap'
  },
  blogHeading: {
    fontSize: '34px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      fontWeight: 'bold'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px'
    }
  },
  blogPara: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  profileImage: {
    width: '219.436px',
    height: '263px',
    flexShrink: 0,
    borderRadius: '50px',
    boxShadow: '20px 30px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '2px',
      right: '2px',
      bottom: '2px',
      left: '2px',
      borderRadius: '50px',
      background: 'linear-gradient(to right, #06BCC1, #004CFF)',
      zIndex: -1,
      transition: '200ms'
    }
  },
  name: {
    paddingLeft: '52px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingTop: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px',
      paddingTop: '20px'
    }
  },
  gird12StyleH2: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px', // Adjust the font size for screens up to 'sm' (600px)
      paddingTop: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '16px', // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
      paddingTop: '10px'
    }
  },
  gird12StyleH3: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px', // Adjust the font size for screens up to 'sm' (600px)
      paddingTop: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '16px', // Adjust the font size for screens between 'sm' (600px) and 'md' (960px)
      paddingTop: '10px'
    }
  },
  arrowIcon: {
    fontSize: '2rem',
    cursor: 'pointer',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'black',
    margin: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.5s ease'
  },
  pd10Bottom: {
    paddingBottom: '10px!important'
  },
  pd5: {
    padding: '5px'
  },
  buttonLink: {
    'font-family': 'Satoshi',
    'font-size': '16px',
    'font-style': 'normal',
    'font-weight': '500',
    padding: '0!important',
    'text-transform': 'none',
    '& span': {
      'align-items': 'start',
      'justify-content': 'start'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  typographyCls: {
    'font-family': 'Satoshi',
    'font-size': '22px',
    'font-style': 'normal',
    'font-weight': '700',
    'line-height': '28px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  heading: {
    color: '#171717',
    fontSize: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  highlightedText: {
    'font-size': '40px',
    'font-family': 'Satoshi',
    'font-style': 'normal',
    'font-weight': 400,
    'line-height': 'normal',
    'padding-top': '23px'
  },
  pdTop: {
    padding: '10px',
    paddingLeft: '0px'
  },
  descText: {
    color: '#171717',
    fontSize: '20px',
    fontFamily: 'Satoshi',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  },
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: '#E9F0FD !important'
    }
  },
  multilineInput: {
    '& .MuiOutlinedInput-multiline': {
      backgroundColor: '#E9F0FD !important'
    }
  },
  betaLogo: {
    fontSize: '17px',
    color: '#06B5BA',
    fontFamily: 'Satoshi',
    marginLeft: '2px',
    fontWeight: 500,
    marginTop: '-7px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-4px',
      fontSize: '14px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  hompePgGradientText: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'normal'
    }
  },
  logo: {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'auto'
    },
    opacity: 1
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E3EBFD',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: '08'
    }
  },
  displayColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  displayFlex: {
    display: 'flex'
  },
  firstDivImgIcon: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center'
  },
  demoBtn: {
    height: '35px',
    width: '200px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    margin: '0px 16px 0px 8px',
    padding: '0px 14px'
  },
  mainContact: {
    [theme.breakpoints.up('md')]: {
      background:
        'linear-gradient(to right, rgba(0, 76, 255, 0.20) 0%,  rgba(6, 188, 193, 0.20) 100%)',
      padding: '42px 0'
    }
  },
  contactSection: {
    [theme.breakpoints.up('md')]: {
      background: 'none!important'
    }
  },
  contactForm: {
    [theme.breakpoints.up('md')]: {
      padding: '56px',
      background: '#ffffff',
      'border-radius': '20px'
    }
  },
  homePgText: {
    fontFamily: 'Satoshi',
    fontWeight: 700,
    fontVariantLigatures: 'none',
    fontSize: '56px !important',
    lineHeight: '70px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '56px !important',
      lineHeight: '70px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '46px !important',
      lineHeight: '60px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px !important',
      lineHeight: '46px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px !important',
      lineHeight: '46px !important'
    }
  },
  // textTransition: {
  //   display: 'inline-block',
  //   transition: 'opacity 1s ease-in-out',
  //   opacity: 1,
  //   animation: 'fadeinout 4s linear forwards'
  // },
  // textFadeOut: {
  //   opacity: 0
  // },
  // textTransition: {
  //   display: 'inline-block',
  //   transition: 'opacity 1s ease-in-out, transform 1s ease-in-out'
  // },
  // textFadeOut: {
  //   opacity: 0,
  //   transform: 'translateY(-20px)' // Slide up when fading out
  // },
  // textEnter: {
  //   opacity: 1,
  //   transform: 'translateY(20px)' // Start from below
  // },
  // textFadeIn: {
  //   opacity: 1,
  //   transform: 'translateY(0)' // Move up into place
  // },
  textTransition: {
    display: 'inline-block',
    transition: 'opacity 1s ease-in-out, transform 1s ease-in-out' // Same duration for both opacity and transform
  },
  textFadeOut: {
    opacity: 0,
    transform: 'translateY(20px)' // Slide up when fading out
  },
  textEnter: {
    opacity: 0,
    transform: 'translateY(20px)' // New word starts below (off-screen)
  },
  textFadeIn: {
    opacity: 1,
    transform: 'translateY(0)' // Move upwards to the normal position
  },
  // textTransition: {
  //   display: 'inline-block',
  //   transition: 'opacity 1s ease-in-out, transform 1s ease-in-out' // Smooth transition for both properties
  // },
  // textFadeOut: {
  //   opacity: 0,
  //   transform: 'translateY(-20px)' // Slide up when fading out
  // },
  // textEnter: {
  //   opacity: 0, // Start as invisible
  //   transform: 'translateY(20px)' // Start below the visible area
  // },
  // textFadeIn: {
  //   opacity: 1, // Fade in
  //   transform: 'translateY(0)' // Move to the original position
  // },
  homePgText1: {
    fontSize: '22px !important',
    lineHeight: '28px !important',
    fontFamily: 'Satoshi',
    fontWeight: 700,
    color: '#171717 !important'
  },
  typographyButton: {
    fontSize: '18px !important',
    lineHeight: 'normal !important',
    fontFamily: 'Satoshi',
    fontWeight: 700,
    color: '#FFFFFF !important'
  },
  typographyButtonDemo: {
    fontSize: '18px !important',
    lineHeight: 'normal !important',
    fontFamily: 'Satoshi',
    fontWeight: 700,
    color: '#000000 !important',
    paddingRight: '20px'
  },
  homePageButton: {
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '10px 32px',
    '&:hover': {
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.18) inset, 6px 0px 8px 0px rgba(6, 182, 196, 0.30), 0px 6px 10px 0px rgba(1, 92, 246, 0.30)'
    }
  },
  borderButtonStyle: {
    position: 'relative',
    minHeight: '44px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  homePageBox: {
    background: 'rgba(255, 255, 255, 0.70)',
    boxShadow: '0px 4px 8px 0px rgba(16, 12, 62, 0.05)',
    borderRadius: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    overflow: 'hidden', // Ensures border radius is applied correctly
    boxSizing: 'border-box' // Makes sure padding and borders are included
  },
  innerBox: {
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    backgroundColor: '#fff' // Optional: Add a background color for the boxes
  },
  homePgBoxText: {
    fontFamily: 'Satoshi',
    fontWeight: 700,
    fontSize: '40px !important',
    lineHeight: '40px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '40px !important',
      lineHeight: '40px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '26px !important',
      lineHeight: '40px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px !important',
      lineHeight: '40px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px !important',
      lineHeight: '40px !important'
    }
  },
  homePgBoxText1: {
    fontFamily: 'Satoshi',
    color: '#373737',
    fontWeight: 700,
    fontVariantLigatures: 'none',
    textAlign: 'center',
    textAlignLast: 'center',
    fontSize: '24px !important',
    lineHeight: '30px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px !important',
      lineHeight: '30px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px !important',
      lineHeight: '21px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      lineHeight: '21px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
      lineHeight: '21px !important'
    }
  },
  homePgBoxText2: {
    fontFamily: 'Satoshi',
    color: '#5E5E5E',
    fontWeight: 500,
    textAlign: 'center',
    textAlignLast: 'center',
    fontSize: '18px !important',
    lineHeight: '20px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
      lineHeight: '28px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      lineHeight: '16px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px !important',
      lineHeight: '16px !important'
    }
  },
  homePgBoxText3: {
    fontFamily: 'Satoshi',
    textAlign: 'center',
    textAlignLast: 'center',
    color: '#171717',
    fontWeight: 700,
    fontSize: '22px !important',
    lineHeight: '30px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '22px !important',
      lineHeight: '30px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
      lineHeight: '21px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      lineHeight: '21px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px !important',
      lineHeight: '21px !important'
    }
  },
  homePgBoxText4: {
    fontFamily: 'Satoshi',
    color: '#5E5E5E',
    textAlign: 'center',
    textAlignLast: 'center',
    fontWeight: 400,
    padding: '0px 20px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '18px !important',
    lineHeight: '26px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px !important',
      lineHeight: '26px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important',
      lineHeight: '19px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '19px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px !important',
      lineHeight: '19px !important'
    }
  },
  homePgBoxText5: {
    fontFamily: 'Satoshi',
    color: '#373737',
    fontWeight: 700,
    padding: '0px 20px 0px 0px',
    fontSize: '22px !important',
    lineHeight: '28px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '22px !important',
      lineHeight: '28px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
      lineHeight: '18px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      lineHeight: '18px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px !important',
      lineHeight: '18px !important'
    }
  },
  homePgBoxText6: {
    fontFamily: 'Satoshi',
    color: '#5E5E5E',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 20px 0px 0px',
    fontSize: '18px !important',
    lineHeight: '26px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px !important',
      lineHeight: '26px !important',
      display: 'flex'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important',
      lineHeight: '18px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '19px !important'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  homePgText2: {
    fontSize: '34px !important',
    lineHeight: '50px !important',
    fontFamily: 'Satoshi',
    color: '#171717',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700
  },
  homePgText22: {
    fontFamily: 'Satoshi',
    color: '#171717',
    fontWeight: 700,
    fontSize: '34px !important',
    lineHeight: '50px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '34px !important',
      lineHeight: '50px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '26px !important',
      lineHeight: '37px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px !important',
      lineHeight: '32px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
      lineHeight: '24px !important'
    }
  },
  homePgText3: {
    fontFamily: 'Satoshi',
    color: '#171717',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: '20px !important',
    lineHeight: '20px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '13px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      lineHeight: '13px !important'
    }
  },
  pressReleseHeading: {
    fontFamily: 'Satoshi',
    color: '#171717',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: '20px !important',
    lineHeight: '20px !important',
    paddingBottom: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '13px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      lineHeight: '13px !important'
    }
  },
  homePageBox2: {
    display: 'inline-flex'
  },
  homePageBox3: {
    padding: '10px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '10px 0px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '10px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px'
    }
  },
  innerBox2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textAlignLast: 'center',
    gap: '10px',
    flexShrink: 0,
    padding: '0px 24px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 24px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 24px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 24px 0px 24px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 4px 0px 4px'
    }
  },
  innerBox3: {
    display: 'flex',
    padding: '0px 12px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textAlignLast: 'center',
    flexShrink: 0,
    backgroundColor: '#fff' // Optional: Add a background color for the boxes
  },
  homePgBoxText7: {
    fontFamily: 'Satoshi',
    color: '#373737',
    fontWeight: 700,
    padding: '0px 20px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    textAlignLast: 'center',
    fontSize: '20px !important',
    lineHeight: '30px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px !important',
      lineHeight: '30px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
      lineHeight: '24px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '24px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px !important',
      lineHeight: '22px !important'
    }
  },
  pressReleseTime: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    }
  },
  pressLocation: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    color: '#171717',
    paddingLeft: '5px',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    }
  },
  homePgPressTxt: {
    fontFamily: 'Satoshi',
    fontWeight: 700,
    paddingTop: '10px',
    fontSize: '24px !important',
    lineHeight: '34px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px !important',
      lineHeight: '34px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '22px !important',
      lineHeight: '28px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
      lineHeight: '24px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
      lineHeight: '24px !important'
    }
  },
  homePgPressHeading: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    color: '#171717',
    fontSize: '18px !important',
    lineHeight: '20px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
      lineHeight: '24px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      lineHeight: '22px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px !important',
      lineHeight: '22px !important'
    }
  },
  homePgPressContent: {
    fontFamily: 'Satoshi !important',
    fontWeight: '400 !important',
    color: '#171717',
    paddingTop: '10px',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px !important',
      lineHeight: '24px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important',
      lineHeight: '24px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important'
    }
  },
  homePgSectionSpacing: {
    paddingTop: '100px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '100px'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '80px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '80px'
    }
  },
  homePgSec1Grid_1: {
    position: 'relative',
    justifyContent: 'space-between',
    zIndex: 1,
    display: 'flex',
    maxWidth: '1440px',
    margin: '0 auto' /* Center the grid itself horizontally */,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  homePgSec1_1: {
    display: 'flex',
    flexDirection: 'column',
    padding: '60px 0px 30px 200px',
    [theme.breakpoints.down('lg')]: {
      padding: '60px 0px 30px 200px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '60px 0px 30px 100px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 30px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px'
    }
  },
  homePgSec1_2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 200px 30px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '30px 200px 30px 0px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '30px 100px 30px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 30px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  homePgGif1: {
    maxWidth: '100%',
    pointerEvents: 'none',
    maxHeight: '400px',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '400px'
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '340px'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '260px'
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '252px'
    }
  },
  homePgSec3_1: {
    maxWidth: '1440px',
    margin: '0 auto' /* Center the container horizontally */,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 30px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 30px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 30px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 18px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 22px'
    }
  },
  homePgSec4_1: {
    padding: '0px 120px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 120px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px'
    }
  },
  homePgSec4_2: {
    borderRadius: '8px',
    minHeight: '430px',
    paddingBottom: '10px',
    boxShadow:
      '0px 0px 30.5px 0px rgba(3, 134, 223, 0.24), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.50) inset',
    [theme.breakpoints.down('lg')]: {
      minHeight: '430px'
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '350px'
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '390px'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '0px'
    }
  },
  homePgSec3_2: {
    maxWidth: '1440px',
    margin: '0 auto',
    padding: '44px 132px 12px 132px',
    [theme.breakpoints.down('lg')]: {
      padding: '44px 132px 12px 132px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '44px 32px 12px 32px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '44px 32px 12px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '44px 20px 12px 20px'
    }
  },
  homePgSec2_1: {
    position: 'relative',
    display: 'flex',
    maxWidth: '1440px',
    justifyContent: 'center',
    padding: '0px 90px',
    margin: '0 auto' /* Center the grid itself horizontally */,
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      padding: '0px 90px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 60px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px'
    }
  },
  homePgImg: {
    borderRadius: '8px',
    maxWidth: '90%',
    height: '220px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '90%',
      height: '220px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
      height: '190px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      height: '110px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      height: '110px'
    }
  },
  // homeNewsBoxImg: {
  //   borderRadius: '8px',
  //   maxWidth: '90%',
  //   height: '220px',
  //   [theme.breakpoints.down('lg')]: {
  //     maxWidth: '90%',
  //     height: '220px'
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     maxWidth: '90%',
  //     height: '190px'
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     maxWidth: '90%',
  //     height: '110px'
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     maxWidth: '100%',
  //     height: '110px'
  //   }
  // },
  // homeNewsImg: {
  //   display: 'flex',
  //   alignItems: 'start',
  //   justifyContent: 'start',
  //   paddingBottom: '10px',
  //   width: '100%',
  //   [theme.breakpoints.down('xs')]: {
  //     justifyContent: 'center',
  //     alignItems: 'center'
  //   }
  // },
  homeNewsBoxImg: {
    borderRadius: '8px',
    maxWidth: '90%',
    maxHeight: '170px', // Set maximum height for large screens
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '170px'
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '150px'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '150px'
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '150px',
      maxWidth: '100%',
      paddingBottom: '20px'
    }
  },
  homeNewsImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Maintains aspect ratio by covering the container
    borderRadius: 'inherit'
  },
  homePgSec5_1: {
    padding: '0px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px 0px 0px'
    }
  },
  homePgSec6_1: {
    minHeight: '436px',
    [theme.breakpoints.down('lg')]: {
      minHeight: '436px'
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '376px'
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '386px'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '0px'
    }
  },
  homePgSec5_2: {
    paddingLeft: '20px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px'
    }
  },
  homePgSec6_2: {
    maxWidth: '1440px',
    margin: '0 auto',
    padding: '0px 132px 0px 132px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 132px 0px 132px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 32px 0px 32px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 32px 0px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 0px 20px'
    }
  },
  sponserImg: {
    width: '160px',
    [theme.breakpoints.down('lg')]: {
      width: '160px'
    },
    [theme.breakpoints.down('md')]: {
      width: '160px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '120px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '65px'
    }
  },
  testimonialImg: {
    width: '90px',
    [theme.breakpoints.down('lg')]: {
      width: '90px'
    },
    [theme.breakpoints.down('md')]: {
      width: '90px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '35px'
    }
  },
  testimonialImg1: {
    width: '160px',
    [theme.breakpoints.down('lg')]: {
      width: '160px'
    },
    [theme.breakpoints.down('md')]: {
      width: '160px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '120px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '95px'
    }
  },
  testimonialCard: {
    width: '450px',
    [theme.breakpoints.down('lg')]: {
      width: '450px'
    },
    [theme.breakpoints.down('md')]: {
      width: '450px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    }
  },
  buttonContainer: {
    display: 'flex',
    gap: '30px',
    padding: '10px',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      gap: '20px',
      flexDirection: 'column' // Stack buttons on smaller screens
    }
  }
}));

function HomePage() {
  const url = process.env.REACT_APP_API;
  const classes = useStyles();
  const [helpPopover, setHelpPopover] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [loadingSaveContact, setLoadingSaveContact] = useState(false);
  const isCompanyMenuClicked = useSelector((state) => state.tokenReducer.isCompanyMenuClicked);
  const intializeContactInfo = {
    name: '',
    email: '',
    message: '',
    companyName: '',
    jobTitle: '',
    companyRole: null,
    phoneNumber: '+91',
    type: 'contact_us',
    country: 'in'
  };
  const [contactInfo, setContactInfo] = useState({ ...intializeContactInfo });

  const scrollDownToContact = () => {
    const ele = document.getElementById('contactSection');
    window.scrollTo({ top: ele.offsetTop, behavior: 'smooth' });
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  const sections = [
    {
      title: 'Ease your due diligence on companies',
      description:
        'Get a comprehensive snapshot of key information & metrics for any private company at a glance',
      videoLink: 'https://public.privateblok.ai/assets/PB-4-01.mp4'
    },
    {
      title: 'Build your thesis on any theme or area of interest',
      description:
        'Understand the recent landscape and noteworthy trends in an investment area you may be contemplating',
      videoLink: 'https://public.privateblok.ai/assets/PB-4-02.mp4'
    },
    {
      title: 'Understand and dive deep into competition',
      description: 'Discover competitors and compare and contrast them on specific parameters',
      videoLink: 'https://public.privateblok.ai/assets/PB-4-04.mp4'
    }
  ];

  useEffect(() => {
    setSelectedIndex(0);
  }, []);

  useEffect(() => {
    if (isCompanyMenuClicked) {
      dispatch(setCompanyMenuClickedStatus(false));
      setTimeout(() => {
        scrollDownToContact();
      }, 600);
    }
  }, [isCompanyMenuClicked]);

  const companyRole = [
    { field: 'Angel Investor' },
    { field: 'Venture Capital' },
    { field: 'Wealth Manager/ Financial Advisor' },
    { field: 'Syndicate' },
    { field: 'Private Equity Firm' },
    { field: 'Family office' },
    { field: 'Others' }
  ];

  const handlePhoneNumberChange = (value) => {
    const formattedPhoneNumber = `+${value}`;
    setContactInfo({ ...contactInfo, phoneNumber: formattedPhoneNumber });
  };

  const { t } = useTranslation();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhoneNo = (phoneNumber) => {
    if (
      (phoneNumber.startsWith('+91') && phoneNumber.length === 13) ||
      (phoneNumber.startsWith('+1') && phoneNumber.length === 12)
    ) {
      return true;
    } else if (!phoneNumber.startsWith('+91') && !phoneNumber.startsWith('+1')) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = () => {
    if (!contactInfo.name) {
      setError(t('validation.Name'));
      return true;
    } else if (!contactInfo.email) {
      setError(t('validation.Email'));
      return true;
    } else if (!validateEmail(contactInfo.email)) {
      setError(t('validation.InvalidEmail'));
      return true;
    } else if (!contactInfo.companyRole) {
      setError(t('validation.companyRole'));
      return true;
    } else if (!contactInfo.phoneNumber) {
      setError(t('validation.phoneNumberErrMessage'));
      return true;
    } else if (!validatePhoneNo(contactInfo.phoneNumber)) {
      setError(t('validation.phoneNumberInvalid'));
      return true;
    }
    return false;
  };

  const saveContact = async () => {
    if (isValid()) return;

    setError('');
    setLoadingSaveContact(true);
    try {
      const getUser = `${url}/contactus`;
      await postApi(getUser, contactInfo);
      setContactInfo({ ...intializeContactInfo });
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 5000);
    } catch (err) {
      setError(t('contactus.apiFail'));
    }
    setLoadingSaveContact(false);
  };
  const isDesktop = useMediaQuery(theme.breakpoints.up('800'));

  const helpCenterCheckPass = async () => {
    try {
      postApi(`${url}/v1/user/user_initial_onboarding_flag`, {
        first_time_user_flag: false
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseHelpPopover = async () => {
    setHelpPopover(false);
  };

  useEffect(() => {
    if (state?.isFirstTimeUser === true) {
      setHelpPopover(false);
      helpCenterCheckPass();
      dispatch(setIsFirstTimeUser(true));
    }
  }, [state]);

  const primaryWords = ['Simplify', 'Elevate', 'Personalize', 'Copilot'];
  const secondaryWords = ['Analysis', 'Insights', 'Workflows', 'Investing'];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [fade, setFade] = useState(false);

  const pressRelese = [
    {
      time: '2024-09-24 08:18:00',
      location: 'Bengaluru, India',
      title: 'AI Copilot PrivateBlok Set to Revolutionize Investment in Private Markets',
      heading:
        'PrivateBlok simplifies investments for VCs, PE firms, Wealth Management firms and investors in start-ups and private companies',
      content:
        'Built with a vision of unlocking the hidden potential of investments in private markets, GenAI-powered SaaS platform PrivateBlok now launches in India. Set out to make the investment journey seamless and personalised for investors, PrivateBlok is geared towards professional investors including Venture Capital firms, Wealth Management firms, Private Equity firms, Family Offices, among others. PrivateBlok has leading venture capital firm Fireside Ventures and financial services company Nuvama Wealth and Investment among its initial clients.'
    }
  ];

  const pressReleseNews = [
    {
      title: 'AI Copilot PrivateBlok Set to Revolutionize Investment in Private Markets',
      heading: 'NewsVoir',
      imgLink: 'https://public.privateblok.ai/assets/PB-News-01-1.png',
      content:
        'Bengaluru (Karnataka) [India], September 25: Built with a vision of unlocking the hidden potential of investments in private markets, GenAI-powered SaaS platform PrivateBlok now launches in India. Set out to make the investment journey seamless and personalised for investors...',
      link: 'https://www.business-standard.com/content/press-releases-ani/ai-copilot-privateblok-set-to-revolutionize-investment-in-private-markets-124092600011_1.html'
    },
    {
      title: 'Revolutionizing Private Market Investments: Introducing PrivateBlok',
      imgLink: 'https://public.privateblok.ai/assets/PB-News-02.png',
      content:
        'PrivateBlok, a GenAI-powered SaaS platform, has launched in India to streamline and personalize the investment journey for professional investors. Its advanced AI capabilities provide unique insights, aiming to maximize investment potentials in private markets. PrivateBlok supports investor reporting, portfolio tracking, and offers customized solutions for its clients.',
      link: 'https://www.devdiscourse.com/article/headlines/3100726-revolutionizing-private-market-investments-introducing-privateblok?amp'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Start fading out the current word
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % primaryWords.length); // Change the word
        setFade(false); // Start fading in the new word after it changes
      }, 1000); // Wait for the fade-out effect to complete (1s)
    }, 3000); // Change word every 3 seconds (includes fade duration)

    return () => clearInterval(interval);
  }, [primaryWords.length]);

  const dateFormat = (timestamp) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [datePart, timePart] = timestamp.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hour, minute] = timePart.split(':');
    const utcDateObj = new Date(Date.UTC(year, month - 1, day, hour, minute));
    const options = {
      year: 'numeric',
      month: 'short', // Use 'short' for abbreviated month names, or 'long' for full month names
      day: 'numeric',
      timeZone: userTimeZone
    };
    const formattedTime = utcDateObj.toLocaleString('en-IN', options);
    return formattedTime;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Dialog open={helpPopover} fullWidth={true} maxWidth="sm" onClose={handleCloseHelpPopover}>
        <Box p={0} width="100%">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <div>
              <IconButton
                className={classes.closeBtn}
                onClick={() => {
                  handleCloseHelpPopover();
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
          <DialogContent style={{ paddingTop: '0px' }}>
            <>
              <Box
                style={{
                  borderRadius: '5px',
                  paddingTop: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Typography style={{ fontFamily: 'Satoshi', file_size: '16px', fontWeight: 700 }}>
                  Visit the Help Center to understand how to use PrivateBlok
                </Typography>
                <Typography style={{ fontFamily: 'Satoshi', file_size: '16px', fontWeight: 700 }}>
                  and learn about its features
                </Typography>
                <br />
                <Button
                  type="submit"
                  onClick={() => {
                    navigate('/help-center');
                  }}
                  className={classes.demoBtn}
                >
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#FFFFFF',
                      textTransform: 'none',
                      fontFamily: 'Satoshi'
                    }}
                  >
                    Go to Help Center
                  </Typography>
                </Button>
              </Box>
            </>
          </DialogContent>
        </Box>
      </Dialog>
      <div
        style={{
          position: 'relative',
          maxWidth: '1440px', // Ensure the background is constrained within the maxWidth
          margin: '0 auto' // Center the container
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 100,
            left: 90,
            right: 90,
            bottom: 50,
            background:
              'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%))',
            filter: 'blur(100px)',
            zIndex: 0
          }}
        />
        <Grid className={classes.homePgSec1Grid_1}>
          <div className={classes.homePgSec1_1}>
            <div style={{ padding: '0px 20px' }}>
              <Typography className={classes.homePgText}>
                <span
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                  className={`${classes.textTransition} ${
                    fade ? classes.textFadeOut : classes.textEnter
                  } ${!fade ? classes.textFadeIn : ''}`}
                >
                  {primaryWords[currentWordIndex]}
                </span>
              </Typography>
              <Typography className={classes.homePgText}>
                <span
                  style={{
                    backgroundImage: 'linear-gradient(to right, #171717 0%, #171717 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  Your Private Market
                </span>
              </Typography>
              <Typography className={classes.homePgText}>
                <span
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                  className={`${classes.textTransition} ${
                    fade ? classes.textFadeOut : classes.textEnter
                  } ${!fade ? classes.textFadeIn : ''}`}
                >
                  {secondaryWords[currentWordIndex]}
                </span>
              </Typography>
            </div>
            <div style={{ padding: '20px 0px', display: 'flex', alignItems: 'center' }}>
              <HomePage1 style={{ paddingRight: '8px' }} />
              <Typography className={classes.homePgText1}>
                AI Copilot for Private Markets
                <sup
                  style={{
                    fontFamily: 'Satoshi',
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '22px',
                    fontStyle: 'normal',
                    color: '#171717'
                  }}
                >
                  {/* &nbsp;TM */}
                  &#174;
                </sup>
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  dispatch(setCompanyMenuClickedStatus(true));
                }}
                className={classes.homePageButton}
              >
                <Typography className={classes.typographyButton}>Schedule a Demo</Typography>
              </Button>
              <Button onClick={handleClick} className={classes.borderButtonStyle}>
                <HomePagePlayIcon style={{ paddingRight: '8px', paddingLeft: '20px' }} />
                <Typography className={classes.typographyButtonDemo}>
                  See Copilot in Action
                </Typography>
              </Button>
              <Dialog
                id={id}
                open={open}
                onClose={handleClose}
                fullScreen={true}
                PaperProps={{
                  style: {
                    backgroundColor: 'transparent', // Make the background transparent
                    boxShadow: 'none' // Remove the default shadow
                  }
                }}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
              >
                <DialogContent style={{ padding: 0 }}>
                  {/* Close button */}
                  {!loading && (
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        zIndex: 1
                      }}
                      onClick={handleClose}
                    >
                      <CloseIcon style={{ color: '#FFFFFF' }} />
                    </IconButton>
                  )}

                  {/* Loading Icon */}
                  {loading && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}

                  {/* Video Element */}
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1
                    }}
                  >
                    <video
                      autoPlay
                      playsInline
                      controls
                      onLoadStart={() => setLoading(true)} // Show loading icon
                      onCanPlay={() => setLoading(false)} // Hide loading icon once video is ready
                      style={{
                        width: '80vw', // Set video width to 80% of the viewport width
                        height: 'auto' // Adjust height automatically to maintain aspect ratio
                      }}
                    >
                      <source
                        src="https://public.privateblok.ai/assets/CopilotInAction.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
          {isDesktop && (
            <div className={classes.homePgSec1_2}>
              <img
                src="/images/homePageImgs/Comp 1_1.gif"
                alt="GIF"
                className={classes.homePgGif1}
              />
            </div>
          )}
        </Grid>
        {!isDesktop && (
          <div className={classes.homePgSec1_2}>
            <img
              style={{ zIndex: 1 }}
              src="/images/homePageImgs/Comp 1_1.gif"
              alt="GIF"
              className={classes.homePgGif1}
            />
          </div>
        )}
        <Grid className={classes.homePgSec2_1}>
          <Grid container className={classes.homePageBox}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box className={classes.innerBox}>
                <Typography className={classes.homePgBoxText}>
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    10K+
                  </span>
                </Typography>
                <Typography className={classes.homePgBoxText1}>Companies</Typography>
                <Typography className={classes.homePgBoxText2}>
                  Analyze at your fingertips
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box className={classes.innerBox}>
                <Typography className={classes.homePgBoxText}>
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    360°
                  </span>
                </Typography>
                <Typography className={classes.homePgBoxText1}>Data Insights</Typography>
                <Typography className={classes.homePgBoxText2}>
                  Combine public and proprietary data
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box className={classes.innerBox}>
                <Typography className={classes.homePgBoxText}>
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    Personalized
                  </span>
                </Typography>
                <Typography className={classes.homePgBoxText1}>Workflows</Typography>
                <Typography className={classes.homePgBoxText2}>
                  Adapt AI to your methodology
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: 'none',
          padding: '50px 0px 0px 0px',
          // display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          onClick={() => {
            dispatch(setCompanyMenuClickedStatus(true));
          }}
          className={classes.homePageButton}
        >
          <Typography className={classes.typographyButton}>Schedule a Demo</Typography>
        </Button>
      </div>
      <div className={classes.homePgSectionSpacing} />
      <Grid>
        <div className={classes.homePgSec3_1}>
          <Typography
            style={{ textAlign: 'center', textAlignLast: 'center' }}
            className={classes.homePgText22}
          >
            A revolutionary
            <span
              style={{
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                padding: '0px 10px'
              }}
            >
              Gen AI Platform
            </span>
            customized for the domain of
          </Typography>
        </div>
        <div
          style={{
            maxWidth: '1440px',
            margin: '0 auto' /* Center the container horizontally */,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Typography className={classes.homePgText22}>Private Markets</Typography>
        </div>
        <div className={classes.homePgSec3_2}>
          <div
            style={{
              background: `transparent linear-gradient(90deg, #8F34EA1A 0%, #2B4BDA1A 100%) 0% 0% no-repeat padding-box`,
              borderRadius: '8px',
              padding: '26px 12px'
            }}
          >
            <Grid container className={classes.homePageBox2}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className={classes.innerBox2}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingBottom: '10px',
                      width: '100%'
                    }}
                  >
                    <img src={'/images/homePageImgs/PB-2-01.png'} className={classes.homePgImg} />
                  </div>
                  <Typography className={classes.homePgBoxText3}>Generative AI Powered</Typography>
                  <Typography className={classes.homePgBoxText4}>
                    Our sophisticated Gen AI Algorithms digest and analyse vast amounts of
                    unstructured and structured data, to provide you with actionable insights
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className={classes.innerBox2}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingBottom: '10px',
                      width: '100%'
                    }}
                  >
                    <img src={'/images/homePageImgs/PB-2-02.png'} className={classes.homePgImg} />
                  </div>
                  <Typography className={classes.homePgBoxText3}>
                    Comprehensive and Reliable
                  </Typography>
                  <Typography className={classes.homePgBoxText4}>
                    Curated private markets information from multiple trusted sources, ensuring you
                    get a holistic and reliable view
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className={classes.innerBox2}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingBottom: '10px',
                      width: '100%'
                    }}
                  >
                    <img src={'/images/homePageImgs/PB-2-03.png'} className={classes.homePgImg} />
                  </div>
                  <Typography className={classes.homePgBoxText3}>Privacy and Security</Typography>
                  <Typography className={classes.homePgBoxText4}>
                    Built with guardrails around privacy and security, ensuring your data and
                    analysis stays for your eyes only
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      <div className={classes.homePgSectionSpacing} />
      <Grid style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <div className={classes.homePgSec3_1}>
          <Typography
            style={{ textAlign: 'center', textAlignLast: 'center' }}
            className={classes.homePgText22}
          >
            A
            <span
              style={{
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                padding: '0px 10px'
              }}
            >
              Smart Copilot
            </span>
            that adapts to your way of investing
          </Typography>
        </div>
        {/* <div className={classes.homePgSec4_1}> */}
        <div className={classes.homePgSec3_2}>
          <Grid container className={classes.homePageBox2}>
            <Grid className={classes.homePageBox3} item lg={4} md={4} sm={4} xs={12}>
              <Box style={{ padding: '0px 20px' }} className={classes.innerBox3}>
                <div className={classes.homePgSec4_2}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '20px 0px',
                      borderRadius: '8px'
                    }}
                  >
                    <img src={'/images/homePageImgs/PB-1-02.png'} className={classes.homePgImg} />
                  </div>
                  <Typography className={classes.homePgBoxText3}>
                    Cutting-edge chat interface
                  </Typography>
                  <Typography style={{ paddingTop: '10px' }} className={classes.homePgBoxText4}>
                    An intuitive and interactive way to transform your investing experience
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid className={classes.homePageBox3} item lg={4} md={4} sm={4} xs={12}>
              <Box style={{ padding: '0px 20px' }} className={classes.innerBox3}>
                <div className={classes.homePgSec4_2}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '20px 0px',
                      borderRadius: '8px'
                    }}
                  >
                    <img
                      src={'/images/homePageImgs/PB-HomePgAI.jpeg'}
                      className={classes.homePgImg}
                    />
                  </div>
                  <Typography className={classes.homePgBoxText3}>Custom reports</Typography>
                  <Typography style={{ paddingTop: '10px' }} className={classes.homePgBoxText4}>
                    Templated reports that auto-generate answers to your preferred questions
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid className={classes.homePageBox3} item lg={4} md={4} sm={4} xs={12}>
              <Box style={{ padding: '0px 20px' }} className={classes.innerBox3}>
                <div className={classes.homePgSec4_2}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '20px 0px',
                      borderRadius: '8px'
                    }}
                  >
                    <img src={'/images/homePageImgs/PB-1-03.png'} className={classes.homePgImg} />
                  </div>
                  <Typography style={{ padding: '0px 20px' }} className={classes.homePgBoxText3}>
                    Plug in private data and subscriptions
                  </Typography>
                  <Typography style={{ paddingTop: '10px' }} className={classes.homePgBoxText4}>
                    Bring the nuggets from your past experience into the future - plug in any source
                    at will
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '12px' }}>
        <Button className={classes.homePageButton}>
          <Typography
            className={classes.typographyButton}
            onClick={() => {
              dispatch(setCompanyMenuClickedStatus(true));
            }}
          >
            Learn More
          </Typography>
        </Button>
      </div>
      <div className={classes.homePgSectionSpacing} />
      <div
        style={{
          maxWidth: '1440px',
          margin: '0 auto' /* Center the container horizontally */
        }}
      >
        <Typography className={classes.homePgText3}>
          <span
            style={{
              backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              padding: '0px 10px'
            }}
          >
            INVESTMENT INTELLIGENCE
          </span>
        </Typography>
        <Typography
          style={{ textAlign: 'center', textAlignLast: 'center', paddingTop: '8px' }}
          className={classes.homePgText22}
        >
          Accelerated, Refined
        </Typography>
        <div
          style={{
            maxWidth: '1440px',
            margin: '0 auto'
          }}
          className={classes.homePgSec4_1}
        >
          <Grid style={{ padding: '32px 0px' }} container>
            <Grid lg={4} md={4} sm={12} xs={12} order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
              <Grid container>
                {sections.map((section, index) => {
                  const isSelected = index === selectedIndex;

                  return (
                    <Grid
                      lg={12}
                      md={12}
                      sm={4}
                      xs={4}
                      key={index}
                      onClick={() => setSelectedIndex(index)}
                      style={{ cursor: 'pointer' }}
                      className={classes.homePgSec5_1}
                    >
                      <div
                        style={{
                          height: '2px',
                          background: isSelected
                            ? `transparent linear-gradient(90deg, #8F34EA 0%, #2B4BDA 100%) 0% 0% no-repeat padding-box`
                            : '#767676',
                          transition: 'background 0.3s ease-in-out'
                        }}
                      />
                      <div style={{ padding: '16px 0px' }}>
                        <Typography
                          className={classes.homePgBoxText5}
                          style={{
                            opacity: isSelected ? 1 : 0.5, // Added opacity change
                            transition: 'opacity 0.3s ease-in-out'
                          }}
                        >
                          {section.title}
                        </Typography>
                        <Typography
                          className={classes.homePgBoxText6}
                          style={{
                            opacity: isSelected ? 1 : 0.5, // Added opacity change
                            transition: 'opacity 0.3s ease-in-out'
                          }}
                        >
                          {section.description}
                        </Typography>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              className={classes.homePgSec5_2}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: `transparent linear-gradient(90deg, #8F34EA1A 0%, #2B4BDA1A 100%) 0% 0% no-repeat padding-box`,
                  height: '100%',
                  borderRadius: '8px'
                }}
              >
                {sections[selectedIndex].videoLink && (
                  <video
                    key={sections[selectedIndex].videoLink}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      maxWidth: '90%',
                      height: '100%',
                      pointerEvents: 'none'
                    }}
                  >
                    <source src={sections[selectedIndex].videoLink} type="video/mp4" />
                  </video>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button className={classes.homePageButton}>
          <Typography
            className={classes.typographyButton}
            onClick={() => {
              dispatch(setCompanyMenuClickedStatus(true));
            }}
          >
            Schedule a Demo
          </Typography>
        </Button>
      </div>
      <div className={classes.homePgSectionSpacing} />
      <div
        style={{
          maxWidth: '1440px',
          margin: '0 auto' /* Center the container horizontally */
        }}
      >
        <Typography className={classes.homePgText3}>
          <span
            style={{
              backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              padding: '0px 10px'
            }}
          >
            FUND OPERATIONS
          </span>
        </Typography>
        <Typography
          style={{ textAlign: 'center', textAlignLast: 'center', paddingTop: '8px' }}
          className={classes.homePgText22}
        >
          Seamless Tracking, Reporting & Communications at scale
        </Typography>
        <Grid container className={classes.homePgSec3_2}>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Box style={{ padding: '16px' }}>
              <div
                className={classes.homePgSec6_1}
                style={{
                  borderRadius: '8px',
                  border: '1px solid #004CFF1A',
                  background: `transparent linear-gradient(90deg, #06BCC10D 0%, #004CFF0D 100%) 0% 0% no-repeat padding-box`,
                  padding: '10px 0px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 0px',
                    width: '100%'
                  }}
                >
                  <img
                    src={'/images/homePageImgs/PB-3-01.png'}
                    style={{
                      border: '1px solid #B3CDFA',
                      borderRadius: '8px'
                    }}
                    className={classes.homePgImg}
                  />
                </div>
                <Typography style={{ padding: '0px 24px' }} className={classes.homePgBoxText3}>
                  Track your portfolios and watchlists live
                </Typography>
                <Typography style={{ paddingTop: '10px' }} className={classes.homePgBoxText4}>
                  Get breaking news on themes and companies of interest, with the live tracker
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Box style={{ padding: '16px' }}>
              <div
                className={classes.homePgSec6_1}
                style={{
                  borderRadius: '8px',
                  border: '1px solid #004CFF1A',
                  background: `transparent linear-gradient(90deg, #06BCC10D 0%, #004CFF0D 100%) 0% 0% no-repeat padding-box`,
                  padding: '10px 0px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 0px',
                    width: '100%'
                  }}
                >
                  <img
                    src={'/images/homePageImgs/PB-3-02.png'}
                    style={{
                      border: '1px solid #B3CDFA',
                      borderRadius: '8px'
                    }}
                    className={classes.homePgImg}
                  />
                </div>
                <Typography style={{ padding: '0px 24px' }} className={classes.homePgBoxText3}>
                  Get answers to investor queries in real-time
                </Typography>
                <Typography style={{ paddingTop: '10px' }} className={classes.homePgBoxText4}>
                  Be able to answer any ad-hoc query on demand, with our AI bot Byz
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Box style={{ padding: '16px' }}>
              <div
                className={classes.homePgSec6_1}
                style={{
                  borderRadius: '8px',
                  border: '1px solid #004CFF1A',
                  background: `transparent linear-gradient(90deg, #06BCC10D 0%, #004CFF0D 100%) 0% 0% no-repeat padding-box`,
                  padding: '10px 0px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 0px',
                    width: '100%'
                  }}
                >
                  <img
                    src={'/images/homePageImgs/PB-3-03.png'}
                    style={{
                      border: '1px solid #B3CDFA',
                      borderRadius: '8px'
                    }}
                    className={classes.homePgImg}
                  />
                </div>
                <Typography style={{ padding: '0px 24px' }} className={classes.homePgBoxText3}>
                  Easily follow company updates and create fund reports
                </Typography>
                <Typography style={{ paddingTop: '10px' }} className={classes.homePgBoxText4}>
                  Ease your recurrent processes and reporting with custom report templates
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '4px' }}>
          <Button className={classes.homePageButton}>
            <Typography
              className={classes.typographyButton}
              onClick={() => {
                dispatch(setCompanyMenuClickedStatus(true));
              }}
            >
              Schedule a Demo
            </Typography>
          </Button>
        </div>
      </div>
      <div className={classes.homePgSectionSpacing} />
      <Grid
        style={{
          background: `transparent linear-gradient(90deg, #8F34EA1A 0%, #2B4BDA1A 100%) 0% 0% no-repeat padding-box`,
          paddingBottom: '30px'
        }}
      >
        <div className={classes.homePgSec3_2}>
          <Typography
            style={{ textAlign: 'center', textAlignLast: 'center', paddingBottom: '20px' }}
            className={classes.homePgText22}
          >
            Articles
          </Typography>
          <div
            style={{
              background: '#FFF',
              border: '2px solid #8F34EA33',
              borderRadius: '8px',
              padding: '24px'
            }}
          >
            <Grid container>
              {pressRelese.map((press, index) => (
                <div
                  style={{
                    padding: '8px 0px',
                    cursor: 'pointer'
                  }}
                  key={index}
                >
                  <Grid container justifyContent={'center'}>
                    <Typography className={classes.pressReleseHeading}>
                      <span
                        style={{
                          backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          padding: '0px 10px'
                        }}
                      >
                        PRESS RELEASE
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    style={{
                      background: '#F7F7FC',
                      border: '2px solid #E8E8E8',
                      borderRadius: '8px',
                      padding: '24px 22px'
                    }}
                    // onClick={() => {
                    //   navigate('/press-release');
                    // }}
                    onClick={() => {
                      window.open('/press-release', '_blank'); // Opens in a new tab
                    }}
                  >
                    <Grid lg={4} md={4} sm={4} xs={12}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ClockIconGradient style={{ paddingRight: '5px' }} />
                        <Typography className={classes.pressReleseTime}>
                          {dateFormat(press.time)}
                        </Typography>
                        <Typography className={classes.pressLocation}>{press.location}</Typography>
                      </div>
                      <Typography className={classes.homePgPressTxt}>
                        <span
                          style={{
                            backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          }}
                        >
                          {press.title}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid className={classes.homePgSec5_2} lg={8} md={8} sm={8} xs={12}>
                      <Typography className={classes.homePgPressHeading}>
                        {press.heading}
                      </Typography>
                      <Typography className={classes.homePgPressContent}>
                        {press.content}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
            <Grid container justifyContent={'center'}>
              <Typography className={classes.pressReleseHeading}>
                <span
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    padding: '20px 10px 10px 10px'
                  }}
                >
                  IN THE NEWS
                </span>
              </Typography>
            </Grid>
            <Grid container>
              {pressReleseNews.map((press, index) => (
                <div
                  style={{
                    padding: '8px 0px',
                    cursor: 'pointer'
                  }}
                  key={index}
                >
                  <Grid
                    container
                    style={{
                      background: '#F7F7FC',
                      border: '2px solid #E8E8E8',
                      borderRadius: '8px',
                      padding: '24px 22px'
                    }}
                    onClick={() => {
                      window.open(press.link, '_blank'); // Opens in a new tab
                    }}
                  >
                    <Grid lg={4} md={4} sm={4} xs={12}>
                      <div className={classes.homeNewsBoxImg}>
                        <img src={press?.imgLink} className={classes.homeNewsImg} />
                      </div>
                    </Grid>
                    <Grid className={classes.homePgSec5_2} lg={8} md={8} sm={8} xs={12}>
                      <Typography className={classes.homePgPressHeading}>
                        <span
                          style={{
                            backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          }}
                        >
                          {press.title}
                        </span>
                      </Typography>
                      <Typography className={classes.homePgPressContent}>
                        <span style={{ fontWeight: '700' }}>{press.heading}</span>
                      </Typography>
                      <Typography className={classes.homePgPressContent}>
                        {press.content}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
          </div>
        </div>
      </Grid>
      <div className={classes.homePgSectionSpacing} />
      <Grid>
        <Grid className={classes.homePgSec6_2}>
          <Typography
            style={{ textAlign: 'center', textAlignLast: 'center' }}
            className={classes.homePgText22}
          >
            <span
              style={{
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                padding: '0px 10px'
              }}
            >
              Partnerships
            </span>
            and Sponsorships
          </Typography>
          <Grid>
            <div
              style={{
                paddingTop: '32px'
              }}
              className="sponser-imageContainer"
            >
              <img
                src={'/images/homePageImgs/FireSide.png'}
                className={classes.sponserImg}
                alt="Microsoft Home"
              />
              <img
                src={'/images/homePageImgs/nuvama.png'}
                className={classes.sponserImg}
                alt="Microsoft Home"
              />
              <img
                src={'/images/homePageImgs/microsoft.png'}
                className={classes.sponserImg}
                alt="Microsoft Home"
              />
              <img
                src={'/images/homePageImgs/aws.png'}
                className={classes.sponserImg}
                alt="AWS Home"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.homePgSectionSpacing} />
      <Grid>
        <Grid style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <Typography
            style={{ textAlign: 'center', textAlignLast: 'center' }}
            className={classes.homePgText22}
          >
            Testimonials
          </Typography>
          <Typography style={{ paddingTop: '10px' }} className={classes.homePgBoxText4}>
            See how PrivateBlok has transformed the experience of our clients
          </Typography>
          <div className={classes.homePgSec3_2}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                overflowX: 'auto', // Allow horizontal scrolling
                overflowY: 'hidden', // Disable vertical scrolling
                gap: '16px', // Add space between the items
                paddingBottom: '16px' // Add padding at the bottom for scrollbar space
              }}
            >
              <div
                style={{
                  backgroundImage: 'linear-gradient(to right, #CDF1F333 0%, #CDDBFF33 100%)',
                  borderRadius: '8px',
                  border: '1px solid #CDF1F3',
                  padding: '32px',
                  flexShrink: 0
                }}
                className={classes.testimonialCard}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative'
                  }}
                >
                  <img
                    src={'/images/homePageImgs/testimonial-coma.png'}
                    alt="Testimonial"
                    style={{ flex: '0 0 auto' }}
                    className={classes.testimonialImg}
                  />
                  <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <img
                      className={classes.testimonialImg1}
                      src={'/images/homePageImgs/FireSide.png'}
                      alt="FireSide"
                    />
                  </div>
                </div>
                <Typography
                  style={{ padding: '24px 0px', height: '240px', overflow: 'auto' }}
                  className={classes.homePgBoxText7}
                >
                  At Fireside Ventures, we have always explored the use of newer technologies that
                  enhance and optimise our investments. Simultaneously we continue to scout for
                  newer and emerging consumer brands that can be added to our portfolio. India is a
                  very exciting market and a fast moving one and PrivateBlok’s Gen AI copilot
                  assists us in these processes.
                </Typography>
                <Typography style={{ fontWeight: 700 }} className={classes.homePgBoxText4}>
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      padding: '0px 10px'
                    }}
                  >
                    Shuchi Pandya
                  </span>
                </Typography>
                <Typography
                  style={{ fontWeight: 400, color: '#767676' }}
                  className={classes.homePgBoxText4}
                >
                  Principal
                </Typography>
                <Typography
                  style={{ fontWeight: 700, color: '#171717' }}
                  className={classes.homePgBoxText4}
                >
                  Fireside Ventures
                </Typography>
              </div>
              <div
                style={{
                  backgroundImage: 'linear-gradient(to right, #CDF1F333 0%, #CDDBFF33 100%)',
                  border: '1px solid #CDF1F3',
                  borderRadius: '8px',
                  padding: '32px',
                  flexShrink: 0
                }}
                className={classes.testimonialCard}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative'
                  }}
                >
                  <img
                    src={'/images/homePageImgs/testimonial-coma.png'}
                    alt="Testimonial"
                    style={{ flex: '0 0 auto' }}
                    className={classes.testimonialImg}
                  />
                  <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <img
                      className={classes.testimonialImg1}
                      src={'/images/homePageImgs/nuvama.png'}
                      alt="FireSide"
                    />
                  </div>
                </div>
                <Typography
                  style={{ padding: '24px 0px', height: '240px', overflow: 'auto' }}
                  className={classes.homePgBoxText7}
                >
                  PrivateBlok is revolutionary and simple to use. Its advanced capabilities have
                  been aiding in speedy and informed decision-making. We are excited to see how the
                  product evolves. We believe it possesses the potential to drive growth in terms of
                  investments in the private sector in India.
                </Typography>
                <Typography style={{ fontWeight: 700 }} className={classes.homePgBoxText4}>
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      padding: '0px 10px'
                    }}
                  >
                    Shiv Sehgal
                  </span>
                </Typography>
                <Typography
                  style={{ fontWeight: 400, color: '#767676' }}
                  className={classes.homePgBoxText4}
                >
                  President and Head
                </Typography>
                <Typography
                  style={{ fontWeight: 700, color: '#171717' }}
                  className={classes.homePgBoxText4}
                >
                  Nuvama Capital Markets
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        style={{
          background: '#E9F0FD',
          display: 'none'
        }}
        className="gird-9-style"
      >
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <div style={{ margin: '0 30px' }}>
            <Typography
              style={{
                fontSize: '20px',
                fontWeight: 500,
                color: '#171717'
              }}
            >
              Blog
            </Typography>
          </div>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              overflowX: 'auto',
              overflowY: 'hidden'
            }}
          >
            <div style={{ margin: '0 20px', paddingBottom: '10px', minWidth: '160px' }}>
              <div className="box">
                <img
                  src={'/images/blogImgs/BlogImg.png'}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    maxWidth: '100%'
                  }}
                />
              </div>
              <br />
              <Typography className={classes.blogHeading}>Optimized Reach</Typography>
              <br />
              <Typography className={classes.blogPara}>
                It's all about getting your message in front of the right audience and creating
                those valuable relationships.
              </Typography>
              <br />
              <br />
              <div className="line-break-blog"></div>
              <br />
              <br />
              <Button className={classes.blogBtn}>
                <Typography className={classes.blogBtnTxt}>Learn more</Typography>
              </Button>
            </div>
            <div style={{ margin: '0 20px', paddingBottom: '10px', minWidth: '160px' }}>
              <div className="box">
                <img
                  src={'/images/blogImgs/BlogImg.png'}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    maxWidth: '100%'
                  }}
                />
              </div>
              <br />
              <Typography className={classes.blogHeading}>Optimized Reach</Typography>
              <br />
              <Typography className={classes.blogPara}>
                It's all about getting your message in front of the right audience and creating
                those valuable relationships.
              </Typography>
              <br />
              <br />
              <div className="line-break-blog"></div>
              <br />
              <br />
              <Button className={classes.blogBtn}>
                <Typography className={classes.blogBtnTxt}>Learn more</Typography>
              </Button>
            </div>
            <div style={{ margin: '0 20px', paddingBottom: '10px', minWidth: '160px' }}>
              <div className="box">
                <img
                  src={'/images/blogImgs/BlogImg.png'}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    maxWidth: '100%'
                  }}
                />
              </div>
              <br />
              <Typography className={classes.blogHeading}>Optimized Reach</Typography>
              <br />
              <Typography className={classes.blogPara}>
                It's all about getting your message in front of the right audience and creating
                those valuable relationships.
              </Typography>
              <br />
              <br />
              <div className="line-break-blog"></div>
              <br />
              <br />
              <Button className={classes.blogBtn}>
                <Typography className={classes.blogBtnTxt}>Learn more</Typography>
              </Button>
            </div>
          </div>
        </div>
      </Grid>
      <div className={classes.mainContact}>
        <Grid
          id="contactSection"
          className={`gird-10-style ${classes.contactSection}`}
          style={{
            background: '#FFFFFF',
            maxWidth: '1440px',
            margin: '0 auto'
          }}
        >
          <Grid container className={classes.contactForm}>
            <Grid item lg={12} md={12} sm={12}>
              <Typography>
                <span
                  className="heading-5-fontsize"
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  {t('homepage.weDLoveTo')}
                </span>
              </Typography>
              <div className={classes.pdTop}>
                <Typography className={classes.descText}>
                  {t('homepage.pleaseFillOutThis')}
                </Typography>
              </div>
              <div className="line-break-blog"></div>
              <br />
              {successMessage ? (
                <>
                  <br />
                  <Box
                    style={{
                      background: '#C7F1BF',
                      borderRadius: '5px',
                      opacity: 1,
                      width: '100%',
                      height: '40px',
                      display: 'flex', // Set display to flex
                      alignItems: 'center' // Vertically center align the content
                    }}
                  >
                    <CheckCircleOutlineIcon
                      style={{
                        marginLeft: '13px', // Set margin left to 13px
                        marginRight: '2px', // Set margin right to 1px
                        color: '#3C962A' // Set color of the icon to #3C962A
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#206113',
                        opacity: 1,
                        fontFamily: 'Satoshi',
                        fontWeight: 'normal'
                      }}
                    >
                      {t('contactus.successStatus')}
                    </Typography>
                  </Box>
                </>
              ) : null}
            </Grid>
            <Grid container lg={12} md={12} sm={12} xs={12} style={{ padding: '0px 6px 0px 6px' }}>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('homepage.Name')}
                    value={contactInfo.name}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, name: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('homepage.emailID')}
                    value={contactInfo.email}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, email: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Company Name"
                    value={contactInfo.companyName}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, companyName: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Job Title"
                    value={contactInfo.jobTitle}
                    className={classes.root}
                    onChange={(e) => {
                      setContactInfo({ ...contactInfo, jobTitle: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <Autocomplete
                    options={companyRole}
                    className={classes.autocomplete}
                    getOptionLabel={(option) => option.field || ''}
                    renderInput={(params) => (
                      <form noValidate>
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={t('homepage.companyRole')}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <>{params.InputProps.startAdornment}</>
                          }}
                          autoComplete="off"
                        />
                      </form>
                    )}
                    onChange={(event, value) =>
                      setContactInfo({ ...contactInfo, companyRole: value })
                    }
                    autoHighlight
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '6px 6px 6px 6px' }}>
                <Box width="100%">
                  <PhoneInput
                    inputStyle={{
                      height: '56px',
                      width: '100%',
                      color: '#08070d',
                      backgroundColor: '#E9F0FD !important',
                      background: '#E9F0FD'
                    }}
                    value={contactInfo?.phoneNumber}
                    country={contactInfo?.country}
                    countryCodeEditable={false}
                    onChange={handlePhoneNumberChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '6px 12px 6px 12px' }}>
              <Box width="100%">
                <TextField
                  type="text"
                  multiline
                  rows={5}
                  label={t('homepage.Message')}
                  variant="outlined"
                  value={contactInfo.message}
                  className={`${classes.root} ${classes.multilineInput}`}
                  onChange={(e) => {
                    setContactInfo({ ...contactInfo, message: e.target.value });
                  }}
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#08070d',
                      opacity: 0.8,
                      padding: '0px !important'
                    }
                  }}
                />
              </Box>
              <Box width="100%">
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      className="tryButton"
                      disabled={loadingSaveContact}
                      style={{ background: loadingSaveContact ? '#ccc' : '' }}
                      onClick={saveContact}
                    >
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: '#FFFFFF',
                          textTransform: 'none'
                        }}
                      >
                        {t('homepage.Submit')}
                      </Typography>
                    </Button>
                  </div>
                  <div>
                    {loadingSaveContact && (
                      <>
                        <LinearProgress />
                      </>
                    )}
                    {error && (
                      <>
                        <Box
                          style={{
                            background: '#FDF6F6',
                            borderRadius: '5px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <ErrorOutlineIcon
                            style={{
                              marginLeft: '13px', // Set margin left to 13px
                              marginRight: '5px', // Set margin right to 1px
                              color: '#EB5B55' // Set color of the icon to #3C962A
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#EB5B55',
                              marginRight: '15px'
                            }}
                          >
                            {error}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <FooterMenu />
    </>
  );
}
export default HomePage;
