import { useState, useEffect } from 'react';

import * as yup from 'yup';

export const useValidEmail = (initialValue) => {
  const [email, setEmail] = useState(initialValue);
  const [emailIsValid, setEmailIsValid] = useState(true);

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required()
    });

    if (email.length === 0) {
      setEmailIsValid(true);
      return;
    }

    const isValid = emailSchema.isValidSync({ email });

    setEmailIsValid(isValid);
  }, [email]);

  return { email, setEmail, emailIsValid };
};

export const useValidName = (initialValue) => {
  const [name, setName] = useState(initialValue);
  const [nameIsValid, setNameIsValid] = useState(true);

  useEffect(() => {
    const nameSchema = yup.object().shape({
      name: yup.string().min(2).required()
    });

    if (name.length === 0) {
      setNameIsValid(true);
      return;
    }

    const isValid = nameSchema.isValidSync({ name });

    setNameIsValid(isValid);
  }, [name]);

  return { name, setName, nameIsValid };
};

export const useValidPassword = (initialValue) => {
  const [password, setPassword] = useState(initialValue);
  const [passwordIsValid, setPasswordIsValid] = useState(true);

  useEffect(() => {
    const passwordSchema = yup.object().shape({
      /* eslint-disable */
      password: yup
        .string()
        .required()
        .matches(
          /^(?=\S)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{8,})(?![\s\S]*\s$)/
        )
    });
    if (password.length === 0) {
      setPasswordIsValid(true);
      return;
    }

    const isValid = passwordSchema.isValidSync({ password });

    setPasswordIsValid(isValid);
  }, [password]);

  return { password, setPassword, passwordIsValid };
};

export const useValidUsername = (initialValue) => {
  const [username, setUsername] = useState(initialValue);
  const [usernameIsValid, setUsernameIsValid] = useState(true);

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().email().required()
    });

    if (username.length === 0) {
      setUsernameIsValid(true);
      return;
    }

    const isValid = usernameSchema.isValidSync({ username });

    setUsernameIsValid(isValid);
  }, [username]);

  return { username, setUsername, usernameIsValid };
};

export const useValidCode = (initialValue) => {
  const [code, setCode] = useState(initialValue);
  const [codeIsValid, setCodeIsValid] = useState(true);

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required()
    });

    if (code.length === 0) {
      setCodeIsValid(true);
      return;
    }

    const isValid = codeSchema.isValidSync({ code });

    setCodeIsValid(isValid);
  }, [code]);

  return { code, setCode, codeIsValid };
};

export const useValidInviteCode = (initialValue) => {
  const [inviteCode, setInviteCode] = useState(initialValue);
  const [inviteCodeIsValid, setInviteCodeIsValid] = useState(true);

  useEffect(() => {
    const inviteCodeSchema = yup.object().shape({
      inviteCode: yup.string().min(2).required()
    });

    if (inviteCode.length === 0) {
      setInviteCodeIsValid(true);
      return;
    }

    const isValid = inviteCodeSchema.isValidSync({ inviteCode });

    setInviteCodeIsValid(isValid);
  }, [inviteCode]);

  return { inviteCode, setInviteCode, inviteCodeIsValid };
};