import React from 'react';
import DialogSignIn from '../shared/dialogSignIn';
import SignIn from '../auth/signIn';

const SignedInWarning = () => {
  return (
    <>
      <DialogSignIn
        dialogProps={{
          open: true,
          handleClose: () => {},
          Component: SignIn,
          isDialog: true
        }}
      />
    </>
  );
};
export default SignedInWarning;
