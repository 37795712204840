import axios from 'axios';

export const postApi = async (url, data) => {
  return await axios.post(url, data);
};

export const putApi = async (url, data, headers = null) => {
  return (await headers) ? axios.put(url, data, headers) : axios.put(url, data);
};

export const getApi = async (url, params = {}, tokenVal = null) => {
  const headers = {};
  if (tokenVal) {
    headers['bt-user-access-token-header'] = tokenVal;
  }

  const response = await axios.get(url, { params, headers });
  return response;
};

export const deleteApi = async (url, tokenVal = null) => {
  const headers = {};
  if (tokenVal) {
    headers['bt-user-access-token-header'] = tokenVal;
  }
  const response = await axios.delete(url, { headers });
  return response;
};

export const streamApi = async (url, tokenVal, data) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: tokenVal
    }
  });

  let responseBody = null;

  const currentHeaders = response.headers;
  const headers = {
    'X-Pb-Plan-Remaining-Credits': currentHeaders.get('X-Pb-Plan-Remaining-Credits'),
    'X-Pb-Plan-Changed-To': currentHeaders.get('X-Pb-Plan-Changed-To'),
    'X-Pb-Plan-Current': currentHeaders.get('X-Pb-Plan-Current')
  };

  if (!response.ok) {
    try {
      responseBody = await response.text(); // Read the response body as text
    } catch (e) {
      console.error('Error reading response body:', e);
    }

    const errorMessage = { status: response.status, text: responseBody, headers: headers };
    throw new Error(JSON.stringify(errorMessage)); // Convert the object to a string
  }

  return { result: response.body, headers: headers };
};
