import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { Typography, makeStyles } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const useStyles = makeStyles((theme) => ({
  mainDiv: {
    borderRadius: '16px !important',
    height: '566px',
    width: '500px'
  },
  autocompleteInput: {
    height: '4px !important'
  },
  autocompleteInputDiv: {
    borderRadius: '10px!important'
  },
  autocompleteRoot: {
    margin: '0px 10px'
  },
  closeBtn: {
    position: 'absolute !important',
    right: '10px',
    top: '8px'
  },
  autoCompletRoot: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '1px !important'
    }
  },
  companyList: {
    height: '350px',
    overflow: 'auto',
    margin: '2px 0px !important'
  },
  companyLi: {
    paddingLeft: '0px !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    '&:hover': {
      borderRadius: '3px',
      opacity: '0.9',
      background: 'rgb(248, 250, 253) !important'
    }
  },
  saveBtn: {
    background: 'linear-gradient(90deg,#004CFF,#06BCC1)'
  },
  removeLink: {
    fontWeight: 'lighter',
    fontSize: '13px',
    cursor: 'pointer',
    color: 'blue',
    margin: '0px 10px',
    '&:hover': {
      color: theme.palette.error.main,
      textDecoration: 'underline'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  }
}));

const DeleteWatchlistPopOver = ({
  openState = false,
  handleClose = () => {},
  watchlistName = '',
  trigger = () => {}
}) => {
  const classes = useStyles();
  return (
    <Dialog open={openState} fullWidth={true} maxWidth="sm">
      <Box p={0} width="100%">
        <IconButton
          className={classes.closeBtn}
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
        <br />
        <DialogContent>
          <>
            <Box
              style={{
                background: '#FDF6F6',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ErrorOutlineIcon
                style={{
                  marginLeft: '13px', // Set margin left to 13px
                  marginRight: '5px', // Set margin right to 1px
                  color: '#EB5B55' // Set color of the icon to #3C962A
                }}
              />
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#EB5B55',
                  marginRight: '15px',
                  fontFamily: 'Satoshi',
                  fontWeight: 'normal'
                }}
              >
                Your watchlist{' '}
                <span style={{ fontWeight: 'bold', padding: '0px 2px 0px 2px' }}>
                  {watchlistName}
                </span>{' '}
                will be permanently deleted.
              </Typography>
            </Box>
            <br />
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                  trigger();
                }}
                style={{
                  margin: '10px',
                  border: 'none',
                  textTransform: 'none',
                  height: '33px',
                  color: '#FFFFFF',
                  backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
                }}
              >
                <DeleteForeverIcon style={{ color: '#FFFFFF' }} />
                Delete
              </Button>
            </div>
            <br />
          </>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeleteWatchlistPopOver;
