import { LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { getApi, postApi, putApi } from '../../../services/token';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TearSheet from './report/tearSheet';
import TearSheetSearchBar from './tearSheetSearchBar';
import TearSheetTemplate from './templates/templates';
import { createTemplate } from './tearSheetTemplateMocks';
import ErrorToast from '../../atoms/errorToast';
import SuccessToast from '../../atoms/successToast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  setTearSheetEntitiesOptions,
  setTearSheetData,
  setTearSheetKeys,
  setTearSheetTemplateOptions
} from '../action';
import AddEntitiesPopOver from '../../molecules/addEntitiesPopUp';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    minHeight: 'calc(100vh - 120px)',
    backgroundColor: '#F7F9FD'
  },
  tearSheetDiv: {
    padding: '0px 20px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 15px 15px'
    }
  },
  maxWidthDiv: {
    maxWidth: '1440px',
    margin: '0px auto',
    position: 'relative'
  },
  '@media print': {
    tearSheetDiv: {
      visibility: 'visible',
      position: 'absolute',
      left: 0,
      top: '-10px'
    },
    mainDiv: {
      visibility: 'hidden'
    }
  }
}));

const default_temp_id = process.env.REACT_APP_DEFAULT_TEMPLATE_ID;
const TearSheetMainPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentPage = props?.currentRoute;
  const entity_type = searchParams.get('entity_type');
  const entity_id = searchParams.get('entity_id');
  let templateId = searchParams.get('template_id') || '';
  const companyUrl = process.env.REACT_APP_COMPANY_API;
  const onlyCompany = process.env.REACT_APP_ONLY_COMPANIES === 'true';
  const companyNameOptions = useSelector((state) => state.tokenReducer.tearSheetEntitiesOptions);
  const [allCompanyOptions, setAllCompanyOptions] = useState([]);
  const companyData = useSelector((state) => state.tokenReducer.tearSheetData);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [poperSuccessMessage, setPoperSuccessMessage] = useState('');
  const templateList = useSelector((state) => state.tokenReducer.tearSheetTemplateOptions);
  const [allTemplateList, setAllTemplateList] = useState([]);
  const [tearSheetStatus, setTearSheetStatus] = useState('');
  const [triggerError, setTriggerErrorMessage] = useState('');
  const [templateData, setTemplateData] = useState();
  const [initialTemplateData, setInitialTemplateData] = useState();
  const [templateValidationErrors, setTemplateValidationErrors] = useState({});
  const [openPoper, setOpenPoper] = useState(false);
  const [triggerSelectedCompanies, setTriggerSelectedCompanies] = useState([]);
  const [optionsEntityType, setOptionsEntityType] = useState('all');
  const tearSheetKeysRedux = useSelector((state) => state.tokenReducer.tearSheetKeys);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getEntitiesList = async () => {
    try {
      let companyListData, payload;
      payload = {
        entity_types: onlyCompany ? 'company' : 'company,theme'
      };
      if (currentPage === t('tearSheet.tearsheetRoute')) {
        payload = templateId ? { ...payload, template_id: templateId } : payload;
        companyListData = await getApi(
          `${companyUrl}/v1/company_tear_sheet/template_entities`,
          payload
        );
      } else {
        companyListData = await getApi(`${companyUrl}/v1/entity/search`, payload);
      }
      if (companyListData && companyListData.data) {
        const parsedData =
          currentPage === t('tearSheet.tearsheetRoute')
            ? companyListData?.data?.data
            : companyListData?.data?.data?.items;
        if (currentPage !== t('tearSheet.tearsheetRoute')) {
          setAllCompanyOptions(parsedData);
        } else {
          dispatch(setTearSheetEntitiesOptions(parsedData));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getTearSheetData = async () => {
    if (!entity_id || !templateId) {
      dispatch(setTearSheetData(null));
      return;
    } else {
      try {
        const res = await getApi(`${companyUrl}/v1/company_tear_sheet`, {
          entity_id: entity_id,
          entity_type: entity_type,
          template_id: templateId
        });
        const data = res.data.data;
        dispatch(setTearSheetData(data));
      } catch (err) {
        dispatch(setTearSheetData(null));
        setApiError(t('tearSheet.apiError'));
        setTimeout(() => setApiError(''), 10000);
      }
    }
  };
  const getTemplateData = async () => {
    if (!templateId) {
      return;
    } else if (templateId === 'new') {
      setInitialTemplateData(JSON.parse(JSON.stringify({ ...createTemplate })));
      let duplicatedTemplate = location?.state?.duplicatedTemplate;
      if (duplicatedTemplate) {
        setTemplateData(duplicatedTemplate);
      } else {
        setTemplateData(JSON.parse(JSON.stringify({ ...createTemplate })));
      }
    } else {
      try {
        const res = await getApi(`${companyUrl}/v1/company_tear_sheet/template`, {
          template_id: templateId
        });
        const data = res.data.data[0];
        setTemplateData(data);
        setInitialTemplateData(JSON.parse(JSON.stringify({ ...data })));
      } catch (err) {
        setTemplateData(null);
        setInitialTemplateData(null);
        setApiError(t('tearSheet.apiError'));
        setTimeout(() => setApiError(''), 10000);
      }
    }
  };
  const getTemplateList = async () => {
    try {
      let queryParams = entity_id ? `?entity_id=${entity_id}&entity_type=${entity_type}` : '';
      let res = await getApi(`${companyUrl}/v1/company_tear_sheet/templates${queryParams}`);
      let data = res.data.data;
      if (currentPage === t('tearSheet.templateRoute')) {
        setAllTemplateList(data);
      } else {
        dispatch(setTearSheetTemplateOptions(data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCompanyChange = (event, value) => {
    if (value === null) {
      dispatch(setTearSheetKeys({}));
      dispatch(setTearSheetData(null));
      navigate(`/tearsheet`);
    } else {
      navigate(
        `/tearsheet?entity_type=${value?.entity_type}&entity_id=${value?.entity_id}${
          templateId ? `&template_id=${templateId}` : ''
        }`
      );
    }
  };
  const handleTemplateChange = (event) => {
    if (currentPage === t('tearSheet.templateRoute')) {
      navigate(`/template?template_id=${event.target.value}`);
    } else {
      dispatch(setTearSheetKeys({}));
      navigate(
        `/tearsheet?${
          entity_id ? `entity_type=${entity_type}&entity_id=${entity_id}&` : ''
        }template_id=${event.target.value}`
      );
    }
  };
  const handleManageTemplate = (event) => {
    dispatch(setTearSheetKeys({ ...tearSheetKeysRedux }));
    navigate(
      `/template${
        event.target.value === 'new'
          ? '?template_id=new'
          : templateId
          ? `?template_id=${templateId}`
          : ''
      }`
    );
  };
  const handleTearSheetTrigger = async () => {
    setTriggerErrorMessage('');
    setLoading(true);
    try {
      const payload = {
        template_id: templateId,
        entity_list: companyNameOptions.filter((obj) => obj.entity_id === entity_id)
      };
      await postApi(`${companyUrl}/v1/company_tear_sheet`, payload);
      setTearSheetStatus(t('tearSheet.inQueue'));
    } catch (err) {
      const errorStatus = err?.response?.status;
      if (errorStatus === 403 || errorStatus === 402) {
        setTriggerErrorMessage(t('tearSheet.triggerLimitError'));
      } else {
        setTriggerErrorMessage(t('tearSheet.triggerFailedMessage'));
      }
      if (companyData?.response && Object.keys(companyData?.response).length > 0) {
        setTimeout(() => setTriggerErrorMessage(''), 10000);
      }
      console.log(err);
    }
    setLoading(false);
  };
  const handleDuplicate = () => {
    let duplicatedTemplate = {};
    duplicatedTemplate.name = initialTemplateData.name + ' (Copy)';
    duplicatedTemplate.entity_list = initialTemplateData.entity_list;
    duplicatedTemplate.question_groups = initialTemplateData.question_groups;
    navigate('/template?template_id=new', { state: { duplicatedTemplate: duplicatedTemplate } });
  };
  const modifyErrors = (modifiedValidationErrors) => {
    let question_groups_errors = modifiedValidationErrors?.question_groups;
    if (question_groups_errors?.length) {
      question_groups_errors.sort((a, b) => a.index - b.index);
      for (let i = 0; i < templateData?.question_groups.length; i++) {
        if (question_groups_errors.length <= i) {
          question_groups_errors.push({});
        } else if (i !== question_groups_errors[i]?.index) {
          question_groups_errors.splice(i, 0, {});
        }
        let questions_errors = question_groups_errors[i]?.questions;
        if (questions_errors) {
          questions_errors.sort((a, b) => a.index - b.index);
          for (let j = 0; j < templateData?.question_groups?.[i]?.questions?.length; j++) {
            if (questions_errors.length <= j) {
              questions_errors.push({});
            } else if (j !== questions_errors?.[j]?.index) {
              questions_errors.splice(j, 0, {});
            }
          }
        }
      }
    }
  };
  const handleSaveTemplate = async () => {
    setLoading(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTemplateValidationErrors(null);
    setApiError('');
    const endpoint =
      templateId === 'new'
        ? `${companyUrl}/v1/company_tear_sheet/template`
        : `${companyUrl}/v1/company_tear_sheet/template`;

    const method = templateId === 'new' ? postApi : putApi;

    let payload = { ...templateData };
    delete payload.company_list;
    try {
      const res = await method(endpoint, payload);

      if (res.data.data?.errors) {
        let modifiedValidationErrors = res.data.data?.errors;
        modifyErrors(modifiedValidationErrors);
        setTemplateValidationErrors(modifiedValidationErrors);
        setApiError(t('tearSheet.templateValidationError'));
        setTimeout(() => setApiError(''), 10000);
        setLoading(false);
        return;
      }

      dispatch(setTearSheetKeys({}));

      const successMessage =
        templateId === 'new'
          ? t('tearSheet.templateCreateSuccessful')
          : t('tearSheet.templateSaveSuccessful');
      setPoperSuccessMessage(successMessage);
      setTimeout(() => setPoperSuccessMessage(''), 10000);
      const templateIdParam = templateId === 'new' ? res.data.data?.response : templateId;
      if (templateId === 'new') {
        navigate(`/template?template_id=${templateIdParam}`, {
          state: {
            openPopUp: true
          }
        });
      } else {
        setOpenPoper(true);
      }
    } catch (err) {
      console.log(err);
      setApiError(t('tearSheet.apiError'));
      setTimeout(() => setApiError(''), 10000);
    }
    setLoading(false);
  };

  const handleDiscardTemplate = () => {
    navigate(`/tearsheet`, { state: { successMessage: t('tearSheet.templateDiscardText') } });
  };

  const handlePoperSubmit = async () => {
    if (triggerSelectedCompanies?.length == 0) {
      return;
    }
    setTriggerErrorMessage('');
    setLoading(true);
    try {
      const payload = {
        template_id: templateId,
        entity_list: triggerSelectedCompanies
      };
      await postApi(`${companyUrl}/v1/company_tear_sheet`, payload);
      handleClosePoper();
      navigate(`/tearsheet?template_id=${templateId}`, {
        state: { successMessage: t('tearSheet.tearSheetsRequested') }
      });
    } catch (err) {
      const errorStatus = err?.response?.status;
      if (errorStatus === 403 || errorStatus === 402) {
        setTriggerErrorMessage(t('tearSheet.triggerLimitError'));
      } else {
        setTriggerErrorMessage(t('tearSheet.triggerFailedMessage'));
      }
      console.log(err);
    }
    setLoading(false);
  };

  const handleClosePoper = () => {
    setTriggerErrorMessage('');
    setTriggerSelectedCompanies([]);
    setOpenPoper(false);
  };

  const handlePoperSearchChange = (event, value) => {
    setPoperSuccessMessage('');
    setTriggerErrorMessage('');
    setTriggerSelectedCompanies(value);
  };

  const handlePoperDeleteChange = (key) => {
    setTriggerErrorMessage('');
    setTriggerSelectedCompanies((prev) => prev.filter((option) => option.entity_id !== key));
  };

  const initialise = () => {
    setApiError('');
    // setCompanyData(null);
    setOptionsEntityType('all');
    setTemplateData(null);
    setInitialTemplateData(null);
    setTemplateValidationErrors(null);
    setOpenPoper(location?.state?.openPopUp || false);
    if (location?.state?.successMessage && !successMessage) {
      setSuccessMessage(location?.state?.successMessage);
      setTimeout(() => setSuccessMessage(''), 15000);
    } else {
      setSuccessMessage('');
    }
    setTriggerErrorMessage('');
  };

  const getAllData = async () => {
    if (currentPage === t('tearSheet.tearsheetRoute')) {
      await Promise.all([getTemplateList(), getEntitiesList(), getTearSheetData()]);
    } else {
      await Promise.all([getTemplateList(), getEntitiesList(), getTemplateData()]);
    }
  };

  const handleRedux = async () => {
    if (currentPage === t('tearSheet.tearsheetRoute')) {
      const reduxEntityId = tearSheetKeysRedux?.entity_id;
      const reduxEntityType = tearSheetKeysRedux?.entity_type;
      const reduxTemplateId = tearSheetKeysRedux?.templateId;
      if (!entity_id && !templateId && currentPage === t('tearSheet.tearsheetRoute')) {
        if ((reduxEntityId || reduxTemplateId) && reduxTemplateId) {
          navigate(
            `/tearsheet?${
              reduxEntityId ? `entity_id=${reduxEntityId}&entity_type=${reduxEntityType}&` : ''
            }template_id=${reduxTemplateId}`
          );
          dispatch(setTearSheetKeys({ ...tearSheetKeysRedux, lists_updated: false }));
          return;
        } else {
          navigate(`/tearsheet?template_id=${default_temp_id}`);
        }
      }
      if (reduxEntityId !== entity_id || reduxTemplateId !== templateId) {
        dispatch(
          setTearSheetKeys({
            entity_id: entity_id,
            entity_type: entity_type,
            templateId: templateId || default_temp_id
          })
        );
        await getAllData();
      }
    } else {
      await getAllData();
    }
  };

  useEffect(() => {
    const initial = async () => {
      setLoading(true);
      initialise();
      await handleRedux();
      await fetchStatus();
      setLoading(false);
    };
    initial();
  }, [entity_id, templateId, currentPage]);
  useEffect(() => {
    let id;
    if (
      tearSheetStatus === t('tearSheet.inProgress') ||
      tearSheetStatus === t('tearSheet.inQueue')
    ) {
      id = setInterval(fetchStatus, 60000);
    }
    return () => clearInterval(id);
  }, [tearSheetStatus]);

  const fetchStatus = async () => {
    if (!entity_id || !templateId) {
      setTearSheetStatus('');
      return;
    }
    try {
      const queryParams = {
        template_id: templateId,
        entity_type: entity_type,
        entity_id: entity_id
      };
      const res = await getApi(`${companyUrl}/v1/company_tear_sheet/status`, queryParams);
      console.log(res);
      const status = res.data.data.job_status;
      setTearSheetStatus(status);
      if (status !== t('tearSheet.inProgress') || tearSheetStatus === t('tearSheet.inQueue')) {
        if (status === t('tearSheet.completed')) {
          setLoading(true);
          await getTearSheetData();
          setLoading(false);
        } else if (status === t('tearSheet.failed')) {
          setTriggerErrorMessage(t('tearSheet.triggerFailedMessage'));
          if (companyData?.response && Object.keys(companyData?.response).length > 0) {
            setTimeout(() => setTriggerErrorMessage(''), 10000);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching status:', error);
    }
  };
  const tearSheetSearchBarProps = {
    companyNameOptions: companyNameOptions,
    entity_id: entity_id,
    handleCompanyChange: handleCompanyChange,
    templateId: templateId,
    handleTemplateChange: handleTemplateChange,
    templateList: currentPage === t('tearSheet.tearsheetRoute') ? templateList : allTemplateList,
    handleManageTemplate: handleManageTemplate,
    currentPage: currentPage,
    handleDuplicate: handleDuplicate,
    setFilterEntityType: setOptionsEntityType,
    filterEntityType: optionsEntityType
  };
  const tearSheetProps = {
    tearSheetData: companyData,
    templateList: templateList,
    templateId: templateId,
    tearSheetStatus: tearSheetStatus,
    handleTearSheetTrigger: handleTearSheetTrigger,
    triggerError: triggerError
  };
  const popOverProps = {
    openState: openPoper,
    title: t('tearsheet.requestPoperTitle'),
    handleClose: handleClosePoper,
    selectedValues: triggerSelectedCompanies,
    handleSubmit: handlePoperSubmit,
    // options: onlyCompany
    //   ? templateData?.entity_list?.filter(
    //       (option) => getNestedValue(option, 'entity_type') === 'company'
    //     )
    //   : templateData?.entity_list,
    options: templateData?.entity_list,
    handleSearchChange: handlePoperSearchChange,
    handleDeleteChange: handlePoperDeleteChange,
    optionFormat: {
      key: 'entity_id',
      value: 'entity_name',
      chipValue: 'entity_type'
    },
    btnText: t('tearSheet.requestPoperBtn'),
    successMessage: poperSuccessMessage,
    errorMessage: triggerError,
    setFilterEntityType: setOptionsEntityType,
    filterEntityType: optionsEntityType
  };
  return (
    <div className={classes.mainDiv}>
      {loading && <LinearProgress />}
      <ErrorToast errorMessage={apiError} />
      <SuccessToast successMessage={successMessage} />
      <AddEntitiesPopOver {...popOverProps} />
      <div className={classes.maxWidthDiv}>
        <TearSheetSearchBar {...tearSheetSearchBarProps} />
        <div className={classes.tearSheetDiv}>
          {currentPage === t('tearSheet.tearsheetRoute') ? (
            <TearSheet {...tearSheetProps} />
          ) : (
            <TearSheetTemplate
              template={templateData}
              initialTemplate={initialTemplateData}
              setTemplate={setTemplateData}
              companyNameOptions={allCompanyOptions}
              handleSaveTemplate={handleSaveTemplate}
              handleDiscardTemplate={handleDiscardTemplate}
              validationErrors={templateValidationErrors}
              numOfTemplates={templateList.length}
              setValidationErrors={setTemplateValidationErrors}
              disabled={
                templateList?.find((template) => template.template_id === templateId)?.data_used ===
                'pb_data'
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TearSheetMainPage;
