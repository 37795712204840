import { Box, Typography, makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '2px 4px',
    marginBottom: '8px'
  },
  icon: {
    marginRight: '8px',
    color: '#EB5B55',
    fontSize: '17px'
  },
  typo: {
    fontSize: '12px',
    color: '#EB5B55'
  }
}));

const InputError = ({ errorMessage }) => {
  const classes = useStyles();
  return (
    errorMessage && (
      <Box className={classes.box}>
        <ErrorOutline className={classes.icon} />
        <Typography className={classes.typo}>{errorMessage}</Typography>
      </Box>
    )
  );
};

export default InputError;
