import React, { useEffect } from 'react';
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../atoms/header';
import SubHeader from '../atoms/subHeader';
import Para from '../atoms/paragraph';
import CustomList from '../atoms/customList';
import FooterMenu from '../footer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  headingColor: {
    'background-image': 'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%)',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  header: {
    marginBottom: theme.spacing(0.8),
    fontFamily: 'Satoshi',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '38px'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: '16px 24px 24px 24px',
    textAlign: 'justify',
    color: theme.palette.text.secondary,
    borderRadius: '16px!important',
    [theme.breakpoints.only('xs')]: {
      padding: '20px'
    }
  },
  customContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '87rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0!important'
    }
  },
  containerDiv: {
    padding: '20px 32px 20px 32px',
    background: '#F7F9FD',
    [theme.breakpoints.only('xs')]: {
      padding: '20px'
    }
  },
  upperCase: {
    textTransform: 'uppercase!important'
  },
  subHeading: {
    color: '#171717',
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    padding: '25px 0 5px 0'
  },
  para: {
    color: '#171717',
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    'padding-top': '10px'
  },
  fw600: {
    fontWeight: 600
  },
  listItem: {
    '& li': {
      padding: '0!important',
      '& span': {
        color: 'var(--Neutral-Colors-Color-800, var(--grey7, #171717))',
        fontFeatureSettings: "'clig' 0, 'liga' 0",
        fontFamily: 'Satoshi',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px'
      }
    }
  }
}));

const TermsAndConditions = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll(0, 0);
  });

  const listItems = [
    'Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.',
    'Trick, defraud, or mislead us and others, especially in any attempt to learn sensitive account information such as user passwords.',
    'Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.',
    'Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.',
    'Use any information obtained from the Site to harass, abuse, or harm another person.',
    'Make improper use of our support services or submit false reports of abuse or misconduct.',
    'Use the Site in a manner inconsistent with any applicable laws or regulations.',
    'Engage in unauthorized framing of or linking to the Site.',
    'Copy, reproduce, modify or disassemble the Site, in whole or in part, to create any derivative work, or in any way use any part of the Site, except as expressly permitted herein.',
    "Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party's uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.",
    'Delete the copyright or other proprietary rights notice from the Site or any Content.',
    'Attempt to impersonate another user or person or use the username of another user.',
    'Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.',
    'Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.',
    'Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.',
    'Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.',
    'Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.'
  ];

  const termAndTermination = [
    'These Terms of Use shall remain in full force and effect while you use the Site.',
    'WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.',
    'Following the termination of these Terms you will pay Us any outstanding amount or fee owed by you. The termination of these Terms shall not relieve you of any liability that you may have incurred or may incur in relation to use of the Site provided prior to such termination. On termination of these Terms, We may delete any content or other materials relating to your use of the Site and We will have no liability to you or any third party for doing so.'
  ];

  const paymentTerms = [
    'All billing and contact information provided is true and correct;',
    'No applicable law shall be violated in making the payment; and',
    'No payment shall be made through third party accounts.'
  ];

  return (
    <>
      <div className={classes.containerDiv}>
        <Container className={classes.customContainer} maxWidth="md">
          <Paper className={classes.paper}>
            <Header>Terms of Use</Header>
            <div>
              <Para>
                These Terms of Use (hereinafter referred to as “Agreement” or “Terms”) constitute a
                legally binding agreement made between you, whether personally or on behalf of an
                entity ("you" or “Your”) and BlokTrek Technologies Private Limited, doing business
                as BlokTrek ("BlokTrek", "PrivateBlok," "we," "us", or "our"). These Terms govern
                your access and use of our website located at{' '}
                <a href="https://www.privateblok.ai">www.privateblok.ai</a>, and all associated
                sites linked to <a href="https://www.privateblok.ai">www.privateblok.ai</a>, its
                subsidiaries and affiliates, including BlokTrek's sites around the world
                (collectively referred to as the “Site”)
              </Para>
              <Para>
                PLEASE READ THIS AGREEMENT CAREFULLY. IT CONTAINS IMPORTANT TERMS THAT AFFECT YOU
                AND YOUR USE OF THE SITE. BY USING OR ACCESSING THE SITE, YOU HEREBY ACCEPT AND
                AGREE TO BE LEGALLY BOUND BY ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU
                DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT USE THE SITE.
              </Para>
              <Para>
                BlokTrek reserves the right, at its sole discretion, to change, modify, add, or
                remove portions of these Terms, at any time. It is your responsibility to check
                these Terms periodically for changes. Your continued use of the Site following the
                posting of changes will mean that you accept and agree to the changes. As long as
                you comply with these Terms, BlokTrek grants you a personal, non-exclusive,
                non-transferable, limited privilege to enter and use the Site.
              </Para>
              <Para>
                Supplemental terms and conditions or documents that may be posted on the Site from
                time to time are hereby expressly incorporated herein by reference. BlokTrek will
                alert you about any changes by updating the "Last updated" date of these Terms of
                Use, and you waive any right to receive specific notice of each such change. The
                information provided on the Site is not intended for distribution to or use by any
                person or entity in any jurisdiction or country where such distribution or use would
                be contrary to law or regulation or which would subject us to any registration
                requirement within such jurisdiction or country. Accordingly, those persons who
                choose to access the Site from other locations do so on their own initiative and are
                solely responsible for compliance with local laws, if and to the extent local laws
                are applicable.
              </Para>
            </div>

            <div>
              <SubHeader>REGISTRATION AND ACCESS</SubHeader>
              <Para>
                <span className={classes.fw600}>Minimum Age.</span> You must be at least 18 years
                old, or the minimum age required in your country to consent to use and access the
                Site. If you are under 18 you must have your parent or legal guardian's permission
                to use the Services.
              </Para>
              <Para>
                <span className={classes.fw600}>Registration.</span> Access to certain portions of
                the Site may require you to register yourself with us, you must provide accurate and
                complete information to register for an account in order to use and access the Site.
                You may not share your account credentials or make your account available to anyone
                else and are responsible for all activities that occur under your account. If you
                create an account or use the Site on behalf of another person or entity, you must
                have the authority to accept these Terms on their behalf.
              </Para>
            </div>

            <div>
              <SubHeader>INTELLECTUAL PROPERTY RIGHTS</SubHeader>
              <Para>
                All text, graphics, user interfaces, visual interfaces, photographs, trademarks,
                logos, and computer code (collectively, "Content"), including but not limited to the
                design, structure, selection, coordination, expression, "look and feel" and
                arrangement of such Content, contained on the Site is owned, controlled or licensed
                by or to BlokTrek, and is protected by trade dress, copyright, patent and trademark
                laws, and various other intellectual property rights and unfair competition laws.
              </Para>
              <Para>
                We reserve all rights, title and interest in and to the Site and the Content,
                including all related intellectual property rights.
              </Para>
              <Para>
                Except as expressly provided in these Terms, no part of the Site and no Content may
                be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, or distributed in any way (including "mirroring") to any
                other computer, server, website, or other medium for publication or distribution or
                for any commercial enterprise, without BlokTrek's express prior written consent.
                These Terms grant no implied license, right, or interest in or to any copyright,
                patent, trade secret, trademark, URL, domain, invention, Content, or other
                intellectual property right of ours.
              </Para>
              <Para>
                You agree to abide by all applicable intellectual property laws, as well as any
                additional restrictions contained in the Site. All present and future rights in and
                to any trade secrets, copyright, trademark, or any other proprietary material
                contained in the Site shall continue to remain the sole property of ours, even after
                the discontinuation of your account for any reason whatsoever.
              </Para>
            </div>

            <div>
              <SubHeader>PROHIBITED ACTIVITIES</SubHeader>
              <Para>
                You may not access or use the Site for any purpose other than that for which we make
                it available. The Site may not be used in connection with any commercial endeavors
                except those that are specifically endorsed or approved by us. You hereby agree not
                to:
              </Para>
              <div>
                <CustomList items={listItems} />
              </div>
              <Para>
                As a condition of use of the Site, you warrant that you will not use the Site for
                any purpose that is unlawful or illegal under any Applicable Law or prohibited by
                these terms, conditions, and notices including both specific and implied. In
                addition, the Site shall not be used in any manner, which could damage, disable,
                overburden, or impair it or interfere with any other party's use and/or enjoyment of
                the Site. We reserve the right to terminate your use of the Site for violating any
                of the prohibited uses.
              </Para>
            </div>

            <div>
              <SubHeader>SUBMISSIONS</SubHeader>
              <Para>
                You acknowledge and agree that any questions, comments, suggestions, ideas,
                feedback, or other information regarding the Site ("<strong>Submissions</strong>")
                provided by you to us are non-confidential and shall become our sole property.
                BlokTrek shall own exclusive rights, including all intellectual property rights, and
                shall be entitled to the unrestricted use and dissemination of these Submissions for
                any lawful purpose, commercial or otherwise, without acknowledgment or compensation
                to you. You hereby waive all moral rights to any such Submissions, and you hereby
                warrant that any such Submissions are original with you or that you have the right
                to submit such Submissions. You agree there shall be no recourse against us for any
                alleged or actual infringement or misappropriation of any proprietary right in your
                Submissions.
              </Para>
            </div>

            <div>
              <SubHeader>THIRD-PARTY WEBSITE AND CONTENT</SubHeader>
              <Para>
                The Site may contain (or you may be sent via the Site) links to other websites (“
                <strong>Third-Party Websites</strong>”) as well as articles, photographs, text,
                graphics, pictures, designs, music, sound, video, information, applications,
                software, and other content or items belonging to or originating from third parties
                (“
                <strong>Third-Party Content</strong>”). Such Third-Party Websites and Third-Party
                Content are not investigated, monitored, or checked for accuracy, appropriateness,
                or completeness by us, and we are not responsible for any Third-Party Website
                accessed through the Site or Third-Party Content posted on, available through, or
                installed from the Site, including the content, accuracy, offensiveness, opinions,
                reliability, privacy practices, or other policies of or contained in the Third-Party
                Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use
                or installation of any Third-Party Websites or any Third-Party Content does not
                imply approval or endorsement thereof by us. If you decide to leave the Site and
                access the Third-Party Websites or to use or install any Third-Party Content, you do
                so at your own risk, and you should be aware these Terms of Use no longer govern.
                You should review the applicable terms and policies, including privacy and data
                gathering practices, of any website to which you navigate from the Site or relating
                to any applications you use or install from the Site. Any purchases you make through
                Third-Party Websites will be through other websites and from other companies, and we
                take no responsibility whatsoever in relation to such purchases which are
                exclusively between you and the applicable third party. You agree and acknowledge
                that we do not endorse the products or services offered on Third-Party Websites and
                you shall hold us harmless from any harm caused by your purchase of such products or
                services. Additionally, you shall hold us harmless from any losses sustained by you
                or harm caused to you relating to or resulting in any way from any Third-Party
                Content or any contact with Third-Party Websites.
              </Para>
              <Para>
                The platform uses Generative AI on data obtained from the Ministry of Corporate
                Affairs and publicly available data, including facts obtained from news. For list of
                attributed news data for each company, please refer to the "News" tab for respective
                company on the portal page.
              </Para>
            </div>

            <div>
              <SubHeader>SITE MANAGEMENT</SubHeader>
              <Para>
                BlokTrek reserves the right, but not the obligation, to: (1) monitor the Site for
                violations of these Terms of Use; (2) take appropriate legal action against anyone
                who, in our sole discretion, violates the law or these Terms of Use, including
                without limitation, reporting such user to law enforcement authorities; (3) in our
                sole discretion and without limitation, refuse, restrict access to, limit the
                availability of, or disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof; (4) in our sole discretion and without
                limitation, notice, or liability, to remove from the Site or otherwise disable all
                files and content that are excessive in size or are in any way burdensome to our
                systems; and (5) otherwise manage the Site in a manner designed to protect our
                rights and property and to facilitate the proper functioning of the Site.
              </Para>
            </div>

            <div>
              <SubHeader>CORRECTIONS</SubHeader>
              <Para>
                There may be information on the Site that contains typographical errors,
                inaccuracies, or omissions, including descriptions, pricing, availability, and
                various other information. We reserve the right to correct any errors, inaccuracies,
                or omissions and to change or update the information on the Site at any time,
                without prior notice.
              </Para>
            </div>

            <div>
              <SubHeader>TERM AND TERMINATION</SubHeader>
              <Para>
                There may be information on the Site that contains typographical errors,
                inaccuracies, or omissions, including descriptions, pricing, availability, and
                various other information. We reserve the right to correct any errors, inaccuracies,
                or omissions and to change or update the information on the Site at any time,
                without prior notice.
              </Para>
              <div>
                <CustomList items={termAndTermination} isNumberedList={false} />
              </div>
            </div>

            <div>
              <SubHeader>Payment Terms</SubHeader>
              <Para>
                If you subscribe to our paid services, You shall pay us all applicable Fees as has
                been communicated to you, using Our selected payment method within 10 (Ten) days of
                receipt of Our invoice.
              </Para>
              <Para>
                The Fees communicated to you is exclusive of any applicable taxes. All applicable
                taxes shall be borne by you. You will withhold any applicable taxes if required by
                law to do so and shall provide us with an official tax certificate or any other
                relevant document.
              </Para>
              <Para>Any change in fee structure shall be informed to you in advance.</Para>
              <Para>You will ensure that.</Para>
              <Para>
                Subscription fee is non-refundable other than in case where wrong payments have been
                done or mandated by law. If We terminate the agreement, you will be refunded on
                pro-rata basis. Any refunds to be made will be made through the same medium as
                originally received from.
              </Para>
              <Para>
                Non-compliance of these Payment Terms shall lead to the suspension of your account
                with Us.
              </Para>
              <div>
                <CustomList items={paymentTerms} />
              </div>
            </div>

            <div>
              <SubHeader>CONFIDENTIALITY</SubHeader>
              <Para>
                The parties acknowledge that they shall receive information belonging to the other
                party that can be reasonably construed to be confidential and privileged information
                not intended to be disclosed to any third parties or other party, unless it needs to
                be disclosed for the fulfillment of these Terms. The parties agree to use reasonable
                standards of care to keep such information confidential. Nothing contained herein
                shall affect the ability of the parties to make disclosures mandated by applicable
                laws.
              </Para>
            </div>

            <div>
              <SubHeader>PRIVACY</SubHeader>
              <Para>
                BlokTrek's Privacy Policy applies to use of this Site, and its terms are made a part
                of these Terms of Use by this reference. To view BlokTrek's Privacy Policy,{' '}
                <a
                  href="https://public.privateblok.ai/policies/privacy_policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here
                </a>
                . Additionally, by using the Site, you acknowledge and agree that Internet
                transmissions are never completely private or secure. You understand that any
                message or information you send to the Site may be read or intercepted by others,
                even if there is a special notice that a particular transmission (for example,
                credit card information) is encrypted.
              </Para>
            </div>

            <div>
              <SubHeader>USE OF OUR LOGO AND TRADEMARK</SubHeader>
              <Para>
                The trademarks, logos and service marks displayed on the Site are Our property. You
                are not permitted to use the trademarks, logos and service marks without Our prior
                written consent or of the respective third party.
              </Para>
            </div>

            <div>
              <SubHeader>MODIFICATIONS AND INTERRUPTIONS</SubHeader>
              <Para>
                We reserve the right to change, modify, or remove the contents of the Site at any
                time or for any reason at our sole discretion without notice. We also reserve the
                right to modify or discontinue all or part of the Site without notice at any time.
                We will not be liable to you or any third party for any modification, price change,
                suspension, or discontinuance of the Site.We will give you at least 30 days advance
                notice of changes to these Terms that materially adversely impact you either via
                email or an in-product notification. All other changes will be effective as soon as
                we post them to our website. If you do not agree to the changes, you must stop using
                our Services.
              </Para>
              <Para>
                We cannot guarantee the Site will be always available. We shall not be held
                responsible for non-availability of the Site during periodic maintenance operations
                or any unplanned suspension of access to the Site that may occur due to any reason
                beyond Our control. You agree that we have no liability whatsoever for any loss,
                damage, or inconvenience caused by your inability to access or use the Site during
                any downtime or discontinuance of the Site. Nothing in these Terms of Use will be
                construed to obligate us to maintain and support the Site or to supply any
                corrections, updates, or releases in connection therewith.
              </Para>
            </div>

            <div>
              <SubHeader>FORCE MAJEURE</SubHeader>
              <Para>
                Neither parties will be liable for any inadequate performance of their obligations
                under these Terms, for reasons beyond their control (i.e., War, Government
                legislations, accidents, floods, etc.)
              </Para>
            </div>

            <div>
              <SubHeader>GOVERNING LAW AND JURISDICTION</SubHeader>
              <Para>
                These Terms shall be governed by and construed in accordance with the laws of India
                and the courts of Bangalore shall have exclusive jurisdiction to any disputes,
                claims and actions, whether contractual or non-contractual, arising out of or in
                connection to these Terms
              </Para>
            </div>

            <div>
              <SubHeader>INDEMNIFICATION AND LIMITATION OF LIABILITY</SubHeader>
              <Para>
                You shall indemnify and hold Us , Our Affiliates and Our respective officers,
                directors, employees and agents harmless from and against all claims, damages,
                losses and expenses (including reasonable attorneys' fees) arising out of any claim
                arising from a breach of Your obligations under these Terms.
              </Para>
              <Para>
                BLOKTREK WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
                EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR
                OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR
                AGGREGATE LIABILITY UNDER THESE TERMS WILL NOT EXCEED THE GREATER OF THE AMOUNT YOU
                PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE
                LIABILITY AROSE OR (Amount). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE
                MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
              </Para>
              <Para>
                Some countries and states do not allow the disclaimer of certain warranties or the
                limitation of certain damages, so some or all of the terms above may not apply to
                you, and you may have additional rights. In that case, these Terms only limit our
                responsibilities to the maximum extent permissible in your country of residence.
              </Para>
            </div>

            <div>
              <SubHeader>DISCLAIMER</SubHeader>
              <Para>
                The Site is provided on an “As Is” basis without warranties of any kind. We make no
                representation or warranty, either express or implied, regarding the adequacy,
                completeness, usefulness and accuracy of the Site. You shall exercise your
                discretion while using the Site or the content therein.
              </Para>
              <Para>
                Nothing contained in the Site shall constitute or should be construed as a
                solicitation, offer or recommendation by Us.
              </Para>
              <Para>
                We accept no liability for any errors or omissions, with respect to any information
                provided to you whether on behalf of itself or third parties.
              </Para>
              <Para>
                The Site is for educational and information purposes only and not intended to
                provide any financial, investment or legal advice. You shall seek the help of a
                professional in pursuing such advice. Any article, publication, statement, report,
                information, data or content available on the Site or on any link to the Site will
                not be sought as an advice or opinion from Us.
              </Para>
            </div>

            <div>
              <SubHeader>ASSIGNMENT</SubHeader>
              <Para>
                You may not assign or transfer any rights or obligations under these Terms and any
                attempt to do so will be void. We may assign our rights or obligations under these
                Terms to any affiliate, subsidiary, or successor in interest of any business
                associated with our Services.
              </Para>
            </div>

            <div>
              <SubHeader>DELAY IN ENFORCING THESE TERMS</SubHeader>
              <Para>
                Our failure to enforce a provision is not a waiver of our right to do so later. If
                any portion of these Terms is determined to be invalid or unenforceable, that
                portion will be enforced to the maximum extent permissible and it will not affect
                the enforceability of any other terms.
              </Para>
            </div>

            <div>
              <SubHeader>ENTIRE AGREEMENT</SubHeader>
              <Para>
                These Terms contain the entire agreement between you and BlokTrek regarding the
                usage of the site and, supersedes any prior or contemporaneous agreements between
                you and BlokTrek.
              </Para>
            </div>
          </Paper>
        </Container>
      </div>
      <FooterMenu />
    </>
  );
};

export default TermsAndConditions;
