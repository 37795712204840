import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import { Typography, makeStyles } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import CustomTextField from '../../../atoms/textfield';

const useStyles = makeStyles(() => ({
  mainDiv: {
    borderRadius: '16px !important',
    height: '566px',
    width: '500px'
  },
  autocompleteInput: {
    height: '4px !important'
  },
  closeBtn: {
    position: 'absolute !important',
    right: '10px',
    top: '8px'
  },
  saveBtn: {
    background: 'linear-gradient(90deg,#004CFF,#06BCC1)'
  },
  dialogClass: {
    borderRadius: '16px !important',
    height: 'auto',
    width: '500px'
  },
  dialogMain: {
    display: 'flex',
    'flex-direction': 'column',
    gap: '20px'
  },
  errorMessage: {
    color: 'red'
  }
}));

const CustomDialog = ({ title = '', open = false, save = () => {}, handleClose = () => {} }) => {
  const classes = useStyles();
  const [templateName, setTemplateName] = useState('');
  const [templateDesc, setTemplateDesc] = useState('');
  const [submitTemplate, seSubmitTemplate] = useState(false);

  const resetTemplateForm = () => {
    setTemplateName('');
    setTemplateDesc('');
    seSubmitTemplate(false);
  };

  const onSave = async () => {
    seSubmitTemplate(true);
    await save(templateName, templateDesc, resetTemplateForm);
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialogClass }}>
      <IconButton
        className={classes.closeBtn}
        onClick={() => {
          resetTemplateForm();
          handleClose();
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle style={{ margin: '20px 0px 0px' }}>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.dialogMain}>
          <div>
            <CustomTextField
              value={templateName}
              handleChange={(value) => {
                setTemplateName(value);
              }}
              placeholder="Template Name"
            />
            {submitTemplate && !templateName && (
              <span className={classes.errorMessage}>Required *</span>
            )}
          </div>
          <div>
            <CustomTextField
              value={templateDesc}
              handleChange={(value) => {
                setTemplateDesc(value);
              }}
              placeholder="Template Description"
            />
            {submitTemplate && !templateDesc && (
              <span className={classes.errorMessage}>Required *</span>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'flex', flexDirection: 'column', padding: '0px 30px 16px' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.saveBtn}
          onClick={onSave}
          style={{ textTransform: 'none', fontFamily: 'Satoshi', fontSize: '16px' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
