import { Divider, Typography, makeStyles } from '@material-ui/core';
import { Box, Paper } from '@mui/material';
import { defaultSection } from '../tearSheetTemplateMocks';
import CustomTextField from '../../../atoms/textfield';
import TemplateSectionCard from './templateSectionCard';
import CustomButton from '../../../atoms/buttons/button';
import { Add, DeleteForever, Save } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { SaveAs } from '@mui/icons-material';
import ErrorToast from '../../../atoms/errorToast';
import SecondaryButton from '../../../atoms/buttons/secondaryBtn';
import DeleteButton from '../../../atoms/buttons/deleteBtn';
import InputError from '../../../atoms/inputError';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import MultiSearch from '../../../atoms/entitySearch/multiSearch';
import {
  entitesFilterToDisplayMap,
  entityTypeToDisplayMap
} from '../../../../constants/tearSheet/tearSheet';
import { getNestedValue } from '../../../utils/utils';
import CustomChip from '../../../atoms/chip';
import EntitiesChipLabel from '../../../atoms/entityChipLabel';

const useStyles = makeStyles((theme) => ({
  tearSheet: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '16px !important',
    padding: '16px'
  },
  formLevelOne: {
    display: 'flex',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  keyDiv: {
    width: '20%',
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  valueDiv: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  keyTypography: {
    fontSize: '16px',
    fontWeight: '550',
    overflowWrap: 'break-word'
  },
  selectedTypography: {
    fontWeight: '400',
    fontSize: '16px',
    margin: '0px 10px 0px 0px'
  },
  selectedCompaniesDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  divider: {
    backgroundColor: '#b1c8ff',
    margin: '15px 0px'
  },
  sectionsDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  sectionCardDiv: {
    width: '86%',
    margin: '8px 0 8px 14%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  addSectionBtn: {
    margin: '8px 14%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0'
    }
  },
  mainActionBtns: {
    display: 'flex',
    justifyContent: 'end'
  },
  discardBtn: {
    margin: '0px 8px'
  },
  inputErrorDisplacer: {
    marginTop: '-33px'
  },
  textClickable: {
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none',
    margin: '0px 4px'
  }
}));

const MAX_AGE = 60 * 60 * 24 * 1000;
const MAX_QUESTIONS = process.env.REACT_APP_TEAR_SHEET_QUESTION_LIMIT;
const TearSheetTemplate = ({
  companyNameOptions,
  template,
  setTemplate,
  handleSaveTemplate,
  handleDiscardTemplate,
  validationErrors,
  numOfTemplates,
  disabled,
  initialTemplate,
  setValidationErrors
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sectionsCopied, setSectionsCopied] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [pasteEnabled, setPasteEnabled] = useState(false);
  const [optionsEntityType, setOptionsEntityType] = useState('all');
  const handleSectionCopy = (section, sectionIndex) => {
    localStorage.setItem(
      'section',
      JSON.stringify({
        timestamp: new Date().getTime(),
        data: { ...section }
      })
    );
    setSectionsCopied(Array(template?.question_groups?.length).fill(false));
    setSectionsCopied((prev) => {
      const updatedSectionsCopied = [...prev];
      updatedSectionsCopied[sectionIndex] = true;
      return updatedSectionsCopied;
    });
    cleanupLocalStorage('section');
    setTimeout(() => {
      setSectionsCopied(Array(template?.question_groups?.length).fill(false));
    }, 5000);
  };

  useEffect(() => {
    setSectionsCopied(Array(template?.question_groups?.length).fill(false));
  }, []);

  const handleSectionPaste = (sectionIndex) => {
    let copiedSection = localStorage.getItem('section');
    if (!copiedSection) {
      return;
    }

    copiedSection = JSON.parse(copiedSection);
    const now = new Date().getTime();
    if (now - copiedSection.timestamp > MAX_AGE) {
      localStorage.removeItem('section');
      return;
    }
    handleSectionChange(sectionIndex, copiedSection.data);
  };

  const cleanupLocalStorage = (key) => {
    const itemStr = localStorage.getItem(key);
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date().getTime();
      if (now - item?.timestamp > MAX_AGE) {
        localStorage.removeItem(key);
      } else {
        setPasteEnabled(true);
      }
    }
  };

  useEffect(() => {
    cleanupLocalStorage('section');
    const cleanupInterval = setInterval(() => {
      cleanupLocalStorage('section');
    }, 60 * 60 * 1000);
    return () => clearInterval(cleanupInterval);
  }, []);

  const validateQuestionLimit = (updatedTemplate) => {
    const numOfQuestions = updatedTemplate?.question_groups.reduce((acc, curr) => {
      return acc + curr.questions.length;
    }, 0);
    if (numOfQuestions > MAX_QUESTIONS) {
      return false;
    }
    return true;
  };
  const handleTemplateChange = (key, value) => {
    let updatedTemplate = JSON.parse(JSON.stringify({ ...template }));
    updatedTemplate[key] = value;
    if (!validateQuestionLimit(updatedTemplate)) {
      setErrorMessage(t('tearSheet.questionLimitError'));
      setTimeout(() => setErrorMessage(''), 10000);
      return;
    }
    setTemplate(updatedTemplate);
  };
  const handleRemoveCompany = (entity_id) => {
    let updatedCompanyList = JSON.parse(JSON.stringify([...template.entity_list]));
    updatedCompanyList = updatedCompanyList.filter(
      (obj) => getNestedValue(obj, 'entity_id') !== entity_id
    );
    handleTemplateChange('entity_list', updatedCompanyList);
  };
  const handleSectionChange = (index, value) => {
    let updatedSections = JSON.parse(JSON.stringify([...template.question_groups]));
    updatedSections[index] = value;
    handleTemplateChange('question_groups', updatedSections);
  };
  const handleDeleteSection = (index) => {
    let updatedSections = JSON.parse(JSON.stringify([...template.question_groups]));
    updatedSections.splice(index, 1);
    let updatedSectionsCopied = JSON.parse(JSON.stringify([...sectionsCopied]));
    updatedSectionsCopied.splice(index, 1);
    setSectionsCopied(updatedSectionsCopied);
    if (validationErrors?.question_groups) {
      setValidationErrors((prev) => {
        let updatedErrors = { ...prev };
        updatedErrors.question_groups.splice(index, 1);
        return updatedErrors;
      });
    }
    handleTemplateChange('question_groups', updatedSections);
  };
  const handleAddSection = () => {
    let updatedSections = JSON.parse(JSON.stringify([...template.question_groups]));
    updatedSections.push(defaultSection);
    let updatedSectionsCopied = JSON.parse(JSON.stringify([...sectionsCopied]));
    updatedSectionsCopied.push(false);
    setSectionsCopied(updatedSectionsCopied);
    if (validationErrors?.question_groups) {
      setValidationErrors((prev) => ({
        ...prev,
        question_groups: [...prev.question_groups, {}]
      }));
    }
    handleTemplateChange('question_groups', updatedSections);
  };
  const handleSectionsReorder = (result) => {
    if (!result.destination) return; // dropped outside the list
    const reorderedSections = Array.from(template.question_groups);
    const [reorderedSection] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, reorderedSection);
    let updatedSectionsCopied = JSON.parse(JSON.stringify([...sectionsCopied]));
    const [reorderedSectionCopied] = updatedSectionsCopied.splice(result.source.index, 1);
    updatedSectionsCopied.splice(result.destination.index, 0, reorderedSectionCopied);
    setSectionsCopied(updatedSectionsCopied);
    if (validationErrors?.question_groups) {
      let updateSectionsErrors = JSON.parse(JSON.stringify([...validationErrors.question_groups]));
      const [reorderedSectionError] = updateSectionsErrors.splice(result.source.index, 1);
      updateSectionsErrors.splice(result.destination.index, 0, reorderedSectionError);
      setValidationErrors((prev) => ({
        ...prev,
        question_groups: updateSectionsErrors
      }));
    }
    handleTemplateChange('question_groups', reorderedSections);
  };
  const filteredOptions = () => {
    if (optionsEntityType) return companyNameOptions;
    return companyNameOptions.filter(
      (option) => getNestedValue(option, 'entity_type') === optionsEntityType
    );
  };
  return (
    <Paper className={classes.tearSheet}>
      <ErrorToast errorMessage={errorMessage} />
      {template ? (
        <div>
          <div className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>
                {t('tearSheet.templateNameLabel')}
              </Typography>
            </div>
            <div className={classes.valueDiv}>
              <CustomTextField
                value={template.name}
                handleChange={(value) => handleTemplateChange('name', value)}
                placeholder={t('tearSheet.templateNamePlaceholder')}
                errorMessage={validationErrors?.name}
                disabled={disabled}
              />
            </div>
          </div>
          <Divider className={`${classes.formLevelOne} ${classes.divider}`} />
          <div className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>
                {t('tearSheet.entitiesLabel')}
              </Typography>
            </div>
            <div className={classes.valueDiv}>
              <MultiSearch
                placeholder={t('tearSheet.searchBarPlaceholder')}
                handleChange={(event, value) => handleTemplateChange('entity_list', value)}
                options={filteredOptions()}
                selectedValues={template.entity_list}
                optionFormat={{
                  key: 'entity_id',
                  value: 'entity_name',
                  chipValue: 'entity_type'
                }}
                clearable={true}
                errorMessage={validationErrors?.entity_list}
                disabled={disabled}
                chipValueMap={entityTypeToDisplayMap}
                setFilterEntityType={setOptionsEntityType}
                filterEntityType={optionsEntityType}
                filterTabsMap={entitesFilterToDisplayMap}
              />
            </div>
          </div>
          {template?.entity_list && template.entity_list.length > 0 && (
            <div className={classes.formLevelOne}>
              <div className={classes.keyDiv} />
              <div className={classes.selectedCompaniesDiv}>
                <Typography className={classes.selectedTypography}>Selected</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {template.entity_list.map(
                    (comp, index) =>
                      comp && (
                        <div key={index}>
                          <CustomChip
                            value={comp?.entity_id}
                            label={<EntitiesChipLabel entity={comp} />}
                            handleDelete={handleRemoveCompany}
                            disabled={disabled}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          )}
          <Divider className={`${classes.formLevelOne} ${classes.divider}`} />
          <div className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>Sections</Typography>
            </div>
            {template?.question_groups?.length === 0 && validationErrors?.question_groups_error && (
              <div className={classes.valueDiv} style={{ marginTop: '8px' }}>
                <InputError errorMessage={validationErrors?.question_groups_error} />
              </div>
            )}
          </div>
          <div className={classes.sectionsDiv}>
            <DragDropContext onDragEnd={handleSectionsReorder}>
              <Droppable droppableId="sections">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {template.question_groups?.map((section, sectionIndex) => (
                      <Draggable
                        key={sectionIndex}
                        draggableId={String(sectionIndex)}
                        index={sectionIndex}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classes.sectionCardDiv}
                            style={{ userSelect: 'none', ...provided.draggableProps.style }}
                          >
                            <TemplateSectionCard
                              sectionIndex={sectionIndex}
                              validationErrors={validationErrors?.question_groups?.[sectionIndex]}
                              section={section}
                              copied={sectionsCopied[sectionIndex]}
                              handleSectionChange={(value) =>
                                handleSectionChange(sectionIndex, value)
                              }
                              handleDeleteSection={() => handleDeleteSection(sectionIndex)}
                              handleSectionCopy={() => handleSectionCopy(section, sectionIndex)}
                              handleSectionPaste={() => handleSectionPaste(sectionIndex)}
                              disabled={disabled}
                              pasteEnabled={pasteEnabled}
                              dragHandleProps={{ ...provided.dragHandleProps }}
                              copyEnabled={template?._id}
                              setValidationErrors={setValidationErrors}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {!disabled && (
            <>
              <div className={classes.addSectionBtn}>
                {/* <CustomButton
                  StartIcon={Add}
                  btnText="Add Section"
                  handleClick={handleAddSection}
                  //
                /> */}
                <SecondaryButton
                  StartIcon={Add}
                  btnText={t('tearSheet.addSection')}
                  handleClick={handleAddSection}
                  //
                />
              </div>
              <div className={classes.mainActionBtns}>
                <div className={classes.discardBtn}>
                  {/* <CustomButton
                    btnText="Discard Changes"
                    StartIcon={Delete}
                    handleClick={handleDiscardTemplate}
                  /> */}
                  <DeleteButton
                    btnText={t('tearSheet.templateDiscardBtn')}
                    StartIcon={DeleteForever}
                    handleClick={handleDiscardTemplate}
                    disabled={JSON.stringify(initialTemplate) === JSON.stringify(template)}
                  />
                </div>
                <div className={classes.saveBtn}>
                  <CustomButton
                    btnText={
                      template?._id
                        ? t('tearSheet.saveTemplateBtn')
                        : t('tearSheet.createTemplateBtn')
                    }
                    StartIcon={template?._id ? Save : SaveAs}
                    handleClick={handleSaveTemplate}
                    disabled={JSON.stringify(initialTemplate) === JSON.stringify(template)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <Box textAlign="center">
          {numOfTemplates ? (
            <Typography>{t('tearSheet.templateSelectToEdit')}</Typography>
          ) : (
            <Typography>
              {t('tearSheet.templateCreateNewLeft')}
              <Link
                to="/template?template_id=new"
                className={classes.textClickable}
                onClick={() => window.scroll(0, 0)}
              >
                Create New Template
              </Link>
              {t('tearSheet.templateCreateNewRight')}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default TearSheetTemplate;
