import { makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  mainBox: {
    overflowY: 'auto'
  },
  section: {
    background: '#F7F9FD',
    border: '1px solid #E4EBFC',
    borderRadius: '8px',
    padding: '20px 20px 12px 20px',
    marginBottom: '10px'
  },
  section2: {
    marginTop: '20px',
    paddingLeft: '8px'
  },
  sectionHeading: {
    color: '#171717',
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '8px'
  },
  sectionPara: {
    color: '#171717',
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '8px'
  },
  sectionList: {
    margin: '0px 0px 8px 0px',
    padding: '0px 0px 0px 20px'
  },
  sectionListItem: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#171717'
  }
}));
const AgreementContent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>
          1. THIS SOFTWARE AS A SERVICE (SAAS) TRIAL SUBSCRIPTION AGREEMENT (Agreement) is between
        </Typography>
        <Typography className={classes.sectionHeading}>Your Organization</Typography>
        <Typography className={classes.sectionHeading}>AND</Typography>
        <Typography className={classes.sectionPara}>
          <strong>BLOKTREK</strong> Inc, a company duly incorporated under the laws of Delaware, USA
          and having its registered office at 6 Brians Way, Princeton Jct, NJ 08550, hereinafter
          referred to as <strong>BLOKTREK</strong>.
        </Typography>
        <Typography className={classes.sectionPara}>
          This agreement governs enrollment in and use of the Trial Services by you, your
          Organization, and anyone given access to the Trial Services.
        </Typography>
        <Typography className={classes.sectionPara}>
          You are entering into this Agreement on behalf of your Organization and not in your
          individual capacity. By using your Organization-assigned email address to enroll in the
          Trial Services, you represent that you:
        </Typography>
        <Box component="ol" className={classes.sectionList}>
          <Typography component="li" className={classes.sectionListItem}>
            have authority to enter into this Agreement on behalf of your Organization; and
          </Typography>
          <Typography component="li" className={classes.sectionListItem}>
            will comply with your Organization's policies relevant to enrollment, purchase, and use
            of the Trial Services.
          </Typography>
        </Box>
        <Typography className={classes.sectionPara}>
          Licenses for the Trial Services are owned and controlled by your Organization. This is
          true even if you provide your personal payment information when enrolling in the Trial
          Services. Your Organization has full control over the Trial Services and may cancel,
          reassign, or otherwise restrict access to the Trial Services at any time.
        </Typography>
        <Typography className={classes.sectionPara}>
          <strong>BlokTrek</strong> has developed <strong>“PrivateBlok”</strong> software as a
          service offering ("Software”). By accessing or using the trial version of the Software,
          you agree to be bound by the terms and conditions of this Agreement.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>2. License Grant</Typography>
        <Typography className={classes.sectionPara}>
          The Company hereby grants the User a non-exclusive, non-transferable, limited license to
          use the Software for a trial period of 90 days from the date of activation ("Trial
          Period") solely for evaluation purposes.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>3. Scope of Use</Typography>
        <Typography className={classes.sectionPara}>
          The User may use the Software solely for internal business purposes and evaluation. The
          User may not use the Software for commercial productionpurposes during the Trial Period.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>4. Restrictions</Typography>
        <Typography className={classes.sectionPara}>The User agrees not to:</Typography>
        <Box component="ul" className={classes.sectionList}>
          <Typography component="li" className={classes.sectionListItem}>
            Use the Software for any commercial production purposes.
          </Typography>
          <Typography component="li" className={classes.sectionListItem}>
            Copy, modify, or create derivative works of the Software.
          </Typography>
          <Typography component="li" className={classes.sectionListItem}>
            Reverse engineer, decompile, or disassemble the Software.
          </Typography>
          <Typography component="li" className={classes.sectionListItem}>
            Rent, lease, or sublicense the Software.
          </Typography>
          <Typography component="li" className={classes.sectionListItem}>
            Remove or alter any proprietary notices or labels on the Software.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>5. Data</Typography>
        <Typography className={classes.sectionPara}>
          During the Trial Period, the User may submit content or data into the Software. The User
          retains all rights to their data. Upon termination or expiration of the Trial Period,
          theCompany may delete or disable access to any data provided by the User.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>6. Trial Period and Termination</Typography>
        <Typography className={classes.sectionPara}>
          This Agreement and the license granted herein will terminate automatically at the end of
          the Trial Period unless extended by the Company in writing. The Company may terminate this
          Agreement at any time without notice if the User breaches any term of this Agreement. Upon
          termination, the User must cease all use of the Software and destroy all copies in their
          possession.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>7. Ownership</Typography>
        <Typography className={classes.sectionPara}>
          The Software is licensed, not sold. The Company retains all rights, title, and interest in
          and to the Software, including all intellectual property rights.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>8. No Warranty</Typography>
        <Typography className={classes.sectionPara}>
          The Software is provided "as is" without any warranties of any kind, either express or
          implied, including but not limited to warranties of merchantability, fitness for a
          particular purpose, and non-infringement. The Company does not warrant that the Software
          will be uninterrupted or error-free.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>9. Limitation of Liability</Typography>
        <Typography className={classes.sectionPara}>
          To the maximum extent permitted by law, the Company shall not be liable for any indirect,
          incidental, special, or consequential damages arising out of or related to the use or
          inability to use the Software, even if the Company has been advised of the possibility of
          such damages.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>10. Support</Typography>
        <Typography className={classes.sectionPara}>
          During the Trial Period, the Company may, but is not obligated to, provide limited
          technical support for the Software.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>11. Governing Law</Typography>
        <Typography className={classes.sectionPara}>
          This Agreement shall be governed by and construed in accordance with the laws of India,
          without regard to its conflict of law principles.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>12. Entire Agreement</Typography>
        <Typography className={classes.sectionPara}>
          This Agreement constitutes the entire agreement between the parties regarding the use of
          the Software during the Trial Period and supersedes all prior or contemporaneous
          understandings regarding such subject matter.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>13. Amendments</Typography>
        <Typography className={classes.sectionPara}>
          The Company reserves the right to modify this Agreement at any time. The User will be
          notified of any changes and must accept the updated terms to continue using the Software.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>14. Contact Information</Typography>
        <Typography className={classes.sectionPara}>
          If you have any questions about this Agreement, please contact us at:
        </Typography>
        <Typography className={classes.sectionPara}>
          <strong>BlokTrek</strong>
          <br />
          Indiqube Edge, Bellandur, Bangalore 560103
        </Typography>
      </Box>
      <Box className={classes.section2}>
        <Typography className={classes.sectionHeading}>Transition to Paid License</Typography>
        <Typography className={classes.sectionPara}>
          Upon expiration of the Trial Period, the User will have the option to purchase a full
          license for the Software. The terms and conditions of the full license agreement, which
          will be presented at the time of purchase, will supersede this Trial License Agreement.
        </Typography>
      </Box>
    </Box>
  );
};

export default AgreementContent;
