import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    padding: '6px 8px',
    '&:disabled': {
      background: '#cccccc'
    }
  },
  startIcon: {
    marginRight: '-4px',
    fontSize: '17px',
    color: 'white'
  },
  typo: {
    fontSize: '14px',
    color: '#FFFFFF'
  }
}));

const CustomButton = ({
  handleClick = () => {},
  StartIcon = null,
  btnText = '',
  styleClass = {},
  disabled = null
}) => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      fullWidth
      className={`${classes.root} ${styleClass}`}
      onClick={handleClick}
      startIcon={StartIcon ? <StartIcon className={classes.startIcon} /> : null}
      disabled={disabled}
    >
      <Typography className={classes.typo}>{btnText}</Typography>
    </Button>
  );
};

export default CustomButton;
