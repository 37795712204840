import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { postApi, putApi } from '../../../../services/token';
import { makeStyles } from '@material-ui/core/styles';
import LoadingPopover from '../../loadingPopover/loadingPopover';
import ErrorToast from '../../../atoms/errorToast';
import SuccessToast from '../../../atoms/successToast';
import { Button, Grid } from '@mui/material';
// import CustomChip from '../atoms/chip';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as FileUploadIcon } from '../../../../components/icons/FileUploadIcon.svg';
import { ReactComponent as FileRemoveIcon } from '../../../../components/icons/FileRemoveIcon.svg';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: '20%',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      margin: '15px 0 23px 0'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '40%'
    },
    float: 'right'
  },
  uploadText: {
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center'
  },
  uploadTextHeading: {
    fontFamily: 'Satoshi',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '30px',
    color: '#171717 !important'
  },
  uploadTextSubHeading: {
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '30px',
    color: '#171717 !important'
  },
  uploadHeadderFiles: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'left'
  },
  uploadFilesName: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textAlign: 'left',
    wordBreak: 'break-all',
    color: '#171717',
    paddingTop: '16px'
  },
  uploadHeadderTags: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center'
  },
  uploadHeadderDelete: {
    fontFamily: 'Satoshi',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'right'
  },
  uploadCheckBoxTest: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '18.9px',
    textAlign: 'left'
  },
  uploadButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px'
  },
  cancleButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #797F8D, #797F8D)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#FFFFFF',
      zIndex: -1,
      transition: '200ms'
    }
  },
  fileGrid: {
    padding: '60px 0px 0px 0px'
  },
  removeFile: {
    fontFamily: 'Satoshi',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'right',
    padding: '16px 5px 5px 0px'
  },
  uploadHeadderTagsMain: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center',
    padding: '16px 5px 5px 0px'
  }
}));

const UploadDocExpFile = ({ handleClosePopup, handleUploadPopup }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const classes = useStyles();
  const url = process.env.REACT_APP_INGESTION_API_URL;
  useEffect(() => {
    const successTimer = setTimeout(() => {
      setSuccessMessage(null);
    }, 7000);

    const errorTimer = setTimeout(() => {
      setErrorMessage(null);
    }, 7000);

    return () => {
      clearTimeout(successTimer);
      clearTimeout(errorTimer);
    };
  }, [successMessage, errorMessage]);

  const handleRemoveFile = (uuid) => {
    const updatedFiles = files.filter((file) => file.uuid !== uuid);
    setFiles(updatedFiles);
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const payload = createPayload();

      const response = await postApi(`${url}/v1/document/ingestion`, payload);

      const uploadUrls = response.data.data.upload_urls;

      for (const filename in uploadUrls) {
        const uploadKey = uploadUrls[filename].upload_key;

        const fileIndex = files.findIndex((file) => file.name === filename);
        if (fileIndex !== -1) {
          const file = files[fileIndex].file;
          const formData = new FormData();
          formData.append(filename, file);

          try {
            await putApi(uploadKey, formData, {
              headers: {
                skipAuth: 'true'
              }
            });
            handleUploadPopup();
          } catch (error) {
            setErrorMessage(`Error uploading file ${filename}.`);
          }
        }
      }

      setSuccessMessage('Files uploaded successfully.');
      setIsLoading(false); // Hide loader
      setFiles([]);
    } catch (error) {
      setErrorMessage(error.response.data.error.detail);
      setIsLoading(false); // Hide loader
    }
  };

  const createPayload = () => {
    const payload = {
      upload_type: 'file',
      input_source: 'doc_explorer',
      file_details: files.map((file) => {
        let association_types = file.association_types;
        let size = file.size;
        const source_link = file.name;
        let association_details = {};
        const document_type = file.document_type;
        if (file.association_types.includes('webLinks')) {
          association_types = ['no_association_type'];
        } else {
          if (
            file.sectorSelectedValues &&
            file.sectorSelectedValues.length > 0 &&
            file.association_types.includes('themes')
          ) {
            association_details['themes_list'] = file.sectorSelectedValues.map(
              (sector) => sector.key
            );
          }

          if (
            file.companySelectedValues &&
            file.companySelectedValues.length > 0 &&
            file.association_types.includes('company')
          ) {
            association_details['cin_list'] = file.companySelectedValues.map(
              (company) => company.key
            );
          }
        }
        return {
          association_types,
          size,
          source_link,
          association_details,
          document_type
        };
      })
    };
    return payload;
  };

  const validateFileTags = () => {
    const missingDocumentTypeFiles = files.filter((file) => !file.document_type);
    const invalidTagAssociationFiles = files.filter((file) => {
      if (file.association_types.includes('miscellaneous') || file.document_type === 'webLinks') {
        return false;
      } else if (file.association_types.includes('company')) {
        return file.companySelectedValues.length === 0;
      } else if (file.association_types.includes('themes')) {
        return file.sectorSelectedValues.length === 0;
      }
      return true;
    });

    if (missingDocumentTypeFiles.length === 0 && invalidTagAssociationFiles.length === 0) {
      handleUpload();
    } else {
      let errorMessage = '';
      if (missingDocumentTypeFiles.length > 0) {
        const missingDocumentTypeFileNames = missingDocumentTypeFiles.map((file) => file.name);
        errorMessage += `Please associate file type to ${missingDocumentTypeFileNames.join(
          ', '
        )}. `;
      } else if (invalidTagAssociationFiles.length > 0) {
        const invalidTagAssociationFileNames = invalidTagAssociationFiles.map((file) => file.name);
        errorMessage += `Please associate valid tags to ${invalidTagAssociationFileNames.join(
          ', '
        )}.`;
      }
      setErrorMessage(errorMessage);
    }
  };

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      uuid: uuidv4(),
      association_types: ['miscellaneous'],
      companySelectedValues: [],
      document_type: 'reports',
      sectorSelectedValues: [],
      preview: URL.createObjectURL(file),
      file: file
    }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop
  });

  return (
    <>
      <LoadingPopover open={isLoading} />
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      {/* <div style={{ height: 'calc(100vh - 240px)', overflowY: 'auto', padding: '5px 4px 0px 0px' }}> */}
      <div style={{ height: 'calc(100vh - 240px)', padding: '5px 4px 0px 0px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px' }}>
            <FileRemoveIcon style={{ cursor: 'pointer' }} onClick={() => handleClosePopup()} />
          </div>
          <Typography className={classes.uploadTextHeading}>Upload Documents</Typography>
          <Typography className={classes.uploadTextSubHeading}>
            By analyzing your documents, Doc Explorer delivers responses aligned with your most
            important information needs.
          </Typography>
          <br />
          <div
            {...getRootProps()}
            style={{
              border: '2px dashed #0276E8',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              height: '160px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <input {...getInputProps()} />
            <FileUploadIcon />
            <Typography className={classes.uploadText}>
              Drag & Drop here or browse files from <br />{' '}
              <span style={{ color: '#004CFF' }}>Your Computer</span>
            </Typography>
          </div>
        </Grid>
        <div style={{ padding: '20px 0px' }}>
          <Grid
            style={{ height: 'calc(100vh - 540px)', overflowY: 'auto' }}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            {files.length > 0 && (
              <>
                {files.map((file) => (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <Typography className={classes.uploadFilesName}>{file.name}</Typography>
                      </div>
                      <div>
                        <Tooltip title={'File will be removed from upload'}>
                          <FileRemoveIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleRemoveFile(file.uuid)}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </Grid>
        </div>
      </div>
      {files.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
          <Button
            className={classes.cancleButton}
            style={{ width: '80px', height: '30px' }}
            onClick={() => {
              setFiles([]);
            }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#08070D',
                textTransform: 'none',
                fontFamily: 'Satoshi'
              }}
            >
              Cancel
            </Typography>
          </Button>
          <div style={{ paddingLeft: '10px' }}></div>
          <Button
            className={classes.uploadButton}
            onClick={() => {
              validateFileTags();
            }}
            style={{ width: '80px', height: '30px' }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#FFFFFF',
                textTransform: 'none',
                fontFamily: 'Satoshi'
              }}
            >
              Upload
            </Typography>
          </Button>
        </div>
      )}
    </>
  );
};

export default UploadDocExpFile;
