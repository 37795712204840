import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';

const Title = ({ title }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Typography className={classes.docExplorerText}>
      <span>{t(title)}</span>
      <sup>Beta</sup>
    </Typography>
  );
};

export default Title;
