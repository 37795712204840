import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { makeStyles } from '@material-ui/core';

import { ReactComponent as SortDirectionUp } from '../../../components/icons/SortDirectionUp.svg';
import { ReactComponent as SortDirectionDown } from '../../../components/icons/SortDirectionDown.svg';
import { ReactComponent as SortDirectionUpAndDown } from '../../../components/icons/SortDirectionUpAndDown.svg';

const SortArrow = ({ columnName, sortData }) => {
  const columnData = sortData.find((x) => x.columnName === columnName);
  const showColumnOrder = columnData?.order;
  return (
    <>
      {showColumnOrder !== '' ? (
        showColumnOrder === 'Desc' ? (
          <SortDirectionDown style={{ padding: '0px 3px' }} />
        ) : (
          <SortDirectionUp style={{ padding: '0px 6px' }} />
        )
      ) : (
        <SortDirectionUpAndDown style={{ padding: '0px 6px' }} />
      )}
    </>
  );
};

const useStyles = makeStyles({
  table: {
    '& th': {
      fontWeight: '700',
      // padding: '0px',
      fontFamily: 'Satoshi',
      border: '1px solid rgba(229, 229, 229, 1)',
      background: 'rgba(233, 233, 233, 1) !important',
      position: 'sticky'
    },
    '& td': {
      fontFamily: 'Satoshi',
      border: '1px solid rgba(229, 229, 229, 1)',
      position: 'sticky'
    }
  },
  tableHeadCell: {
    padding: '10px !important'
  },
  skeleton: {
    width: '100%',
    height: '53px !important'
  },
  rowCell: {
    padding: '8px !important'
  }
});

const TableComp = (tableProps) => {
  const classes = useStyles();
  const { tableData, columnHeaders, loading, styleClass, sortState, emptyTable, tableRowComps } =
    tableProps;
  return (
    <TableContainer>
      <Table className={classes.table}>
        {columnHeaders && (
          <TableHead>
            <TableRow>
              {columnHeaders.map((column) => (
                <TableCell
                  key={column.displayName}
                  className={`${classes.tableHeadCell} ${column.styleClass}`}
                  onClick={() => {
                    if (column.sort) {
                      column.sort(column.key);
                    }
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {column.displayName}
                    {column.sort && <SortArrow columnName={column.key} sortData={sortState} />}
                  </div>
                </TableCell>
              ))}
              {/* <TableCell className={`${classes.stickyColumn}`}>{t('list.delete')}</TableCell> */}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={loading?.colCnt}>
                {Array.from({ length: loading?.rowCnt }).map((_, index) => (
                  <Skeleton key={index} animation="wave" className={classes.skeleton} />
                ))}
              </TableCell>
            </TableRow>
          ) : tableData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={emptyTable?.colCnt} style={styleClass?.emptyTable}>
                <emptyTable.comp />
              </TableCell>
            </TableRow>
          ) : (
            tableData.map((row, index) => (
              <TableRow key={index}>
                {tableRowComps.map((cell, index) => (
                  <TableCell key={index} className={`${classes.rowCell} ${cell?.styleClass}`}>
                    <cell.comp row={row} />
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComp;
