import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  hoverLink: {
    textDecoration: 'None',
    color: 'inherit',
    '&:hover': {
      cursor: 'pointer',
      color: 'blue'
    }
  }
}));
const HoverLink = ({ href, target, displayText }) => {
  const classes = useStyles();
  return (
    <a href={href} className={classes.hoverLink} target={target} rel="noreferrer">
      {displayText}
    </a>
  );
};

export default HoverLink;
