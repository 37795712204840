import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button, Box, Popover } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  popover: {
    borderRadius: '8px!important'
  },
  headingColor: {
    'background-image': 'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%)',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  skip: {
    color: '#171717!important',
    'font-family': 'Satoshi!important',
    'font-size': '14px!important',
    'font-style': 'normal!important',
    'font-weight': '500!important',
    'line-height': '24px!important',
    textTransform: 'none!important'
  },
  topHeading: {
    'font-family': 'Satoshi!important',
    'font-size': '24px!important',
    'font-style': 'normal!important',
    'font-weight': '700!important',
    'line-height': '38px!important'
  },
  topSubHeading: {
    'font-family': 'Satoshi!important',
    'font-size': '14px!important',
    'font-style': 'normal!important',
    'font-weight': '700!important',
    'line-height': '22px!important'
  },
  paraHeading: {
    'font-family': 'Satoshi!important',
    'font-size': '20px!important',
    'font-style': 'normal!important',
    'font-weight': '700!important',
    'line-height': '28px!important'
  },
  para: {
    'font-family': 'Satoshi!important',
    'font-size': '14px!important',
    'font-style': 'normal!important',
    'font-weight': '400!important',
    'line-height': '24px!important'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)!important'
  },
  alignNumberItemCentre: {
    'align-items': 'center',
    display: 'flex'
  },
  noteCss: {
    marginTop: '10px',
    display: 'flex',
    padding: '14px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    borderRadius: '4px',
    background: '#EFF3FC',
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  prev: {
    color: '#171717!important',
    textTransform: 'none!important',
    'margin-right': '6px!important'
  },
  next: {
    'border-radius': '4px!important',
    background: 'linear-gradient(90deg, #004EFE -1.88%, #06BAC2 99.73%)!important',
    textTransform: 'none!important'
  },
  margin0: {
    margin: '0!important'
  },
  disabledCls: {
    opacity: '0.5!important'
  }
}));

const StepPopover = ({
  hideBackdrop,
  value,
  currentStep,
  customStyle,
  onClose = () => {},
  skip = () => {},
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  }
}) => {
  const classes = useStyles();
  const {
    step,
    totalSteps,
    topHeading,
    topSubHeading,
    paraHeading,
    para,
    note,
    onClickPrev,
    onclickNext,
    nextBtnText
  } = currentStep;
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (value) {
      setAnchorEl(value);
    }
  }, [value]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const skipClose = () => {
    handleClose();
    skip();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'custom-popover' : undefined;

  const style = {
    width: 500,
    p: '24px!important',
    pt: 2,
    pb: 3,
    borderRadius: 2
  };

  const page = `${step}/${totalSteps}`;
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={skipClose}
        style={
          customStyle
            ? customStyle
            : {
                marginTop: '10px'
              }
        }
        classes={{ paper: classes.popover }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        BackdropProps={
          hideBackdrop
            ? {}
            : {
                classes: {
                  root: classes.backdrop
                }
              }
        }
      >
        <Paper sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {topHeading && (
              <>
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  className={`${classes.headingColor} ${classes.topHeading}`}
                >
                  {topHeading}
                </Typography>
                <Button onClick={skipClose} className={classes.skip}>
                  Skip{' '}
                </Button>
              </>
            )}
          </Box>
          {topSubHeading && (
            <Typography id="modal-description" className={classes.topSubHeading}>
              {topSubHeading}
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography
              variant="body2"
              sx={{ mt: 2 }}
              className={`${!topHeading ? classes.margin0 : ''} ${classes.headingColor} ${
                classes.paraHeading
              }`}
            >
              <strong>{paraHeading}</strong>
            </Typography>
            {!topHeading && (
              <Button onClick={skipClose} className={classes.skip}>
                Skip
              </Button>
            )}
          </Box>
          <Typography variant="body2" sx={{ mt: 1 }} className={classes.para}>
            {para}
          </Typography>
          {note && <div className={classes.noteCss}>{note}</div>}
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" className={classes.alignNumberItemCentre}>
              {page}
            </Typography>
            <Box>
              <Button
                variant="text"
                disabled={step === 1}
                className={`${classes.prev} ${step == 1 ? classes.disabledCls : ''}`}
                onClick={onClickPrev}
              >
                Prev
              </Button>
              <Button variant="contained" className={classes.next} onClick={onclickNext}>
                {nextBtnText ? nextBtnText : 'Next'}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default StepPopover;
