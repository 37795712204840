import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Tooltip, Box, Button, Typography, Popover } from '@material-ui/core';
import { ReactComponent as ThubmsUp } from '../../../icons/ThubmsUp.svg';
import { ReactComponent as ThumbsDown } from '../../../icons/ThumbsDown.svg';
import { ReactComponent as ThumbsDownFilled } from '../../../icons/ThumbsDownFilled.svg';
import { ReactComponent as ThumbsUpFilled } from '../../../icons/ThumbsUpFilled.svg';
import { setAllMessages, setChatScroll } from '../../action';
import { postApi } from '../../../../services/token';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonSaveFeedback: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px',
    textTransform: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  cancleButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px'
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontWeight: 500,
      fontSize: '12px'
    },
    '&::placeholder': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  popoverPaper: {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px #0000000D'
  }
}));

const FeedbackComponent = ({ messageData, isLastBotMessage }) => {
  const dispatch = useDispatch();
  const allMessages = useSelector((state) => state.tokenReducer.allMessages);
  const uuid = useSelector((state) => state.tokenReducer.uuID);
  const getFeedbackURL = `${process.env.REACT_APP_API}/user/feedback`;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  function handleFeedback(like, unlike, feedback, messageId) {
    let body = {
      question_id: allMessages[messageId].messageId,
      question: allMessages[messageId].question,
      answer: allMessages[messageId].answer,
      like: like,
      unlike: unlike,
      feedback: feedback,
      session_id: uuid
    };
    const feedbackPromise = postApi(getFeedbackURL, {
      ...body
    });

    feedbackPromise
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  }

  const handleLike = (messageData) => {
    const messageId = messageData.messageId;
    const currentMessage = allMessages[messageId];
    const updatedAllMessages = {
      ...allMessages,
      [messageId]: {
        ...currentMessage,
        like: true,
        unlike: false
      }
    };
    dispatch(setAllMessages(updatedAllMessages));
    dispatch(setChatScroll(false));
    handleFeedback(true, false, '', messageData.messageId);
  };

  const handleRemoveLike = (messageData) => {
    const messageId = messageData.messageId;
    const currentMessage = allMessages[messageId];
    const updatedAllMessages = {
      ...allMessages,
      [messageId]: {
        ...currentMessage,
        like: false,
        unlike: false
      }
    };
    dispatch(setAllMessages(updatedAllMessages));
    dispatch(setChatScroll(false));
    handleFeedback(false, false, '', messageId);
  };

  const handleRemoveDislike = (event, messageData) => {
    const messageId = messageData.messageId;
    const currentMessage = allMessages[messageId];
    const updatedAllMessages = {
      ...allMessages,
      [messageId]: {
        ...currentMessage,
        like: false,
        unlike: false
      }
    };
    dispatch(setAllMessages(updatedAllMessages));
    dispatch(setChatScroll(false));
    handleFeedback(false, false, '', messageId);
  };

  const handleDislike = (event, messageData) => {
    const messageId = messageData.messageId;
    const currentMessage = allMessages[messageId];
    const updatedAllMessages = {
      ...allMessages,
      [messageId]: {
        ...currentMessage,
        like: false,
        unlike: true
      }
    };
    dispatch(setAllMessages(updatedAllMessages));
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (messageData) => {
    const messageId = messageData.messageId;
    setOpen(false);
    handleFeedback(false, false, '', messageId);
  };

  const handleSave = (messageData) => {
    const messageId = messageData.messageId;
    setOpen(false);
    handleFeedback(false, true, feedback, messageId);
    setFeedback('');
  };

  return (
    <div style={{ paddingLeft: '20px' }}>
      {isLastBotMessage && (
        <>
          <Tooltip
            title={
              messageData.like ? t('askBot.feedbackTooltipThanks') : t('askBot.feedbackLikeTooltip')
            }
          >
            {messageData.like ? (
              <ThumbsUpFilled
                style={{ padding: '4px', cursor: 'pointer' }}
                onClick={() => handleRemoveLike(messageData)}
              />
            ) : (
              <ThubmsUp
                style={{ padding: '4px', cursor: 'pointer' }}
                onClick={() => handleLike(messageData)}
              />
            )}
          </Tooltip>
          <Tooltip
            title={
              messageData.unlike
                ? t('askBot.feedbackTooltipThanks')
                : t('askBot.feedbackDislikeTooltip')
            }
          >
            {messageData.unlike ? (
              <ThumbsDownFilled
                style={{ padding: '4px', cursor: 'pointer' }}
                onClick={(event) => handleRemoveDislike(event, messageData)}
              />
            ) : (
              <ThumbsDown
                style={{ padding: '4px', cursor: 'pointer' }}
                onClick={(event) => handleDislike(event, messageData)}
              />
            )}
          </Tooltip>
        </>
      )}
      {!isLastBotMessage && (
        <>
          <Tooltip
            title={
              messageData.like ? t('askBot.feedbackTooltipThanks') : t('askBot.feedbackLikeTooltip')
            }
          >
            {messageData.like ? (
              <ThumbsUpFilled
                style={{ padding: '4px' }}
                onClick={() => handleRemoveLike(messageData)}
              />
            ) : (
              <ThubmsUp style={{ padding: '4px' }} onClick={() => handleLike(messageData)} />
            )}
          </Tooltip>
          <Tooltip
            title={
              messageData.unlike
                ? t('askBot.feedbackTooltipThanks')
                : t('askBot.feedbackDislikeTooltip')
            }
          >
            {messageData.unlike ? (
              <ThumbsDownFilled
                style={{ padding: '4px' }}
                onClick={(event) => handleRemoveDislike(event, messageData)}
              />
            ) : (
              <ThumbsDown
                style={{ padding: '4px' }}
                onClick={(event) => handleDislike(event, messageData)}
              />
            )}
          </Tooltip>
        </>
      )}
      <Popover
        id="botCompFeedbackPopOver"
        open={open}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={event ? { top: event.clientY, left: event.clientX } : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.popoverPaper }}
      >
        <Box style={{ width: '250px', height: '175px' }}>
          <div>
            <Typography
              style={{
                fontSize: '12px',
                color: '#000000',
                fontWeight: 400,
                lineHeight: '16.2px',
                textAlign: 'left',
                paddingTop: '8px',
                paddingLeft: '14px'
              }}
            >
              {t('askBot.feedbackThankyou')}
            </Typography>
            <Box style={{ paddingLeft: '13px', paddingRight: '13px', paddingTop: '10px' }}>
              <TextField
                value={feedback}
                multiline
                rows={3}
                onChange={(e) => setFeedback(e.target.value)}
                variant="outlined"
                placeholder={t('askBot.feedbackTellMore')}
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.textField
                  }
                }}
              />
            </Box>
          </div>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            style={{
              paddingRight: '13px',
              paddingBottom: '13px',
              paddingTop: '8px',
              gap: '5px'
            }}
          >
            <Button
              className={classes.cancleButton}
              style={{
                backgroundColor: '#F7F9FD',
                textTransform: 'none',
                color: '#000000',
                borderRadius: '4px',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '16px'
              }}
              onClick={() => handlePopoverClose(messageData)}
            >
              {t('askBot.feedbackCancel')}
            </Button>
            <Button
              className={classes.buttonSaveFeedback}
              style={{
                textTransform: 'none',
                color: '#000000',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '16px',
                background: 'linear-gradient(180deg, #004CFF 0%, #06BCC1 100%)'
              }}
              onClick={() => handleSave(messageData)}
            >
              {t('askBot.feedbackSave')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default FeedbackComponent;
