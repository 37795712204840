import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Skeleton
} from '@mui/material';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import { getApi } from '../../../services/token';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDashBoardNews, setNews } from '../action';
import { ReactComponent as ClockIconGradient } from '../../../components/icons/ClockIconGradient.svg';

const dateFormat = (timestamp) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [datePart, timePart] = timestamp.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hour, minute] = timePart.split(':');
  const utcDateObj = new Date(Date.UTC(year, month - 1, day, hour, minute));
  const options = {
    year: 'numeric',
    month: 'short', // Use 'short' for abbreviated month names, or 'long' for full month names
    day: 'numeric',
    timeZone: userTimeZone
  };
  const formattedTime = utcDateObj.toLocaleString('en-IN', options);
  return formattedTime;
};

function NewsFeed({ classes, newsPageSize, dashBoardNews }) {
  const url = process.env.REACT_APP_COMPANY_API;
  const pageSize = newsPageSize;

  const [companyDetails, setCompanyDetails] = useState(null);
  const [newsFeed, setNewsFeed] = useState(null);

  const dashBoardNewsRedux = useSelector((state) => state.tokenReducer.dashBoardNews);
  const companyDetailsNewsRedux = useSelector((state) => state.tokenReducer.news);
  const companyDashBoardDetails = useSelector(
    (state) => state.tokenReducer.companyDashBoardDetails
  );
  const companyDetailsRedux = useSelector((state) => state.tokenReducer.companyDetails);

  useEffect(() => {
    if (dashBoardNews === true) {
      setCompanyDetails(companyDashBoardDetails);
      setNewsFeed(dashBoardNewsRedux);
    } else {
      setCompanyDetails(companyDetailsRedux);
      setNewsFeed(companyDetailsNewsRedux);
    }
  }, [dashBoardNewsRedux, companyDetailsNewsRedux, companyDetailsRedux, companyDashBoardDetails]);

  const currentActiveCompany =
    companyDetails && companyDetails.length > 0
      ? companyDetails.find((x) => x.active === true)
      : null;

  const activeCin = currentActiveCompany?.data?.base?.CIN;

  const [loading, setLoading] = useState(false);
  // let pageNumber = newsFeed[activeCin] ? newsFeed[activeCin].pageNumber : 1;
  let pageNumber =
    newsFeed && activeCin && newsFeed[activeCin] ? newsFeed[activeCin].pageNumber : 1;

  const dispatch = useDispatch();
  const getNews = async () => {
    setLoading(true);
    try {
      let res = await getApi(`${url}/v1/company/${activeCin}/news`, {
        page_size: pageSize,
        page_number: pageNumber
      });
      let updatedItems = [];
      let updatedNews = JSON.parse(JSON.stringify(newsFeed));
      if (updatedNews[activeCin]) {
        updatedItems = JSON.parse(JSON.stringify(updatedNews[activeCin].items));
      } else {
        updatedNews[activeCin] = {};
      }
      updatedItems = [...updatedItems, ...res.data.data.items];
      updatedNews[activeCin].items = updatedItems;
      updatedNews[activeCin].pageNumber =
        res.data.data.metadata.total_records <= pageSize * pageNumber ? -1 : pageNumber + 1;
      if (dashBoardNews === true) {
        dispatch(setDashBoardNews({ ...updatedNews }));
      } else {
        dispatch(setNews({ ...updatedNews }));
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {newsFeed &&
            newsFeed[activeCin] &&
            newsFeed[activeCin].items &&
            newsFeed[activeCin].items.map((x, i) => {
              const published_date = x['Published Date'];
              const title = x['Raw Title'];
              const link = x['News Link'];
              const source = x['Source'];
              const image_url = x['Image Link'];
              return (
                <Grid key={i} item xs={12} sx={{ flexGrow: 1, paddingTop: '15px' }}>
                  <Card className={classes.newCard} key={link} elevation={0}>
                    <CardActionArea
                      href={link}
                      target="_blank"
                      sx={{ flexGrow: 1, display: 'flex' }}
                    >
                      {/* <CardContent style={{ flexGrow: 8, width: '80%', padding: '7px' }}> */}
                      <CardContent className={classes.newsCardContent}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ClockIconGradient style={{ paddingRight: '5px' }} />
                          <Typography className={classes.newsDateTime}>
                            {dateFormat(published_date)}
                          </Typography>
                        </div>
                        <Typography className={classes.newsTitle}>{title}</Typography>
                        <Typography className={classes.newsSource}>{source}</Typography>
                      </CardContent>
                      {image_url ? (
                        <CardMedia
                          component="img"
                          image={image_url}
                          className={classes.newsCardMedia}
                        />
                      ) : (
                        <div></div>
                      )}
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
          {newsFeed &&
            newsFeed[activeCin] &&
            newsFeed[activeCin].items &&
            newsFeed[activeCin].items.length === 0 && (
              <div
                style={{
                  paddingTop: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <Typography className={classes.investorsName}>Data Not Available</Typography>
              </div>
            )}
          {loading &&
            Array.from({ length: pageSize }).map((_, index) => (
              <Grid key={index} item xs={12} sx={{ flexGrow: 1, paddingTop: '15px' }}>
                <Card className={classes.newCard} key={index} elevation={0}>
                  <div style={{ width: '100%' }}>
                    <Skeleton
                      variant="rectangle"
                      animation="wave"
                      style={{ margin: 10 }}
                      width="40%"
                      height={10}
                    />
                    <Skeleton
                      variant="rectangle"
                      animation="wave"
                      style={{ margin: 10 }}
                      width="80%"
                      height={10}
                    />
                    <Skeleton
                      variant="rectangle"
                      animation="wave"
                      style={{ margin: 10 }}
                      width="30%"
                      height={10}
                    />
                  </div>
                </Card>
              </Grid>
            ))}
          {pageNumber > 0 && (
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}
            >
              <Button style={{ textTransform: 'none' }} onClick={getNews}>
                <Typography className={classes.newsSeeMore}>See More</Typography>
                <SeeMore style={{ paddingLeft: '5px' }} />
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
export default NewsFeed;
