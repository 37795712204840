import DeletePopOver from '../../../atoms/delete-dialog';
import { hideShowMessage, updateParamsInURL } from '../../../../helpers';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { deleteApi } from '../../../../services/token';
import { useTranslation } from 'react-i18next';

const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;
const DeleteReport = ({
  reportName,
  open,
  handleClose,
  entityType,
  entityId,
  reportId,
  setErrorMessage,
  setLoading
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const onDeleteReport = async () => {
    try {
      setLoading(true);
      const url = `${COMPANY_API_URL}/v2/report/${reportId}?entity_id=${entityId}&entity_type=${entityType}`;
      await deleteApi(url);
      updateParamsInURL({
        navigate,
        location,
        removeParams: ['report_id'],
        addParams: { success: 'report-deleted' }
      });
      handleClose();
      setLoading(false);
    } catch (error) {
      hideShowMessage(
        error?.response?.data?.error?.detail || t('tearSheet.erroReport'),
        setErrorMessage
      );
      setLoading(false);
    }
  };

  return (
    <>
      <DeletePopOver
        content={t('report.contentMessage')}
        openState={open}
        text={reportName || 'report'}
        trigger={onDeleteReport}
        handleClose={handleClose}
      />
    </>
  );
};

export default DeleteReport;
