import { Typography, makeStyles } from '@material-ui/core';
import { Paper } from '@mui/material';
import { defaultSection } from '../tearSheetTemplateMocks';
import CustomTextField from '../../../../../atoms/textfield';
import TemplateSectionCard from './templateSectionCard';
import CustomButton from '../../../../../atoms/buttons/button';
import { Add, DeleteForever } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ErrorToast from '../../../../../atoms/errorToast';
import SecondaryButton from '../../../../../atoms/buttons/secondaryBtn';
import DeleteButton from '../../../../../atoms/buttons/deleteBtn';
import InputError from '../../../../../atoms/inputError';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isEqual } from 'lodash';

const useStyles = makeStyles((theme) => ({
  tearSheet: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '16px !important',
    padding: '16px'
  },
  formLevelOne: {
    display: 'flex',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  keyDiv: {
    width: '20%',
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  valueDiv: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  keyTypography: {
    fontSize: '16px',
    fontWeight: '550',
    overflowWrap: 'break-word'
  },
  selectedTypography: {
    fontWeight: '400',
    fontSize: '16px',
    margin: '0px 10px 0px 0px'
  },
  selectedCompaniesDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  divider: {
    backgroundColor: '#b1c8ff',
    margin: '15px 0px'
  },
  sectionsDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  sectionCardDiv: {
    width: '86%',
    margin: '8px 0 8px 14%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  addSectionBtn: {
    margin: '8px 14%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0'
    }
  },
  mainActionBtns: {
    display: 'flex',
    justifyContent: 'end'
  },
  discardBtn: {
    margin: '0px 8px'
  },
  inputErrorDisplacer: {
    marginTop: '-33px'
  },
  textClickable: {
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none',
    margin: '0px 4px'
  },
  duplicateBtn: {
    padding: '13px 12px 13px 20px',
    minWidth: '0px',
    marginLeft: '10px'
  }
}));

const MAX_AGE = 60 * 60 * 24 * 1000;
const MAX_QUESTIONS = process.env.REACT_APP_TEAR_SHEET_QUESTION_LIMIT;
const TearSheetTemplate = ({
  template,
  setTemplate,
  handleSaveTemplate,
  handleDiscardTemplate,
  handleDeleteTemplate,
  validationErrors,
  disabled,
  initialTemplate,
  setValidationErrors,
  handleDuplicate,
  handleUseTemplate,
  handleGenerateReport,
  savetemplateBtn
}) => {
  console.log(JSON.stringify(template));
  const { t } = useTranslation();
  const classes = useStyles();
  const [sectionsCopied, setSectionsCopied] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [pasteEnabled, setPasteEnabled] = useState(false);

  const handleSectionCopy = (section, sectionIndex) => {
    localStorage.setItem(
      'section',
      JSON.stringify({
        timestamp: new Date().getTime(),
        data: { ...section }
      })
    );
    setSectionsCopied(Array(template?.question_groups?.length).fill(false));
    setSectionsCopied((prev) => {
      const updatedSectionsCopied = [...prev];
      updatedSectionsCopied[sectionIndex] = true;
      return updatedSectionsCopied;
    });
    cleanupLocalStorage('section');
    setTimeout(() => {
      setSectionsCopied(Array(template?.question_groups?.length).fill(false));
    }, 5000);
  };

  useEffect(() => {
    setSectionsCopied(Array(template?.question_groups?.length).fill(false));
  }, []);

  const handleSectionPaste = (sectionIndex) => {
    let copiedSection = localStorage.getItem('section');
    if (!copiedSection) {
      return;
    }

    copiedSection = JSON.parse(copiedSection);
    const now = new Date().getTime();
    if (now - copiedSection.timestamp > MAX_AGE) {
      localStorage.removeItem('section');
      return;
    }
    handleSectionChange(sectionIndex, copiedSection.data);
  };

  const cleanupLocalStorage = (key) => {
    const itemStr = localStorage.getItem(key);
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date().getTime();
      if (now - item?.timestamp > MAX_AGE) {
        localStorage.removeItem(key);
      } else {
        setPasteEnabled(true);
      }
    }
  };

  useEffect(() => {
    cleanupLocalStorage('section');
    const cleanupInterval = setInterval(() => {
      cleanupLocalStorage('section');
    }, 60 * 60 * 1000);
    return () => clearInterval(cleanupInterval);
  }, []);

  const validateQuestionLimit = (updatedTemplate) => {
    const numOfQuestions = updatedTemplate?.question_groups.reduce((acc, curr) => {
      return acc + curr.questions.length;
    }, 0);
    if (numOfQuestions > MAX_QUESTIONS) {
      return false;
    }
    return true;
  };
  const handleTemplateChange = (key, value) => {
    let updatedTemplate = JSON.parse(JSON.stringify({ ...template }));
    updatedTemplate[key] = value;
    if (!validateQuestionLimit(updatedTemplate)) {
      setErrorMessage(t('tearSheet.questionLimitError'));
      setTimeout(() => setErrorMessage(''), 10000);
      return;
    }
    setTemplate(updatedTemplate);
  };

  const handleSectionChange = (index, value) => {
    let updatedSections = JSON.parse(JSON.stringify([...template.question_groups]));
    updatedSections[index] = value;
    handleTemplateChange('question_groups', updatedSections);
  };
  const handleDeleteSection = (index) => {
    let updatedSections = JSON.parse(JSON.stringify([...template.question_groups]));
    updatedSections.splice(index, 1);
    let updatedSectionsCopied = JSON.parse(JSON.stringify([...sectionsCopied]));
    updatedSectionsCopied.splice(index, 1);
    setSectionsCopied(updatedSectionsCopied);
    if (validationErrors?.question_groups) {
      setValidationErrors((prev) => {
        let updatedErrors = { ...prev };
        updatedErrors.question_groups.splice(index, 1);
        return updatedErrors;
      });
    }
    handleTemplateChange('question_groups', updatedSections);
  };
  const handleAddSection = () => {
    let updatedSections = JSON.parse(JSON.stringify([...template.question_groups]));
    updatedSections.push(defaultSection);
    let updatedSectionsCopied = JSON.parse(JSON.stringify([...sectionsCopied]));
    updatedSectionsCopied.push(false);
    setSectionsCopied(updatedSectionsCopied);
    if (validationErrors?.question_groups) {
      setValidationErrors((prev) => ({
        ...prev,
        question_groups: [...prev.question_groups, {}]
      }));
    }
    handleTemplateChange('question_groups', updatedSections);
  };
  const handleSectionsReorder = (result) => {
    if (!result.destination) return; // dropped outside the list
    const reorderedSections = Array.from(template.question_groups);
    const [reorderedSection] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, reorderedSection);
    let updatedSectionsCopied = JSON.parse(JSON.stringify([...sectionsCopied]));
    const [reorderedSectionCopied] = updatedSectionsCopied.splice(result.source.index, 1);
    updatedSectionsCopied.splice(result.destination.index, 0, reorderedSectionCopied);
    setSectionsCopied(updatedSectionsCopied);
    if (validationErrors?.question_groups) {
      let updateSectionsErrors = JSON.parse(JSON.stringify([...validationErrors.question_groups]));
      const [reorderedSectionError] = updateSectionsErrors.splice(result.source.index, 1);
      updateSectionsErrors.splice(result.destination.index, 0, reorderedSectionError);
      setValidationErrors((prev) => ({
        ...prev,
        question_groups: updateSectionsErrors
      }));
    }
    handleTemplateChange('question_groups', reorderedSections);
  };

  return (
    <Paper className={classes.tearSheet}>
      <ErrorToast errorMessage={errorMessage} />
      <div>
        {template?.template_name && (
          <div style={{ paddingBottom: '20px' }} className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>
                {t('tearSheet.templateNameLabel')}
              </Typography>
            </div>
            <div className={classes.valueDiv}>
              <CustomTextField
                value={template.template_name}
                handleChange={(value) => handleTemplateChange('template_name', value)}
                placeholder={t('tearSheet.templateNamePlaceholder')}
                errorMessage={validationErrors?.template_name}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {template?.description && (
          <div style={{ paddingBottom: '20px' }} className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>
                {t('tearSheet.templateDescriptionLabel')}
              </Typography>
            </div>
            <div className={classes.valueDiv}>
              <CustomTextField
                value={template.description}
                handleChange={(value) => handleTemplateChange('description', value)}
                placeholder={t('tearSheet.templateDescriptionPlaceholder')}
                errorMessage={validationErrors?.description}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {/* {template?.report && (
          <div style={{ paddingBottom: '20px' }} className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>Report Name</Typography>
            </div>
            <div className={classes.valueDiv}>
              <CustomTextField
                value={template.report}
                handleChange={(value) => handleTemplateChange('report', value)}
                placeholder={'Custom Report Name'}
                errorMessage={validationErrors?.report}
              />
            </div>
          </div>
        )} */}
        {(template?.report || template?.entity_type) && (
          <div style={{ paddingBottom: '20px' }} className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>Report Name</Typography>
            </div>
            <div className={classes.valueDiv}>
              <CustomTextField
                value={template.report}
                handleChange={(value) => handleTemplateChange('report', value)}
                placeholder={'Custom Report Name'}
                errorMessage={validationErrors?.report}
              />
            </div>
          </div>
        )}
        {template?.entity_type && (
          <div style={{ paddingBottom: '20px' }} className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
              <Typography className={classes.keyTypography}>
                {template.entity_type === 'company' && 'Company'}
                {template.entity_type === 'theme' && 'Theme'}
              </Typography>
            </div>
            <div className={classes.valueDiv}>
              <CustomTextField value={template.entity_name} disabled={true} />
            </div>
          </div>
        )}
        <div className={classes.formLevelOne}>
          <div className={classes.keyDiv}>
            <Typography className={classes.keyTypography}>Sections</Typography>
          </div>
          {template?.question_groups?.length === 0 && validationErrors?.question_groups_error && (
            <div className={classes.valueDiv} style={{ marginTop: '8px' }}>
              <InputError errorMessage={validationErrors?.question_groups_error} />
            </div>
          )}
        </div>
        <div className={classes.sectionsDiv}>
          <DragDropContext onDragEnd={handleSectionsReorder}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {template.question_groups &&
                    template.question_groups.length > 0 &&
                    template.question_groups?.map((section, sectionIndex) => (
                      <Draggable
                        key={sectionIndex}
                        draggableId={String(sectionIndex)}
                        index={sectionIndex}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classes.sectionCardDiv}
                            style={{ userSelect: 'none', ...provided.draggableProps.style }}
                          >
                            <TemplateSectionCard
                              sectionIndex={sectionIndex}
                              validationErrors={validationErrors?.question_groups?.[sectionIndex]}
                              section={section}
                              copied={sectionsCopied[sectionIndex]}
                              handleSectionChange={(value) =>
                                handleSectionChange(sectionIndex, value)
                              }
                              handleDeleteSection={() => handleDeleteSection(sectionIndex)}
                              handleSectionCopy={() => handleSectionCopy(section, sectionIndex)}
                              handleSectionPaste={() => handleSectionPaste(sectionIndex)}
                              disabled={disabled}
                              pasteEnabled={pasteEnabled}
                              dragHandleProps={{ ...provided.dragHandleProps }}
                              copyEnabled={template?.template_id}
                              setValidationErrors={setValidationErrors}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div style={{ padding: '10px' }}>
          {!disabled && (
            <div className={classes.addSectionBtn}>
              {/* <CustomButton
                  StartIcon={Add}
                  btnText="Add Section"
                  handleClick={handleAddSection}
                  //
                /> */}
              <SecondaryButton
                StartIcon={Add}
                btnText={t('tearSheet.addSection')}
                handleClick={handleAddSection}
                //
              />
            </div>
          )}
          <div className={classes.mainActionBtns}>
            {disabled && (
              <div className={classes.saveBtn}>
                <CustomButton btnText={'Use Template'} handleClick={handleDuplicate} />
              </div>
            )}
            {!disabled && !isEqual(initialTemplate.question_groups, template.question_groups) && (
              <div style={{ padding: '0px 20px' }} className={classes.saveBtn}>
                <CustomButton
                  btnText={'Save as a Template'}
                  handleClick={handleUseTemplate}
                  disabled={savetemplateBtn}
                />
              </div>
            )}
            {!disabled && (
              <div className={classes.saveBtn}>
                <CustomButton btnText={'Generate Report'} handleClick={handleGenerateReport} />
              </div>
            )}
            <div style={{ display: 'none' }}>
              <DeleteButton
                btnText={t('tearSheet.templateDeleteTemplatedBtn')}
                StartIcon={DeleteForever}
                handleClick={handleDeleteTemplate}
              />
              <DeleteButton
                btnText={t('tearSheet.templateDeleteTemplatedBtn')}
                StartIcon={DeleteForever}
                handleClick={handleSaveTemplate}
              />
            </div>
            <div style={{ display: 'none' }} className={classes.discardBtn}>
              <DeleteButton
                btnText={t('tearSheet.templateDiscardBtn')}
                StartIcon={DeleteForever}
                handleClick={handleDiscardTemplate}
                disabled={isEqual(initialTemplate.question_groups, template.question_groups)}
              />
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default TearSheetTemplate;
