import { getApi } from '../../../../services/token';
import { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { setTearSheetTemplateOptions } from '../../action';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainBox: {
    padding: '4px 14px 4px 14px',
    display: 'flex',
    gap: '20px'
  },
  tabBox: {
    borderRadius: '4px',
    display: 'flex',
    padding: '2px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E4EBFC',
    background: '#FFF',
    marginRight: '6px',
    minWidth: '10px',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      '& p': {
        color: 'rgba(23, 23, 23, 0.30) !important'
      }
    }
  },
  selectedTabBox: {
    background: '#004CFF !important'
  },
  tabTypo: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600,
    color: '#171717'
  },
  selectedTabTypo: {
    color: '#ffffff'
  },
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '10px !important',
      fontFamily: 'Satoshi !important'
    }
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  optionTypo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '4px 0px'
  }
}));

const TemplateDropdown = ({
  selectedFilter,
  handleReportChange,
  templateId,
  refetchTemplateOptions
}) => {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [autocompleteLoadState, setAutocompleteLoadState] = useState(false);
  const [selectedTab, setSelectedTab] = useState('pre_defined');
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false); // State to control dropdown open status
  const dispatch = useDispatch();
  const classes = useStyles();

  const tabsOptions = [
    { key: 'pre_defined', value: 'Pre Defined' },
    { key: 'user_defined', value: 'User Defined' }
  ];

  const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;
  console.log(selectedOption);
  // Fetch options from API and update the templateOptions
  const getAutocompleteOptions = async () => {
    setAutocompleteLoadState(true); // Set loading state
    setOpen(false); // Close dropdown while loading

    try {
      const [userDefinedResponse, preDefinedResponse] = await Promise.all([
        getApi(
          `${COMPANY_API_URL}/v2/template?entity_type=${
            selectedFilter === 'theme' ? 'theme,sub_theme' : selectedFilter
          }&template_type=user_defined`
        ),
        getApi(
          `${COMPANY_API_URL}/v2/template?entity_type=${
            selectedFilter === 'theme' ? 'theme,sub_theme' : selectedFilter
          }&template_type=pre_defined`
        )
      ]);

      const userDefinedOptions = userDefinedResponse?.data?.data || [];
      const preDefinedOptions = preDefinedResponse?.data?.data || [];

      const newOptions = [...userDefinedOptions, ...preDefinedOptions];

      setTemplateOptions(newOptions); // Set template options from the response
      dispatch(setTearSheetTemplateOptions(newOptions)); // Dispatch action to update global state
      handleTabChange('pre_defined'); // Set default tab to pre_defined
    } catch (error) {
      console.error(error);
    }
    setAutocompleteLoadState(false); // End loading state
  };

  // Fetch options when selectedFilter changes
  useEffect(() => {
    const fetchAutocompleteOptions = async () => {
      setFilteredOptions([]); // Clear options initially
      setSelectedOption(null); // Reset selected option
      setAutocompleteLoadState(true); // Show loading state

      try {
        await getAutocompleteOptions(); // Fetch options
      } catch (error) {
        console.error(error);
      } finally {
        setAutocompleteLoadState(false); // Stop loading state after fetching
      }
    };

    fetchAutocompleteOptions();
    setOpen(false); // Ensure dropdown is closed when filter changes
  }, [selectedFilter]);

  // Ensure filtered options update based on the current tab
  const handleTabChange = (key) => {
    setSelectedTab(key);
    filterTemplateOptions(key); // Ensure filteredOptions is updated correctly
  };

  // Update filtered options whenever the templateOptions or selectedTab changes
  useEffect(() => {
    filterTemplateOptions(selectedTab); // Apply filtering immediately when tab changes
  }, [templateOptions, selectedTab]);

  const filterTemplateOptions = (filterKey) => {
    const relevantOptions = templateOptions.filter((option) => option.template_type === filterKey);
    setFilteredOptions(relevantOptions); // Set filtered options immediately
  };

  // If refetchTemplateOptions is provided, allow re-fetching from parent component
  useEffect(() => {
    if (refetchTemplateOptions) {
      refetchTemplateOptions.current = getAutocompleteOptions;
    }
  }, [refetchTemplateOptions]);

  // Update selected option when templateId changes
  useEffect(() => {
    if (templateId && templateOptions.length) {
      const selectedTemplate = templateOptions.find((option) => option.template_id === templateId);
      setSelectedOption(selectedTemplate || null); // Set the selected option if found
    } else {
      setSelectedOption(null);
    }
  }, [templateId, templateOptions]);

  return (
    <>
      <Autocomplete
        disabled={autocompleteLoadState || !filteredOptions.length} // Disable if loading or no options
        options={filteredOptions.length ? filteredOptions : [{}]} // Ensure options are updated properly
        className={classes.root}
        style={{ width: '400px' }}
        getOptionLabel={(option) => option.template_name || ''} // Handle empty object case
        noOptionsText={
          <div>
            <Box className={classes.mainBox}>
              {tabsOptions.map((option) => (
                <Button
                  variant="outlined"
                  className={`${classes.tabBox} ${
                    selectedTab === option.key && classes.selectedTabBox
                  }`}
                  key={option.key}
                  onClick={() => handleTabChange(option.key)}
                >
                  <Typography
                    className={`${classes.tabTypo} ${
                      selectedTab === option.key && classes.selectedTabTypo
                    }`}
                  >
                    {option.value}
                  </Typography>
                </Button>
              ))}
            </Box>
          </div>
        }
        renderInput={(params) => (
          <form noValidate>
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select"
              label="Template Library"
              autoComplete="off"
              InputLabelProps={{
                classes: { root: classes.inputLabel }
              }}
              onClick={() => setOpen(true)} // Manually open the dropdown on click
            />
          </form>
        )}
        renderOption={(option) =>
          option.template_name ? (
            <Typography className={classes.optionTypo}>{option.template_name}</Typography>
          ) : (
            <Typography className={classes.optionTypo}>No options available</Typography> // Handle empty options case
          )
        }
        onChange={handleReportChange}
        value={selectedOption}
        open={open} // Controlled open state
        onClose={() => setOpen(false)} // Close when the dropdown loses focus
        ListboxComponent={(props) => (
          <div {...props}>
            <Box className={classes.mainBox}>
              {tabsOptions.map((option) => (
                <Button
                  variant="outlined"
                  className={`${classes.tabBox} ${
                    selectedTab === option.key && classes.selectedTabBox
                  }`}
                  key={option.key}
                  onClick={() => handleTabChange(option.key)}
                >
                  <Typography
                    className={`${classes.tabTypo} ${
                      selectedTab === option.key && classes.selectedTabTypo
                    }`}
                  >
                    {option.value}
                  </Typography>
                </Button>
              ))}
            </Box>
            {props.children && props.children}
          </div>
        )}
      />
    </>
  );
};

export default TemplateDropdown;
