import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Pagination } from '@mui/material';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { postApi, putApi } from '../../../services/token';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Cancel from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isShowCompanyPage, setCompanyApiContext } from '../action';
import AddCompaniesPopOver from './addCompaniesPopUp';
import { Add, CheckCircleOutline } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E3EBFD',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '1px !important'
    },
    width: '400px'
  },
  searchIcon: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  exploreCompGrid: {
    paddingLeft: '50px',
    paddingRight: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  extfilterPadding: {
    paddingLeft: '57.5px',
    paddingRight: '57.5px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '17.5px',
      paddingRight: '17.5px'
    }
  },
  successMessageGrid: {
    paddingLeft: '57.5px',
    paddingRight: '57.5px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '17.5px',
      paddingRight: '17.5px'
    }
  },
  successMessageStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  requestCompanyTextStyle: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  paginationGrid: {
    display: 'none',
    // display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px'
  },
  companyScroll: {
    overflow: `auto`,
    display: `grid`,
    'align-content': `flex-start`,
    width: '100%'
  },
  closeIcon: {
    borderRadius: '50%',
    transition: 'color 0.3s ease !important',
    boxSizing: 'border-box',
    backgroundColor: '#E3EBFD',
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '14px !important',
    margin: 0,
    padding: 0,
    color: '#f9cdcc',
    '&:hover': {
      color: '#EB5B55',
      backgroundColor: '#FFFFFF'
    }
  },
  demoBtn: {
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none'
  },
  successBox: {
    background: '#C7F1BF',
    borderRadius: '5px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 6px',
    position: 'absolute',
    right: '35%',
    [theme.breakpoints.down('xs')]: {
      left: '10px !important',
      marginRight: '45px !important'
    }
  }
}));
const WatchlistCompanies = ({ watchlist, selectedItem }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const companyUrl = process.env.REACT_APP_API;
  const [companyListData, setCompanyListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const getFilteredCompanyData = async () => {
    setLoading(true);
    let response = await postApi(`${companyUrl}/v1/user/watchlist/${selectedItem}`, {
      page_limit: 100
    });
    const parsedData = response.data.data[0].companies;
    setCompanyListData(parsedData);
    setLoading(false);
  };

  const handleCompanyClick = (cin, name) => {
    if (matches) dispatch(isShowCompanyPage('/explore-all-companies'));

    const body = {
      cin: cin,
      name: name
    };
    dispatch(setCompanyApiContext(body));
    navigate('/askbyz');
  };

  useEffect(() => {
    getFilteredCompanyData();
  }, [selectedItem]);

  const [openPopUp, setOpenPopUp] = useState(false);
  const handleClosePopUp = () => {
    setOpenPopUp(false);
  };
  // const exploreHeight = matches ? 'calc(100vh - 174px)' : 'calc(100vh - 294.5px)';
  const handleCompaniesAdded = () => {
    getFilteredCompanyData();
    setSuccessMessage(t('Watchlist.AddedSuccessfully'));
    setTimeout(() => setSuccessMessage(''), 5000);
  };
  const removeCompnayFromWatchList = async (company_cin) => {
    await putApi(`${companyUrl}/v1/user/watchlist/${selectedItem}`, {
      delete_cins: [company_cin]
    });
    await getFilteredCompanyData();
  };

  return (
    <>
      <AddCompaniesPopOver
        openState={openPopUp}
        handleClose={handleClosePopUp}
        watchlistedCompanies={companyListData}
        watchlistId={selectedItem}
        trigger={handleCompaniesAdded}
      />
      <Grid
        container
        style={{
          height: '100vh',
          'overflow-y': 'auto',
          backgroundColor: '#FFFFFF',
          borderRadius: '24px',
          maxWidth: '1440px',
          margin: '0 auto'
        }}
      >
        <div className={classes.companyScroll}>
          <Grid item xs={12} className={classes.exploreCompGrid}>
            <Grid
              item
              xs={12}
              className={classes.successMessageGrid}
              style={{
                display: 'flex',
                justifyContent: 'end',
                padding: 0,
                margin: '10.5px 7.5px 0px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {successMessage && (
                    <Box m={1} className={classes.successBox}>
                      <CheckCircleOutline
                        style={{
                          marginRight: '2px',
                          color: '#3C962A',
                          backgroundColor: '#C7F1BF',
                          borderRadius: '50%'
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: '13px',
                          color: '#206113',
                          opacity: 1
                        }}
                      >
                        {successMessage}
                      </Typography>
                    </Box>
                  )}
                </div>
              </div>
            </Grid>
            <div>
              <Grid container>
                {!loading && !watchlist.is_default === true && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    sx={{ flexGrow: 1, paddingTop: '10.5px' }}
                  >
                    <Card
                      sx={{
                        maxWidth: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px solid #F0F0F0',
                        margin: '7.5px',
                        borderRadius: '10px',
                        boxShadow: '0px 2px 4px 0px #0000000D',
                        '&:hover': { backgroundColor: '#EFF3FC' }
                      }}
                      style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                      elevation={0}
                      onClick={() => {
                        setOpenPopUp(true);
                      }}
                    >
                      <CardActionArea sx={{ flexGrow: 1, display: 'flex' }}>
                        <CardContent sx={{ flexGrow: 1 }}>
                          <div style={{ display: 'flex', alignContent: 'center' }}>
                            <Add
                              style={{
                                margin: '0px 2px 0px 2px',
                                fontSize: '20px',
                                color: '#004CFF'
                              }}
                            />
                            <Typography
                              style={{
                                fontFamily: 'Satoshi',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                backgroundImage:
                                  'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              }}
                            >
                              {t('portfolio.Addcompanytowatchlist')}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )}
                {loading ? (
                  <>
                    {Array.from({ length: 10 }).map((_, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2.4}
                        key={index}
                        sx={{ flexGrow: 1, paddingTop: '10.5px' }}
                      >
                        <Card
                          sx={{
                            maxWidth: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #F0F0F0',
                            borderRadius: '10px',
                            marginLeft: '7.5px',
                            marginRight: '7.5px',
                            boxShadow: '0px 2px 4px 0px #0000000D'
                          }}
                          style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                          key={index}
                          elevation={0}
                        >
                          <Grid style={{ display: 'flex', alignItems: 'center' }}>
                            <Skeleton
                              variant="circular"
                              animation="wave"
                              style={{ margin: 10 }}
                              width={50}
                              height={50}
                            />
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              style={{ margin: 10 }}
                              width="40%"
                              height={10}
                            />
                          </Grid>
                        </Card>
                      </Grid>
                    ))}
                  </>
                ) : (
                  companyListData.length > 0 &&
                  companyListData.map(({ name, cin, images }) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      key={cin}
                      sx={{ flexGrow: 1, paddingTop: '10.5px' }}
                    >
                      <Card
                        sx={{
                          maxWidth: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          border: '1px solid #F0F0F0',
                          margin: '7.5px',
                          borderRadius: '10px',
                          boxShadow: '0px 2px 4px 0px #0000000D',
                          '&:hover': { backgroundColor: '#EFF3FC' }
                        }}
                        style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                        key={name}
                        elevation={0}
                        onClick={() => handleCompanyClick(cin, name)}
                      >
                        <CardActionArea sx={{ flexGrow: 1, display: 'flex' }}>
                          {images && images.logo ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '55px',
                                height: '55px',
                                paddingLeft: '10px'
                              }}
                            >
                              <img
                                src={images.logo}
                                alt="Logo"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'contain'
                                }}
                              />
                            </div>
                          ) : (
                            <BusinessIcon style={{ paddingLeft: '20px' }} />
                          )}
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              style={{
                                fontFamily: 'Satoshi',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                color: '#000000B2'
                              }}
                            >
                              {name}
                            </Typography>
                          </CardContent>
                          {!watchlist.is_default === true && (
                            <Cancel
                              className={classes.closeIcon}
                              onClick={(e) => {
                                e.stopPropagation();
                                removeCompnayFromWatchList(cin);
                              }}
                            />
                          )}
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.paginationGrid}>
            <Pagination
              shape="rounded"
              count={100}
              onChange={() => {}}
              size={xSmallScreen ? 'small' : 'medium'}
              page={1}
            />
          </Grid>
          <Grid item xs={12} style={{ height: '20px' }} />
        </div>
      </Grid>
    </>
  );
};

export default WatchlistCompanies;
