const onlyCompany = process.env.REACT_APP_ONLY_COMPANIES === 'true';
export const entityTypeToDisplayMap = [
  {
    key: 'all',
    value: 'All'
  },
  {
    key: 'company',
    value: 'Company'
  },
  {
    key: 'theme',
    value: 'Theme'
  }
  // {
  //   key: 'sub_theme',
  //   value: 'Sub-theme'
  // },
  // {
  //   key: 'focus_area',
  //   value: 'Focus Area'
  // }
];

export const entitesFilterToDisplayMap = [
  {
    key: 'all',
    value: 'All'
  },
  {
    key: 'company',
    value: 'Companies'
  },
  {
    key: 'theme',
    value: 'Themes',
    disabled: onlyCompany
  }
];

export const entitesFilterToDisplayMapTheme = [
  {
    key: 'pre_defined',
    value: 'Pre Defined'
  },
  {
    key: 'user_defined',
    value: 'User Defined'
  },
  {
    key: 'add-theme',
    value: 'Add Theme',
    isButton: true,
    isShow: false
  }
];

export const selecteEntitesFilterToDisplayMap = [
  {
    key: 'existing_reports',
    value: 'Existing Reports',
    disabled: true,
    isText: true
  },
  {
    key: 'create_new',
    value: 'Create New',
    isButton: true,
    isShow: false
  }
];

export const allMap = {
  key: 'all',
  value: 'All'
};

export const dummyOptions = [
  {
    entity_type: 'company',
    entity_name: '&Me',
    entity_id: 'CIN6DC64F5CA04F47F28F',
    logo: 'https://d2c6tj6pz1k2np.cloudfront.net/company_images/CIN6DC64F5CA04F47F28F/1721065404_4f94fb3d1dd206fc87691cf8ae9d5ed6.jpeg'
  },
  {
    entity_type: 'company',
    entity_name: '0Xpass',
    entity_id: 'CIN146DB919A1D54B2987',
    logo: 'https://d2c6tj6pz1k2np.cloudfront.net/company_images/CIN146DB919A1D54B2987/1720503919_66d414a2cb3c82d530724cf529ee64e6.jpeg'
  },
  {
    entity_type: 'company',
    entity_name: '1 India Family Mart',
    entity_id: 'CIN4F6D178B460845208B',
    logo: 'https://d2c6tj6pz1k2np.cloudfront.net/company_images/CIN4F6D178B460845208B/1721065842_5bb51114d61a8c04ac9e08c635c7bb25.png'
  },
  {
    entity_type: 'company',
    entity_name: '10Club',
    entity_id: 'CIN9E61B0B1A1B84913BF',
    logo: 'https://d2c6tj6pz1k2np.cloudfront.net/company_images/U51909KA2021PTC147248/1702305494_10b75b3e8e93abf89a06cc2f0272c52d.jpeg'
  },
  {
    entity_type: 'company',
    entity_name: '13Sqft',
    entity_id: 'CIN95B6EB50E3314680BC',
    logo: 'https://d2c6tj6pz1k2np.cloudfront.net/company_images/CIN95B6EB50E3314680BC/1721065431_6aa0ae35aed98278cb1fadaa0aeab06c.jpeg'
  }
];
