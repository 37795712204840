import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  cardImg: {
    width: '100%',
    height: '274px',
    borderRadius: '20px',
    objectFit: 'cover'
  },
  empName: {
    color: '#171717!important',
    'font-size': '18px!important',
    'font-weight': '600!important',
    'line-height': '24px!important'
  },
  empDetail: {
    'font-size': '16px',
    'font-weight': 500,
    'line-height': '29px',
    textAlign: 'center',
    textAlignLast: 'center',
    margin: 0
  },
  socialMediaIcon: {
    [theme.breakpoints.up('sm')]: {
      padding: '7px',
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '45%'
    }
  },
  mainCard: {
    'margin-top': '10px'
  },
  fontFamily: {
    'font-family': 'Satoshi!important'
  },
  linkedIn: {
    'font-size': '16px',
    'font-weight': 500,
    'line-height': '29px',
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: 0
  },
  cardContent: {
    padding: '0px 0px 24px 0px !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  accSummary: {
    minHeight: '25px !important',
    '& .MuiAccordionSummary-content': {
      margin: '0px',
      fontFamily: 'Satoshi',
      fontSize: '16px'
    },
    '& .Mui-expanded': {
      margin: '0px !important'
    }
  }
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const { name, imageUrl, designation, text, linkedUrl } = props;
  return (
    <Card sx={{ width: '320px' }} className={classes.mainCard} elevation={0}>
      <CardMedia
        className={classes.cardImg}
        component="img"
        height="194"
        image={imageUrl}
        alt="Paella dish"
      />
      <CardContent className={classes.cardContent}>
        <br />
        <Typography className={`${classes.empName} ${classes.fontFamily}`}>
          {`${name} `}
          <img
            src="/images/icons/Linkedin - Icon.svg"
            className={classes.linkedIn}
            alt=""
            onClick={() => window.open(linkedUrl, '_blank')}
          />
        </Typography>
        <p className={`${classes.empDetail} ${classes.fontFamily}`}>{designation}</p>
        <Accordion elevation={0} style={{ display: 'contents' }}>
          <AccordionSummary expandIcon={<ExpandMore />} className={classes.accSummary}>
            Bio
          </AccordionSummary>
          <AccordionDetails>
            <br />
            <p className={`${classes.empDetail} ${classes.fontFamily}`}>
              {text.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
            <br />
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}
