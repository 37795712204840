import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import ContactSupportComponent from './contactSupportComponent';

const useStyles = makeStyles({
  helpCenterContentMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '22px !important',
    fontWeight: '700 !important',
    paddingBottom: '16px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  }
});

function LiveTrackerHelpCenter() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentMainTxt}>Live Tracker</Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Live Tracker is your one-stop solution for being updated with key business, finance, and
          management news for your preferred list of companies and sectors, aggregated across all
          major news sources, and summarized with pertinent information for quick consumption.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            controls
            style={{
              maxWidth: '60%',
              maxHeight: '100%',
              width: '60%',
              height: 'auto'
            }}
          >
            <source
              src="https://public.privateblok.ai/help_center/livetracker.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </Grid>
      <ContactSupportComponent />
    </Grid>
  );
}

export default LiveTrackerHelpCenter;
