import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as DeleteBinIcon } from '../../../components/icons/DeleteBinIcon.svg';

export default function EditDeleteMenu({
  watchlist_id,
  name,
  setOpenDeleteWCPopUp,
  setCurrentWatchlist,
  setCurrentWatchlistValue,
  classes,
  t
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = (e) => {
    e.stopPropagation();
    setCurrentWatchlist(watchlist_id);
    setCurrentWatchlistValue(name);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpenDeleteWCPopUp(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src="/images/icons/3dots.svg" alt="Menu" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={handleEdit}>
          <span className={classes.iconPd}>
            <img src="/images/icons/edit.svg" alt="Edit" />
          </span>
          <span>{t('watchlist.editName')}</span>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <span className={classes.iconPd}>
            <DeleteBinIcon style={{ cursor: 'pointer' }} />
          </span>
          <span>{t('list.delete')}</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
