import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import ReactLinkify from 'react-linkify';
const MapAccordian = ({ title, mapAccordianData, classes }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleAccordionToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
          {' '}
          {decoratedText}
        </a>
      )}
    >
      <div style={{ paddingTop: '15px' }}>
        <Accordion
          defaultExpanded={true}
          style={{
            backgroundColor: '#FAFAFA',
            borderRadius: '8px',
            border: '1px solid #E8E8E8'
          }}
          x
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<SeeMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ height: '56px', minHeight: '56px', display: 'flex' }}
            onClick={handleAccordionToggle}
          >
            <Typography className={`${classes.fontSize14} ${classes.fontWeight500}`}>
              {title ? title : 'Map'}
            </Typography>
            <div style={{ marginLeft: 'auto' }}>
              <Typography
                style={{
                  fontSize: '12px',
                  color: '#1D1D1D',
                  fontWeight: 500,
                  paddingRight: '5px',
                  paddingTop: '1.5px'
                }}
              >
                {isExpanded ? 'Show' : 'Hide'}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: '0px' }} id="map-details">
            <Typography>
              {mapAccordianData &&
                Object.keys(mapAccordianData).length > 0 &&
                Object.keys(mapAccordianData).map((x1, i1) => {
                  return (
                    <div key={i1} className={classes.itemContainer}>
                      <Typography title={x1} className={`${classes.fontSize12} ${classes.mapKey}`}>
                        {x1}
                      </Typography>
                      <Typography
                        title={mapAccordianData[x1]}
                        className={`${classes.fontSize12} ${classes.mapValue} ${classes.wordbreak}`}
                      >
                        {mapAccordianData[x1]}
                      </Typography>
                    </div>
                  );
                })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </ReactLinkify>
  );
};

export default MapAccordian;
