import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { useValidCode } from '../../hooks/useAuthHooks';
import { Code } from './authComponents';
import { getApi } from '../../services/token';
import { AuthContext } from './contexts/authContext';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': { cursor: 'pointer' },
    color: '#004CFF'
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  verifyGrid: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start'
    }
  },
  verifyPaper: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  }
}));

const VerifyEmail = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const classes = useStyles();
  const [checkPass, setCheckPass] = useState(false);

  const verifyEmail = state?.verifyEmail ? state?.verifyEmail : false;
  const { code, setCode, codeIsValid } = useValidCode('');
  const [error, setError] = useState('');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmlMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [session, setSession] = useState([]);
  const paperWidth = isDesktop ? '60%' : '100%';
  const gridHeight = isDesktop
    ? 'calc(100vh - 121px)'
    : isSmlMobile
    ? 'calc(100vh - 155px)'
    : 'calc(100vh - 121px)';

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  useEffect(() => {
    if (verifyEmail === false) {
      navigate('/home');
    }
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        const btn = document.getElementById('verifyBtn');
        btn.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    async function fetchSesh() {
      const sessionResult = await authContext.getSession();
      setSession(sessionResult);
    }
    fetchSesh();
  }, []);

  const url = process.env.REACT_APP_API;
  const sendClicked = async () => {
    setCheckPass(false);
    setError(null);
    try {
      await authContext.verifyAttributeCode('email', code);
      try {
        await getApi(`${url}/v1/user/verify`, null, session.accessToken.jwtToken);
        navigate('/profile', {
          state: { emailVerified: 'email is verified' }
        });
      } catch (err) {
        navigate('/profile');
      }
    } catch (err) {
      setCheckPass(true);
      setError('Invalid Code');
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        height: gridHeight
      }}
    >
      {!isMobile && ( // Conditionally render only on non-mobile screens
        <Grid item lg={5}>
          <Box
            sx={{
              background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
              borderRadius: '3px',
              opacity: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: gridHeight
            }}
          >
            <img src="/images/PBLogo_White.svg" alt="PrivateBlok-Logo" className="logo" />
          </Box>
        </Grid>
      )}
      <Grid
        xs={12}
        sm={12}
        lg={7}
        container
        className={classes.verifyGrid}
        style={{ height: gridHeight }}
        item
      >
        <Paper elevation={0} className={classes.verifyPaper} style={{ width: paperWidth }}>
          <Box width="100%">
            <Typography
              style={{
                fontSize: '24px',
                lineHeight: '70px',
                letterSpacing: '0px',
                color: '#08070D'
              }}
            >
              {t('verify.emailHeading')}
            </Typography>
            <Typography style={{ fontSize: '16px', letterSpacing: '0px', color: '#0C0C0C' }}>
              {t('verify.emailText')}
            </Typography>
            <br />
            <Code codeIsValid={codeIsValid} setCode={setCode} />
            <>
              {checkPass ? (
                <>
                  <Box
                    marginY={1}
                    style={{
                      background: '#FDF6F6',
                      borderRadius: '5px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padddingTop: '10px'
                    }}
                  >
                    <ErrorOutlineIcon
                      style={{
                        marginLeft: '13px', // Set margin left to 13px
                        marginRight: '5px', // Set margin right to 1px
                        color: '#EB5B55' // Set color of the icon to #3C962A
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#EB5B55',
                        marginRight: '15px'
                      }}
                    >
                      {error}
                    </Typography>
                  </Box>
                </>
              ) : (
                <div></div>
              )}
            </>
            <br />
            <Button
              id="verifyBtn"
              color="primary"
              variant="contained"
              style={{
                width: '100%',
                background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
                height: '50px'
              }}
              onClick={sendClicked}
            >
              <Typography
                style={{
                  fontSize: '18px',
                  fontWeight: 'medium',
                  color: '#FFFFFF',
                  textTransform: 'none'
                }}
              >
                {t('signUp.sendCodeBtn')}
              </Typography>
            </Button>
            <br />
            <Button
              style={{
                width: '100%',
                color: '#0C0C0C',
                fontSize: '16px',
                textTransform: 'none'
              }}
              className={classes.button}
              onClick={() => navigate('/home')}
            >
              {t('signUp.cancelButton')}
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default VerifyEmail;
