export const validateReportData = (data, setErrorObject) => {
  let isValid = true;
  const errors = {
    report_name: data.report_name ? null : 'Report name is required.',
    entity_list: [],
    question_groups: []
  };

  if (!Array.isArray(data.entity_list) || data.entity_list.length === 0) {
    errors.entity_list = 'Entity list must contain at least one entity.';
    isValid = false;
  } else {
    data.entity_list.forEach((entity) => {
      const entityErrors = {
        entity_type: entity.entity_type ? null : 'Entity type is required.',
        entity_id: entity.entity_id ? null : 'Entity ID is required.'
      };
      if (!entity.entity_type || !entity.entity_id) {
        isValid = false;
      }
      errors.entity_list.push(entityErrors);
    });
  }

  if (!Array.isArray(data.question_groups) || data.question_groups.length === 0) {
    errors.question_groups = 'Question groups must contain at least one group.';
    isValid = false;
  } else {
    data.question_groups.forEach((group) => {
      const groupErrors = {
        name: group.name ? null : 'Heading is required.',
        questions: []
      };
      if (!group.name) {
        isValid = false;
      }
      if (!Array.isArray(group.questions) || group.questions.length === 0) {
        groupErrors.questions = 'Questions must contain at least one question.';
        isValid = false;
      } else {
        group.questions.forEach((question) => {
          const questionErrors = {
            question: question.question ? null : 'Question is required.',
            type: question.type ? null : 'Question type is required.',
            question_type: question.question_type ? null : 'Required.'
          };
          if (!question.question || !question.type || !question.question_type) {
            isValid = false;
          }
          groupErrors.questions.push(questionErrors);
        });
      }
      errors.question_groups.push(groupErrors);
    });
  }

  setErrorObject(errors);
  return isValid;
};
