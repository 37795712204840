import { Box, Typography, makeStyles } from '@material-ui/core';
import { Print, RestorePage } from '@mui/icons-material';
import { formatedTime } from '../../utils/formatedTime';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import ErrorToast from '../../../atoms/errorToast';
import { Info } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  tearSheetHeader: {
    display: 'flex',
    marginBottom: '10px'
  },
  printButton: {
    display: 'flex',
    position: 'absolute',
    right: '35px',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      right: '30px'
    }
  },
  '@media print': {
    printButton: {
      visibility: 'hidden'
    }
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  infoDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    background: '#cccccc',
    borderRadius: '5px',
    marginRight: '15px'
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '21px',
    color: '#1d1d1d'
  },
  infoTypo: {
    color: '#1d1d1d'
  }
}));
const TearSheetHeader = ({
  tearSheetData,
  templateList,
  templateId,
  tearSheetStatus,
  handleTearSheetTrigger,
  errorMessage
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.tearSheetHeader}>
      {tearSheetData?.metadata?.logo && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px'
          }}
        >
          <img
            src={tearSheetData?.metadata?.logo}
            alt="Logo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
          {/* (
            <Business
              style={{
                maxWidth: '80%',
                maxHeight: '80%',
                objectFit: 'contain'
              }}
            />
          ) */}
        </div>
      )}
      <div
        style={{
          fontFamily: 'Satoshi',
          paddingLeft: '15px',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontFamily: 'Satoshi',
            fontSize: '20px',
            fontWeight: '600'
          }}
        >
          {tearSheetData?.metadata?.entity_name}
        </p>
      </div>
      {tearSheetData?.response && Object.keys(tearSheetData?.response).length > 0 && (
        <>
          {tearSheetData?.metadata?.updated_at && (
            <div
              style={{
                fontFamily: 'Satoshi',
                paddingLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p
                style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  fontStyle: 'italic'
                }}
              >
                ({formatedTime(tearSheetData?.metadata?.updated_at, true)})
              </p>
            </div>
          )}
          <div className={classes.printButton}>
            <ErrorToast errorMessage={errorMessage} />
            {templateList.find((temp) => temp.template_id === templateId)?.data_used !==
              'pb_data' && (
              <>
                {!tearSheetData?.metadata?.generated_from_latest_template && (
                  <Box className={classes.infoBox}>
                    <div className={classes.infoDiv}>
                      <Info className={classes.infoIcon} />
                      <Typography className={classes.infoTypo}>
                        {t('tearSheet.notLatest')}
                      </Typography>
                    </div>
                  </Box>
                )}
                {tearSheetStatus === t('tearSheet.inProgress') ||
                tearSheetStatus === t('tearSheet.inQueue') ? (
                  <IconButton>
                    <Tooltip title={t('tearSheet.inProcessToolTip')}>
                      <CircularProgress size={24} />
                    </Tooltip>
                  </IconButton>
                ) : (
                  <IconButton onClick={handleTearSheetTrigger}>
                    <Tooltip title={t('tearSheet.RefreshToolTip')}>
                      <RestorePage />
                    </Tooltip>
                  </IconButton>
                )}
              </>
            )}
            <IconButton style={{ marginLeft: '10px' }} onClick={() => window.print()}>
              <Print />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};

export default TearSheetHeader;
