import { useEffect } from 'react';

const useFavicon = (iconUrl) => {
  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    const previousIcon = favicon.href;

    // Update the favicon to the new URL
    favicon.href = iconUrl;

    // Reset to the previous favicon on cleanup
    return () => {
      favicon.href = previousIcon;
    };
  }, [iconUrl]);
};

export default useFavicon;
