import DeletePopOver from '../../../atoms/delete-dialog';
import { hideShowMessage, updateParamsInURL } from '../../../../helpers';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { deleteApi } from '../../../../services/token';
import { useTranslation } from 'react-i18next';

const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;
const DeleteTheme = ({
  themeName,
  open,
  handleClose,
  entityType,
  themeId,
  setErrorMessage,
  setLoading
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const onDeleteTheme = async () => {
    try {
      setLoading(true);
      const url = `${COMPANY_API_URL}/v1/fts/${themeId}?entity_type=${entityType}`;
      await deleteApi(url);
      updateParamsInURL({
        navigate,
        location,
        addParams: { success: 'theme-deleted' }
      });
      handleClose();
      setLoading(false);
    } catch (error) {
      hideShowMessage(
        error?.response?.data?.error?.detail || t('tearSheet.erroReport'),
        setErrorMessage
      );
      setLoading(false);
    }
  };

  return (
    <>
      <DeletePopOver
        content={t('theme.contentMessage')}
        openState={open}
        text={themeName || 'theme'}
        trigger={onDeleteTheme}
        handleClose={handleClose}
        closeOnDelete={false}
      />
    </>
  );
};

export default DeleteTheme;
