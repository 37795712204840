import { useTranslationHook } from '../../hooks/useTranslationHook';

export const manageInitialSortState = [
  { columnName: 'file_name', order: '' },
  { columnName: 'status', order: '' },
  { columnName: 'file_size', order: '' },
  { columnName: 'upload_type', order: '' },
  { columnName: 'updated_at', order: 'Desc' }
];

// columnHeadersConstants.js
export const columnHeadersList = () => {
  const { t } = useTranslationHook();
  return [
    {
      displayName: t('list.file'),
      key: 'file_name',
      sort: true,
      styleClass: 'fileNameCell'
    },
    {
      displayName: t('list.status'),
      key: 'status',
      sort: true
    },
    {
      displayName: 'Tags', // This doesn't need translation
      sort: null
    },
    {
      displayName: t('list.processedAt'),
      key: 'updated_at',
      sort: true
    },
    {
      displayName: t('list.size'),
      key: 'file_size',
      sort: true,
      styleClass: 'fileSizeCell'
    },
    {
      displayName: t('list.uploadedType'),
      key: 'upload_type',
      sort: true
    },
    {}
  ];
};
