import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@mui/material';
import { ReactComponent as ContactSupportIcon } from '../../../components/icons/ContactSupportIcon.svg';
import { setCompanyMenuClickedStatus } from '../../shared/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  helpCenterContactSupport: {
    marginTop: '20px',
    padding: '20px 0px',
    backgroundColor: '#F4F8FF',
    width: '100%',
    border: '1px solid #CCDBFF',
    borderRadius: '8px'
  },
  helpCenterContactSupportSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    fontWeight: '700 !important',
    color: '#373737 !important',
    paddingBottom: '10px !important',
    lineHeight: '24px !important',
    display: 'flex',
    alignItems: 'center'
  },
  demoBtn: {
    height: '35px',
    width: '150px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    margin: '0px 16px 0px 8px',
    padding: '0px 14px'
  }
});

function ContactSupportComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid className={classes.helpCenterContactSupport} lg={12}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography className={classes.helpCenterContactSupportSubContent}>
          <ContactSupportIcon style={{ paddingRight: '10px' }} /> Still can't find what you're
          looking for?
        </Typography>
        <Button
          type="submit"
          onClick={() => {
            navigate('/home');
            dispatch(setCompanyMenuClickedStatus(true));
          }}
          className={classes.demoBtn}
        >
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#FFFFFF',
              textTransform: 'none',
              fontFamily: 'Satoshi'
            }}
          >
            Contact Support
          </Typography>
        </Button>
      </div>
    </Grid>
  );
}

export default ContactSupportComponent;
