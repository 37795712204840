import React, { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useValidPassword } from '../../hooks/useAuthHooks';
import { Password } from './authComponents';

import { AuthContext } from './contexts/authContext';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export default function ChangePassword() {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [checkPass, setCheckPass] = useState(false);

  const { password: oldPassword, setPassword: setOldPassword } = useValidPassword('');

  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid
  } = useValidPassword('');

  const isValid = !newPasswordIsValid || newPassword.length === 0;

  const history = useNavigate();

  const authContext = useContext(AuthContext);

  const changePassword = async () => {
    setCheckPass(false);
    if (oldPassword === newPassword) {
      setCheckPass(true);
      setError(t('changePwd.samePwdMessage'));
    } else {
      try {
        await authContext.changePassword(oldPassword, newPassword);
        await signOut();
      } catch (err) {
        if (err.code === 'NotAuthorizedException') {
          setCheckPass(true);
          setError(t('changePwd.wrongPwdMessage'));
        }
        if (err.code === 'LimitExceededException') {
          setCheckPass(true);
          setError(t('changePwd.somethingWentWrongMessage'));
        }
      }
    }
  };

  const signOut = async () => {
    try {
      await authContext.signOut();
      history('/signin', {
        state: { message: t('changePwd.successMessage') }
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const updatePassword = (
    <>
      <Box width={'80%'} m={1}>
        <Typography
          style={{ fontSize: '24px', lineHeight: '70px', letterSpacing: '0px', color: '#08070D' }}
        >
          {t('changePwd.changeText')}
        </Typography>
      </Box>
      <Box width="80%" m={1}>
        <Password
          label={t('changePwd.oldPassword')}
          setPassword={setOldPassword}
          password={oldPassword}
          validate={false}
        />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label={t('changePwd.newPassword')}
          passwordIsValid={newPasswordIsValid}
          setPassword={setNewPassword}
          password={newPassword}
          validate={true}
        />
      </Box>
      <>
        {checkPass ? (
          <>
            <Box
              m={1}
              style={{
                background: '#FDF6F6',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ErrorOutlineIcon
                style={{
                  marginLeft: '13px', // Set margin left to 13px
                  marginRight: '5px', // Set margin right to 1px
                  color: '#EB5B55' // Set color of the icon to #3C962A
                }}
              />
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#EB5B55',
                  marginRight: '15px'
                }}
              >
                {error}
              </Typography>
            </Box>
          </>
        ) : (
          <div></div>
        )}
      </>

      <Box width={'80%'} m={1}>
        <Button
          disabled={isValid}
          color="primary"
          variant="contained"
          style={{
            width: '100%',
            background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
            height: '50px'
          }}
          onClick={changePassword}
        >
          <Typography
            style={{
              fontSize: '18px',
              fontWeight: 'medium',
              color: '#FFFFFF',
              textTransform: 'none'
            }}
          >
            {t('changePwd.button')}
          </Typography>
        </Button>
      </Box>
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          {updatePassword}
        </Grid>
      </Grid>
    </Grid>
  );
}
