import { getMainDomain } from './subDomainUtil';
import { getSession } from '../auth/libs/cognito';

export const setLocalStorageToCookies = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('CognitoIdentityServiceProvider')) {
      const value = localStorage.getItem(key);
      document.cookie = `${key}=${value}; domain=.${getMainDomain()}; path=/`; // Replace with your domain
    }
  });
};

export const transferCookiesToLocalStorage = async () => {
  // Retrieve all cookies
  const allCookies = document.cookie.split('; ');

  // Filter cookies starting with "CognitoIdentityServiceProvider"
  const cognitoCookies = allCookies.filter((cookie) =>
    cookie.startsWith('CognitoIdentityServiceProvider')
  );

  // Transfer filtered cookies to localStorage
  cognitoCookies.forEach((cookie) => {
    const [cookieName, cookieValue] = cookie.split('=');
    localStorage.setItem(cookieName, cookieValue);
    document.cookie = `${cookieName}=; domain=.${getMainDomain()};expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
  const session = await getSession();
  return session?.idToken?.jwtToken;
};
