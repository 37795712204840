import { Box, Paper } from '@mui/material';
import TearSheetRequestComp from './tearSheetRequestComp';
import TearSheetHeader from './tearSheetHeader';
import TearSheetContent from './tearSheetContent';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  tearSheet: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '16px !important',
    padding: '16px' // Add padding as needed
  }
}));

const TearSheet = ({
  tearSheetData = {},
  templateList = [],
  templateId = '',
  tearSheetStatus = '',
  handleTearSheetTrigger = () => {},
  triggerError = ''
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tearSheetHeaderProps = {
    tearSheetData: tearSheetData,
    templateList: templateList,
    templateId: templateId,
    tearSheetStatus: tearSheetStatus,
    handleTearSheetTrigger: handleTearSheetTrigger,
    errorMessage: triggerError
  };
  return (
    <Paper className={classes.tearSheet}>
      {tearSheetData ? (
        <>
          <TearSheetHeader {...tearSheetHeaderProps} />
          {tearSheetData?.response && Object.keys(tearSheetData?.response).length > 0 ? (
            <TearSheetContent tearSheetData={tearSheetData} />
          ) : (
            <TearSheetRequestComp
              handleTrigger={handleTearSheetTrigger}
              status={tearSheetStatus}
              errorMessage={triggerError}
            />
          )}
        </>
      ) : (
        <Box textAlign="center">
          <Typography>{t('tearSheet.selectCompanyText')}</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default TearSheet;
