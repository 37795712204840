import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as LeftArrowBlack } from '../../../../components/icons/aa.svg';

const useStyles = makeStyles(() => ({
  templateHeading: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '22px !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '30px !important',
    cursor: 'pointer',
    padding: '10px 0px'
  }
}));

const Heading = ({ tempHeading }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: 'flex',
        gap: '14px',
        alignItems: 'center'
      }}
    >
      <div style={{ cursor: 'pointer' }} onClick={() => window.history.back()}>
        <LeftArrowBlack style={{ fontSize: '20px' }} />
      </div>
      <Typography className={classes.templateHeading}>{tempHeading}</Typography>
    </div>
  );
};

export default Heading;
