import { TextField, CircularProgress, Typography, makeStyles, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState, useEffect, useCallback, useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../../components/icons/SearchIcon.svg';
import { getDisplayTextFromMap, getNestedValue } from '../../utils/utils';
import EntityTypeFilterTabs from '../../atoms/entitySearch/entityTypeFilterTabs';
import { ReactComponent as DeleteBinIcon } from '../../icons/DeleteBinIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import StaticChip from '../../atoms/staticChip';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '10px !important',
      fontFamily: 'Satoshi !important'
    }
  },
  searchIcon: {
    marginRight: '4px',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  optionTypo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '4px 0px'
  },
  actionIcons: {
    display: 'none',
    flex: '0 0 20%',
    justifyContent: 'flex-end',
    gap: '8px'
  },
  listboxItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '&:hover $actionIcons': {
      display: 'flex'
    }
  },
  firstChild: {
    flex: '0 0 80%',
    overflow: 'hidden'
  }
}));

const defaultFn = () => {};

const defaultFnTrue = () => {
  return true;
};

const AutocompleteSearch = ({ searchableProps }) => {
  const {
    getAutocompleteOptions = defaultFn,
    isPresistSearchValue = false,
    onAutoCompleteChange = defaultFn,
    queryParamName = 'search',
    tabOptions = [],
    selectedTab = '',
    onSelectedTabOption,
    APIDependency = [],
    handleOnBlur,
    activeEntityType = '',
    showChips,
    onEditTheme,
    onDeleteTheme,
    closeSearch,
    setParentClose,
    isDefaultOption = defaultFnTrue
  } = searchableProps;

  const location = useLocation();
  // const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [stopAPICall, setStopAPICall] = useState(false);
  const [initialActiveEntityType, setInitialActiveEntityType] = useState(activeEntityType);
  const [chipsData, setChipsData] = useState([]);

  // const [selectOptFromEntity, setSelectFromEntity] = useState(null);

  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const inputRef = useRef(null);

  useEffect(() => {
    if (closeSearch) {
      setOpen(false);
      if (inputRef.current) {
        inputRef.current.blur();
      }
      setParentClose(false);
    }
  }, [closeSearch]);

  const getOptions = async (input, page, apDependency) => {
    const dataToSend = [input, page, ...apDependency];
    setLoading(true);
    try {
      const { newOptions, total_records, chipsData } = await getAutocompleteOptions(dataToSend);
      setChipsData(chipsData);

      if (page === 1) {
        const isDefaultOpt = newOptions.find((x) => isDefaultOption(x));
        if (isDefaultOpt) {
          setInputValue(isDefaultOpt.entity_name);
        }
        setOptions(newOptions);
        setScrollPosition(0);
        const listbox = document.getElementById('listbox-id');
        if (listbox) {
          listbox.scrollTop = 0;
        }
      } else {
        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
      }

      setHasMore(newOptions.length > 0 && options.length !== total_records);

      const selected = newOptions.find((option) => option.entity_name === input);
      if (selected) {
        setSelectedItem(selected);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onPageScroll = async (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop - (options?.length || 0) * 4 <= clientHeight + 10;
    if (isBottom && hasMore && !isFetchingMore) {
      setScrollPosition(scrollTop);
      setIsFetchingMore(true);
      await handleScroll();
      setIsFetchingMore(false);
    }
  };

  const checkEntityValidation = () => {
    if (!activeEntityType) return true;

    return !stopAPICall && initialActiveEntityType === activeEntityType;
  };

  const onActiveEntityChange = async (inputValue, activeEntityType) => {
    if (!inputValue && activeEntityType) {
      setPage(1);
      setScrollPosition(0);
      setStopAPICall(true);
      setOptions([]);
      const dep = [activeEntityType, selectedTab];
      try {
        await getOptions(inputValue, 1, [...dep]);
      } catch (error) {
        console.error('Failed to fetch options:');
      }
      setStopAPICall(false);
    }
  };

  useEffect(() => {
    onActiveEntityChange(inputValue, activeEntityType);
  }, [activeEntityType]);

  useEffect(() => {
    if (
      !inputValue &&
      scrollPosition === 0 &&
      page === 1 &&
      options.length < 1 &&
      checkEntityValidation()
    ) {
      getOptions(inputValue, page, [...APIDependency]);
    }
  }, [inputValue, scrollPosition, page, options.length]);

  useEffect(() => {
    const listbox = document.getElementById('listbox-id');
    if (listbox && scrollPosition > 0) {
      listbox.scrollTop = scrollPosition;
    }
  }, [options, scrollPosition]);

  const fetchOptions = useCallback(
    async (input, page) => {
      if (!input) return;
      getOptions(input, page, [...APIDependency]);
    },
    [...APIDependency]
  );

  useEffect(() => {
    if (isPresistSearchValue) {
      const query = new URLSearchParams(location.search).get([queryParamName]);
      setInputValue(query || '');
    }
  }, [location.search, isPresistSearchValue]);

  useEffect(() => {
    if (!inputValue) {
      if (checkEntityValidation()) {
        setOptions([]);
      }
      setSelectedItem(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      fetchOptions(inputValue, 1);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, fetchOptions]);

  useEffect(() => {
    onAutoCompleteChange(selectedItem);
    // if (selectedItem) {
    //   // if (isPresistSearchValue) {
    //     // updateParamsInURL({navigate, location, queryParamName, selectedItem.entity_name})

    //   //   // const params = new URLSearchParams(location.search);
    //   //   // params.set([queryParamName], selectedItem.entity_name);
    //   // }
    // }
  }, [selectedItem, isPresistSearchValue]);

  const handleScroll = async () => {
    try {
      await getOptions(inputValue, page + 1, [...APIDependency]);
    } catch (error) {
      console.error('Failed to fetch options:');
    }
    setPage((prev) => prev + 1);
  };

  const handleFocus = () => {
    setOpen(true);
    // setSelectFromEntity(null);
    setScrollPosition(0);
    setInitialActiveEntityType(activeEntityType);
  };

  const handleAutocompleteClose = () => {
    // if (activeEntityType && selectOptFromEntity?.entity_name && selectOptFromEntity) {
    //   setInputValue(selectOptFromEntity?.entity_name);
    //   setSelectedItem(selectOptFromEntity);
    //   setSelectFromEntity(null);
    //   setOptions([selectOptFromEntity]);
    //   onAutoCompleteChange(selectOptFromEntity);
    //   setHasMore(false);
    // }
  };

  const onSelectEntity = (value) => {
    setInputValue(value?.entity_name || '');
    setSelectedItem(value);
    onAutoCompleteChange(value);
    // setSelectFromEntity(value);
    setOpen(false);
  };

  const onSelectedSearch = (event, value) => {
    if (value && isDefaultOption(value)) {
      console.log('Default option selected with empty input, ignoring.');
    } else {
      // setSelectFromEntity(null);
      onSelectEntity(value);
      setOpen(false);
    }
  };
  const [callOnBlur, setCallonBlur] = useState(false);

  const checkAndClearOptInput = () => {
    const cond = !callOnBlur && options.length === 1 && isDefaultOption(options[0]);
    if (cond) {
      setInputValue('');
      setCallonBlur(true);
    }
    return cond;
  };

  useEffect(() => {
    if (callOnBlur) {
      onBlur();
    }
  }, [callOnBlur]);

  const onBlur = async () => {
    if (!checkAndClearOptInput()) {
      setCallonBlur(false);
    }
    if (checkEntityValidation()) {
      setTimeout(() => {
        handleOnBlur();
        if (!inputValue) {
          setOptions([]);
          setStopAPICall(false);
          setPage(1);
          setScrollPosition(0);
        }
      }, 100);
    } else {
      setStopAPICall(true);
    }
    setOpen(false);
  };

  const onKeyDownPress = (event) => {
    if (event.key === 'Enter') {
      const matchedOption = options.find(
        (option) => getNestedValue(option, 'entity_name').toLowerCase() === inputValue.toLowerCase()
      );
      if (matchedOption) {
        onSelectedSearch(null, matchedOption);
      }
    }
  };

  return (
    <>
      <Autocomplete
        options={options}
        open={open}
        className={classes.root}
        getOptionLabel={(option) => getNestedValue(option, 'entity_name') || ''}
        isOptionEqualToValue={(option, value) => option.entity_id === value.entity_id}
        onChange={onSelectedSearch}
        onKeyDown={onKeyDownPress}
        // noOptionsText={''}
        onInputChange={(event, value) => {
          if (event.type !== 'blur' || !selectedItem || selectedItem.entity_name !== inputValue) {
            // setSelectFromEntity(null);
            setInputValue(value);
            if (!value) {
              setOptions([]);
            }
          }
        }}
        inputValue={inputValue}
        loading={loading}
        onClose={() => {
          handleAutocompleteClose();
          // setOptions([]);
        }}
        ListboxProps={{ onScroll: onPageScroll, id: 'listbox-id' }}
        renderInput={(params) => (
          <TextField
            onFocus={handleFocus}
            onBlur={onBlur}
            inputRef={inputRef}
            {...params}
            variant="outlined"
            placeholder="Search"
            label="Search"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <SearchIcon className={classes.searchIcon} />
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            InputLabelProps={{ classes: { root: classes.inputLabel } }}
          />
        )}
        renderOption={(option) => (
          <div className={classes.listboxItem}>
            <div className={classes.firstChild}>
              <Typography className={classes.optionTypo}>
                {getNestedValue(option, 'entity_name')}
              </Typography>
            </div>
            <StaticChip displayText={getDisplayTextFromMap(option, 'type', [])} />
            {showChips && selectedTab === 'user_defined' && (
              <div className={classes.actionIcons}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditTheme(option);
                  }}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteTheme(option);
                  }}
                  size="small"
                >
                  <DeleteBinIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}
        ListboxComponent={(props) => (
          <EntityTypeFilterTabs
            chipsData={chipsData}
            showChips={showChips}
            tabsOptions={tabOptions}
            props={props}
            setFilterTab={onSelectedTabOption}
            selectedTab={selectedTab}
            onSelectEntity={onSelectEntity}
          />
        )}
      />
      {/* <div>Selected Item: {selectedItem ? JSON.stringify(selectedItem) : 'No item selected'}</div> */}
    </>
  );
};

export default AutocompleteSearch;
