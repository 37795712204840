import { Box, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as InfoIcon } from '../icons/InfoIconHover.svg';

const useStyles = makeStyles(() => ({
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#F4F8FF',
    padding: '5px 10px',
    margin: '11px 0px',
    borderLeft: '3px solid #3473EE',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.10)',
    width: 'fit-content'
  },
  icon: {
    marginRight: '8px',
    color: '#22A428'
  },
  typo: {
    fontSize: '13px',
    color: '#171717',
    fontWeight: '500'
  }
}));

const InfoBar = ({ infoMessage }) => {
  const classes = useStyles();
  return (
    infoMessage && (
      <Box className={classes.box}>
        <InfoIcon className={classes.icon} />
        <Typography className={classes.typo}>{infoMessage}</Typography>
      </Box>
    )
  );
};

export default InfoBar;
