import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: '#171717',
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    padding: '25px 0 5px 0',
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px'
    }
  }
}));

const subHeader = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.subHeading}>
      {children}
    </Typography>
  );
};
export default subHeader;
