import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  Typography
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    display: 'flex',
    padding: '0px',
    alignItems: 'center'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  expandIcon: {
    transition: 'transform 0.3s!important',
    'font-size': '16px!important',
    padding: '10px 0'
  },
  expandIconOpen: {
    transform: 'rotate(0deg)'
  },
  expandIconClosed: {
    transform: 'rotate(270deg)'
  },
  summaryContent: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  textContainer: {
    flex: 1
  },
  liItemBtSelected: {
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%)) !important',
    padding: '8px 10px !important'
  },
  pd10: {
    padding: '10px !important'
  },
  gradientColor: {
    'background-clip': 'text !important',
    'background-image':
      'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) !important',
    '-webkit-text-fill-color': 'transparent !important'
  },
  themeCls: {
    fontSize: '13px !important',
    color: '#000000 !important',
    'font-family': 'Satoshi !important',
    'padding-left': '26px !important'
  },
  mainAccSumm: {
    height: '56px!important',
    minHeight: '56px!important',
    display: 'flex!important'
  },
  faAccSummary: {
    height: '15px!important',
    minHeight: '15px!important',
    display: 'flex!important',
    padding: '15px 0!important'
  }
}));

const FocusAreaList = ({ classes, data, faProps, faClasses }) => {
  const { faExpanded, setFaExpanded, selectedFaItem, setSelectedFaItem } = faProps;
  // const [expandedSub, setExpandedSub] = useState({});

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setSelectedFaItem({ entity_type: 'focus_area', id: panel });
    // setFaExpanded({ ...faExpanded, [panel]: isExpanded });
    setFaExpanded({ [panel]: isExpanded });
  };

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((focusArea, index) => (
          <ListItem disablePadding key={index}>
            <Accordion
              className={`${classes.width100} ${classes.borderBt}`}
              elevation={0}
              expanded={faExpanded[focusArea.focus_area_id]}
              onChange={handleAccordionChange(focusArea.focus_area_id)}
            >
              <ListItemButton
                className={`${
                  faExpanded[focusArea.focus_area_id] ? faClasses.liItemBtSelected : faClasses.pd10
                }`}
              >
                <AccordionSummary className={faClasses.faAccSummary}>
                  <div className={faClasses.iconContainer}>
                    <ExpandMoreIcon
                      className={`${faClasses.expandIcon} ${
                        faExpanded[focusArea.focus_area_id]
                          ? faClasses.expandIconOpen
                          : faClasses.expandIconClosed
                      }`}
                    />
                  </div>
                  <div className={`${faClasses.textContainer}`}>
                    <Typography
                      className={`${classes.subLevelAccordian} ${
                        faExpanded[focusArea.focus_area_id] ? classes.wt500 : classes.wt400
                      }`}
                    >
                      {focusArea.focus_area}
                    </Typography>
                  </div>
                </AccordionSummary>
              </ListItemButton>
              <AccordionDetails className={classes.pd0}>
                <List disablePadding>
                  {focusArea &&
                    focusArea.themes &&
                    focusArea.themes.length > 0 &&
                    focusArea.themes.map((theme) => (
                      <ListItemButton
                        className={`${classes.borderBt} ${
                          selectedFaItem?.id === theme.id ? classes.selectedBackGround : ''
                        }`}
                        onClick={() => {
                          setSelectedFaItem({ entity_type: 'theme', id: theme.id });
                        }}
                        key={theme.id}
                      >
                        <Typography
                          className={`${faClasses.themeCls} ${
                            selectedFaItem?.id === theme.id
                              ? `${faClasses.gradientColor} ${classes.wt600}`
                              : classes.wt400
                          }`}
                        >
                          {theme.name}
                        </Typography>
                      </ListItemButton>
                      /* <ListItem disablePadding key={theme.id} className={classes.subListItem}>
                        <Accordion
                          style={{ width: '100%' }}
                          elevation={0}
                          expanded={expandedSub[`subPanel${subIndex}`]}
                          onChange={handleSubAccordionChange(`subPanel${subIndex}`)}
                        >
                          <ListItemButton className={classes.listHeading}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              style={{
                                height: '56px',
                                minHeight: '56px',
                                display: 'flex',
                                padding: '0px'
                              }}
                            >
                              <Typography style={{ fontSize: '15px' }}>{theme.name}</Typography>
                            </AccordionSummary>
                          </ListItemButton>
                          <AccordionDetails style={{ padding: '0px' }}>
                            <List disablePadding>
                              {theme.sub_themes.map((subTheme) => (
                                <ListItemButton key={subTheme.id} className={classes.subSubListItem}>
                                  <Typography
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      color: '#000000',
                                      'font-family': 'Satoshi'
                                    }}
                                  >
                                    {subTheme.name}
                                  </Typography>
                                </ListItemButton>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem> */
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </ListItem>
        ))}
    </>
  );
};

const ParentAccordion = ({ classes, focusAreaData, faProps }) => {
  const faClasses = useStyles();
  const { faPexpanded, setFapExpanded, t } = faProps;
  const handleChange = (panel) => (event, isExpanded) => {
    setFapExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={faPexpanded === 'panel1'}
      onChange={handleChange('panel1')}
      className={classes.parentAccordion}
    >
      <AccordionSummary
        className={`${classes.rlpd10} ${faClasses.mainAccSumm}`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.pListHeading}>{t('summary.focusArea')}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px' }}>
        {focusAreaData && focusAreaData.length > 0 && (
          <FocusAreaList
            faClasses={faClasses}
            classes={classes}
            data={focusAreaData}
            faProps={faProps}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ParentAccordion;
