import { makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  mainDiv: {
    marginLeft: '12px',
    background: '#F0F0F0',
    border: '1px solid #E8E8E8',
    borderRadius: '4px',
    padding: '2px 10px'
  },
  displayTypo: {
    fontSize: '12px',
    fontWeight: 400,
    whiteSpace: 'nowrap !important'
  }
}));

const StaticChip = ({ displayText }) => {
  const classes = useStyles();
  return (
    displayText && (
      <Box className={classes.mainDiv}>
        <Typography className={classes.displayTypo}>{displayText}</Typography>
      </Box>
    )
  );
};

export default StaticChip;
