import { MenuItem, Typography, makeStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SingleSearch from '../../atoms/entitySearch/singleSearch';
import { templateActions } from './tearSheetTemplateMocks';
import { ReactComponent as DuplicateIcon } from '../../icons/duplicate.svg';
import SecondaryButton from '../../atoms/buttons/secondaryBtn';
import { InfoOutlined } from '@material-ui/icons';
import {
  entitesFilterToDisplayMap,
  entityTypeToDisplayMap
} from '../../../constants/tearSheet/tearSheet';
import { getNestedValue } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  companySearchDiv: {
    width: '300px',
    margin: '0px 15px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
      width: '280px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  select: {
    backgroundColor: '#FFFFFF',
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& fieldset': {
      borderWidth: '1px !important',
      borderRadius: '3px',
      borderColor: '#E3EBFD !important'
    },
    '& ul.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px'
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Satoshi !important'
    }
  },
  templateSelectForm: {
    width: '305px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px !important',
      width: '280px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '10px !important'
    }
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  mainDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px'
    }
  },
  searchDiv: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column'
    }
  },
  searchIcon: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  publicDataText: {
    fontSize: '14px',
    margin: '0px 0px 1px 8px',
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  manageTemplateDiv: {
    [theme.breakpoints.down('sm')]: {
      display: 'None'
    }
  },
  duplicateBtn: {
    padding: '13px 12px 13px 20px',
    minWidth: '0px',
    marginLeft: '10px'
  },
  infoDiv: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const TearSheetSearchBar = ({
  companyNameOptions = [],
  entity_id = '',
  handleCompanyChange = () => {},
  templateId = '',
  handleTemplateChange = () => {},
  templateList = [],
  handleManageTemplate = () => {},
  currentPage = '',
  handleDuplicate = () => {},
  setFilterEntityType = () => {},
  filterEntityType = 'all'
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filteredOptions = () => {
    if (filterEntityType == 'all') return companyNameOptions;
    return companyNameOptions.filter(
      (option) => getNestedValue(option, 'entity_type') === filterEntityType
    );
  };
  return (
    <div className={classes.mainDiv}>
      <div className={classes.searchDiv}>
        {currentPage === t('tearSheet.tearsheetRoute') && (
          <div className={classes.companySearchDiv}>
            <SingleSearch
              searchIcon={true}
              options={filteredOptions()}
              placeholder={t('tearSheet.searchBarPlaceholder')}
              label={t('tearSheet.entitiesLabel')}
              value={entity_id}
              handleChange={handleCompanyChange}
              optionFormat={{
                key: 'entity_id',
                value: 'entity_name',
                chipValue: 'entity_type'
              }}
              chipValueMap={entityTypeToDisplayMap}
              setFilterEntityType={setFilterEntityType}
              filterEntityType={filterEntityType}
              filterTabsMap={entitesFilterToDisplayMap}
            />
          </div>
        )}
        {(currentPage === t('tearSheet.tearsheetRoute') || templateId !== 'new') && (
          <FormControl
            className={classes.templateSelectForm}
            style={currentPage === 'template' ? { margin: '0px !important' } : {}}
          >
            <InputLabel className={classes.inputLabel}>
              {t('tearSheet.templateSearchLabel')}
            </InputLabel>
            <Select
              label="template"
              className={classes.select}
              value={templateId ? templateId : 'placeHolder'}
              onChange={handleTemplateChange}
              style={{ fontFamily: 'Satoshi' }}
            >
              <MenuItem value="placeHolder" style={{ display: 'none' }}>
                <span>Select Template</span>
              </MenuItem>
              {templateList.map((template, index) => (
                <MenuItem key={index} value={template.template_id}>
                  <span>{template.template_name}</span>
                  {template.data_used === 'pb_data' && (
                    <span className={classes.publicDataText}>{t('tearSheet.publicDataText')}</span>
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {currentPage === t('tearSheet.templateRoute') && templateId !== 'new' && (
          <SecondaryButton
            StartIcon={DuplicateIcon}
            styleClass={classes.duplicateBtn}
            handleClick={handleDuplicate}
            toolTip="Duplicate Template"
          />
        )}
      </div>
      {currentPage === t('tearSheet.templateRoute') &&
        templateId !== 'new' &&
        templateList?.find((template) => template.template_id === templateId)?.data_used ===
          'pb_data' && (
          <div className={classes.infoDiv}>
            <InfoOutlined style={{ fontSize: '22px', marginRight: '6px' }} />
            <Typography>{t('tearSheet.notEditable')}</Typography>
          </div>
        )}
      {currentPage === t('tearSheet.tearsheetRoute') && (
        <div className={classes.manageTemplateDiv}>
          <FormControl className={classes.manageTemplateSelect}>
            {/* <InputLabel className={classes.inputLabel}>
                {t('tearSheet.templateSearchLabel')}
            </InputLabel> */}
            <Select
              // label="template"
              className={classes.select}
              value={'placeHolder'}
              onChange={handleManageTemplate}
              style={{ fontFamily: 'Satoshi' }}
            >
              <MenuItem value="placeHolder" style={{ display: 'none' }}>
                <span>Manage Template</span>
              </MenuItem>
              {templateActions.map((action, index) => (
                <MenuItem key={index} value={action.key}>
                  <span>{action.value}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default TearSheetSearchBar;
