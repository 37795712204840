import React from 'react';
import NewsFeed from '../askBot/news';
import AccordianwithFyTable from './fyTable';
import MapAccordian from './map';
import MapTableAccordian from './mapTable';
import AccordianwithNestedTable from './nestedTable';
import StringAccordian from './string';
import AccordianwithTable from './table';
import AccordianTableNoHeader from './tableNoHeader';

const truncateStr = (str, n) => {
  return str && str.length > n ? str.slice(0, n - 1) + '...' : str;
};

const CustomAccordian = ({ config, index }) => {
  const componentData = config?.componentData;
  const APITabs = componentData?.APITabs;
  const classes = componentData?.classes;
  const currentTabsData =
    APITabs && componentData?.allTabsData && config?.componentData?.allTabsData.length > 0
      ? config?.componentData?.allTabsData[index][APITabs[index]]
      : null;

  if (APITabs && APITabs.length > 0 && APITabs[index] === 'News')
    return <NewsFeed classes={classes} newsPageSize={5} dashBoardNews={false} />;

  if (!currentTabsData) return <></>;
  return (
    <div>
      {currentTabsData &&
        Object.keys(currentTabsData).map((x, i) => {
          const currentVal = currentTabsData;
          if (x === 'Social Media') {
            return;
          }
          if (currentVal[x].bt_type === 'table') {
            return (
              <AccordianwithTable
                key={i}
                title={x}
                tableAccordianData={currentVal[x]?.bt_value}
                classes={classes}
                truncateStr={truncateStr}
              />
            );
          } else if (currentVal[x].bt_type === 'table_no_header') {
            return (
              <AccordianTableNoHeader
                key={i}
                title={x}
                tableAccordianData={currentVal[x]?.bt_value}
                classes={classes}
              />
            );
          } else if (currentVal[x].bt_type === 'map') {
            return (
              <MapAccordian
                key={i}
                title={x}
                mapAccordianData={currentVal[x]?.bt_value}
                classes={classes}
              />
            );
          } else if (currentVal[x].bt_type === 'map_table') {
            return (
              <MapTableAccordian
                key={i}
                title={x}
                mapAccordianData={currentVal[x]?.bt_value}
                classes={classes}
              />
            );
          } else if (currentVal[x].bt_type === 'nested_table') {
            return (
              <AccordianwithNestedTable
                key={i}
                title={x}
                tableAccordianData={currentVal[x]?.bt_value}
                classes={classes}
                truncateStr={truncateStr}
              />
            );
          } else if (currentVal[x].bt_type === 'string') {
            return (
              <StringAccordian
                key={i}
                title={x}
                strAccordianData={currentVal[x]?.bt_value}
                currentTabsData={currentTabsData}
                classes={classes}
              />
            );
          } else if (currentVal[x].bt_type === 'fy_map') {
            return (
              <AccordianwithFyTable
                key={i}
                title={x}
                tableAccordianData={currentVal[x]?.bt_value}
                isFyMap={true}
                classes={classes}
                truncateStr={truncateStr}
              />
            );
          } else if (currentVal[x].bt_type === 'fy_table') {
            return (
              <AccordianwithFyTable
                key={i}
                title={x}
                tableAccordianData={currentVal[x]?.bt_value}
                isFyTable={true}
                classes={classes}
              />
            );
          }
          return <></>;
        })}
      {/* <StringAccordian />
        <AccordianwithNestedTable />
        <AccordianwithTable />
        {companyDetails ? (<MapAccordian />) : null} */}
    </div>
  );
};

export default CustomAccordian;
