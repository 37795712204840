import React from 'react';
import { Grid } from '@material-ui/core';
import TemplateDropdown from './templateDropdown.js';

const TearSheetSearchBar = (tearSheetSearchBarProps) => {
  const {
    t,
    selectedFilter,
    location,
    navigate,
    updateParamsInURL,
    searchParams,
    templateId,
    refetchTemplateOptions
  } = tearSheetSearchBarProps;

  console.log(searchParams);

  const handleReportChange = (event, value) => {
    if (value?.template_id) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { template_id: value.template_id }
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <TemplateDropdown
        t={t}
        selectedFilter={selectedFilter}
        handleReportChange={handleReportChange}
        templateId={templateId}
        refetchTemplateOptions={refetchTemplateOptions}
      />
    </Grid>
  );
};

export default TearSheetSearchBar;
