import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SignedInWarning from '../components/shared/signedInWarning';
import { AuthContext } from '../components/auth/contexts/authContext';

const ProtectedRoute = () => {
  const [token, setToken] = useState('initial');
  let auth = useContext(AuthContext);
  useEffect(() => {
    async function sessionInfo() {
      const session = await auth.getSession();
      const tokenVal = session?.idToken?.jwtToken;
      setToken(tokenVal);
    }
    sessionInfo();
  }, [auth]);
  if (token === 'initial') return <></>;
  return <>{token ? <Outlet /> : <SignedInWarning />}</>;
};

export default ProtectedRoute;
