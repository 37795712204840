import { signOut } from '../auth/libs/cognito';
import { setLocalStorageToCookies, transferCookiesToLocalStorage } from './storageUtil';

const subDomainException = ['dev', 'qa', 'qa-ds'];
export const handleSubDomain = async (signedIn) => {
  const host = window.location.host;
  const parts = host.split('.');
  const domain = getMainDomain();
  if (!signedIn && parts.length > 2 && !subDomainException.includes(parts[0])) {
    const resp = await transferCookiesToLocalStorage();
    if (!resp) {
      window.location.href = window.location.protocol + '//' + domain;
    }
  }
};

export const getMainDomain = () => {
  const host = window.location.host;
  const parts = host.split('.');
  const domain = parts.slice(-2).join('.');
  return domain;
};

export const redirectMainToSubDomain = async (subDomain) => {
  const sub_domain_url = window.location.protocol + '//' + subDomain + '.' + getMainDomain();
  if (sub_domain_url != window.location.origin) {
    setLocalStorageToCookies();
    await signOut();
    window.location.href = sub_domain_url;
  }
};
