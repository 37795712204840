import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Pagination, Button, Skeleton, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { getApi } from '../../../../services/token';
import UploadDocExpFile from './upload';
import { Dialog, DialogContent } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDocExp, setDeTags } from '../../action';

const useStyles = makeStyles(() => ({
  mainFileGridContainer: {
    // height: '100%',
    height: 'calc(100vh - 180px)',
    paddingLeft: '20px'
  },
  box: {
    padding: '10px 20px',
    background: `transparent linear-gradient(90deg, #8F34EA1A 0%, #2B4BDA1A 100%) 0% 0% no-repeat padding-box`,
    borderRadius: '8px',
    height: '100%'
  },
  docExplorerMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    fontWeight: '600 !important',
    color: '#373737 !important'
  },
  pagination: {
    padding: '0px'
  },
  borderButtonStyle: {
    marginLeft: 'auto',
    position: 'relative',
    height: '32px',
    width: '80px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  textBtn: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#171717 !important',
    padding: '0px 2px'
  },
  fileName: {
    fontFamily: 'Satoshi !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: '#373737 !important',
    lineHeight: '20px',
    lineBreak: 'anywhere'
  }
}));

const DocExplorerFiles = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [openUploadState, setOpenUploadState] = useState(false);
  const [allIngestionData, setIngestionData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [sortOrder, setSortOrder] = useState('Desc');
  const [sortBy, setSortBy] = useState('updated_at');
  const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
  const dispatch = useDispatch();
  const selectedDocExp = useSelector((state) => state.tokenReducer.selectedDocExp);
  const deTags = useSelector((state) => state.tokenReducer.deTags);

  const [cachedPages, setCachedPages] = useState({});

  const getDocFromDocLib = async () => {
    if (cachedPages[pageNumber]) {
      // If data for the page is cached, use it and avoid API call
      setIngestionData(cachedPages[pageNumber]);
      return;
    }

    try {
      setLoading(true);
      setIngestionData([]); // Clear current data
      let queryStr = `page_size=${pageSize}&page_number=${pageNumber}`;
      queryStr += sortBy ? `&sort_by=${sortBy}` : '';
      queryStr += sortOrder ? `&sort_order=${sortOrder}` : '';
      queryStr += `&input_source=doc_explorer`;
      const ingestionData = await getApi(`${ingestionUrl}/v1/document?${queryStr}`);
      const { data } = ingestionData?.data || {};
      let { items = [], metadata = {} } = data || {};
      const {
        total_records = totalRecords,
        page_number = pageNumber,
        page_size = pageSize
      } = metadata;

      // Handle page adjustment if necessary
      const pageChangeFlag = (page_number - 1) * pageSize >= total_records;
      if (page_number > 1 && pageChangeFlag) {
        setPageNumber(page_number - 1);
        return;
      }

      items = items.filter(
        (item) =>
          item.association_types &&
          Array.isArray(item.association_types) &&
          item.association_types.length
      );

      // Cache the data by page number
      setCachedPages((prevCache) => ({ ...prevCache, [pageNumber]: items }));
      setIngestionData(items);
      setPageNumber(page_number);
      setPageSize(page_size);
      setTotalRecords(total_records);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocFromDocLib();
  }, [pageNumber, sortOrder, sortBy, pageSize]);

  const handleCheckboxChange = (doc) => {
    let updatedSelectedDocs;

    // Check if the document is already selected
    const isSelected = selectedDocExp.some((d) => d.doc_id === doc.doc_id);

    if (isSelected) {
      // If it's already selected, remove it from selectedDocs
      updatedSelectedDocs = selectedDocExp.filter((d) => d.doc_id !== doc.doc_id);
    } else {
      // If it's not selected, add it to selectedDocs
      updatedSelectedDocs = [
        ...selectedDocExp,
        {
          doc_id: doc.doc_id,
          file_name: `${doc.file_name}.${doc.file_format}`,
          tags: doc.tags
        }
      ];
    }
    dispatch(setSelectedDocExp(updatedSelectedDocs));

    const updatedDeTags = Array.from(new Set(updatedSelectedDocs.flatMap((d) => d.tags)));

    // Dispatch the updated deTags list
    dispatch(setDeTags(updatedDeTags));
  };
  console.log(allIngestionData);
  console.log(setSortOrder);
  console.log(setSortBy);
  console.log('deTags ' + JSON.stringify(deTags));
  const updatedTotalPages = Math.floor((totalRecords - 1) / [pageSize]) + 1;

  const handleClick = () => {
    setOpenUploadState(true);
  };

  const handleClosePopup = () => {
    setOpenUploadState(false);
  };

  const handleUploadPopup = () => {
    setCachedPages({});
    setPageNumber(1);
    getDocFromDocLib();
    setOpenUploadState(false);
  };

  return (
    <>
      <Dialog open={openUploadState} fullWidth={true} maxWidth="lg">
        <DialogContent style={{ padding: '24px' }}>
          <UploadDocExpFile
            handleClosePopup={handleClosePopup}
            handleUploadPopup={handleUploadPopup}
          />
        </DialogContent>
      </Dialog>

      <div className={classes.mainFileGridContainer}>
        <Box className={classes.box}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '6px 0px 20px 0px'
            }}
          >
            <div>
              <Typography className={classes.docExplorerMainTxt}>
                {t('docExplorer.documents')}
              </Typography>
            </div>
            <div>
              <Button className={classes.borderButtonStyle} onClick={() => handleClick()}>
                <Typography className={classes.textBtn}>Upload</Typography>
              </Button>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
          >
            <div style={{ height: 'calc(100vh - 280px)', overflowY: 'auto' }}>
              {loading === true ? (
                <>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <div key={index}>
                      <Card
                        sx={{
                          maxWidth: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '8px',
                          border: '1px solid #E8E8E8'
                        }}
                        elevation={0}
                      >
                        <Skeleton animation="wave" height={10} width="20%" style={{ margin: 20 }} />
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="70%"
                          style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                        />
                      </Card>
                      <br />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {allIngestionData.map((doc) => (
                    <div key={doc.doc_id}>
                      <label>
                        <div style={{ display: 'flex' }}>
                          <div style={{ padding: '5px 0px', position: 'relative' }}>
                            <input
                              type="checkbox"
                              checked={selectedDocExp.some((d) => d.doc_id === doc.doc_id)}
                              onChange={() => handleCheckboxChange(doc)}
                              style={{
                                backgroundColor: selectedDocExp.some((d) => d.doc_id === doc.doc_id)
                                  ? '#004CFF'
                                  : '#FFFFFF',
                                height: '18px',
                                width: '18px',
                                border: '1px solid #004CFF',
                                borderRadius: '4px',
                                cursor: 'pointer'
                              }}
                            />
                          </div>
                          <div style={{ padding: '5px 10px' }}>
                            <Typography className={classes.fileName}>
                              {doc.file_name}.{doc.file_format}
                            </Typography>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div
              style={{
                display:
                  loading === true && Object.keys(cachedPages).length === 0 ? 'none' : 'flex',
                justifyContent: 'center'
              }}
            >
              <Pagination
                className={classes.pagination}
                shape="rounded"
                count={updatedTotalPages}
                onChange={(e, value) => {
                  setPageNumber(value);
                }}
                size={'small'}
                page={pageNumber}
              />
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default DocExplorerFiles;
