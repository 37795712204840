import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headingColor: {
    'background-image': 'linear-gradient(90deg, #004CFF 0%, #06BCC1 10%)',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    [theme.breakpoints.only('xs')]: {
      'background-image': 'linear-gradient(90deg, #004CFF 0%, #06BCC1 40%)'
    }
  },
  header: {
    marginBottom: theme.spacing(0.8),
    fontFamily: 'Satoshi',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      lineHeight: '38px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '20px'
    }
  }
}));

const header = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h3" className={`${classes.headingColor} ${classes.header}`}>
      {children}
    </Typography>
  );
};
export default header;
