import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem
} from '@mui/material';
import { Typography, makeStyles } from '@material-ui/core';
import { Close, ErrorOutline } from '@mui/icons-material';
import CustomButton from '../atoms/buttons/button.js';
import { CheckCircleOutlined } from '@material-ui/icons';
import {
  entitesFilterToDisplayMap,
  entityTypeToDisplayMap
} from '../../constants/tearSheet/tearSheet.js';
import MultiSearch from '../atoms/entitySearch/multiSearch.js';
import StaticChip from '../atoms/staticChip.js';
import { getDisplayTextFromMap } from '../utils/utils.js';
// import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    borderRadius: '16px !important',
    height: '566px',
    width: '500px'
  },
  autocompleteInput: {
    height: '4px !important'
  },
  autocompleteInputDiv: {
    borderRadius: '10px!important'
  },
  autocompleteRoot: {
    margin: '0px 10px'
  },
  closeBtn: {
    position: 'absolute !important',
    right: '10px',
    top: '8px'
  },
  companyList: {
    height: '348px',
    overflow: 'auto',
    margin: '2px 0px !important'
  },
  companyLi: {
    paddingLeft: '0px !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    '&:hover': {
      borderRadius: '3px',
      opacity: '0.9',
      background: 'rgb(248, 250, 253) !important'
    }
  },
  saveBtn: {
    background: 'linear-gradient(90deg,#004CFF,#06BCC1)'
  },
  removeLink: {
    fontWeight: 'lighter',
    fontSize: '13px',
    cursor: 'pointer',
    color: 'blue',
    margin: '0px 10px',
    '&:hover': {
      color: theme.palette.error.main,
      textDecoration: 'underline'
    }
  },
  submitBtn: {
    padding: '6px 16px',
    '& p': {
      fontSize: '16px'
    }
  },
  nameAndChipDiv: {
    display: 'flex',
    alignItems: 'center'
  }
}));

// const companyUrl = process.env.REACT_APP_COMPANY_API;
const AddEntitiesPopOver = ({
  openState = false,
  title = 'Add Companies',
  handleClose = () => {},
  selectedValues = [],
  handleSubmit = () => {},
  options = [],
  handleSearchChange = () => {},
  handleDeleteChange = () => {},
  optionFormat = { key: 'key', value: 'value' },
  btnText = 'Save',
  errorMessage = '',
  successMessage = '',
  setFilterEntityType = () => {},
  filterEntityType = 'all'
}) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const multiSearchProps = {
    handleChange: handleSearchChange,
    options: options,
    selectedValues: selectedValues,
    placeholder: 'Select',
    optionFormat: optionFormat,
    chipValueMap: entityTypeToDisplayMap,
    filterTabsMap: entitesFilterToDisplayMap,
    clearable: true,
    setFilterEntityType: setFilterEntityType,
    filterEntityType: filterEntityType
  };
  return (
    <Dialog open={openState} classes={{ paper: classes.mainDiv }}>
      <IconButton className={classes.closeBtn} onClick={handleClose}>
        <Close />
      </IconButton>
      <DialogTitle style={{ margin: '20px 0px 0px' }}>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent style={{ position: 'relative', display: 'grid' }}>
        <MultiSearch {...multiSearchProps} />
        <List className={classes.companyList}>
          {selectedValues &&
            selectedValues.map((comp) => (
              <ListItem key={comp?.[optionFormat.key]} className={classes.companyLi}>
                <div className={classes.nameAndChipDiv}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      color: '#000000B2',
                      margin: '0px 0px 0px 10px'
                    }}
                  >
                    {comp?.[optionFormat.value]}
                  </Typography>
                  <StaticChip
                    displayText={getDisplayTextFromMap(
                      comp,
                      optionFormat.chipValue,
                      entityTypeToDisplayMap
                    )}
                  />
                </div>
                <Typography
                  className={classes.removeLink}
                  onClick={() => handleDeleteChange(comp?.[optionFormat.key])}
                >
                  remove
                </Typography>
              </ListItem>
            ))}
        </List>
        {errorMessage && (
          <Box
            m={1}
            style={{
              background: '#FDF6F6',
              borderRadius: '5px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'center',
              position: 'absolute',
              bottom: '0px'
            }}
          >
            <ErrorOutline
              style={{
                marginLeft: '13px', // Set margin left to 13px
                marginRight: '5px', // Set margin right to 1px
                color: '#EB5B55' // Set color of the icon to #3C962A
              }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: '#EB5B55',
                marginRight: '15px'
              }}
            >
              {errorMessage}
            </Typography>
          </Box>
        )}
        {successMessage && (
          <Box
            m={1}
            style={{
              background: '#E0F2F1',
              borderRadius: '5px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'center',
              position: 'absolute',
              top: '50px'
            }}
          >
            <CheckCircleOutlined
              style={{
                marginLeft: '13px', // Set margin left to 13px
                marginRight: '5px', // Set margin right to 1px
                color: '#4DB6AC' // Set color of the icon to #3C962A
              }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: '#4DB6AC',
                marginRight: '15px'
              }}
            >
              {successMessage}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'flex', flexDirection: 'column', padding: '0px 30px 16px' }}>
        <CustomButton handleClick={handleSubmit} btnText={btnText} styleClass={classes.submitBtn} />
      </DialogActions>
    </Dialog>
  );
};

export default AddEntitiesPopOver;
