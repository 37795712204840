import { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  Typography
} from '@mui/material';
import { getApi } from '../../../services/token';
import { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Summary from '../summary';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, makeStyles } from '@material-ui/core';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useNavigate, useSearchParams } from 'react-router-dom';
import WatchlistEditDelete from './watchlistEditDelete';
import FocusAreaList from './focusArea';
import { GET_FTS_MAPPING_URL } from '../../../constants/apiUrls';

const url = process.env.REACT_APP_API;
const useStyles = makeStyles((theme) => ({
  logo: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    opacity: 1
  },
  ptList: {
    padding: '20px!important',
    background: 'var(--BG-Colors-BG2, #EAF1FE)',
    height: '100vh',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '260px'
    }
  },
  listSidebar: {
    height: '100vh',
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      position: 'sticky!important',
      top: '0!important'
    }
  },
  liWidth: {
    [theme.breakpoints.up('xs')]: {
      width: '260px'
    }
  },
  menuHeading: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  headingWt: {
    'font-family': 'Satoshi',
    'font-size': '16px',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': '24px',
    padding: '17px 10px 17px 10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      paddingLeft: '2px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  listHeading: {
    display: 'block',
    padding: '0px 10px 0px 10px',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #F0F0F0',
    color: '#000000'
  },
  pListHeading: {
    'font-family': 'Satoshi!important',
    'font-size': '16px',
    'font-style': 'normal!important',
    'font-weight': '700!important',
    'line-height': '22px!important'
  },
  flexP: {
    display: 'flex!important',
    'justify-content': 'space-between!important'
  },
  addWatchlistCls: {
    fontSize: '14px',
    padding: '6px',
    position: 'relative',
    fontWeight: 700,
    cursor: 'pointer',
    '& span:first-of-type': {
      fontSize: '18px'
    }
  },
  gradientColor: {
    'background-clip': 'text !important',
    'background-image':
      'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) !important',
    '-webkit-text-fill-color': 'transparent !important'
  },
  mdBt10: {
    marginBottom: '10px'
  },
  rlpd10: {
    padding: '0 10px!important'
  },
  subLevelAccordian: {
    'font-family': 'Satoshi !important',
    'font-size': '14px!important',
    'font-style': 'normal !important',
    'line-height': '18px !important',
    padding: '8px 0'
  },
  pd0: {
    padding: '0!important'
  },
  width100: {
    width: '100% !important'
  },
  watchlistLiHeading: {
    display: 'block !important',
    padding: '0px 10px 0px 10px !important',
    justifyContent: 'flex-start !important',
    color: '#000000 !important',
    background: '#ffffff'
  },
  watchLiMainAccSumm: {
    height: '56px!important',
    minHeight: '56px!important',
    display: 'flex!important',
    padding: '0px!important'
  },
  selectedBackGround: {
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%)) !important'
  },
  whiteBackGround: {
    background: '#ffffff'
  },
  borderBt: {
    'border-bottom': '1px solid #F0F0F0!important'
  },
  wt600: {
    fontWeight: '600 !important'
  },
  wt500: {
    fontWeight: '500!important'
  },
  wt400: {
    fontWeight: '400 !important'
  },
  wtListName: {
    fontSize: '14px!important',
    fontFamily: 'Satoshi!important',
    color: '#000000!important'
  },
  compListBox: {
    minHeight: 'calc(100vh - 119.5px)!important',
    width: '230px!important',
    [theme.breakpoints.up('md')]: {
      width: '300px!important'
    },
    borderRight: '1px solid #E4EBFC!important',
    backgroundColor: '#FFFFFF!important'
  },
  parentAccordion: {
    boxShadow: 'none!important'
  }
}));

// const companyUrl = process.env.REACT_APP_COMPANY_API;

const Portfolio = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const isTab = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [summaryData, setSummaryData] = useState([]);
  const [themeOptions, setThemeOptions] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedItem = decodeURIComponent(searchParams.get('selectedItem'));
  const entityId = decodeURIComponent(searchParams.get('entityId'));
  const theme = decodeURIComponent(searchParams.getAll('category'));
  const [themes, setThemes] = useState([theme]);
  // const [showSeeMore, setShowSeeMore] = useState(true);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summaryTitle, setSummaryTitle] = useState('');
  const [isSector, setIsSector] = useState(false);
  const classes = useStyles();
  const [isSectorShown, setIsSectorShown] = useState(false);
  const [focusAreaData, setFocusAreaData] = useState([]);
  // const [selectedItem, setSelectedItem] = useState();
  // const themeOptions = [
  //   { key: 'KEY_PEOPLE', value: 'Key People' },
  //   { key: 'FUNDING', value: 'Funding' }
  // ];
  const sectorApi = process.env.REACT_APP_COMPANY_API;
  const [companyNameOptions, setCompanyNameOptions] = useState([]);
  const [currentWatchLists, setCurrentWatchlists] = useState([]);

  const [watchlists, setWatchlists] = useState(null);

  const [faExpanded, setFaExpanded] = useState({});
  const [faPexpanded, setFapExpanded] = useState('panel1');
  const [selectedFaItem, setSelectedFaItem] = useState(null);

  const getWatchlists = async () => {
    const { data } = await getApi(`${url}/v1/user/watchlist`);
    setWatchlists(data?.data && data?.data.length > 0 ? data?.data : []);
  };
  useEffect(() => {
    if (watchlists && watchlists.length > 0 && selectedItem === 'null') {
      navigate(
        `/tracker?selectedItem=${encodeURIComponent(
          watchlists[0].watchlist_id
        )}&entityId=${'watchlist'}`
      );
    }
  }, [watchlists]);

  const getCompanyData = async () => {
    const sectorListData = await getApi(`${sectorApi}/sector`);
    if (sectorListData && sectorListData.data) {
      const option = sectorListData.data.data;
      setCompanyNameOptions(option);
    }
  };

  const getThemeData = async () => {
    const themeOptionsData = await getApi(`${url}/v1/summary/themes`);
    if (themeOptionsData && themeOptionsData.data) {
      const option = themeOptionsData.data.data;
      setThemeOptions(option);
    }
  };

  const fetchFocusAreaData = async () => {
    const postTokenURL = `${GET_FTS_MAPPING_URL}`;
    const resp = await getApi(postTokenURL);
    const respData = resp?.data?.data && resp?.data?.data.length > 0 ? resp.data.data : [];
    setFocusAreaData(respData);
  };

  const init = async () => {
    // setThemeOptions;
    await fetchFocusAreaData();
    await getWatchlists();
    await getCompanyData();
    await getThemeData();
  };

  useEffect(() => {
    init();
  }, []);

  const loadNextPage = async (isAppend = false) => {
    await getSummaryData(isAppend);
  };

  useEffect(() => {
    if (page > 1) loadNextPage(true);
  }, [page]);

  useEffect(() => {
    handleThemeClick();
  }, [themes]);

  const updateSummaryData = (
    currSummData,
    setSummData,
    summData,
    isAppend,
    isWatchListData = false
  ) => {
    // if (!(currSummData && currSummData.length > 0)) setShowSeeMore(false);
    if (currSummData && currSummData.length < 10) setShowSeeMore(false);
    if (isAppend) currSummData = [...summData, ...currSummData];
    setSummData(currSummData);

    if (isWatchListData) {
      const currWatchListData =
        currSummData && currSummData.length > 0 ? currSummData[0].watchlist : [];

      setCurrentWatchlists(currWatchListData);
    } else {
      // setCurrentWatchlists([]);
      const currWatchListData =
        currSummData && currSummData.length > 0 ? currSummData[0].watchlist : [];

      setCurrentWatchlists(currWatchListData);
    }
  };

  const getCurrentWatchlistName = () => {
    const watchlist =
      watchlists && watchlists.find((watchlist) => watchlist.watchlist_id === selectedItem);
    return watchlist ? watchlist.name : null;
  };

  const getCurrentFocusAreaThemeName = () => {
    const data = focusAreaData;
    if (entityId === 'theme') {
      for (let i = 0; i < data.length; i++) {
        const themes = data[i].themes;
        for (let j = 0; j < themes.length; j++) {
          if (themes[j].id === selectedItem) {
            return themes[j].name;
          }
        }
      }
    } else if (entityId === 'focus_area') {
      for (let i = 0; i < data.length; i++) {
        if (data[i].focus_area_id === selectedItem) {
          return data[i].focus_area;
        }
      }
    }
    return '';
  };

  const getCurrentActiveName = () => {
    return getCurrentWatchlistName() || selectedItem;
  };

  const convertString = (str) => {
    if (!str) {
      return '';
    }

    const words = str.split('_');
    const formattedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formattedString ? `${formattedString} ` : '';
  };

  const getSummaryData = async (isAppend) => {
    setIsSector(true);
    setLoading(true);

    let themeParams = '';

    if (theme !== '') {
      themeParams = `category=${encodeURIComponent(theme)}`;
    }
    let summaryUrl = '';
    if (['focus_area', 'theme'].includes(entityId)) {
      summaryUrl = `${url}/v1/summary/fts?entity_id=${selectedItem}&entity_type=${entityId}&page_number=${String(
        page
      )}&page_size=10${themeParams !== '' ? `&${themeParams}` : ''}`;
    } else {
      summaryUrl = `${url}/v1/summary?entity=${encodeURIComponent(
        entityId
      )}&entity_id=${encodeURIComponent(selectedItem)}&page_number=${String(page)}&page_size=10${
        themeParams !== '' ? `&${themeParams}` : ''
      }`;
    }
    try {
      const sectorData = await getApi(summaryUrl);
      setSummaryTitle(
        `${
          entityId === 'watchlist' ? 'Watchlist Highlights' : convertString(entityId) + 'Highlights'
        } - ${
          entityId === 'watchlist' ? getCurrentWatchlistName() : getCurrentFocusAreaThemeName()
        }`
      );
      const parsedData = sectorData?.data?.data.summary_list;
      setIsSectorShown(parsedData && parsedData.length > 0);
      let currentSummaryData = parsedData && parsedData.length > 0 ? parsedData : [];
      updateSummaryData(currentSummaryData, setSummaryData, summaryData, isAppend);
    } catch (err) {
      setIsSectorShown(false);
      setShowSeeMore(false);
      setSummaryTitle(
        `${
          entityId === 'watchlist' ? 'Watchlist Highlights' : convertString(entityId) + 'Highlights'
        } - ${
          entityId === 'watchlist' ? getCurrentWatchlistName() : getCurrentFocusAreaThemeName()
        }`
      );
    }
    setLoading(false);
  };

  const [expanded, setExpanded] = useState(true);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const [expandedHighlights, setExpandedHighlights] = useState(true);

  const handleAccordionHighlightsChange = () => {
    setExpandedHighlights(!expandedHighlights);
  };

  useEffect(() => {
    if (selectedItem !== 'null') {
      loadNextPage();
    } else {
      setLoading(true);
    }
  }, [selectedItem, theme, watchlists]);

  const handleItemClick = async (item, entity) => {
    setPage(1);
    setShowSeeMore(true);
    // setSelectedItem(item);

    let themeParams = '';
    themeParams = themes.map((theme) => `category=${encodeURIComponent(theme)}`).join('&');
    navigate(
      `/tracker?selectedItem=${encodeURIComponent(item)}&entityId=${entity}${
        themeParams !== '' ? `&${themeParams}` : ''
      }`
    );
  };

  useEffect(() => {
    if (focusAreaData && focusAreaData.length > 0 && ['focus_area', 'theme'].includes(entityId)) {
      if (
        entityId &&
        selectedItem &&
        (selectedFaItem?.entity_type !== entityId || selectedFaItem?.id !== selectedItem)
      ) {
        if (entityId === 'theme') {
          const currentFocusArea = focusAreaData.find((x) =>
            x.themes.find((x) => x.id === selectedItem)
          );
          if (currentFocusArea && currentFocusArea?.focus_area_id) {
            setFaExpanded({ [currentFocusArea?.focus_area_id]: true });
          }
        }
        setSelectedFaItem({ entity_type: entityId, id: selectedItem });

        if (entityId === 'focus_area') setFaExpanded({ [selectedItem]: true });
      }
    } else if (!['focus_area', 'theme'].includes(entityId)) {
      setFaExpanded({});
      setSelectedFaItem(null);
    }
  }, [selectedItem, entityId, focusAreaData.length]);

  useEffect(() => {
    if (selectedFaItem && selectedFaItem.id) {
      handleFaItemClick(selectedFaItem);
    }
  }, [selectedFaItem]);

  const handleFaItemClick = async ({ entity_type, id }) => {
    setPage(1);
    setShowSeeMore(true);

    let themeParams = '';
    themeParams = themes.map((theme) => `category=${encodeURIComponent(theme)}`).join('&');
    navigate(
      `/tracker?selectedItem=${id}&entityId=${entity_type}${
        themeParams !== '' ? `&${themeParams}` : ''
      }`
    );
  };

  const handleThemeClick = async () => {
    setPage(1);
    setShowSeeMore(true);
    let themeParams = '';
    if (Array.isArray(themes)) {
      const individualThemes = themes.flatMap((theme) => theme.split(','));
      themeParams = individualThemes
        .map((theme) => `category=${encodeURIComponent(theme)}`)
        .join('&');
    }
    navigate(
      `/tracker?selectedItem=${encodeURIComponent(selectedItem)}&entityId=${entityId}${
        themeParams !== 'category=' ? `&${themeParams}` : ''
      }`
    );
  };

  const [open, setOpen] = useState(false);
  const [openWatchlist, setOpenWatchlist] = useState(false);

  const handleCloseWatchlist = () => {
    setOpenWatchlist(false);
  };

  const handleOpenWatchlist = () => {
    setOpenWatchlist(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const selectSector = (sector, entity) => {
    handleItemClick(sector, entity);
    setSummaryData([]);
    handleClose();
  };

  const useIsItemSelected = (options) => {
    return useMemo(() => {
      if (options && options.length > 0) {
        const selectedSet = new Set(options);
        return selectedSet.has(selectedItem);
      }
      return false;
    }, [options, selectedItem]);
  };

  // const getWatchListsName = () => watchlists && watchlists.map((x) => x.name);
  // const memoizedIsPortfolioSelected = useIsItemSelected(getWatchListsName(), selectedItem);
  const memoizedIsSectorSelected = useIsItemSelected(companyNameOptions, selectedItem);

  const showSectorOpt = false;
  const SideBarSectorList = () => {
    return (
      <div className={`${classes.ptList} ${classes.listSidebar}`}>
        <List disablePadding className={classes.liWidth}>
          <ListItem disablePadding className={classes.mdBt10}>
            <Accordion
              className={classes.width100}
              elevation={0}
              expanded={expandedHighlights}
              onChange={handleAccordionHighlightsChange}
            >
              <ListItemButton className={classes.watchlistLiHeading}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.watchLiMainAccSumm}
                >
                  <Typography className={classes.pListHeading}>
                    {t('portfolio.highlights')}
                  </Typography>
                </AccordionSummary>
              </ListItemButton>
              <AccordionDetails className={classes.pd0}>
                {watchlists &&
                  watchlists.map((option, index) => (
                    <ListItemButton
                      key={index}
                      selected={selectedItem === option?.watchlist_id}
                      // onClick={() => selectSector(option)}
                      onClick={() => {
                        if (selectedItem !== option?.watchlist_id) {
                          selectSector(t(option.watchlist_id), 'watchlist');
                        }
                      }}
                      className={`${classes.pListHeading} ${classes.borderBt} ${
                        selectedItem === option?.watchlist_id
                          ? classes.selectedBackGround
                          : classes.whiteBackGround
                      }`}
                    >
                      <Typography
                        className={`${classes.wtListName} ${
                          selectedItem === option?.watchlist_id
                            ? `${classes.gradientColor} ${classes.wt600}`
                            : classes.wt400
                        }`}
                      >
                        {option.name}
                      </Typography>
                    </ListItemButton>
                  ))}
              </AccordionDetails>
            </Accordion>
          </ListItem>
          {showSectorOpt && (
            <ListItem disablePadding className={classes.mdBt10}>
              <Accordion
                className={classes.width100}
                style={{
                  width: '100%'
                }}
                elevation={0}
                expanded={expanded}
                onChange={handleAccordionChange}
              >
                <ListItemButton
                  // selected={expanded && selectedItem !== t('tab.Tracker')}
                  sx={{
                    display: 'block',
                    padding: '0px 10px 0px 10px',
                    justifyContent: 'flex-start',
                    borderBottom: '1px solid var(#F0F0F0, #F0F0F0)',
                    color: '#000000',
                    background: '#ffffff',
                    '&:hover': {
                      background:
                        expanded && memoizedIsSectorSelected
                          ? 'hsla(218, 89%, 51%, 0.32)'
                          : '#ffffff'
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                      height: '56px',
                      minHeight: '56px',
                      display: 'flex',
                      padding: '0px'
                    }}
                  >
                    <Typography className={classes.pListHeading}>{t('tab.Tracker')}</Typography>
                  </AccordionSummary>
                </ListItemButton>
                <AccordionDetails style={{ padding: '0px' }}>
                  {companyNameOptions.map((option, index) => (
                    <ListItemButton
                      key={index}
                      selected={selectedItem === option}
                      // onClick={() => selectSector(option)}
                      onClick={() => {
                        if (selectedItem !== option) {
                          selectSector(t(option), 'sector');
                        }
                      }}
                      style={{
                        border: '1px solid #E4EBFC',
                        borderTop: 'none',
                        borderRight: 'none',
                        // background: selectedItem === option ? '#a7bbd9' : '#f5f8fa'
                        background:
                          selectedItem === option
                            ? 'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%))'
                            : '#ffffff'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: selectedItem === option ? '600' : '400',
                          fontFamily: 'Satoshi',
                          color: '#000000'
                        }}
                      >
                        {option}
                      </Typography>
                    </ListItemButton>
                  ))}
                </AccordionDetails>
              </Accordion>
            </ListItem>
          )}
          <FocusAreaList
            classes={classes}
            focusAreaData={focusAreaData}
            faProps={{
              faExpanded,
              setFaExpanded,
              faPexpanded,
              setFapExpanded,
              selectedFaItem,
              setSelectedFaItem,
              t
            }}
          />
        </List>
      </div>
    );
  };

  const CompanyList = () => {
    return (
      <List disablePadding className={`${classes.listSidebar}`}>
        {currentWatchLists &&
          currentWatchLists.length > 0 &&
          currentWatchLists.map((x, i) => {
            return (
              <ListItem disablePadding key={i}>
                <ListItemButton
                  sx={{
                    padding: '10px',
                    height: '45px',
                    justifyContent: 'flex-start',
                    cursor: 'default',
                    background: '#fafafa',
                    '&:hover': {
                      background: '#FFFFFF'
                    }
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      fontFamily: 'Satoshi',
                      color: '#000000',
                      cursor: 'default'
                    }}
                  >
                    {x.name}
                  </Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
    );
  };

  const CompanyListBox = () => {
    const [watchlistData, setWatchlistData] = useState(null);

    useEffect(() => {
      const currentWatchList =
        watchlists && watchlists.find((watchlist) => watchlist.watchlist_id === selectedItem);
      setWatchlistData(currentWatchList);
    }, []);

    return (
      <>
        {watchlistData && (
          <Box
            sx={{
              minHeight: 'calc(100vh - 119.5px)',
              width: '230px',
              borderRight: '1px solid #E4EBFC',
              backgroundColor: '#FFFFFF'
            }}
          >
            <div>
              <Typography className={`${classes.headingWt} ${classes.flexP}`}>
                <span>{getCurrentActiveName()}</span>
                <WatchlistEditDelete currentWatchList={watchlistData} />
              </Typography>
              <div
                className={`${classes.addWatchlistCls} ${classes.gradientColor}`}
                onClick={() => {
                  navigate('/watchlist-company');
                }}
              >
                <span>+</span> <span>{t('portfolio.Addcompanytowatchlist')}</span>
              </div>
            </div>
            <CompanyList />
          </Box>
        )}
      </>
    );
  };
  return (
    <>
      {isTab && (
        <div style={{ display: 'flex', justifyContent: 'space-between', height: '28px' }}>
          <IconButton onClick={handleOpen}>
            <MenuOpenIcon sx={{ color: '#000000' }} />
            <span
              style={{
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                paddingLeft: '6px'
              }}
              className={classes.menuHeading}
            >
              {selectedItem === t('portfolio.highlights')
                ? t('portfolio.Watchlist')
                : t('portfolio.otherSectors')}
            </span>
          </IconButton>
          {!isSector && (
            <IconButton onClick={handleOpenWatchlist}>
              <MenuOpenIcon sx={{ color: '#000000' }} />
            </IconButton>
          )}
        </div>
      )}
      <div style={{ display: 'flex' }}>
        {isTab && (
          <>
            <Drawer
              anchor="left"
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  backgroundColor: '#F8F9FD'
                }
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton className={classes.logo}>
                  <CloseIcon sx={{ color: '#000000' }} onClick={handleClose} />
                </IconButton>
              </div>
              <SideBarSectorList />
            </Drawer>
          </>
        )}
        {!isTab && (
          <Box className={classes.compListBox}>
            <SideBarSectorList />
          </Box>
        )}
        <Summary
          showSeeMore={showSeeMore}
          loading={loading}
          summaryData={summaryData}
          summaryTitle={summaryTitle}
          setPage={setPage}
          page={page}
          isSectorShown={isSectorShown}
          isSector={isSector}
          watchlists={watchlists}
          themeOptions={themeOptions}
          setThemes={setThemes}
          themes={themes}
        />
        {!isTab && currentWatchLists && currentWatchLists.length > 0 && (
          <>
            <CompanyListBox />
          </>
        )}
        {isTab && currentWatchLists && currentWatchLists.length > 0 && (
          <Drawer
            anchor="right"
            open={openWatchlist}
            onClose={handleCloseWatchlist}
            PaperProps={{
              style: {
                backgroundColor: '#F8F9FD'
              }
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton className={classes.logo}>
                <CloseIcon sx={{ color: '#000000' }} onClick={handleCloseWatchlist} />
              </IconButton>
            </div>
            <CompanyListBox />
          </Drawer>
        )}
      </div>
    </>
  );
};

export default Portfolio;
