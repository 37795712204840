import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import ContactSupportComponent from './contactSupportComponent';

const useStyles = makeStyles({
  helpCenterContentMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '22px !important',
    fontWeight: '700 !important',
    paddingBottom: '16px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  },
  helpCenterContactSupport: {
    marginTop: '20px',
    padding: '32px 0px',
    backgroundColor: '#F4F8FF',
    width: '100%',
    border: '1px solid #CCDBFF',
    borderRadius: '8px'
  },
  helpCenterContactSupportSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    fontWeight: '700 !important',
    color: '#373737 !important',
    paddingBottom: '10px !important',
    lineHeight: '24px !important',
    display: 'flex',
    alignItems: 'center'
  },
  demoBtn: {
    height: '35px',
    width: '150px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    margin: '0px 16px 0px 8px',
    padding: '0px 14px'
  }
});

function AskByzHelpCenter() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentMainTxt}>Ask Byz Q&A</Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubTxt}>
          Welcome to PrivateBlok, your AI Copilot for Private Markets.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Ask Byz is an AI chatbot that engages users in human-like business conversations by
          answering questions on companies, sectors, and people and helping them gain insights
          through relevant information in the form of texts, tables, images, and graphs.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Byz can help you gain deeper understanding and insights about the private markets,
          including companies, sectors and key people. Ask any question and Byz will answer it based
          on publicly available data or your own uploaded documents. It will also suggest relevant
          follow-up questions to guide you along your analysis.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Let's explore this feature.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubTxt}>Explore Companies</Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Browse and navigate across all available private companies on the platform. Narrow down to
          the subset of your interest using the filters. In case you don’t find a particular private
          company, you may place a request for it to be added to the platform.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            controls
            style={{
              maxWidth: '60%',
              maxHeight: '100%',
              width: '60%',
              height: 'auto'
            }}
          >
            <source src="https://public.privateblok.ai/help_center/askbyz.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Grid>
      <ContactSupportComponent />
    </Grid>
  );
}

export default AskByzHelpCenter;
