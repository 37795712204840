import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  timeText: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  padding: {
    paddingRight: '20px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '5px'
    }
  },
  questionText: {
    fontSize: '18px',
    paddingTop: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      paddingTop: '2px'
    }
  }
}));

function UserComponent({ messageData }) {
  const classes = useStyles();
  return (
    <>
      <div id="askBotChildMessageEndRef">
        {/* <Typography
          style={{ color: '#888888', fontWeight: 500 }}
          id="askBotTypoMessageEndRef"
          className={classes.timeText}
        >
          {messageData.question_time}
        </Typography> */}
        <Typography
          id="askBotTypo2MessageEndRef"
          style={{
            color: '#171717',
            'font-family': 'Satoshi',
            'font-size': '16px',
            'font-style': 'normal',
            'font-weight': '700',
            'line-height': 'normal'
          }}
          className={classes.questionText}
        >
          {messageData.question}
        </Typography>
      </div>
    </>
  );
}

export default UserComponent;
