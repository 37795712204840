import { Chip } from '@material-ui/core';

const ChipComponent = ({
  key = '',
  label = '',
  onClick = () => {},
  className = '',
  disabled = false,
  title = ''
}) => {
  return (
    <span title={title}>
      <Chip key={key} label={label} className={className} onClick={onClick} disabled={disabled} />
    </span>
  );
};

export default ChipComponent;
