import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import ContactSupportComponent from './contactSupportComponent';

const useStyles = makeStyles({
  helpCenterContentMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '22px !important',
    fontWeight: '700 !important',
    paddingBottom: '16px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  },
  helpCenterContentSubContent: {
    fontFamily: 'Satoshi !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    paddingBottom: '10px !important',
    color: '#373737 !important'
  }
});

function TearSheetsHelpCenter() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentMainTxt}>Custom Reports</Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Custom Reports lets you generate tailored analyses - whether quick summaries or
          comprehensive overviews - using pre-defined or user-defined templates, on themes or
          companies. Easily generate reports on entities, to empower your data-driven decisions.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <Typography className={classes.helpCenterContentSubContent}>
          Let's explore this feature.
        </Typography>
      </Grid>
      <Grid lg={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            controls
            style={{
              maxWidth: '60%',
              maxHeight: '100%',
              width: '60%',
              height: 'auto'
            }}
          >
            <source
              src="https://public.privateblok.ai/help_center/customreport.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </Grid>
      <ContactSupportComponent />
    </Grid>
  );
}

export default TearSheetsHelpCenter;
