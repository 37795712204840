import { Paper, Tooltip, Typography, makeStyles } from '@material-ui/core';
import CustomTextField from '../../../../../atoms/textfield';
import { Autocomplete, IconButton } from '@mui/material';
import { Add, Check, Close, DragIndicator } from '@material-ui/icons';
import { defaultQuestion, questionLib, questionTypes } from '../tearSheetTemplateMocks.js';
import CustomSelect from '../../../../../atoms/select';
import TertiaryButton from '../../../../../atoms/buttons/tertiaryBtn';
import { useTranslation } from 'react-i18next';
import InputError from '../../../../../atoms/inputError';
import { ContentCopy, ContentPaste } from '@mui/icons-material';
const useStyles = makeStyles(() => ({
  mainDiv: {
    padding: '14px',
    position: 'relative'
  },
  sectionLevelOne: {
    display: 'flex',
    width: '100%'
  },
  closeSectionBtn: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  keyDiv: {
    width: '14%',
    margin: '18px 0px 0px'
  },
  textDiv: {
    width: '56%',
    margin: '16px 0px 0px'
  },
  keyTypography: {
    fontSize: '18px',
    marginTop: '4px'
  },
  questionsDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 0px 0px'
  },
  selectDiv: {
    width: '14%',
    marginLeft: '2%'
  },
  closeQuestionDiv: {
    '& button': {
      padding: '7px',
      marginLeft: '5px',
      margin: '16px 0px 0px'
    }
  },
  addQuestionBtn: {
    margin: '0 14% 0 auto'
  },
  dragDiv: {
    position: 'absolute',
    top: '-4px',
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  dragIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(90deg)',
    cursor: 'all-scroll !important'
  },
  questionAutocomplete: {
    '& .MuiInputBase-input': {
      height: '7px !important'
    }
  }
}));
const TemplateSectionCard = ({
  sectionIndex,
  section,
  handleSectionChange,
  handleDeleteSection,
  validationErrors,
  copied,
  handleSectionCopy,
  handleSectionPaste,
  disabled,
  dragHandleProps,
  pasteEnabled,
  copyEnabled,
  setValidationErrors
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleQuestionChange = (questionIndex, question) => {
    let updatedSection = JSON.parse(JSON.stringify({ ...section }));
    let libQuestion = questionLib.find((questionObj) => questionObj.question === question);
    if (libQuestion) {
      updatedSection.questions[questionIndex] = libQuestion;
      // Merge only the relevant fields from libQuestion, while keeping the rest unchanged
      updatedSection.questions[questionIndex] = {
        ...updatedSection.questions[questionIndex], // Preserve existing fields like question_type
        question: libQuestion.question,
        type: libQuestion.type,
        static_field: libQuestion.static_field
      };
    } else {
      updatedSection.questions[questionIndex].question = question;
    }
    handleSectionChange(updatedSection);
  };

  const handleQuestionTypeChange = (questionIndex, value) => {
    let updatedSection = JSON.parse(JSON.stringify({ ...section }));
    updatedSection.questions[questionIndex].question_type = value;
    handleSectionChange(updatedSection);
  };
  const handleHeadingChange = (value) => {
    let updatedSection = JSON.parse(JSON.stringify({ ...section }));
    updatedSection.name = value;
    handleSectionChange(updatedSection);
  };
  const handleDeleteQuestion = (index) => {
    let updatedSection = JSON.parse(JSON.stringify({ ...section }));
    updatedSection.questions.splice(index, 1);
    handleSectionChange(updatedSection);
    if (validationErrors) {
      setValidationErrors((prev) => {
        let updatedErrors = { ...prev };
        let question_group_errors = updatedErrors.question_groups[sectionIndex];
        if (question_group_errors?.questions) {
          question_group_errors.questions.splice(index, 1);
        }
        return updatedErrors;
      });
    }
  };
  const handleAddQuestion = () => {
    let updatedSection = JSON.parse(JSON.stringify({ ...section }));
    updatedSection.questions.push(defaultQuestion);
    if (validationErrors) {
      setValidationErrors((prev) => {
        let updatedErrors = { ...prev };
        let question_group_errors = updatedErrors.question_groups[sectionIndex];
        if (question_group_errors?.questions) {
          question_group_errors.questions.push({});
        }
        return updatedErrors;
      });
    }
    handleSectionChange(updatedSection);
  };
  return (
    <Paper fullwidth className={classes.mainDiv} elevation={3}>
      {!disabled && (
        <div className={classes.dragDiv}>
          <span className={classes.dragIcon} {...dragHandleProps}>
            <DragIndicator />
          </span>
        </div>
      )}
      <div className={classes.closeSectionBtn}>
        {copyEnabled && (
          <Tooltip
            title={copied ? 'Section Copied!' : 'Copy Section'}
            style={disabled ? { marginRight: '5px' } : {}}
          >
            {copied ? (
              <IconButton>
                <Check style={{ color: '#22A428' }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleSectionCopy}>
                <ContentCopy />
              </IconButton>
            )}
          </Tooltip>
        )}
        {!disabled && (
          <Tooltip title={'Paste Section'}>
            <IconButton onClick={handleSectionPaste} disabled={!pasteEnabled}>
              <ContentPaste />
            </IconButton>
          </Tooltip>
        )}
        {!disabled && (
          <Tooltip title="Remove Section">
            <IconButton onClick={handleDeleteSection}>
              <Close />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.sectionLevelOne}>
        <div className={classes.keyDiv}>
          <Typography className={classes.keyTypography}>
            {t('tearSheet.sectionHeadingLabel')}
          </Typography>
        </div>
        <div className={classes.textDiv}>
          <CustomTextField
            value={section.name}
            errorMessage={validationErrors?.name}
            handleChange={(value) => handleHeadingChange(value)}
            placeholder={t('tearSheet.sectionHeadingPlaceholder')}
            disabled={disabled}
          />
        </div>
      </div>
      {validationErrors?.questions_error && section.questions?.length == 0 && (
        <div style={{ marginTop: '8px' }}>
          <InputError errorMessage={validationErrors?.questions_error} />
        </div>
      )}
      {section.questions?.length > 0 && (
        <div className={classes.questionsDiv}>
          {section.questions.map((questionObj, questionIndex) => (
            <div key={questionIndex} className={classes.sectionLevelOne}>
              <div className={classes.keyDiv}>
                <Typography className={classes.keyTypography}>
                  {`${t('tearSheet.questionLabel')} ${questionIndex + 1}`}
                </Typography>
              </div>
              <div className={classes.textDiv}>
                <Autocomplete
                  freeSolo
                  className={classes.questionAutocomplete}
                  value={questionObj.question}
                  options={questionLib.map((option) => option.question)}
                  onInputChange={(event, value) => handleQuestionChange(questionIndex, value)}
                  renderInput={(params) => (
                    <form noValidate>
                      <CustomTextField
                        params={params}
                        placeholder={t('tearSheet.questionPlaceholder')}
                        label="Byz Question"
                        errorMessage={validationErrors?.questions?.[questionIndex]?.question}
                      />
                    </form>
                  )}
                  disabled={disabled}
                  disableClearable
                  autoHighlight
                  autoComplete
                />
              </div>
              <div className={classes.selectDiv}>
                <CustomSelect
                  value={questionObj.question_type}
                  options={questionTypes}
                  handleChange={(value) => handleQuestionTypeChange(questionIndex, value)}
                  label={t('tearSheet.questionTypeLabel')}
                  placeholder={t('tearSheet.questionTypePlaceholder')}
                  errorMessage={validationErrors?.questions?.[questionIndex]?.question_type}
                  disabled={disabled}
                />
              </div>
              {!disabled && (
                <div className={classes.closeQuestionDiv}>
                  <IconButton onClick={() => handleDeleteQuestion(questionIndex)}>
                    <Close />
                  </IconButton>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {!disabled && (
        <div className={classes.sectionLevelOne}>
          <div className={classes.addQuestionBtn}>
            <TertiaryButton
              handleClick={handleAddQuestion}
              btnText={t('tearSheet.addQuestionBtn')}
              StartIcon={Add}
            />
          </div>
        </div>
      )}
    </Paper>
  );
};

export default TemplateSectionCard;
