import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { Button, Typography, Grid } from '@mui/material';
import './userProfile.css';
import ChangePassword from '../auth/changePassword';
import { useState } from 'react';
// import { getNameFunc, processNameFunc } from '../shared/utils';
import { processNameFunc } from '../shared/utils/usernameFunc';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../components/auth/contexts/authContext';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { deleteApi } from '../../services/token';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as SeeMore } from '../../components/icons/SeeMore.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { getApi } from '../../services/token';
import { setResetState } from '../shared/action';
import { useDispatch } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import { formatedTime } from '../shared/utils/formatedTime';
import InfoBar from '../atoms/infoBar';
import { getDisplayTextFromMap } from '../utils/utils';
import { limitsTypeToDisplayMap } from '../../constants/profile';

const url = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogPaper: {
    margin: 0,
    width: '100%'
  },
  planTable: {
    border: '1px solid #ccc',
    '& tbody tr:nth-child(even)': {
      backgroundColor: '#f6f7fe'
    },
    '& tbody tr:hover': {
      backgroundColor: '#FFFFFF'
    }
  },
  fontStyle: {
    fontFamily: 'Satoshi !important',
    color: '#373737'
  },
  accDetails: { overflowX: 'auto' },
  highlightWidth: {
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      width: 'min-content'
    }
  },
  marginTable: {
    minWidth: 'fit-content'
  },
  planInfoMainBox: {
    marginLeft: '258px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('1200')]: {
      marginLeft: '238px'
    },
    [theme.breakpoints.down('900')]: {
      marginLeft: '188px'
    },
    [theme.breakpoints.down('600')]: {
      margin: '20px'
    }
  },
  planNameBox: {
    display: 'inline-flex',
    padding: '5px 12px',
    alignItems: 'center',
    gap: '30px',
    borderRadius: '4px',
    border: '1px solid #EDEDED',
    marginTop: '64px',
    width: 'fit-content'
  },
  planNameTypo: {
    color: '#373737 !important',
    fontSize: '14px!important',
    fontWeight: '600 !important',
    fontFamily: 'Satoshi !important',
    [theme.breakpoints.down('900')]: {
      fontSize: '12px !important'
    }
  }
}));

function UserProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [nameChar, setNameChar] = useState(null);
  const [nameVal, setNameVal] = useState(null);
  const [emailVal, seteEmailVal] = useState(null);
  const [isemailVerified, setIsemailVerified] = useState(null);
  const [org, setOrg] = useState(null);
  const [dept, setDept] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [tokenVal, setTokenVal] = useState(false);
  const [deleteFail, setDeleteFail] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  // const isSmlMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const gridHeight = isDesktop ? 'calc(100vh - 119.5px)' : '100vh';
  const { state } = useLocation();

  const [planDetails, setPlanDetails] = useState({});
  const [userPlanUsage, setUserPlanUsage] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [emailVerifiedSuccess, setEmailVerifiedSuccess] = useState('');

  const getCurrentUserPlan = async () => {
    try {
      const getCurrentUserPlanUrl = `${process.env.REACT_APP_PAYMENT_API_URL}/user/limits`;
      const { data } = await getApi(getCurrentUserPlanUrl);
      setPlanDetails(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getUserPlanUsage = async () => {
    try {
      const getCurrentUserPlanUrl = `${process.env.REACT_APP_PAYMENT_API_URL}/user/plan/usage`;
      const { data } = await getApi(getCurrentUserPlanUrl);
      setUserPlanUsage(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setEmailVerifiedSuccess(false);
    }, 6000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleClose = () => {
    setEmailVerifiedSuccess(false);
  };

  // disabled plan usage history/ payment history
  // eslint-disable-next-line no-unused-vars
  const getPaymentHistory = async () => {
    try {
      const getCurrentUserPlanUrl = `${process.env.REACT_APP_PAYMENT_API_URL}/user/plan/payment_history`;
      const { data } = await getApi(getCurrentUserPlanUrl);
      setPaymentHistory(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (state?.emailVerified) setEmailVerifiedSuccess(state.emailVerified);
  }, [state]);

  useEffect(() => {
    getCurrentUserPlan();
    getUserPlanUsage();
    // getPaymentHistory();                 disabled plan usage history/ payment history
  }, []);

  useEffect(() => {
    const getName = async () => {
      // const { nameVal, nameCharVar, emailVal, isemailVerified } = await getNameFunc(auth);
      const session = await auth.getSession();
      const response = await getApi(`${url}/v1/user`);
      const { nameVal, nameCharVar, emailVal, isemailVerified, org, dept } =
        processNameFunc(response);
      setTokenVal(session?.accessToken.jwtToken);
      setNameChar(nameCharVar);
      setNameVal(nameVal);
      seteEmailVal(emailVal);
      setIsemailVerified(isemailVerified);
      setOrg(org);
      setDept(dept);
      // setIsemailVerified(response.data.data.email_verified);
    };
    getName();
  }, []);
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
    setDeleteFail(false);
  };
  const deleteAccount = async () => {
    const postTokenURL = `${url}/user`;
    try {
      await deleteApi(postTokenURL, tokenVal);
      homePage();
    } catch (err) {
      setDeleteFail(true);
      console.log(err);
    }
  };

  const history = useNavigate();
  const homePage = () => {
    auth.signOut();
    dispatch(setResetState());
    history('/');
    window.location.reload(false);
  };

  const getAttributeVerificationCode = async () => {
    try {
      await auth.getAttributeVerificationCode();
      history('/verify-email', { state: { emailID: emailVal, verifyEmail: true } });
    } catch (err) {
      console.log(err);
    }
  };

  const userPlanHeaders = ['Feature', 'Total Credits', 'Remaining Credits'];
  const transactionHistoryHeaders = ['Payment Date', 'Amount', 'Payment Mode', 'Status'];
  const usageHistoryHeaders = ['Date', 'Credits Consumed'];
  return (
    <>
      <Grid
        style={{
          minHeight: gridHeight
        }}
      >
        <div style={{ position: 'relative' }}>
          <Box
            sx={{
              background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
              display: 'flex',
              alignItems: 'center',
              height: { xs: '72px', sm: '80px', md: '100px', lg: '142px' }
            }}
          />
          <Avatar
            sx={{
              position: 'absolute',
              left: { xs: '20px', sm: '45px', md: '85px', lg: '115px' },
              bottom: { xs: '-55px', sm: '-70px', md: '-72px', lg: '-72px' },
              height: { xs: '96px', sm: '120px', md: '120px', lg: '120px' },
              width: { xs: '96px', sm: '120px', md: '120px', lg: '120px' },
              border: '1px solid #7DA3FD',
              backgroundColor: '#E3EBFD',
              color: '#0C0C0C',
              fontSize: { xs: '24px', sm: '24px', md: '24px', lg: '24px' },
              fontFamily: 'Satoshi',
              fontWeight: 'normal'
            }}
          >
            {nameChar}
          </Avatar>
        </div>
        <>
          {' '}
          <Typography
            sx={{
              position: 'absolute',
              left: { xs: '130px', sm: '190px', md: '240px', lg: '260px' },
              top: { xs: '125px', sm: '130px', md: '145px', lg: '185px' },
              color: '#fff',
              fontSize: { xs: '18px', sm: '20px', md: '22px', lg: '22px' },
              fontFamily: 'Satoshi',
              fontWeight: '700'
            }}
          >
            {nameVal}
          </Typography>
          <Typography
            sx={{
              position: 'absolute',
              left: { xs: '130px', sm: '190px', md: '240px', lg: '260px' },
              top: { xs: '160px', sm: '170px', md: '190px', lg: '236px' },
              color: '#0C0C0C',
              fontSize: { xs: '15px', sm: '15px', md: '16px', lg: '16px' },
              fontFamily: 'Satoshi',
              fontWeight: 'normal'
            }}
          >
            {emailVal}
          </Typography>{' '}
          <Typography
            sx={{
              position: 'absolute',
              left: { xs: '130px', sm: '190px', md: '240px', lg: '260px' },
              top: { xs: '180px', sm: '190px', md: '215px', lg: '261px' },
              color: '#0C0C0C',
              fontSize: { xs: '15px', sm: '15px', md: '16px', lg: '16px' },
              fontFamily: 'Satoshi',
              fontWeight: 'normal'
            }}
          >
            {dept !== null ? null : null}
            {org}
          </Typography>
        </>
        {/* {isSmlMobile ? (
          <>
            <br />
          </>
        ) : (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )} */}
        {/* <Grid container spacing={0} alignItems="center" justifyContent="center" display="grid"> */}
        <Box className={classes.planInfoMainBox}>
          <Box className={classes.planNameBox}>
            <Typography className={classes.planNameTypo}>
              Current Plan: {planDetails?.plan_name}
            </Typography>
            <Typography className={classes.planNameTypo}>
              Valid Until: {formatedTime(planDetails?.expiry_date)}
            </Typography>
          </Box>
          <InfoBar infoMessage={t('profile.planOrgLevelMessage')} />
        </Box>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={11} sm={11} md={11} lg={10}>
            {isemailVerified === 'false' && (
              <Typography
                sx={{
                  transition: 'color 0.3s ease',
                  fontSize: { xs: '14px', sm: '16px' },
                  fontFamily: 'Satoshi',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: '#322965',
                  marginBottom: '10px'
                }}
              >
                Your email id is not verified,
                <span
                  style={{ color: '#C44545', fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={getAttributeVerificationCode}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = '#6933FF';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = '#B2292E';
                  }}
                >
                  &nbsp; Verify Email
                </span>
              </Typography>
            )}
            {emailVerifiedSuccess ? (
              <Box
                marginY={1}
                style={{
                  background: '#C7F1BF',
                  borderRadius: '5px',
                  opacity: 1,
                  height: '40px',
                  display: 'flex', // Set display to flex
                  alignItems: 'center' // Vertically center align the content
                }}
              >
                <CheckCircleOutlineIcon
                  style={{
                    marginLeft: '13px', // Set margin left to 13px
                    marginRight: '2px', // Set margin right to 1px
                    color: '#3C962A' // Set color of the icon to #3C962A
                  }}
                />
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#206113',
                    opacity: 1,
                    fontFamily: 'Satoshi',
                    fontWeight: 'normal'
                  }}
                >
                  {t('verify.emailSuccessText')}
                </Typography>
                <CloseIcon
                  style={{
                    marginLeft: 'auto', // Align close icon to the right
                    marginRight: '10px', // Add some right margin
                    cursor: 'pointer',
                    color: '#206113' // Set color of the close icon
                  }}
                  onClick={handleClose}
                />
              </Box>
            ) : null}
            <Accordion
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: '8px',
                border: '1px solid #E8E8E8'
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<SeeMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>Plan Details</div>
              </AccordionSummary>
              <AccordionDetails className={classes.accDetails}>
                <Grid container spacing={0} alignItems="center" className={classes.marginTable}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table className={classes.planTable}>
                      <TableHead>
                        <TableRow>
                          {userPlanHeaders.map((header, index) => (
                            <TableCell className={classes.fontStyle} key={index}>
                              <strong>{header}</strong>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planDetails?.limits &&
                          planDetails?.limits.map((limitDetails, index) => (
                            <TableRow key={index}>
                              <TableCell className={classes.fontStyle}>
                                {getDisplayTextFromMap(
                                  limitDetails,
                                  'type',
                                  limitsTypeToDisplayMap
                                )}
                              </TableCell>
                              <TableCell className={classes.fontStyle}>
                                {limitDetails?.total_limits}{' '}
                                {limitDetails?.type === 'INGESTION' && 'MB'}
                              </TableCell>
                              <TableCell className={classes.fontStyle}>
                                {limitDetails?.remaining_limits > 0 ? (
                                  <span
                                    style={{
                                      padding: '2px 8px',
                                      borderRadius: '4px',
                                      backgroundColor: '#D2EED5',
                                      color: '#373737'
                                    }}
                                  >
                                    {limitDetails?.remaining_limits}{' '}
                                    {limitDetails?.type === 'INGESTION' && 'MB'}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      backgroundColor: '#FCCED1',
                                      padding: '2px 8px',
                                      borderRadius: '4px',
                                      color: '#373737'
                                    }}
                                  >
                                    {limitDetails?.remaining_limits}{' '}
                                    {limitDetails?.type === 'INGESTION' && 'MB'}
                                  </span>
                                )}
                              </TableCell>
                              {/* <TableCell className={classes.fontStyle}>
                              {userPlan.status === 'WAITING_FOR_PAYMENT_CONFIRMATION' ? (
                                <span
                                  style={{
                                    backgroundColor: '#ADD8E6',
                                    padding: '0px 5px 0px 5px',
                                    borderRadius: '10px'
                                  }}
                                >
                                  Waiting for payment confirmation
                                </span>
                              ) : userPlan.status === 'ACTIVE' ? (
                                <span>Active</span>
                              ) : (
                                <span
                                  style={{
                                    backgroundColor: '#D3D3D3',
                                    padding: '0px 5px 0px 5px',
                                    borderRadius: '10px'
                                  }}
                                >
                                  Unknown Status
                                </span>
                              )}
                            </TableCell> */}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Grid>
                  {/* ))} */}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} style={{ display: 'none' }}>
            <br />
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={10} style={{ display: 'none' }}>
            <Accordion
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: '8px',
                border: '1px solid #E8E8E8'
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<SeeMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>Payment History</div>
              </AccordionSummary>
              <AccordionDetails className={classes.accDetails}>
                <Grid container spacing={0} alignItems="center" className={classes.marginTable}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {paymentHistory.length === 0 ? (
                      <Typography
                        sx={{
                          color: '#0C0C0C',
                          fontFamily: 'Satoshi',
                          fontWeight: 'normal',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        No payments found
                      </Typography>
                    ) : (
                      <Table className={classes.planTable}>
                        <TableHead>
                          <TableRow>
                            {transactionHistoryHeaders.map((header, index) => (
                              <TableCell className={classes.fontStyle} key={index}>
                                <strong>{header}</strong>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paymentHistory.map((transaction, index) => (
                            <TableRow key={index}>
                              <TableCell className={classes.fontStyle}>
                                {formatedTime(transaction.payment_date, true)}
                              </TableCell>
                              <TableCell className={classes.fontStyle}>
                                {transaction.currency} {transaction.amount}
                              </TableCell>
                              <TableCell className={classes.fontStyle}>
                                {transaction.payment_mode}
                              </TableCell>
                              <TableCell className={classes.fontStyle}>
                                <div
                                  className={classes.highlightWidth}
                                  style={{
                                    padding: '2px 8px 2px 8px',
                                    borderRadius: '10px',
                                    backgroundColor:
                                      transaction.status === 'PAYMENT_SUCCESS'
                                        ? '#C7F1BF'
                                        : transaction.status === 'PAYMENT_FAILED'
                                        ? '#F05A5D'
                                        : transaction.status === 'PAYMENT_INITIATED'
                                        ? '#F0AD4E'
                                        : transaction.status === 'PAYMENT_CANCELLED'
                                        ? '#5BC0DE'
                                        : transaction.status === 'PAYMENT_TIMEOUT'
                                        ? '#FFA07A'
                                        : '#D3D3D3', // Default unknown status
                                    color:
                                      transaction.status === 'PAYMENT_SUCCESS'
                                        ? '#3C962A'
                                        : transaction.status === 'PAYMENT_FAILED'
                                        ? '#FFFFFF'
                                        : transaction.status === 'PAYMENT_INITIATED'
                                        ? '#FFFFFF'
                                        : transaction.status === 'PAYMENT_CANCELLED'
                                        ? '#FFFFFF'
                                        : transaction.status === 'PAYMENT_TIMEOUT'
                                        ? '#FFFFFF'
                                        : '#FFFFFF' // Default unknown status
                                  }}
                                >
                                  {transaction.status === 'PAYMENT_SUCCESS'
                                    ? 'Payment success'
                                    : transaction.status === 'PAYMENT_FAILED'
                                    ? 'Payment failed'
                                    : transaction.status === 'PAYMENT_INITIATED'
                                    ? 'Payment initiated'
                                    : transaction.status === 'PAYMENT_CANCELLED'
                                    ? 'Payment cancelled'
                                    : transaction.status === 'PAYMENT_TIMEOUT'
                                    ? 'Payment timeout'
                                    : 'Unknown Status'}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={10}>
            <Accordion
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: '8px',
                border: '1px solid #E8E8E8'
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<SeeMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>Usage History</div>
              </AccordionSummary>
              <AccordionDetails className={classes.accDetails}>
                <Grid container spacing={0} alignItems="center" className={classes.marginTable}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {paymentHistory.length === 0 ? (
                      <Typography
                        sx={{
                          color: '#0C0C0C',
                          fontFamily: 'Satoshi',
                          fontWeight: 'normal',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        No history yet
                      </Typography>
                    ) : (
                      <Table className={classes.planTable}>
                        <TableHead>
                          <TableRow>
                            {usageHistoryHeaders.map((header, index) => (
                              <TableCell className={classes.fontStyle} key={index}>
                                <strong>{header}</strong>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userPlanUsage.map((usage, index) => (
                            <TableRow key={index}>
                              <TableCell className={classes.fontStyle}>
                                {formatedTime(usage.hit_date)}
                              </TableCell>
                              <TableCell className={classes.fontStyle}>
                                {usage.questions_hit}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={10}>
            <Accordion
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: '8px',
                border: '1px solid #E8E8E8'
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<SeeMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>Setting</div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={12} sm={12} md={6.5} lg={6.5}>
                    <ChangePassword />
                  </Grid>
                  <Grid item xs={0} sm={0} md={1} lg={1} />
                  <Grid item xs={12} sm={12} md={4.5} lg={4.5}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Button
                        variant="outlined"
                        onClick={handleDeleteOpen}
                        style={{
                          margin: '10px',
                          backgroundColor: '#FDF6F6',
                          color: '#FE4D4D',
                          border: '1px solid #FE4D4D'
                        }}
                      >
                        <DeleteForeverIcon style={{ color: '#FE4D4D' }} />
                        {t('profile.deleteAccount')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <br />
        <Dialog
          open={openDelete}
          BackdropProps={{
            classes: {
              root: classes.backDrop
            }
          }}
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth="sm"
        >
          <Box p={0} width="100%">
            <DialogContent>
              <>
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                  display="grid"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      // variant="h6"
                      sx={{
                        color: '#08070D',
                        fontSize: '16px',
                        textAlign: 'center'
                      }}
                    >
                      {t('profile.areYouSure')}
                    </Typography>
                    <br />
                    <Box
                      style={{
                        background: '#FDF6F6',
                        borderRadius: '5px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <ErrorOutlineIcon
                        style={{
                          marginLeft: '13px', // Set margin left to 13px
                          marginRight: '5px', // Set margin right to 1px
                          color: '#EB5B55' // Set color of the icon to #3C962A
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: '14px',
                          color: '#EB5B55',
                          marginRight: '15px',
                          fontFamily: 'Satoshi',
                          fontWeight: 'normal'
                        }}
                      >
                        {t('profile.deleteWarning')}
                      </Typography>
                    </Box>
                    <br />
                    <br />

                    <Button
                      variant="outlined"
                      onClick={deleteAccount}
                      style={{
                        backgroundColor: '#FDF6F6',
                        border: '1px solid #FE4D4D',
                        color: '#FE4D4D',
                        width: '100%',
                        fontFamily: 'Satoshi',
                        fontWeight: 'normal'
                      }}
                      className={classes.button}
                    >
                      <DeleteForeverIcon style={{ color: '#FE4D4D' }} />
                      {t('profile.deleteAccount')}
                    </Button>
                    <br />
                    <br />
                    <Button
                      style={{
                        width: '100%',
                        color: '#0C0C0C',
                        textTransform: 'none'
                      }}
                      className={classes.button}
                      onClick={handleDeleteClose}
                    >
                      <Typography
                        style={{
                          fontSize: '16px',
                          color: '#000000',
                          textTransform: 'none',
                          fontFamily: 'Satoshi',
                          fontWeight: 'normal'
                        }}
                      >
                        {t('newsFeed.cancelButton')}
                      </Typography>
                    </Button>
                    <br />
                    <br />
                    <>
                      {deleteFail ? (
                        <>
                          <Box
                            m={1}
                            style={{
                              background: '#FDF6F6',
                              borderRadius: '5px',
                              height: '40px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{
                                marginLeft: '13px', // Set margin left to 13px
                                marginRight: '5px', // Set margin right to 1px
                                color: '#EB5B55' // Set color of the icon to #3C962A
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: '14px',
                                color: '#EB5B55',
                                marginRight: '15px'
                              }}
                            >
                              {t('profile.deleteFailInfo')}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <div></div>
                      )}
                    </>
                  </Grid>
                </Grid>
              </>
            </DialogContent>
          </Box>
        </Dialog>
      </Grid>
    </>
  );
}
export default UserProfile;
