import { Typography, makeStyles } from '@material-ui/core';
import { Paper } from '@mui/material';
import CustomTextField from '../../../atoms/textfield';
import TemplateSectionCard from './templateSectionCard';
import CustomButton from '../../../atoms/buttons/button';
// import { Add, DeleteForever, Save } from '@material-ui/icons';
// import { useTranslation } from 'react-i18next';
// import { SaveAs } from '@mui/icons-material';
// import ErrorToast from '../../../atoms/errorToast';
import SecondaryButton from '../../../atoms/buttons/secondaryBtn';
import DeleteButton from '../../../atoms/buttons/deleteBtn';
// import InputError from '../../../atoms/inputError';
// import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import { ReactComponent as DuplicateIcon } from '../../../../components/icons/duplicate.svg';
import { postApi } from '../../../../services/token';
import {
  updateParamsInURL,
  removeObjRef,
  hideShowMessage,
  getFirstProperty
} from '../../../../helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import TemplateDialog from './templateDialog';
import Heading from './heading';
import ErrorSpan from '../../../atoms/errorSpan';
import { validateReportData } from '../../../../utility/report_validation';

const useStyles = makeStyles((theme) => ({
  tearSheet: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '16px !important',
    padding: '16px'
  },
  formLevelOne: {
    display: 'flex',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  keyDiv: {
    width: '20%',
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  valueDiv: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  keyTypography: {
    fontSize: '16px',
    fontWeight: '550',
    overflowWrap: 'break-word'
  },
  selectedTypography: {
    fontWeight: '400',
    fontSize: '16px',
    margin: '0px 10px 0px 0px'
  },
  selectedCompaniesDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  divider: {
    backgroundColor: '#b1c8ff',
    margin: '15px 0px'
  },
  sectionsDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  sectionCardDiv: {
    width: '86%',
    margin: '8px 0 8px 14%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  addSectionBtn: {
    margin: '8px 14%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0'
    }
  },
  mainActionBtns: {
    display: 'flex',
    justifyContent: 'end'
  },
  discardBtn: {
    margin: '0px 8px'
  },
  inputErrorDisplacer: {
    marginTop: '-33px'
  },
  textClickable: {
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none',
    margin: '0px 4px'
  },
  duplicateBtn: {
    padding: '13px 12px 13px 20px',
    minWidth: '0px',
    marginLeft: '10px'
  },
  subDiv: {
    'border-radius': '16px !important',
    'box-shadow':
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
  },
  firstDiv: {
    padding: '16px 100px'
  },
  bgColor: {
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%)) !important',
    '& p:first-child': {
      color: 'var(--Neutral-Colors-Color-800, var(--grey7, #171717))',
      fontFamily: 'Satoshi',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700
    }
  }
}));

const intializeReportData = {
  report_name: '',
  entity_list: [
    {
      entity_type: '',
      entity_id: ''
    }
  ],
  question_groups: [
    {
      name: '',
      questions: [
        {
          question: '',
          type: 'query',
          question_type: ''
        }
      ]
    }
  ]
};

const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;

// const MAX_AGE = 60 * 60 * 24 * 1000;
// const MAX_QUESTIONS = process.env.REACT_APP_TEAR_SHEET_QUESTION_LIMIT;
const TearSheetTemplate = ({ searchParams, loadingData }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setLoading, setSuccessMessage, setErrorMessage } = loadingData;

  const [obj, setObj] = useState(removeObjRef(intializeReportData));
  const [errorObject, setErrorObject] = useState({});

  const entity_type = searchParams.get('entity_type') || 'company';
  const entity_id = searchParams.get('entity_id');
  const themeComp = searchParams.get('search');

  const isTheme = entity_type === 'theme';
  // const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (entity_type && entity_id) {
      setObj((prev) => ({
        ...prev,
        entity_list: [
          {
            entity_type,
            entity_id
          }
        ]
      }));
    }
  }, [entity_type, entity_id]);

  const handleChange = (key, value) => {
    setObj((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const addSection = () => {
    const currentObj = { ...obj };
    const questionGroups = currentObj.question_groups;
    questionGroups.push({
      name: '',
      questions: [
        {
          question: '',
          type: 'query',
          question_type: ''
        }
      ]
    });
    setObj(currentObj);
  };

  const createReport = async () => {
    if (validateReportData(obj, setErrorObject)) {
      try {
        setLoading(true);
        const { data } = await postApi(`${COMPANY_API_URL}/v2/report`, obj);
        setLoading(false);
        if (data?.data?.success) {
          setObj(removeObjRef(intializeReportData));
          const addParamObj =
            data?.data?.report_id && data?.data?.report_id.length > 0 && data?.data?.report_id[0]
              ? { success: 'create', report_id: data?.data?.report_id[0] }
              : { success: 'create' };
          updateParamsInURL({
            navigate,
            location,
            addParams: addParamObj,
            removeParams: ['action', 'entity_id']
          });
        } else {
          hideShowMessage(
            getFirstProperty(data?.data?.errors) ||
              'The report creation failed. Please check the data input and try again',
            setErrorMessage
          );
        }
        // hideShowMessage('Your report has been successfully generated', setSuccessMessage);
      } catch (error) {
        hideShowMessage(
          'The report creation failed. Please check the data input and try again',
          setErrorMessage
        );
        setLoading(false);
      }
    }
  };

  const cancel = () => {
    updateParamsInURL({
      navigate,
      location,
      removeParams: ['action', 'entity_id']
    });
  };

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const openPopup = () => {
    if (validateReportData(obj, setErrorObject)) setOpenTemplateDialog(true);
  };

  const onSaveTemplate = async (templateName, templateDesc, resetTemplateForm) => {
    if (templateName && templateDesc) {
      const dataToSend = {
        template_name: templateName,
        entity_type: entity_type,
        entity_id,
        description: templateDesc,
        template_type: 'user_defined', // predefined or userdefined
        question_groups: obj.question_groups
      };
      try {
        setLoading(true);
        const { data } = await postApi(`${COMPANY_API_URL}/v2/template`, dataToSend);
        setLoading(false);
        if (data?.data?.success) {
          resetTemplateForm();
          setOpenTemplateDialog(false);
          hideShowMessage('Your template has been successfully generated', setSuccessMessage);
        } else {
          hideShowMessage(
            getFirstProperty(data?.data?.errors) ||
              'The template creation failed. Please check the data input and try again',
            setErrorMessage
          );
        }
      } catch (error) {
        hideShowMessage(
          'The template creation failed. Please check the data input and try again',
          setErrorMessage
        );
        setLoading(false);
      }
    }
  };

  return (
    <>
      <TemplateDialog
        title="Save as template"
        open={openTemplateDialog}
        save={onSaveTemplate}
        handleClose={() => {
          setOpenTemplateDialog(false);
        }}
      />
      <div className={classes.firstDiv}>
        <Heading tempHeading={`Custom Reports - Create New`} />
        <div className={classes.subDiv}>
          <Paper className={classes.tearSheet}>
            <div>
              <div style={{ paddingBottom: '20px' }} className={classes.formLevelOne}>
                <div className={classes.keyDiv}>
                  <Typography className={classes.keyTypography}>Report Name</Typography>
                </div>
                <div className={classes.valueDiv}>
                  <CustomTextField
                    value={obj.report_name}
                    handleChange={(value) => {
                      handleChange('report_name', value);
                    }}
                    placeholder="Report Name"
                  />
                  <ErrorSpan value={errorObject?.report_name} />
                </div>
              </div>
              <div className={classes.formLevelOne}>
                <div className={classes.keyDiv}>
                  <Typography className={classes.keyTypography}>
                    {isTheme ? 'Theme' : 'Company'}
                  </Typography>
                </div>
                <div className={classes.valueDiv}>
                  <CustomTextField value={themeComp} disabled={true} />
                </div>
              </div>
              <div className={classes.formLevelOne}>
                <div className={classes.keyDiv}>
                  <Typography className={classes.keyTypography}>Sections</Typography>
                </div>
              </div>
              <div className={classes.sectionsDiv}>
                <div className={classes.sectionCardDiv}>
                  <TemplateSectionCard
                    obj={obj}
                    handleChange={handleChange}
                    errorObject={errorObject}
                  />
                </div>
              </div>
              <div className={classes.addSectionBtn}>
                <SecondaryButton btnText="Add Section" handleClick={addSection} />
              </div>
              <div className={classes.mainActionBtns}>
                <div className={classes.saveBtn}>
                  <CustomButton
                    btnText="Save as template"
                    handleClick={openPopup}
                    styleClass={classes.bgColor}
                  />
                </div>
                <div className={classes.discardBtn}>
                  <DeleteButton btnText="Discard Changes" handleClick={cancel} />
                </div>
                <div className={classes.saveBtn}>
                  <CustomButton btnText="Generate Report" handleClick={createReport} />
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default TearSheetTemplate;
