import { TextField, makeStyles } from '@material-ui/core';
import InputError from './inputError';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #004CFF !important',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '3px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '1px !important',
      color: 'rgba(0, 0, 0, 0.87) !important'
    },
    '& .MuiInputLabel-formControl': {
      top: '-10px',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiInputLabel-shrink': {
      top: '0'
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px'
    }
  },
  errorStyle: {
    '& fieldset': {
      border: '1px solid #EB5B55 !important',
      opacity: '1 !important'
    },
    '& .MuiInputLabel-formControl': {
      color: '#eb5b55'
    }
  }
}));

const CustomTextField = ({
  value = null,
  handleChange = () => {},
  placeholder = null,
  label = null,
  params = {},
  errorMessage = '',
  disabled = null
}) => {
  const classes = useStyles();
  return (
    <TextField
      {...params}
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={(event) => handleChange(event.target.value)}
      className={`${classes.root} ${errorMessage && classes.errorStyle}`}
      helperText={errorMessage && <InputError errorMessage={errorMessage} />}
      disabled={disabled}
    ></TextField>
  );
};

export default CustomTextField;
