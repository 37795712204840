import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  listItem: {
    '& li': {
      padding: '0!important',
      '& span': {
        color: 'var(--Neutral-Colors-Color-800, var(--grey7, #171717))',
        fontFeatureSettings: "'clig' 0, 'liga' 0",
        fontFamily: 'Satoshi',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px'
      }
    }
  }
}));

const customList = ({ items, isNumberedList = true, isContainHtml = false }) => {
  const classes = useStyles();

  const indexToAlphabet = (index) => {
    return String.fromCharCode(97 + index);
  };

  return (
    <List component="ol" className={classes.listItem}>
      {items.map((text, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={
              text &&
              text.split('\n').map((item, key) => (
                <React.Fragment key={key}>
                  {key === 0 ? `${isNumberedList ? index + 1 : indexToAlphabet(index)}.  ` : null}
                  {isContainHtml ? (
                    <span dangerouslySetInnerHTML={{ __html: item && item.trim() }} />
                  ) : (
                    item && item.trim()
                  )}
                  <br />
                </React.Fragment>
              ))
            }
          />
        </ListItem>
      ))}
    </List>
  );
};
export default customList;
