import React, { useEffect, useState, useContext } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import './pricing.css';
import { AuthContext } from '../auth/contexts/authContext';
import PaymentSuccess from '../shared/validationPopover';
import { useTranslation } from 'react-i18next';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { ReactComponent as UnselectedRadioBtn } from '../../components/icons/UnselectedRadioBtn.svg';
import { ReactComponent as SelectedRadioBtn } from '../../components/icons/SelectedRadioBtn.svg';
import { getApi, postApi } from '../../services/token';
import { ReactComponent as PricingTickIcon } from '../../components/icons/PricingTickIcon.svg';
import { ReactComponent as RightArrowBtn } from '../../components/icons/RightArrowBtn.svg';
import { Skeleton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PhoneInput from 'react-phone-input-2';
import LoadingPopover from '../shared/loadingPopover/loadingPopover';

const url = process.env.REACT_APP_PAYMENT_API_URL;
const contactUrl = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  blogBtn: {
    position: 'relative',
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  },
  blogBtnDisabled: {
    position: 'relative',
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: '#ccc',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  },
  blogBtnTxt: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#08070D',
    whiteSpace: 'nowrap'
  },
  blogHeading: {
    fontSize: '34px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      fontWeight: 'bold'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px'
    }
  },
  blogPara: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  price: {
    fontSize: '24px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  planPriceStriked: {
    textDecoration: 'line-through',
    fontSize: '15px',
    fontWeight: 400,
    color: '#171717',
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  planDiscountPrice: {
    fontSize: '15px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '18px'
    }
  },
  blogDescription: {
    fontSize: '24px',
    fontWeight: 400,
    color: '#171717',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      fontWeight: 'bold'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px'
    }
  },
  planFeatures: {
    fontWeight: '500',
    fontSize: '16px',
    paddingTop: '10px'
  },
  planDetials: {
    fontWeight: '500',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center'
  },
  planPriceContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  pricingHeading: {
    fontSize: '44px',
    fontWeight: 500,
    color: '#171717',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px'
    }
  },
  isIncludedPlan: {
    fontWeight: '600',
    fontSize: '16px',
    paddingTop: '10px'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  cardContentSkeleton: {
    height: '450px', // Adjust the height as needed
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  cardContentCustom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    alignSelf: 'flex-end'
  },
  cost: {
    fontSize: '30px',
    fontWeight: 700,
    color: '#4D4D4D'
  },
  forwho: {
    fontSize: '20px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center'
  },
  subrcribe: {
    fontSize: '22px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center'
  },
  costAndCredit: {
    fontSize: '15px',
    paddingTop: '10px'
  },
  premiumData: {
    fontSize: '15px',
    paddingTop: '10px'
  },
  otherFeatures: {
    fontSize: '15px'
  },
  screening: {
    paddingTop: '10px',
    fontSize: '15px'
  },
  // blurred: {
  //   filter: 'blur(4px)' // Adjust the blur amount as needed
  // }
  overlayText: {
    fontSize: '14px',
    fontWeight: 'bold',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: '#C7F1BF',
    borderRadius: '5px',
    color: '#3C962A',
    padding: '10px'
  },
  submitSales: {
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingTop: '72px',
    paddingBottom: '72px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  }
}));

function Pricing() {
  const [token, setToken] = useState(null);
  let auth = useContext(AuthContext);
  const navigate = useNavigate();

  const freePlan = 'Free';
  const basicPlan = 'Starter';
  const basicPlan2 = 'Starter 1';

  const { t } = useTranslation();

  if (!process.env.REACT_APP_ENABLE_PRICING) {
    useEffect(() => {
      navigate('/home');
    }, []);

    return <></>;
  }

  const classes = useStyles();

  const redirectToPaymentPage = async (obj) => {
    setLoading(true);
    // if (!(obj.cost === 'Basic' || obj.cost === 'Basic Plus')) return;
    if (!(obj.plan_name === basicPlan)) return;
    // // pass token to form redirect method
    // redirectToPayment(paymentTokenDetail?.data?.response);
    const createPlanApiUrl = `${url}/user/plan`;
    // const body = { plan_id: obj.id };
    const body = { plan_id: billingCycle };
    // const { data } = await postApi(createPlanApiUrl, body);
    // if (data && data.data && data.data.transaction_url) {
    //   window.location.href = data.data.transaction_url;
    // }

    try {
      const { data } = await postApi(createPlanApiUrl, body);
      if (data && data.data && data.data.transaction_url) {
        setLoading(false);
        window.location.href = data.data.transaction_url;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const redirectToSignUp = (obj) => {
    window.scroll(0, 0);
    navigate('/signup', { state: obj });
  };

  const handleChoosePlan = (obj) => {
    if (process.env.REACT_APP_ENABLE_CC_AVENUE_PAYMENT === 'true')
      token ? redirectToPaymentPage(obj) : redirectToSignUp(obj);
  };

  useEffect(() => {
    async function sessionInfo() {
      const session = await auth.getSession();
      const tokenVal = session?.idToken?.jwtToken;
      setToken(tokenVal);
    }
    sessionInfo();
  }, [auth]);

  const [billingCycle, setBillingCycle] = useState(null);
  const [planCardData, setPlanCardData] = useState([]);
  const [contactSalesPopover, setContactSalesPopover] = useState(false);
  const [error, setError] = useState('');
  const [successContactSales, setSuccessContactSales] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingContactSales, setLoadingContactSales] = useState(false);
  const intializeContactSalesInfo = {
    name: '',
    email: '',
    message: '',
    phone_number: '',
    type: 'sales'
  };
  const [contactSalesInfo, setContactSalesInfo] = useState({ ...intializeContactSalesInfo });

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const isValid = () => {
    if (!contactSalesInfo.name) {
      setError(t('validation.Name'));
      return true;
    } else if (!contactSalesInfo.email) {
      setError(t('validation.Email'));
      return true;
    } else if (!validateEmail(contactSalesInfo.email)) {
      setError(t('validation.InvalidEmail'));
      return true;
    } else if (contactSalesInfo.phone_number === '') {
      setError(t('validation.phoneNumberErrMessage'));
      return true;
    }
    return false;
  };

  const saveContactSales = async () => {
    setError('');
    if (isValid()) return;
    setLoadingContactSales(true);
    try {
      const contactSales = `${contactUrl}/contactus`;
      await postApi(contactSales, contactSalesInfo);
      setSuccessContactSales(true);
    } catch {
      setError(t('pricing.contactSalesApiFail'));
    }
    setLoadingContactSales(false);
  };
  const handleCloseContactSalesPopover = () => {
    setSuccessContactSales(false);
    setContactSalesInfo(JSON.parse(JSON.stringify(intializeContactSalesInfo)));
    setContactSalesPopover(false);
    setError('');
  };
  const getPaymentData = async () => {
    try {
      let activeData = null;
      const getPaymentDataUrl = `${process.env.REACT_APP_PAYMENT_API_URL}/plan`;
      const { data } = await getApi(getPaymentDataUrl);
      if (token) {
        const getCurrentUserPlanUrl = `${process.env.REACT_APP_PAYMENT_API_URL}/user/plan`;
        const { data } = await getApi(getCurrentUserPlanUrl);
        activeData = data.data.find((item) => item.is_active === true);
      }
      const planData = data.data.items;
      const planCard = [];
      let basicPlanData = {
        plan_name: basicPlan,
        plan_description: '',
        plan_features: [],
        plan_cost: []
      }; // Initialize basicPlanData

      planData.forEach((plan) => {
        if (plan.plan_name === freePlan) {
          planCard.push(plan);
        } else if (plan.plan_name === basicPlan || plan.plan_name === basicPlan2) {
          basicPlanData.plan_cost.push({
            id: plan.id,
            plan_price: plan.plan_price,
            plan_currency_type: plan.plan_currency_type,
            plan_discount_price: plan.plan_discount_price,
            question_limit: plan.question_limit
          });
          if (plan.plan_name === basicPlan2) {
            basicPlanData.plan_description = plan.plan_description;
            basicPlanData.plan_features = plan.plan_features;
          }
          if (plan.plan_name === basicPlan) {
            setBillingCycle(plan.id);
          }
        }
      });
      planCard.push(basicPlanData);

      // Add the 'Pro' to planCard
      planCard.push({
        id: 'randomid',
        plan_name: 'Pro',
        plan_description: 'Al Copilot for Investors',
        plan_features: [
          'GenAI interface to ask questions on private companies',
          'Strategy and more qualitative questions answered through Gen AI interface',
          'Factual questions for companies'
        ],
        plan_price: 0.0,
        plan_currency_type: 'INR',
        plan_discount_price: 0.0
      });

      if (token) {
        for (let i = 0; i < planCard.length; i++) {
          if (planCard[i].plan_name === freePlan && planCard[i].id === activeData.plan_id) {
            planCard[i].currentActivePlan = true;
          }
          if (planCard[i].plan_name === basicPlan) {
            if (
              planCard[i].plan_cost[0].id === activeData.plan_id ||
              planCard[i].plan_cost[1].id === activeData.plan_id
            ) {
              planCard[i].currentActivePlan = true;
            }
          }
        }
        setPlanCardData(planCard);
      } else {
        setPlanCardData(planCard);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPaymentData();
  }, [token]);

  return (
    <>
      <LoadingPopover open={loading} />
      <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <Grid className="gird-pricing-style">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography>
              <span
                className={classes.pricingHeading}
                style={{
                  backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Pricing and Plans
              </span>
            </Typography>
          </div>
          <br />
          <br />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={8}>
              <div className={classes.aboutusLineSpacing}>
                <div className="pricing-line-break"></div>
              </div>
            </Grid>
          </div>
          <br />
          <br />
          <PaymentSuccess
            condition={[
              t('payment.failedStatus'),
              t('payment.cancelStatus'),
              t('payment.timeoutStatus')
            ]}
            message={t('pricing.failedStatus')}
            isFailed={true}
          />
          <Grid container>
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
              {planCardData.length === 0 &&
                Array.from({ length: 3 }).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      key={index}
                      elevation={0}
                      style={{ backgroundColor: '#EFF3FC', borderRadius: '20px' }}
                    >
                      <CardContent className={classes.cardContentSkeleton}>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              justifyItems: 'center',
                              padding: '10px'
                            }}
                          >
                            <Skeleton variant="rounded" animation="wave" width={210} height={30} />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              justifyItems: 'center',
                              padding: '10px'
                            }}
                          >
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              style={{ margin: 10 }}
                              width="100%"
                              height={10}
                            />
                          </div>
                          <br />
                          <div className="pricing-line-break-1"></div>
                          <br />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {Array.from({ length: 3 }).map((_, index) => (
                              <>
                                <Typography
                                  key={index}
                                  className={classes.plan_features}
                                  style={{
                                    paddingTop: '10px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <PricingTickIcon style={{ paddingRight: '10px' }} />
                                  <Skeleton
                                    variant="rounded"
                                    animation="wave"
                                    style={{ margin: 10 }}
                                    width="50%"
                                    height={10}
                                  />
                                </Typography>
                              </>
                            ))}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              justifyItems: 'center',
                              padding: '10px'
                            }}
                          >
                            <Skeleton
                              variant="square"
                              animation="wave"
                              style={{ margin: 10 }}
                              width="50%"
                              height={30}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              {planCardData.length > 0 &&
                planCardData.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      key={index}
                      elevation={card.currentActivePlan ? 4 : 0}
                      style={{
                        backgroundColor: '#EFF3FC',
                        borderRadius: '20px',
                        position: 'relative'
                      }}
                    >
                      {card.currentActivePlan && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            transform: 'rotate(0deg)'
                          }}
                        >
                          <div
                            style={{
                              background: 'linear-gradient(to right, #6441FF 0%, #8800CC 100%)',
                              padding: '5px',
                              borderRadius: '0px 0px 0px 4px',
                              color: '#FFFFFF',
                              fontFamily: 'Satoshi'
                            }}
                          >
                            Current Plan
                          </div>
                        </div>
                      )}
                      {card.plan_name === freePlan ? (
                        <CardContent style={{ minHeight: '450px' }} className={classes.cardContent}>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '10px'
                              }}
                            >
                              <Typography className={classes.cost}>{card.plan_name}</Typography>
                            </div>
                            <Typography className={classes.forwho}>
                              {card.plan_description}
                            </Typography>
                            <br />
                            <div className="pricing-line-break-1"></div>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {card.plan_features.map((plan_features, index) => (
                                <>
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        paddingTop: '2px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <PricingTickIcon />
                                    </div>
                                    <pre> </pre>
                                    <Typography
                                      key={index}
                                      className={classes.plan_features}
                                      style={{
                                        paddingTop: '10px'
                                      }}
                                    >
                                      {plan_features}
                                    </Typography>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                          <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {token ? (
                                <Button disabled={true} className={classes.blogBtnDisabled}>
                                  <Typography
                                    className={classes.button}
                                    onClick={() => {
                                      console.log(card);
                                      handleChoosePlan(card);
                                    }}
                                    style={{ color: '#FFFFFF' }}
                                  >
                                    Choose this plan
                                  </Typography>
                                  <RightArrowBtn style={{ paddingLeft: '10px' }} />
                                </Button>
                              ) : (
                                <Button className={classes.blogBtn}>
                                  <Typography
                                    onClick={() => {
                                      console.log(card);
                                      handleChoosePlan(card);
                                    }}
                                    style={{ color: '#FFFFFF' }}
                                  >
                                    Choose this plan
                                  </Typography>
                                  <RightArrowBtn style={{ paddingLeft: '10px' }} />
                                </Button>
                              )}
                            </div>
                          </div>
                        </CardContent>
                      ) : null}
                      {card.plan_name === basicPlan ? (
                        <CardContent style={{ minHeight: '450px' }} className={classes.cardContent}>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '10px'
                              }}
                            >
                              <Typography className={classes.cost}>{card.plan_name}</Typography>
                            </div>
                            <Typography className={classes.forwho}>
                              {card.plan_description}
                            </Typography>
                            <br />
                            <div className="pricing-line-break-1"></div>
                            <br />
                            <>
                              <Grid container>
                                <Grid
                                  xs={5.5}
                                  style={{
                                    border: '1px solid #06BCC1',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    padding: '5px', //padding is added to just hide price
                                    backgroundColor:
                                      // billingCycle === card.plan_cost[0].id ? '#E9F0FD' : '#FFFFFF'
                                      billingCycle === card.plan_cost[0]?.id ? '#B9CFF9' : '#E9F0FD'
                                  }}
                                  onClick={() => {
                                    setBillingCycle(card.plan_cost[0].id);
                                  }}
                                >
                                  <div style={{ display: 'flex', padding: '5px 0px 0px 0px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {billingCycle === card.plan_cost[0]?.id ? (
                                        <SelectedRadioBtn
                                          style={{ marginLeft: '10px', paddingRight: '10px' }}
                                        />
                                      ) : (
                                        <UnselectedRadioBtn
                                          style={{ marginLeft: '10px', paddingRight: '10px' }}
                                        />
                                      )}
                                    </div>
                                    {/* padding below is just for hiding th price */}
                                    <div>
                                      <Typography className={`${classes.planPriceContainer}`}>
                                        {card.plan_cost[0]?.question_limit} Credits
                                      </Typography>
                                      {/* below div is just to hide the data */}
                                      <div style={{ display: 'none' }}>
                                        <Typography
                                          className={`${classes.planPriceContainer} ${classes.planPriceStriked}`}
                                        >
                                          <span
                                            style={{
                                              background: '#DF9595',
                                              padding: '0px 3px 0px 0px',
                                              borderRadius: '10px',
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <CurrencyRupeeIcon style={{ fontSize: '17px' }} />
                                            {card.plan_cost[0]?.plan_price}
                                          </span>
                                        </Typography>
                                        <Typography
                                          className={`${classes.planDiscountPrice} ${classes.planPriceContainer}`}
                                        >
                                          <CurrencyRupeeIcon style={{ fontSize: '17px' }} />
                                          {card.plan_cost[0]?.plan_discount_price}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid xs={1} />
                                <Grid
                                  xs={5.5}
                                  style={{
                                    border: '1px solid #06BCC1',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    padding: '5px', //the padding is added just to hide the price
                                    backgroundColor:
                                      // billingCycle === card.plan_cost[1].id ? '#E9F0FD' : '#FFFFFF'
                                      billingCycle === card.plan_cost[1]?.id ? '#B9CFF9' : '#E9F0FD'
                                  }}
                                  onClick={() => {
                                    setBillingCycle(card.plan_cost[1]?.id);
                                  }}
                                >
                                  <div style={{ display: 'flex', padding: '5px 0px 0px 0px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {billingCycle === card.plan_cost[1]?.id ? (
                                        <SelectedRadioBtn
                                          style={{ marginLeft: '10px', paddingRight: '10px' }}
                                        />
                                      ) : (
                                        <UnselectedRadioBtn
                                          style={{ marginLeft: '10px', paddingRight: '10px' }}
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <Typography className={`${classes.planPriceContainer}`}>
                                        {card.plan_cost[1]?.question_limit} Credits
                                      </Typography>
                                      {/* below div is just to hide the data */}
                                      <div style={{ display: 'none' }}>
                                        <Typography
                                          className={`${classes.planPriceContainer} ${classes.planPriceStriked}`}
                                        >
                                          <span
                                            style={{
                                              background: '#DF9595',
                                              padding: '0px 3px 0px 0px',
                                              borderRadius: '10px',
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <CurrencyRupeeIcon style={{ fontSize: '17px' }} />
                                            {card.plan_cost[1]?.plan_price}
                                          </span>
                                        </Typography>
                                        <Typography
                                          className={`${classes.planDiscountPrice} ${classes.planPriceContainer}`}
                                        >
                                          <CurrencyRupeeIcon style={{ fontSize: '17px' }} />
                                          {card.plan_cost[1]?.plan_discount_price}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {card.plan_features.map((plan_features, index) => (
                                <>
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        paddingTop: '2px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <PricingTickIcon />
                                    </div>
                                    <pre> </pre>
                                    <Typography
                                      key={index}
                                      className={classes.plan_features}
                                      style={{
                                        paddingTop: '10px'
                                      }}
                                    >
                                      {plan_features}
                                    </Typography>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                          <br />
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button className={classes.blogBtn}>
                                <Typography
                                  className={classes.button}
                                  onClick={() => {
                                    console.log(card);
                                    handleChoosePlan(card);
                                  }}
                                  style={{ color: '#FFFFFF' }}
                                >
                                  {card.currentActivePlan ? 'Buy additional' : 'Choose this plan'}
                                </Typography>
                                <RightArrowBtn style={{ paddingLeft: '10px' }} />
                              </Button>
                            </div>
                          </div>
                        </CardContent>
                      ) : null}
                      {card.plan_name === 'Pro' ? (
                        <CardContent style={{ minHeight: '450px' }} className={classes.cardContent}>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '10px'
                              }}
                            >
                              <Typography className={classes.cost}>{card.plan_name}</Typography>
                            </div>
                            <Typography className={classes.forwho}>
                              {card.plan_description}
                            </Typography>
                            <br />
                            <div className="pricing-line-break-1"></div>
                            <br />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                filter: 'blur(3px)'
                              }}
                            >
                              {card.plan_features.map((plan_features, index) => (
                                <>
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        paddingTop: '2px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <PricingTickIcon />
                                    </div>
                                    <pre> </pre>
                                    <Typography
                                      key={index}
                                      className={classes.plan_features}
                                      style={{
                                        paddingTop: '10px'
                                      }}
                                    >
                                      {plan_features}
                                    </Typography>
                                  </div>
                                </>
                              ))}
                            </div>
                            <br />
                            <div style={{ fontFamily: 'Satoshi' }} className={classes.overlayText}>
                              Coming Soon . . .
                            </div>
                          </div>
                          {/* <div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button className={classes.blogBtn}>
                                <Typography className={classes.button}>Coming Soon</Typography>
                              </Button>
                            </div>
                          </div> */}
                        </CardContent>
                      ) : null}
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} style={{ justifyContent: 'center', display: 'flex' }}>
            <Grid item sm={12}>
              <Card style={{ backgroundColor: '#EFF3FC', borderRadius: '20px' }} elevation={0}>
                <CardContent className={classes.cardContentCustom}>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px'
                      }}
                    >
                      <Typography className={classes.cost}>Custom</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={6}>
                      <div className={classes.aboutusLineSpacing}>
                        <div className="pricing-line-break-1"></div>
                      </div>
                    </Grid>
                  </div>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography className={classes.costAndCredit}>
                        Get in touch for a customized package that suits your needs.
                      </Typography>
                    </div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        className={classes.blogBtn}
                        onClick={() => {
                          setContactSalesPopover(true);
                        }}
                      >
                        <Typography className={classes.button} style={{ color: '#FFFFFF' }}>
                          Contact Sales
                        </Typography>
                        <RightArrowBtn style={{ paddingLeft: '10px' }} />
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br />
        </Grid>
        <Dialog
          open={contactSalesPopover}
          BackdropProps={{
            classes: {
              root: classes.backDrop
            }
          }}
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth="lg"
        >
          <Grid
            style={{
              background: '#FFFFFF'
            }}
            className={classes.submitSales}
          >
            <Grid container spacing={1}>
              <Grid item sm={12} style={{ height: '25px' }}>
                <div
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    fontSize: '20px',
                    margin: 0,
                    padding: 0,
                    cursor: 'pointer'
                  }}
                >
                  <CloseIcon
                    style={{ fontSize: '34px' }}
                    onClick={handleCloseContactSalesPopover}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontFamily: 'Satoshi',
                    fontSize: '26px'
                  }}
                >
                  <span
                    style={{
                      backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    Tell Us About Your Needs
                  </span>
                </Typography>
                <br />
                <Typography
                  style={{
                    fontWeight: 400,
                    color: '#171717',
                    fontFamily: 'Satoshi',
                    textAlign: 'left'
                  }}
                  className={classes.requestComanyTest2}
                >
                  Please fill out the form, and one of our dedicated sales representatives will get
                  in touch with you.
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                {successContactSales ? (
                  <>
                    <Box width="100%">
                      <Typography
                        style={{
                          fontSize: '26px',
                          fontFamily: 'Satoshi',
                          marginBottom: '10px'
                        }}
                      >
                        <span>{t('payment.successSalesRequestHeading')}</span>
                      </Typography>
                      <Typography style={{ fontSize: '1rem', fontFamily: 'Satoshi' }}>
                        {t('payment.successSalesRequest')}
                      </Typography>
                      <br />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box width="100%">
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={t('payment.Name')}
                        value={contactSalesInfo.name}
                        onChange={(e) => {
                          setContactSalesInfo({ ...contactSalesInfo, name: e.target.value });
                        }}
                        className={classes.root}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: '#08070d',
                            opacity: 0.8
                          }
                        }}
                      />
                    </Box>
                    <br />
                    <Box width="100%">
                      <TextField
                        fullWidth
                        className={classes.root}
                        variant="outlined"
                        label={t('payment.emailID')}
                        value={contactSalesInfo.email}
                        onChange={(e) => {
                          setContactSalesInfo((prev) => ({ ...prev, email: e.target.value }));
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: '#08070d',
                            opacity: 0.8
                          }
                        }}
                      />
                    </Box>
                    <br />
                    <Box width="100%">
                      <PhoneInput
                        inputStyle={{
                          height: '56px',
                          width: '100%'
                        }}
                        country={'in'}
                        onlyCountries={['in']}
                        countryCodeEditable={false}
                        value={contactSalesInfo.phone_number}
                        onChange={(value) =>
                          setContactSalesInfo((prev) => ({
                            ...prev,
                            phone_number: `+${value}`
                          }))
                        }
                      />
                    </Box>
                    <br />
                    <Box width="100%">
                      <TextField
                        type="text"
                        multiline
                        rows={5}
                        label={t('payment.Message')}
                        variant="outlined"
                        value={contactSalesInfo.message}
                        onChange={(e) => {
                          setContactSalesInfo({ ...contactSalesInfo, message: e.target.value });
                        }}
                        fullWidth
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: '#08070d',
                            opacity: 0.8
                          }
                        }}
                      />
                    </Box>
                    <br />
                    <Box width="100%">
                      <Button
                        disabled={loadingContactSales}
                        color="primary"
                        variant="contained"
                        className="tryButton"
                        onClick={saveContactSales}
                        style={{
                          background: loadingContactSales ? '#ccc' : ''
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: 400,
                            color: '#FFFFFF',
                            textTransform: 'none'
                          }}
                        >
                          {t('homepage.Submit')}
                        </Typography>
                      </Button>
                    </Box>
                    {loadingContactSales && (
                      <>
                        <br />
                        <LinearProgress />
                      </>
                    )}
                    {error && (
                      <>
                        <br />
                        <Box
                          style={{
                            background: '#FDF6F6',
                            borderRadius: '5px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <ErrorOutlineIcon
                            style={{
                              marginLeft: '13px', // Set margin left to 13px
                              marginRight: '5px', // Set margin right to 1px
                              color: '#EB5B55' // Set color of the icon to #3C962A
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#EB5B55',
                              marginRight: '15px'
                            }}
                          >
                            {error}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </div>
    </>
  );
}
export default Pricing;
