export const updateParamsInURL = ({
  navigate,
  location,
  addParams = {},
  removeParams = [],
  keepParams = [],
  isNew = false
}) => {
  const params = new URLSearchParams(isNew ? {} : location.search);

  if (removeParams.length > 0) {
    removeParams.forEach((param) => {
      params.delete(param);
    });
  } else if (keepParams.length > 0) {
    const allParams = [...params.keys()];
    allParams.forEach((param) => {
      if (!keepParams.includes(param)) {
        params.delete(param);
      }
    });
  }

  for (const [key, value] of Object.entries(addParams)) {
    params.set(key, value);
  }

  navigate(`?${params.toString()}`);
};

export const removeObjRef = (data) => {
  if (data === null || typeof data === 'undefined') {
    return null;
  }
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (error) {
    return null;
  }
};

export const hideShowMessage = (msg, fn, tout = 5000) => {
  fn(msg);
  setTimeout(() => {
    fn('');
  }, tout);
};

export const arraysMatchExactly = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const getFirstProperty = (obj) => {
  if (!obj || typeof obj !== 'object' || Object.keys(obj).length === 0) {
    return '';
  }

  var firstKey = Object.keys(obj)[0];
  var firstValue = obj[firstKey];
  return firstValue;
};
