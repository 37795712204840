import React, { useState, useEffect, useRef, useContext } from 'react';
import { Grid, Button, useMediaQuery, Tooltip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconButton, TextField } from '@material-ui/core';
import { ReactComponent as PostQuestion } from '../../icons/LogoMark.svg';
import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { LinearProgress } from '@mui/material';
import { postApi, streamApi, getApi } from '../../../services/token';
import { useSelector, useDispatch } from 'react-redux';
import UserComponent from './userComponent';
// import BotComponent from './botComponent';
import BotComponent from './AskByzBotComponent/botComponent';
import CreditsExpiredComponent from './creditsExpired';
import FollowUpComponent from './followUp';
import Disclaimer from './disclaimer';
import {
  setCompanyDetails,
  setCompanyName,
  setCompanyCin,
  setCompanyApiContext,
  setIsCompanyDataVisible,
  setLoading,
  setLoadingAnswerStream,
  setShowCompanyButton,
  setIsCompanyDataPopulated,
  setAllMessages,
  setChatScroll,
  setChatSearchInput,
  isShowCompanyPage,
  updatePreviousRoute,
  setAskBotWidth,
  setStopResponse,
  setRegenerateResponse,
  setQuestionTabs,
  setNews,
  setMcaData,
  setDashBoardQuestion
} from '../action';
import { v4 as uuidv4 } from 'uuid';
import AskBotCompany from './askBotCompany';
import { AuthContext } from '../../auth/contexts/authContext';
import './askBot.css';
import LoopIcon from '@mui/icons-material/Loop';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PaymentSuccess from '../validationPopover';
import CreditWarning from '../creditWarning';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import { ReactComponent as SeeLess } from '../../../components/icons/SeeLess.svg';
import { ReactComponent as CollapseIcon } from '../../../components/icons/CollapseIcon .svg';
import { ReactComponent as ExpandIcon } from '../../../components/icons/ExpandIcon.svg';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { currentTime } from '../utils/formatedTime';
import ErrorToast from '../../atoms/errorToast';
const pricingURL = process.env.REACT_APP_PAYMENT_API_URL;
const screenerEnabled = process.env.REACT_APP_SCREENER_ENABLE;

// const isJsonString = (str) => {
//   try {
//     JSON.parse(str);
//   } catch (e) {
//     return false;
//   }
//   return true;
// };

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    border: 'none'
  },

  questionText: {
    fontFamily: 'Satoshi',
    fontSize: '12px',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.0)',
      fontWeight: 'bold',
      color: '#000000'
    }
  },
  staticDataText: {
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontWeight: 600,
    color: '#000000B2',
    textAlign: 'center'
  },
  // root: {
  //   '&:hover': {
  //     backgroundColor: 'transparent'
  //   },
  //   '& .MuiInputBase-input': {
  //     height: '11px !important',
  //     backgroundColor: '#F0F0F0 !important',
  //     borderRadius: '12px 0 0 12px'
  //   },
  //   paddingBottom: '10px !important',
  //   paddingTop: '0px !important',
  //   '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
  //     color: '#000000',
  //     fontWeight: 600,
  //     background: 'none'
  //   },
  //   '& .MuiInputBase-input::placeholder': {
  //     color: '#000000',
  //     opacity: 1
  //   },
  //   '& .MuiOutlinedInput-adornedEnd': {
  //     backgroundColor: '#F0F0F0',
  //     borderRadius: '12px 12px 12px 12px'
  //   }
  // },
  buttonSaveFeedback: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px',
    textTransform: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  cancleButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px'
  },
  likeBtn: {
    '&:hover': {
      backgroundColor: '#F7F9FD'
    }
  },
  flexCss: {
    display: 'flex',
    gap: '12rem'
  },
  detailHeading: {
    width: '10px'
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontWeight: 500,
      fontSize: '12px'
    },
    '&::placeholder': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  popoverPaper: {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px #0000000D'
  },
  headerCompany: {
    display: 'flex',
    gap: '4px'
  },
  firstDiv: {
    background: '#EDEBEB',
    height: '66px',
    width: '88px',
    '& img': {
      top: '15px',
      left: '23px',
      height: '56%',
      width: '42%',
      'object-fit': 'cover',
      position: 'relative'
    }
  },
  secondDiv: {
    background: '#FFFFFF',
    height: '66px',
    width: '72px',
    '& img': {
      height: '51%',
      width: '47%',
      'object-fit': 'cover',
      position: 'relative',
      left: '16px',
      top: '17px'
    }
  },
  compareData: {
    color: '#004CFF',
    'font-size': '14px',
    float: 'right',
    'text-decoration': 'none',
    paddingRight: '5px'
  },
  companyLogo: {
    top: '45px',
    position: 'relative',
    height: '45%',
    width: '8%',
    marginRight: '6px'
  },
  companyHeading: {
    fontSize: '36px'
  },
  mainDiv: {
    padding: '26px'
  },
  topLevelGrid: {
    overflowY: 'scroll',
    height: '74vh'
  },
  fontSize14: {
    fontSize: '14px!important'
  },
  fontSize12: {
    fontSize: '12px!important'
  },
  fontWeight500: {
    'font-weight': '500!important'
  },
  table: {
    borderCollapse: 'collapse'
  },
  th: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF'
  },
  td: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    backgroundColor: '#FFFFFF'
  },
  alternateCell: {
    backgroundColor: '#f6f7fe'
  },
  resizer: {
    [theme.breakpoints.up('md')]: {
      width: '3px',
      top: 35,
      right: 0,
      cursor: 'col-resize',
      height: 'calc(100% - 35px)',
      position: 'absolute',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      background: '#f3eded',
      '&::before': {
        content: `url('/images/resizeSvg.svg')`,
        position: 'relative'
      },
      '&:hover::before': {
        content: `url('/images/resizeSvgWhite.svg')`
      },
      '&:hover': {
        background: '#004CFF'
      }
    }
  },
  mainChatBotGrid: {
    [theme.breakpoints.up('md')]: {
      position: 'relative!important'
    }
  },
  availableCredit: {
    'padding-left': '0px',
    paddingTop: '1px',
    'font-size': '14px',
    color: 'rgba(0, 0, 0, 0.698)',
    'text-align': 'center',
    display: 'block'
  },
  menuDiv: {
    '& ul': {
      paddingBottom: '0px'
    }
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiInputBase-input': {
      height: '11px !important',
      background: 'linear-gradient(90deg,#EEF3FF,#EEFAFB)',
      borderRadius: '12px 0 0 12px',
      padding: '16px 4px 16px 9px'
    },
    paddingTop: '0px !important',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      color: '#000000B2 !important',
      fontWeight: 500,
      background: 'none'
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 0.6,
      fontWeight: 400
    },
    '& .MuiOutlinedInput-adornedEnd': {
      background: 'linear-gradient(90deg,#EEF3FF,#EEFAFB)',
      borderRadius: '12px 12px 12px 12px',
      paddingRight: '10px'
    }
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0)',
    background: 'linear-gradient(90deg,#EEF3FF,#EEFAFB)',
    overflow: 'hidden',
    borderWidth: '2px',
    border: '1px solid #E7E7E7',
    borderRadius: '10px'
  },
  button: {
    fontSize: '16px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    },
    '&:active': {
      backgroundColor: '#d0d0d0'
    }
  },
  tabClsQuestions: {
    display: 'flex',
    padding: '0px 2px',
    // border: 'none',
    '& div': {
      padding: '2px 3px'
    }
  },
  tabClsBtn: {
    'text-transform': 'none!important',
    padding: '2px 8px !important',
    color: '#767676',
    'border-radius': '6px'
  },
  btnColor: {
    color: '#5e5e5e!important',
    background: '#FFFFFF !important',
    borderRadius: '6px!important',
    border: '1px solid #E7E7E7 !important',
    transition: 'background-color 0.3s ease', // Add a transition for smooth effect
    '&:hover': {
      background: '#f0f0f0 !important' // Change this to your desired hover background color
    }
  },
  active: {
    color: 'white!important',
    background: 'linear-gradient(90deg, rgb(0, 76, 255), rgb(6, 188, 193))',
    borderRadius: '6px !important',
    border: '1px solid #E7E7E7 !important'
  },
  spanText: {
    [theme.breakpoints.down('xs')]: {
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center'
    }
  },
  btnColorCommingSoon: {
    color: '#AEAEAE!important',
    background: '#FFFFFF !important',
    borderRadius: '8px!important',
    border: '1px solid #E7E7E7 !important'
  }
}));

const LoadingDots = () => {
  return (
    <div className="loading-dots-container" id="loadingDotsMain">
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
    </div>
  );
};

const AskBot = ({
  matches,
  setLoadingCompanySelection,
  getUserRemainingCredits,
  setCreditsMainPg
}) => {
  const auth = useContext(AuthContext);
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(document.location.search);
  const plan_id = searchParams.get('user_plan_id');
  const [showSuccess, setShowSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showZeroCreditText, setShowZeroCreditText] = useState('');
  const [availableCredits, setAvailableCredits] = useState('');
  const [currentPlan, setCurrentPlan] = useState(null);
  const [credit, setCredit] = useState(null);
  const [loadCreditChanges, setLoadCreditChanges] = useState(false);
  const [stopResponseInetial, setStopResponseInetial] = useState(false);
  const [mcaErrorMessage, setMcaErrorMessage] = useState('');
  const isDesktop = useMediaQuery('(min-width: 900px)');

  const stopResponseInetialRef = useRef(false);

  const handleStopResponseInetial = () => {
    stopResponseInetialRef.current = true;
  };

  const showCreditExpire = () => {
    if (credit != null) {
      if (loadCreditChanges) {
        setOpenDialog(!(credit > 0));
        setCreditMessage();
      } else if (!loadCreditChanges) {
        setLoadCreditChanges(true);
        setCreditMessage();
      }
    }
  };

  const stopFlagRef = useRef(false);

  const setCreditMessage = () => {
    setAvailableCredits(
      credit === 0
        ? 'Your credits have been consumed. Please purchase additional credits to continue.'
        : `${credit} credits remaining.`
    );

    if (currentPlan && currentPlan.toLowerCase() == 'free') {
      setShowZeroCreditText(t('credit.FreePlanMessage'));
      setTimeout(() => {
        setShowZeroCreditText('');
      }, 10000);
    }
  };

  useEffect(() => {
    showCreditExpire();
  }, [credit, currentPlan]);

  const isCompanyDataVisible = useSelector((state) => state.tokenReducer.isCompanyDataVisible);
  const showCompanyPage = useSelector((state) => state.tokenReducer.showCompanyPage);
  const companyApiContext = useSelector((state) => state.tokenReducer.companyApiContext);
  const companyDetails = useSelector((state) => state.tokenReducer.companyDetails);
  const dashBoardQuestion = useSelector((state) => state.tokenReducer.dashBoardQuestion);
  const [capabilities, setCapabilities] = useState({});
  const [simplePrompts, setSimplePrompts] = useState({});
  const [enhancedPrompts, setEnhancedPrompts] = useState({});
  const dispatch = useDispatch();
  const initialRender = useRef(false);
  const setCompanyWidth = (width) => {
    const companyGrid = document.querySelector('#companyMainGrid');
    if (!companyGrid) return;
    companyGrid.style.width = `calc(100% - ${width})`;
    companyGrid.style['max-width'] = `calc(100% - ${width})`;
  };
  const isCompanyDataPopulated = useSelector((state) => state.tokenReducer.isCompanyDataPopulated);

  const getUserPlan = async () => {
    const createPlanApiUrl = `${pricingURL}/user/plan`;
    const { data } = await getApi(createPlanApiUrl);
    const userPlans = data?.data;
    if (userPlans && userPlans.length > 0) {
      const planCycleDetail = userPlans.find((x) => x.id === plan_id);
      // const planCycleDetail = userPlans.find((x) => x.is_active);
      if (planCycleDetail) setShowSuccess(true);
    }
  };

  const getRemainingCredits = async (isFromChatQuery = false) => {
    const userPlanData = await getUserRemainingCredits();
    if (userPlanData) {
      const { available_question_limit, plan_changed_to } = userPlanData;
      const isPrevCreditSame = available_question_limit === credit;
      setCredit(available_question_limit ? available_question_limit : 0);
      setCreditsMainPg(available_question_limit ? available_question_limit : 0);
      setCurrentPlan(plan_changed_to ? plan_changed_to : '');
      if (isFromChatQuery && isPrevCreditSame) showCreditExpire();
    }
  };

  useEffect(() => {
    if (plan_id) getUserPlan();

    getRemainingCredits();
  }, []);

  const checkCreditAndHideExpiryPopup = () => {
    if (credit === 1) setLoadCreditChanges(false);
  };

  const getWatchlistData = async (cin) => {
    const data = await getApi(`${process.env.REACT_APP_API}/v1/user/watchlist_update?cin=${cin}`);
    const watchlistData = data?.data?.data;
    return {
      in_watchlist: watchlistData?.in_watchlist,
      watchlists: watchlistData?.watchlists || []
    };
  };

  useEffect(() => {
    if (Object.keys(companyApiContext).length === 0) {
      return;
    } else {
      setLoadingCompanySelection(true);
      const { cin, name } = companyApiContext;
      dispatch(setCompanyApiContext({}));
      const getCompanyURL = `${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}`;
      const getCompanyPromise = getApi(getCompanyURL);

      getCompanyPromise
        .then(async (companyData) => {
          if (companyData?.data?.data?.base?.CIN) {
            await getNews(cin);
            await fetchMcaInfo(cin);
            const watchlistData = await getWatchlistData(cin);
            let updatedCompanyDetails = [...companyDetails];
            updatedCompanyDetails = updatedCompanyDetails.map((item) => ({
              ...item,
              active: false
            }));
            const existingIndex = updatedCompanyDetails.findIndex(
              (obj) => obj.data.base.CIN === companyData.data.data.base.CIN
            );
            dispatch(setQuestionTabs(t('tab.Companies')));
            if (existingIndex !== -1) {
              updatedCompanyDetails[existingIndex].active = true;
              updatedCompanyDetails[existingIndex].watchlistData = watchlistData;
              if (questionTabs === t('tab.Companies')) {
                dispatch(setCompanyName(name));
                dispatch(setCompanyCin(cin));
              }
              dispatch(setCompanyDetails([...updatedCompanyDetails]));
            } else {
              const newData = {
                active: true,
                data: companyData.data.data,
                watchlistData,
                userInterest: {
                  interest: '',
                  value: ''
                },
                activeTab: 0
              };
              if (updatedCompanyDetails.length === 5) {
                updatedCompanyDetails.shift();
                updatedCompanyDetails.push(newData);
              } else {
                updatedCompanyDetails.push(newData);
              }
              if (questionTabs === t('tab.Companies')) {
                dispatch(setCompanyName(name));
                dispatch(setCompanyCin(cin));
              }
              dispatch(setCompanyDetails([...updatedCompanyDetails]));
            }
          } else {
            throw new Error('Company details does not exist/contract does not work');
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingCompanySelection(false);
        });
    }
  }, [companyApiContext]);

  useEffect(() => {
    if (showCompanyPage && matches) {
      setValue(1);
      setShowCompaniesTab(false);
      let prevRoute = 'askbyz';
      if (!showCompanyPage.includes('askbyz')) prevRoute = showCompanyPage;

      dispatch(isShowCompanyPage(''));
      dispatch(updatePreviousRoute(prevRoute));
    }
  }, [showCompanyPage]);

  const questionTabs = useSelector((state) => state.tokenReducer.questionTabs);
  // const allMessages = useSelector((state) => state.tokenReducer.allMessages);
  const isMobile = useMediaQuery('(min-width: 500px)');
  useEffect(() => {
    const isCompanyDataPopulatedFlag = companyDetails.length !== 0;
    if ((!isCompanyDataVisible || !isCompanyDataPopulated) && isCompanyDataPopulatedFlag) {
      dispatch(setIsCompanyDataVisible(true));
      dispatch(setIsCompanyDataPopulated(true));
    } else if (!isCompanyDataPopulatedFlag) {
      dispatch(setIsCompanyDataVisible(false));
      dispatch(setIsCompanyDataPopulated(false));
    }

    const currentActiveCompany =
      companyDetails && companyDetails.length > 0
        ? companyDetails.find((x) => x.active === true)
        : null;
    const capabilitiesText = {
      data: [
        {
          text: 'Capabilities'
        },
        {
          text: 'Answers any questions you may have about a startup, from simple to advanced.'
        },
        {
          text: 'Shows key details and metrics about the company, at a glance, in the right panel.'
        },
        {
          text: 'Uses authoritative data sources for enhanced reliability of company information.'
        }
      ],
      message: null
    };
    setCapabilities(capabilitiesText);
    let simplePromptsText;
    let enhancedPromptsText;
    if (questionTabs === t('tab.Sector')) {
      simplePromptsText = {
        data: [
          {
            text: 'Simple Prompts'
          },
          {
            text: '"How is the EV industry evolving in India?"'
          },
          {
            text: '"What are the latest trends in Generative AI?"'
          },
          {
            text: '"Tell me about current Fintech industry landscape?"'
          }
        ],
        message: null
      };
      enhancedPromptsText = {
        data: [
          {
            text: 'Enhanced Prompts'
          },
          {
            text: '"What are the issues with industrializing Machine learning?"'
          },
          {
            text: '"What are big questions for the future of Quantum Computing?"'
          },
          {
            text: '"What are the factors influencing EV sales in the last 2 years?"'
          }
        ],
        message: null
      };
    } else if (questionTabs === t('tab.People')) {
      simplePromptsText = {
        data: [
          {
            text: 'Simple Prompts'
          },
          {
            text: '"Who is Bhavish Aggarwal?"'
          },
          {
            text: '"Is Ravi Bhushan related to Brightchamps?"'
          },
          {
            text: '"Tell me about Saurabh Chandra?"'
          }
        ],
        message: null
      };
      enhancedPromptsText = {
        data: [
          {
            text: 'Enhanced Prompts'
          },
          {
            text: '"Which companies Ashneer Grover has invested in?"'
          },
          {
            text: '"Has Kunal Shah invested in any company apart from CRED?"'
          },
          {
            text: '"How is Nandan Reddy planning to grow his company?"'
          }
        ],
        message: null
      };
    } else if (questionTabs === t('tab.Screener')) {
      simplePromptsText = {
        data: [
          {
            text: 'Simple Prompts'
          },
          {
            text: '"Give me early stage fintech companies and their founders?"'
          },
          {
            text: '"Give me all the Series A companies in the fintech space?"'
          },
          {
            text: '"Give me all profitable startups sorted by valuation?"'
          }
        ],
        message: null
      };
      enhancedPromptsText = {
        data: [
          {
            text: 'Enhanced Prompts'
          },
          {
            text: '"Give me top 5 series A companies by valuation who raised capital after 2019?"'
          },
          {
            text: '"Give me top institutional investors in the healthcare space?"'
          },
          {
            text: '"Is there an ecommerce company that has a revenue of more than 100 Million inr?"'
          }
        ],
        message: null
      };
    } else if (questionTabs === t('tab.Companies')) {
      if (isCompanyDataPopulatedFlag) {
        simplePromptsText = {
          data: [
            {
              text: 'Simple Prompts'
            },
            {
              text: '"Who are the founders of ' + currentActiveCompany.data.base.Name + '"'
            },
            {
              text: '"When was ' + currentActiveCompany.data.base.Name + ' incorporated?"'
            },
            {
              text: '"What is the revenue of ' + currentActiveCompany.data.base.Name + ' in 2022?"'
            }
          ],
          message: null
        };
        enhancedPromptsText = {
          data: [
            {
              text: 'Enhanced Prompts'
            },
            {
              text: '"Tell me about ' + currentActiveCompany.data.base.Name + '"'
            },
            {
              text: '"What is the vision of ' + currentActiveCompany.data.base.Name + '?"'
            },
            {
              text:
                '"How have the revenues of ' +
                currentActiveCompany.data.base.Name +
                ' grown over the last 3 years?"'
            }
          ],
          message: null
        };
      } else {
        simplePromptsText = {
          data: [
            {
              text: 'Simple Prompts'
            },
            {
              text: '"Who are the founders of Aretto?"'
            },
            {
              text: '"How is the EV industry evolving in India?"'
            },
            {
              text: '"What was the revenue of Hala Mobility in 2023?"'
            }
          ],
          message: null
        };
        enhancedPromptsText = {
          data: [
            {
              text: 'Enhanced Prompts'
            },
            {
              text: '"Tell me about business model of company Kapiva?"'
            },
            {
              text: '"What are the issues with industrializing Machine learning?"'
            },
            {
              text: '"Give me the cost structure of Aretto."'
            }
          ],
          message: null
        };
      }
    } else if (questionTabs === t('tab.Fund')) {
      simplePromptsText = {
        data: [
          {
            text: 'Simple Prompts'
          },
          {
            text: '"How has the AUM changed quarter-over quarter for Demo VC1 fund?"'
          },
          {
            text: '"How has the AUM changed quarter-over quarter for Edelweiss Large and Mid Cap fund?"'
          },
          {
            text: '"What is the Fund Manager’s latest Outlook for Edelweiss Large and Mid Cap fund?"'
          }
        ],
        message: null
      };
      enhancedPromptsText = {
        data: [
          {
            text: 'Enhanced Prompts'
          },
          {
            text: '"How has MoICs performance changed over time for Demo VC1 fund?"'
          },
          {
            text: '"What are the investments of Edelweiss Large and Mid Cap fund?"'
          },
          {
            text: '"What factors have contributed to MoICs performance improvement quarter to quarter for Demo VC1 fund ?"'
          }
        ],
        message: null
      };
    }
    setSimplePrompts(simplePromptsText);
    setEnhancedPrompts(enhancedPromptsText);

    if (initialRender.current) {
      setLoadingCompanySelection(false);
    } else {
      initialRender.current = true;
    }
  }, [companyDetails, questionTabs]);

  // useEffect(() => {
  //   if (allMessages !== null) {
  //     dispatch(setAllMessages([]));
  //   }
  // }, [questionTabs]);
  function shallowEqual(objA, objB) {
    for (let key in objB) {
      if (!(key in objA)) {
        return false;
      }
    }
    return true;
  }
  const news = useSelector((state) => state.tokenReducer.news, shallowEqual);
  const newsPageSize = parseInt(process.env.REACT_APP_NEWS_PAGE_SIZE, 10);
  const getNews = async (cin) => {
    if (news[cin] && news[cin].pageNumber) {
      return;
    }
    try {
      let res = await getApi(`${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}/news`, {
        page_size: newsPageSize,
        page_number: 1
      });
      let updatedItems = [];
      let updatedNews = JSON.parse(JSON.stringify(news));
      if (updatedNews[cin]) {
        updatedItems = JSON.parse(JSON.stringify(updatedNews[cin].items));
      } else {
        updatedNews[cin] = {};
      }
      if (res.data.data.items !== null) {
        updatedItems = [...updatedItems, ...res.data.data.items];
        updatedNews[cin].items = updatedItems;
        updatedNews[cin].pageNumber = res.data.data.metadata.total_records <= newsPageSize ? -1 : 2;
        dispatch(setNews({ ...updatedNews }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const requestMcaInfo = async (cin) => {
    try {
      await postApi(`${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}/mca/request`);
      await fetchMcaInfo(cin);
    } catch (error) {
      const errorStatus = error?.response?.status;
      if (errorStatus === 403 || errorStatus === 402) {
        setMcaErrorMessage(t('mca.requestLimitError'));
      } else {
        setMcaErrorMessage(t('mca.requestFailedMessage'));
      }
      setTimeout(() => setMcaErrorMessage(''), 10000);
      console.error('Error requesting MCA info:', error);
    }
  };

  const allMcaData = useSelector((state) => state.tokenReducer.mcaData);
  const fetchMcaInfo = async (cin) => {
    try {
      const mcaInfoData = await getApi(
        `${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}/mca/metrics`
      );
      let updatedMcaData = { ...allMcaData };
      updatedMcaData[cin] = mcaInfoData.data.data;
      dispatch(setMcaData(updatedMcaData));
    } catch (error) {
      console.error('Error fetching MCA info:', error);
    }
  };

  const AskBotGrid = ({ PaymentComponent }) => {
    const storeAskBothWidth = useSelector((state) => state.tokenReducer.askGridWidth);
    const chatScroll = useSelector((state) => state.tokenReducer.chatScroll);
    const companyName = useSelector((state) => state.tokenReducer.companyName);
    const companyCin = useSelector((state) => state.tokenReducer.companyCin);
    const loading = useSelector((state) => state.tokenReducer.loading);
    const loadingAnswerStream = useSelector((state) => state.tokenReducer.loadingAnswerStream);
    const allMessages = useSelector((state) => state.tokenReducer.allMessages);
    const chatSearchInput = useSelector((state) => state.tokenReducer.chatsearchInput);
    const stopResponse = useSelector((state) => state.tokenReducer.stopResponse);
    const regenerateResponse = useSelector((state) => state.tokenReducer.regenerateResponse);
    // const [regenerateResponse, setRegenerateResponse] = useState(false);
    const uuid = useSelector((state) => state.tokenReducer.uuID);
    const chatQueryURL = `${process.env.REACT_APP_CHAT_SERVER_API}`;
    const getCompanyURL = `${process.env.REACT_APP_COMPANY_API}/v1/company`;
    const chatQueryErrorURL = `${process.env.REACT_APP_API}/chat-query-error`;
    const classes = useStyles();
    const [askGridWidth, setAskBotGridWidth] = useState();
    const isMobileLandscape = useMediaQuery('(max-width: 900px) and (orientation:landscape)');
    const [tempStyle, setTempStyle] = useState(null);

    const getTokenVal = async () => {
      const session = await auth.getSession();
      const currentJwtToken = session?.idToken.jwtToken;
      return currentJwtToken;
    };

    function handleQuestionSubmit(event) {
      event.preventDefault();
      if (chatSearchInput === '') {
        return;
      }
      dispatch(setLoadingAnswerStream(true));
      if (chatSearchInput !== '') {
        const qid = uuidv4();
        dispatch(setLoading(true));
        fetchData(qid, null, false);
      }
    }
    const handleQuestionClick = (event, question) => {
      event.preventDefault();
      dispatch(setLoadingAnswerStream(true));
      const qid = uuidv4();
      dispatch(setLoading(true));
      let isCardQuestion = question;
      fetchData(qid, isCardQuestion, false);
    };

    const handleCardQuestionClick = (event, question) => {
      event.preventDefault();
      dispatch(setLoadingAnswerStream(true));
      const qid = uuidv4();
      dispatch(setLoading(true));
      let isCardQuestion = question;
      dispatch(setChatSearchInput(question));
      fetchData(qid, isCardQuestion, false);
    };

    useEffect(() => {
      if (!loadingAnswerStream && dashBoardQuestion) {
        dispatch(setLoadingAnswerStream(true));
        const qid = uuidv4();
        dispatch(setLoading(true));
        let isCardQuestion = dashBoardQuestion;
        dispatch(setChatSearchInput(dashBoardQuestion));
        dispatch(setDashBoardQuestion(null));
        fetchData(qid, isCardQuestion, false);
      }
    }, [dashBoardQuestion]);

    const fetchData = async (qID, isCardQuestion, regenerateResponse) => {
      let questionQuery;
      autoScroll();
      dispatch(setChatScroll(true));
      dispatch(setRegenerateResponse(false));
      setStopResponseInetial(true);
      stopResponseInetialRef.current = false;
      if (isCardQuestion === null) {
        questionQuery = chatSearchInput;
      } else {
        questionQuery = isCardQuestion;
      }

      const apiQid = uuidv4();
      let body = {
        query: questionQuery,
        session_id: uuid,
        streaming: true,
        question_id: apiQid,
        company_name: companyName,
        cin: companyCin,
        question_type: questionTabs.toLowerCase()
      };
      const currentTokenVal = await getTokenVal();

      const newMessage = {
        question: questionQuery,
        question_time: currentTime(),
        messageId: qID
      };
      dispatch(setAllMessages({ ...allMessages, [qID]: { ...allMessages[qID], ...newMessage } }));
      if (!regenerateResponse) {
        const getCompanyPromise = postApi(getCompanyURL, {
          ...body
        });
        getCompanyPromise
          .then(async (companyData) => {
            const cin = companyData.data.data.base.CIN;
            await getNews(cin);
            await fetchMcaInfo(cin);
            let updatedCompanyDetails = [...companyDetails];
            const existingIndex = updatedCompanyDetails.findIndex(
              (obj) => obj.data.base.CIN === companyData.data.data.base.CIN
            );
            if (existingIndex !== -1) {
              if (!updatedCompanyDetails[existingIndex].active) {
                updatedCompanyDetails = updatedCompanyDetails.map((item) => ({
                  ...item,
                  active: false
                }));
                updatedCompanyDetails[existingIndex].active = true;
                dispatch(setCompanyDetails([...updatedCompanyDetails]));
              }
              // dispatch(setIsCompanyDataVisible(true));
            } else {
              updatedCompanyDetails = updatedCompanyDetails.map((item) => ({
                ...item,
                active: false
              }));
              const getCompanyInterest = `${process.env.REACT_APP_API}/v1/user/watchlist_update?cin=${cin}`;
              const getCompanyInterestPromise = getApi(getCompanyInterest);
              getCompanyInterestPromise
                .then((interestData) => {
                  const currentWatchlistData = interestData?.data?.data;
                  const watchlistData = {
                    in_watchlist: currentWatchlistData?.in_watchlist,
                    watchlists: currentWatchlistData?.watchlists || []
                  };

                  return watchlistData;
                })
                .catch(() => {
                  let watchlistData = {
                    in_watchlist: false,
                    watchlists: []
                  };

                  return watchlistData;
                })
                .then((watchlistData) => {
                  let updatedCompanyDetails = [...companyDetails];
                  updatedCompanyDetails = updatedCompanyDetails.map((item) => ({
                    ...item,
                    active: false
                  }));
                  const arrayLength = 5;
                  const existingIndex = updatedCompanyDetails.findIndex(
                    (obj) => obj.data.base.CIN === companyData.data.data.base.CIN
                  );
                  if (existingIndex !== -1) {
                    updatedCompanyDetails[existingIndex].active = true;
                    updatedCompanyDetails[existingIndex].watchlistData = watchlistData;
                  } else {
                    const newData = {
                      active: true,
                      data: companyData.data.data,
                      watchlistData,
                      activeTab: 0
                    };
                    if (updatedCompanyDetails.length === arrayLength) {
                      updatedCompanyDetails.shift();
                      updatedCompanyDetails.push(newData);
                    } else {
                      updatedCompanyDetails.push(newData);
                    }
                  }

                  dispatch(setCompanyDetails([...updatedCompanyDetails]));
                  // dispatch(setIsCompanyDataVisible(true));
                });
            }
          })
          .catch(() => {});
      }

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          const timeoutError = new Error('chat Query 30 seconds time out');
          timeoutError.code = 110; // Custom error code
          reject(timeoutError);
        }, 30000);
      });

      let stopButtonInterval;

      const stopButtonPromise = new Promise((resolve, reject) => {
        stopButtonInterval = setInterval(() => {
          if (stopResponseInetialRef.current === true) {
            setStopResponseInetial(false);
            clearInterval(stopButtonInterval); // Clear interval if condition met
            const stopButtonPromiseErr = new Error('user stop button trigger');
            reject(stopButtonPromiseErr);
          }
        }, 200);
      });

      const chatQueryPromise = Promise.race([
        streamApi(chatQueryURL, currentTokenVal, { ...body }),
        timeoutPromise,
        stopButtonPromise
      ]);

      let accumulatedData = '';
      let updatedMessage;
      chatQueryPromise
        .then(async ({ result }) => {
          // dispatch(setStopResponse(true));
          dispatch(setLoading(false));
          dispatch(setCompanyName(''));
          dispatch(setCompanyCin(''));
          dispatch(setShowCompanyButton(false));
          const followUpQuestionsAPI = postApi(`${process.env.REACT_APP_API}/followup-questions`, {
            session_id: uuid,
            question_id: apiQid
          });
          const reader = result.getReader();
          const decoder = new TextDecoder();
          const processChunk = async (chunk) => {
            dispatch(setStopResponse(true));
            setStopResponseInetial(false);
            let testDecodedChunkIfEmpty = decoder.decode(chunk.value, { stream: true });
            if (chunk.done && accumulatedData === '' && testDecodedChunkIfEmpty === '') {
              updatedMessage = {
                creditsExpired: t('askBot.chatFailMsg')
              };

              dispatch(
                setAllMessages({
                  ...allMessages,
                  [qID]: { ...newMessage, ...updatedMessage }
                })
              );
              dispatch(setStopResponse(false));
              dispatch(setLoadingAnswerStream(false));
              dispatch(setLoading(false));
              dispatch(setChatSearchInput(''));

              let errorBody = {
                session_id: uuid,
                question_id: body.question_id,
                query: body.query,
                error_msg: '500 error 200 response with empty body',
                error_code: 500
              };
              postApi(chatQueryErrorURL, errorBody);
            } else if (chunk.done) {
              stopFlagRef.current = false;
              // const followUpQuestionsData = await followUpQuestionsAPI;
              try {
                const followUpQuestionsData = await followUpQuestionsAPI;
                const show_more_flag = followUpQuestionsData.data.data.show_more_flag;
                const followUpQuestions = {
                  follow_up_q: followUpQuestionsData.data.data.followup_questions,
                  disclaimer: followUpQuestionsData.data.data.disclaimer,
                  showMore: false
                };
                if (show_more_flag) {
                  const showMore = {
                    showMore: true,
                    disclaimer: followUpQuestionsData.data.data.disclaimer
                  };
                  dispatch(
                    setAllMessages({
                      ...allMessages,
                      [qID]: { ...newMessage, ...updatedMessage, ...showMore }
                    })
                  );
                } else {
                  dispatch(
                    setAllMessages({
                      ...allMessages,
                      [qID]: { ...newMessage, ...updatedMessage, ...followUpQuestions }
                    })
                  );
                }
              } catch (error) {
                dispatch(
                  setAllMessages({
                    ...allMessages,
                    [qID]: { ...newMessage, ...updatedMessage }
                  })
                );
              }
              dispatch(setStopResponse(false));
              // dispatch(setShowCompanyButton(true));
              dispatch(setLoadingAnswerStream(false));
              dispatch(setChatSearchInput(''));
              return;
            } else if (stopFlagRef.current) {
              stopFlagRef.current = false;
              dispatch(setStopResponse(false));
              dispatch(setRegenerateResponse(true));
              // dispatch(setShowCompanyButton(true));
              dispatch(setLoadingAnswerStream(false));
              dispatch(setChatSearchInput(''));
              return;
            } else {
              let decodedChunk = decoder.decode(chunk.value, { stream: true });
              accumulatedData += decodedChunk;
              updatedMessage = {
                answer: accumulatedData,
                like: false,
                unlike: false,
                answer_time: currentTime()
              };

              dispatch(
                setAllMessages({
                  ...allMessages,
                  [qID]: { ...newMessage, ...updatedMessage }
                })
              );
              return reader.read().then(processChunk);
            }
          };
          reader.read().then(async (chunk) => {
            await processChunk(chunk);
            checkCreditAndHideExpiryPopup();
            await getRemainingCredits(true);
          });
        })
        .catch(async (error) => {
          console.log(error);
          let errorBody = {
            session_id: uuid,
            question_id: body.question_id,
            query: body.query,
            error_msg: error.message,
            error_code: error.code
          };
          setStopResponseInetial(false);
          dispatch(setStopResponse(false));
          if (error.code === 110) {
            // Handle API timeout here
            dispatch(setLoadingAnswerStream(false));
            dispatch(setLoading(false));
            const updatedMessage = {
              answer: t('askBot.chatTimeOutMsg'),
              like: false,
              unlike: false,
              answer_time: currentTime()
            };
            dispatch(
              setAllMessages({
                ...allMessages,
                [qID]: { ...newMessage, ...updatedMessage }
              })
            );
            dispatch(setChatSearchInput(''));
            dispatch(setRegenerateResponse(true));
          } else {
            const errorMessage =
              error?.message &&
              (() => {
                try {
                  return JSON.parse(error.message);
                } catch (e) {
                  return error.message;
                }
              })();
            if (error.message === 'Failed to fetch') {
              updatedMessage = {
                answer: t('askBot.chatTimeOutMsg'),
                like: false,
                unlike: false,
                answer_time: currentTime()
              };
            } else if (
              errorMessage.status === 401 ||
              errorMessage.status === 403 ||
              errorMessage.status === 404 ||
              errorMessage.status === 501
            ) {
              updatedMessage = {
                creditsExpired:
                  errorMessage.text === null ? t('askBot.chatFailMsg') : errorMessage.text
              };
            } else {
              updatedMessage = {
                creditsExpired: t('askBot.chatFailMsg')
              };
            }
            dispatch(
              setAllMessages({
                ...allMessages,
                [qID]: { ...newMessage, ...updatedMessage }
              })
            );
            dispatch(setLoading(false));
            dispatch(setLoadingAnswerStream(false));
            dispatch(setChatSearchInput(''));
            dispatch(setRegenerateResponse(true));
          }
          await postApi(chatQueryErrorURL, errorBody);
          checkCreditAndHideExpiryPopup();
          await getRemainingCredits(true);
        });
    };

    const fetchshowMore = async (qID) => {
      autoScroll();
      dispatch(setChatScroll(true));
      dispatch(setRegenerateResponse(false));
      dispatch(setLoadingAnswerStream(true));
      dispatch(setLoading(true));
      setStopResponseInetial(true);
      stopResponseInetialRef.current = false;
      const apiQid = uuidv4();
      let body = {
        question_type: 'show_more',
        session_id: uuid,
        streaming: true,
        question_id: apiQid,
        query: ''
      };

      const newMessage = allMessages[qID];

      const setStopShowMoreBtn = {
        showMore: false
      };

      dispatch(
        setAllMessages({
          ...allMessages,
          [qID]: { ...newMessage, ...setStopShowMoreBtn }
        })
      );

      const currentTokenVal = await getTokenVal();

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          const timeoutError = new Error('chat Query 30 seconds time out');
          timeoutError.code = 110; // Custom error code
          reject(timeoutError);
        }, 30000);
      });

      let stopButtonInterval;

      const stopButtonPromise = new Promise((resolve, reject) => {
        stopButtonInterval = setInterval(() => {
          if (stopResponseInetialRef.current === true) {
            setStopResponseInetial(false);
            clearInterval(stopButtonInterval); // Clear interval if condition met
            const stopButtonPromiseErr = new Error('user stop button trigger');
            reject(stopButtonPromiseErr);
          }
        }, 200);
      });

      const chatQueryPromise = Promise.race([
        streamApi(chatQueryURL, currentTokenVal, { ...body }),
        timeoutPromise,
        stopButtonPromise
      ]);
      let accumulatedData = allMessages[qID].answer + '\n\n';
      let updatedMessage;
      chatQueryPromise
        .then(async ({ result }) => {
          dispatch(setLoading(false));
          dispatch(setCompanyName(''));
          dispatch(setCompanyCin(''));
          dispatch(setShowCompanyButton(false));
          const followUpQuestionsAPI = postApi(`${process.env.REACT_APP_API}/followup-questions`, {
            session_id: uuid,
            question_id: apiQid
          });
          const reader = result.getReader();
          const decoder = new TextDecoder();
          const processChunk = async (chunk) => {
            dispatch(setStopResponse(true));
            setStopResponseInetial(false);
            let testDecodedChunkIfEmpty = decoder.decode(chunk.value, { stream: true });
            if (chunk.done && accumulatedData === '' && testDecodedChunkIfEmpty === '') {
              const updatedMessage = {
                // answer: accumulatedData + '\n\n Something went wrong, please retry after some time',
                showMore: false
              };
              dispatch(
                setAllMessages({
                  ...allMessages,
                  [qID]: { ...newMessage, ...updatedMessage }
                })
              );
              dispatch(setStopResponse(false));
              dispatch(setLoadingAnswerStream(false));
              dispatch(setLoading(false));
              dispatch(setChatSearchInput(''));

              let errorBody = {
                session_id: uuid,
                question_id: body.question_id,
                query: body.query,
                error_msg: '500 error 200 response with empty body for show more',
                error_code: 500
              };
              postApi(chatQueryErrorURL, errorBody);
            } else if (chunk.done) {
              stopFlagRef.current = false;
              // const followUpQuestionsData = await followUpQuestionsAPI;
              try {
                const followUpQuestionsData = await followUpQuestionsAPI;
                const show_more_flag = followUpQuestionsData.data.data.show_more_flag;
                const followUpQuestions = {
                  follow_up_q: followUpQuestionsData.data.data.followup_questions,
                  disclaimer: followUpQuestionsData.data.data.disclaimer,
                  showMore: false
                };
                if (show_more_flag) {
                  const showMore = {
                    showMore: true,
                    disclaimer: followUpQuestionsData.data.data.disclaimer
                  };
                  dispatch(
                    setAllMessages({
                      ...allMessages,
                      [qID]: { ...newMessage, ...updatedMessage, ...showMore }
                    })
                  );
                } else {
                  dispatch(
                    setAllMessages({
                      ...allMessages,
                      [qID]: { ...newMessage, ...updatedMessage, ...followUpQuestions }
                    })
                  );
                }
              } catch (error) {
                const followUpQuestionsErr = {
                  showMore: false
                };
                dispatch(
                  setAllMessages({
                    ...allMessages,
                    [qID]: { ...newMessage, ...updatedMessage, ...followUpQuestionsErr }
                  })
                );
              }
              dispatch(setStopResponse(false));
              dispatch(setLoadingAnswerStream(false));
              dispatch(setChatSearchInput(''));
              return;
            } else if (stopFlagRef.current) {
              stopFlagRef.current = false;
              dispatch(setStopResponse(false));
              dispatch(setLoadingAnswerStream(false));
              dispatch(setChatSearchInput(''));
              return;
            } else {
              let decodedChunk = decoder.decode(chunk.value, { stream: true });
              accumulatedData += decodedChunk;
              updatedMessage = {
                answer: accumulatedData,
                showMore: false
              };

              dispatch(
                setAllMessages({
                  ...allMessages,
                  [qID]: { ...newMessage, ...updatedMessage }
                })
              );
              return reader.read().then(processChunk);
            }
          };
          reader.read().then(async (chunk) => {
            await processChunk(chunk);
            checkCreditAndHideExpiryPopup();
            await getRemainingCredits(true);
          });
        })
        .catch(async (error) => {
          setStopResponseInetial(false);
          let errorBody = {
            session_id: uuid,
            question_id: body.question_id,
            query: body.query,
            error_msg: error.message,
            error_code: error.code
          };
          dispatch(setStopResponse(false));
          if (error.code === 110) {
            // Handle API timeout here
            dispatch(setLoadingAnswerStream(false));
            dispatch(setLoading(false));
            const updatedMessage = {
              // answer: accumulatedData + '\n\n Something went wrong, please retry after some time',
              showMore: false
            };
            dispatch(
              setAllMessages({
                ...allMessages,
                [qID]: { ...newMessage, ...updatedMessage }
              })
            );
            dispatch(setChatSearchInput(''));
            dispatch(setRegenerateResponse(true));
          } else {
            const errorMessage =
              error?.message &&
              (() => {
                try {
                  return JSON.parse(error.message);
                } catch (e) {
                  return error.message;
                }
              })();
            if (error.message === 'Failed to fetch') {
              updatedMessage = {
                answer: t('askBot.chatTimeOutMsg'),
                like: false,
                unlike: false,
                answer_time: currentTime()
              };
            } else if (
              errorMessage.status === 401 ||
              errorMessage.status === 403 ||
              errorMessage.status === 404 ||
              errorMessage.status === 501
            ) {
              updatedMessage = {
                // answer: accumulatedData + '\n\n Something went wrong, please retry after some time',
                showMore: false
              };
            } else {
              updatedMessage = {
                // answer: accumulatedData + '\n\n Something went wrong, please retry after some time',
                showMore: false
              };
            }
            dispatch(
              setAllMessages({
                ...allMessages,
                [qID]: { ...newMessage, ...updatedMessage }
              })
            );
            dispatch(setLoading(false));
            dispatch(setLoadingAnswerStream(false));
            dispatch(setChatSearchInput(''));
            dispatch(setRegenerateResponse(true));
          }
          await postApi(chatQueryErrorURL, errorBody);
          checkCreditAndHideExpiryPopup();
          await getRemainingCredits(true);
        });
    };

    const showMore = (qID) => {
      fetchshowMore(qID, null, false);
    };

    const messagesEndRef = useRef(null);
    const prevScrollTop = useRef(0);
    const handleScroll = () => {
      if (messagesEndRef.current) {
        const scrollTop = messagesEndRef.current.scrollTop;

        const clientHeight = messagesEndRef.current.clientHeight;
        const scrollHeight = messagesEndRef.current.scrollHeight;

        const bufferHeight = Math.round(clientHeight * 0.1);
        const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - bufferHeight;
        if (scrollTop > prevScrollTop.current && isScrolledToBottom) {
          dispatch(setChatScroll(true));
        } else if (scrollTop < prevScrollTop.current) {
          dispatch(setChatScroll(false));
        }
        prevScrollTop.current = scrollTop;
      }
    };

    const autoScroll = () => {
      if (messagesEndRef.current && chatScroll) {
        messagesEndRef.current.scrollTo({
          top: messagesEndRef.current.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }
    };

    useEffect(() => {
      autoScroll();
    }, [allMessages]);

    useEffect(() => {
      if (loading === true) {
        if (messagesEndRef.current && chatScroll) {
          messagesEndRef.current.scrollTo({
            top: messagesEndRef.current.scrollHeight,
            left: 0,
            behavior: 'smooth'
          });
        }
      }
    }, [loading]);

    const handleRegenerateResponse = async () => {
      dispatch(setRegenerateResponse(false));
      const qid = uuidv4();
      dispatch(setLoadingAnswerStream(true));
      // setStopResponseInetia(true);
      // stopResponseInetialRef.current = false;
      dispatch(setLoading(true));
      dispatch(
        setChatSearchInput(
          allMessages[Object.keys(allMessages)[Object.keys(allMessages).length - 1]].question
        )
      );
      autoScroll();
      fetchData(
        qid,
        allMessages[Object.keys(allMessages)[Object.keys(allMessages).length - 1]].question,
        true
      );
    };

    const handleStopResponse = () => {
      stopFlagRef.current = true;
    };

    useEffect(() => {
      if (matches) return;

      let resizer = document.querySelector('#resizer'),
        currentGrid = document.querySelector('#mainGridAskbot');
      let delayTimer;
      function onAskGridLoad(resizer, currentGrid) {
        let x, w;
        function mouseDownEvent(e) {
          resizer.style.background = '#004CFF';
          x = e.clientX;
          let sbWidth = window.getComputedStyle(currentGrid).width;
          w = parseInt(sbWidth, 10);
          document.addEventListener('mousemove', mouseMoveEvent);
          document.addEventListener('mouseup', rs_mouseupHandler);
        }

        function mouseMoveEvent(e) {
          window.getSelection().removeAllRanges();
          delayTimer = setTimeout(function () {
            let dx = e.clientX - x;
            let cw = w + dx;
            clearTimeout(delayTimer);
            window.getSelection().removeAllRanges();
            const docWidth = 100 / document.body.clientWidth;
            if (cw * docWidth < 55.84 && cw * docWidth > 29.73) {
              const currentDivWidth = `${cw}px`;
              currentGrid.style.width = currentDivWidth;
              currentGrid.style['max-width'] = currentDivWidth;
              setAskBotGridWidth(currentDivWidth);
              setCompanyWidth(currentDivWidth);
              dispatch(setAskBotWidth(currentDivWidth));
            }
          }, 100);
        }

        function rs_mouseupHandler() {
          resizer.style.background = '';
          document.removeEventListener('mouseup', rs_mouseupHandler);
          document.removeEventListener('mousemove', mouseMoveEvent);
        }

        resizer.addEventListener('mousedown', mouseDownEvent);
      }
      onAskGridLoad(resizer, currentGrid);
    }, []);

    useEffect(() => {
      if (matches) return;
      if (storeAskBothWidth) {
        setAskBotGridWidth(storeAskBothWidth);
        const currentGrid = document.querySelector('#mainGridAskbot');
        currentGrid.style.width = storeAskBothWidth;
        currentGrid.style['max-width'] = storeAskBothWidth;
        setCompanyWidth(storeAskBothWidth);
      }
    }, [storeAskBothWidth]);

    useEffect(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTo({
          top: messagesEndRef.current.scrollHeight,
          left: 0
        });
      }
    }, []);

    const removeTempStyleOnGrid = () => {
      const currentGrid = document.querySelector('div#mainGridAskbot');
      const tempStyleData = {
        mWidth: currentGrid.style['max-width'],
        width: currentGrid.style['width']
      };
      setTempStyle(tempStyleData);
    };

    useEffect(() => {
      if (isCompanyDataVisible) {
        if (tempStyle && tempStyle.mWidth && tempStyle.width) {
          const { mWidth, width } = tempStyle;
          const currentGrid = document.querySelector('#mainGridAskbot');
          currentGrid.style.width = width;
          currentGrid.style['max-width'] = mWidth;
        }
      } else {
        const currentGrid = document.querySelector('div#mainGridAskbot');
        currentGrid.removeAttribute('style');
      }
    }, [isCompanyDataVisible]);

    const toggleCompanyDataVisibility = () => {
      if (!matches) setAskBotGridWidth(isCompanyDataVisible ? '100%' : askGridWidth);
      dispatch(setIsCompanyDataVisible(!isCompanyDataVisible));
    };

    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={isCompanyDataVisible ? 7.3 : 12}
        lg={isCompanyDataVisible ? 7.3 : 12}
        xl={isCompanyDataVisible ? 7.3 : 12}
        sx={{ backgroundColor: '#FFFFFF', borderRadius: '24px' }}
        className={classes.mainChatBotGrid}
        id="mainGridAskbot"
      >
        {PaymentComponent && showSuccess ? (
          <PaymentComponent
            condition={[t('payment.successStatus')]}
            message={`Thanks for purchasing. You now have access to premium data and ${credit} credits`}
          />
        ) : null}
        <div
          id="resizer"
          style={
            !loadingAnswerStream && isCompanyDataPopulated && isCompanyDataVisible
              ? {}
              : { display: 'none' }
          }
          className={classes.resizer}
        ></div>
        {/* <Grid container direction="column" justifyContent="space-between" sx={{ height: '100%' }}> */}
        <Grid
          id="askBotMainContianer"
          container
          direction="column"
          justifyContent="space-between"
          sx={
            isMobileLandscape
              ? { height: 'calc(100vh - -18px)' }
              : matches
              ? { height: 'calc(100vh - 145px)' }
              : { height: 'calc(100vh - 155px)', display: 'flex' }
            // : { height: 'calc(100vh - 185px)', display: 'flex' }
          }
        >
          {companyDetails.length > 0 && isDesktop && (
            <div id="toggleCompanyVisibility" style={{ position: 'absolute', right: -10, top: 25 }}>
              {isCompanyDataVisible ? (
                <Tooltip title={'Collapse'} style={{ cursor: 'pointer' }}>
                  <CollapseIcon
                    onClick={() => {
                      removeTempStyleOnGrid();
                      toggleCompanyDataVisibility();
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={'Expand'} style={{ cursor: 'pointer' }}>
                  <ExpandIcon onClick={toggleCompanyDataVisibility} />
                </Tooltip>
              )}
            </div>
          )}
          <Grid item container justifyContent="center" id="askBotItemContianerMain">
            <Grid
              item
              xs={12}
              md={isCompanyDataVisible ? 12 : 10}
              container
              justifyContent="center"
              sx={{ height: '100%' }}
              id="askBotItemContianer"
            >
              <Grid item xs={0} md={0.3} id="askBotEmpty" />
              <Grid
                item
                xs={12}
                md={11.4}
                id="askBotChildMainItem"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  id="askBotChildItem"
                  md={isCompanyDataVisible ? 12 : 11}
                >
                  <Grid
                    container
                    sx={{
                      height: '100%',
                      paddingTop: matches ? '0px' : '20px'
                    }}
                    id="askBotChildItemContainer"
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      style={{ padding: '5px 0px 5px 0px' }}
                    ></Grid>
                    {allMessages.length === 0 ? (
                      <>
                        <Grid
                          container
                          id="askBotMainMessageContainer"
                          style={{
                            paddingTop: matches ? '0px' : '5px',
                            overflowY: 'auto',
                            height: isMobileLandscape
                              ? 'calc(100vh - 107px)'
                              : 'calc(100vh - 360px)'
                          }}
                        >
                          <Grid
                            container
                            columnSpacing={1}
                            justifyContent={'center'}
                            style={{ justifyContent: 'center' }}
                            id="askBotChildMessageContainer"
                          >
                            <Grid
                              style={{ maxWidth: '350px' }}
                              item
                              sm={matches ? 12 : 4}
                              id="askBotChildItemMessageContainer"
                            >
                              {capabilities?.data?.map((item, index) =>
                                index === 0 ? (
                                  <React.Fragment key={index}>
                                    <Card
                                      id="askBotChildItemCardContainer"
                                      key={index}
                                      sx={{
                                        maxWidth: '100%',
                                        display: 'flex'
                                      }}
                                      style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                                      elevation={0}
                                    >
                                      <CardContent
                                        id="askBotChildItemCardContent"
                                        sx={{ flexGrow: 1 }}
                                        style={{
                                          padding: '10px'
                                        }}
                                      >
                                        <Typography
                                          id="askBotChildItemCardTypo"
                                          className={classes.staticDataText}
                                          style={{
                                            fontFamily: 'Satoshi',
                                            fontSize: '14px',
                                            color: '#000000B2',
                                            paddingBottom: '0px'
                                          }}
                                        >
                                          {item.text}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                    <div style={{ paddingTop: '10px' }}></div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={index}>
                                    <Card
                                      id="askBotChildItemCardContainer"
                                      key={index}
                                      sx={{
                                        maxWidth: '100%',
                                        minHeight: matches ? '0px' : '83px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        border: '1px solid #F0F0F0',
                                        borderRadius: '10px',
                                        boxShadow: '0px 2px 4px 0px #0000000D',
                                        '&:hover': {
                                          backgroundColor: '#EFF3FC'
                                        }
                                      }}
                                      style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                                      elevation={0}
                                    >
                                      <CardContent
                                        id="askBotChildItemCardContent"
                                        sx={{ flexGrow: 1 }}
                                        style={{
                                          padding: '10px',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          id="askBotChildItemCardTypo"
                                          className={classes.questionText}
                                          style={{
                                            fontFamily: 'Satoshi',
                                            fontSize: '14px',
                                            color: '#000000B2',
                                            paddingBottom: '0px'
                                          }}
                                        >
                                          {item.text}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                    <div style={{ paddingTop: '10px' }}></div>
                                  </React.Fragment>
                                )
                              )}
                            </Grid>
                            <Grid
                              style={{ maxWidth: '350px' }}
                              item
                              sm={matches ? 12 : 4}
                              id="askBotMainSimplePrompts"
                            >
                              {simplePrompts?.data?.map((item, index) =>
                                index === 0 ? (
                                  <React.Fragment key={index}>
                                    <Card
                                      id="askBotCardSimplePrompts"
                                      key={index}
                                      sx={{
                                        maxWidth: '100%',
                                        display: 'flex'
                                      }}
                                      style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                                      elevation={0}
                                    >
                                      <CardContent
                                        id="askBotCardContentSimplePrompts"
                                        sx={{ flexGrow: 1 }}
                                        style={{
                                          padding: '10px'
                                        }}
                                      >
                                        <Typography
                                          id="askBotTypoSimplePrompts"
                                          className={classes.staticDataText}
                                          style={{
                                            fontFamily: 'Satoshi',
                                            fontSize: '14px',
                                            color: '#000000B2',
                                            paddingBottom: '0px'
                                          }}
                                        >
                                          {item.text}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                    <div style={{ paddingTop: '10px' }}></div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={index}>
                                    <Card
                                      id="askBotCardSimplePrompts"
                                      key={index}
                                      sx={{
                                        maxWidth: '100%',
                                        minHeight: matches ? '0px' : '83px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        border: '1px solid #F0F0F0',
                                        borderRadius: '10px',
                                        boxShadow: '0px 2px 4px 0px #0000000D',
                                        '&:hover': {
                                          backgroundColor: '#EFF3FC'
                                        }
                                      }}
                                      style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                                      elevation={0}
                                      onClick={(event) =>
                                        handleCardQuestionClick(
                                          event,
                                          item.text.substring(1, item.text.length - 1)
                                        )
                                      }
                                    >
                                      <CardContent
                                        id="askBotCardContentSimplePrompts"
                                        sx={{ flexGrow: 1 }}
                                        style={{
                                          padding: '10px',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          id="askBotTypoSimplePrompts"
                                          className={classes.questionText}
                                          style={{
                                            fontFamily: 'Satoshi',
                                            fontSize: '14px',
                                            color: '#000000B2',
                                            paddingBottom: '0px'
                                          }}
                                        >
                                          {item.text}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                    <div style={{ paddingTop: '10px' }}></div>
                                  </React.Fragment>
                                )
                              )}
                            </Grid>
                            <Grid
                              style={{ maxWidth: '350px' }}
                              item
                              sm={matches ? 12 : 4}
                              id="askBotMainEnhancedPrompts"
                            >
                              {enhancedPrompts?.data?.map((item, index) =>
                                index === 0 ? (
                                  <React.Fragment key={index}>
                                    <Card
                                      id="askBotCardEnhancedPrompts"
                                      key={index}
                                      sx={{
                                        maxWidth: '100%',
                                        display: 'flex'
                                      }}
                                      style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                                      elevation={0}
                                    >
                                      <CardContent
                                        id="askBotCardContentEnhancedPrompts"
                                        sx={{ flexGrow: 1 }}
                                        style={{
                                          padding: '10px'
                                        }}
                                      >
                                        <Typography
                                          id="askBotTypoEnhancedPrompts"
                                          className={classes.staticDataText}
                                          style={{
                                            fontFamily: 'Satoshi',
                                            fontSize: '14px',
                                            color: '#000000B2',
                                            paddingBottom: '0px'
                                          }}
                                        >
                                          {item.text}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                    <div style={{ paddingTop: '10px' }}></div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={index}>
                                    <Card
                                      id="askBotCardEnhancedPrompts"
                                      key={index}
                                      sx={{
                                        maxWidth: '100%',
                                        minHeight: matches ? '0px' : '83px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        border: '1px solid #F0F0F0',
                                        borderRadius: '10px',
                                        boxShadow: '0px 2px 4px 0px #0000000D',
                                        '&:hover': {
                                          backgroundColor: '#EFF3FC'
                                        }
                                      }}
                                      style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                                      elevation={0}
                                      onClick={(event) =>
                                        handleCardQuestionClick(
                                          event,
                                          item.text.substring(1, item.text.length - 1)
                                        )
                                      }
                                    >
                                      <CardContent
                                        id="askBotCardContentEnhancedPrompts"
                                        sx={{ flexGrow: 1 }}
                                        style={{
                                          padding: '10px',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          id="askBotTypoEnhancedPrompts"
                                          className={classes.questionText}
                                          style={{
                                            fontFamily: 'Satoshi',
                                            fontSize: '14px',
                                            color: '#000000B2',
                                            paddingBottom: '0px'
                                          }}
                                        >
                                          {item.text}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                    <div style={{ paddingTop: '10px' }}></div>
                                  </React.Fragment>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Paper
                          id="askBotMainPaper"
                          elevation={0}
                          style={{
                            width: '100%',
                            height: isMobileLandscape
                              ? 'calc(100vh - 161px)'
                              : 'calc(100vh - 383px)'
                          }}
                        >
                          <div
                            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                            id="askBotMainMessageEndRef"
                          >
                            <div
                              id="askBotChildMessageEndRef"
                              ref={messagesEndRef}
                              onScroll={handleScroll}
                              style={{ flex: 1, overflowY: 'auto', paddingTop: '20px' }}
                            >
                              {Object.keys(allMessages).map(
                                (key, answer, follow_up_q, disclaimer) => {
                                  if (allMessages[key].creditsExpired) {
                                    return (
                                      <>
                                        <UserComponent key={key} messageData={allMessages[key]} />
                                        <CreditsExpiredComponent
                                          key={key}
                                          messageData={allMessages[key]}
                                        />
                                      </>
                                    );
                                  } else if (
                                    (allMessages[key].follow_up_q &&
                                      allMessages[key].follow_up_q.length > 0) ||
                                    allMessages[key].disclaimer
                                  ) {
                                    return (
                                      <>
                                        <UserComponent key={key} messageData={allMessages[key]} />
                                        <BotComponent
                                          key={answer}
                                          messageData={allMessages[key]}
                                          showMore={showMore}
                                        />
                                        <Disclaimer
                                          key={disclaimer}
                                          messageData={allMessages[key]}
                                          matches={matches}
                                        />
                                        <FollowUpComponent
                                          key={follow_up_q}
                                          messageData={allMessages[key]}
                                          matches={matches}
                                          handleCardQuestionClick={handleCardQuestionClick}
                                        />
                                      </>
                                    );
                                  } else if (allMessages[key].answer) {
                                    return (
                                      <>
                                        <UserComponent key={key} messageData={allMessages[key]} />
                                        <BotComponent
                                          key={answer}
                                          messageData={allMessages[key]}
                                          showMore={showMore}
                                        />
                                      </>
                                    );
                                  } else {
                                    return (
                                      <UserComponent key={key} messageData={allMessages[key]} />
                                    );
                                  }
                                }
                              )}
                              {loading && (
                                <>
                                  <LinearProgress />
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                        </Paper>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={0} md={0.3} id="askbotGridEmptyChild" />
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" id="askbotGridChild">
            <Grid
              id="askbotGridChildItemContainer"
              item
              xs={12}
              md={isCompanyDataVisible ? 12 : 10}
              container
              justifyContent="center"
              sx={{ height: '100%' }}
            >
              <Grid item xs={0} md={0.3} id="askbotGridChildItemContainerEmpty" />
              <Grid item xs={12} md={11.4} id="askbotGridChildItemContainerRegRes">
                {regenerateResponse && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      id="askbotGridChildItemContainerButton"
                      style={{
                        textTransform: 'none',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '8px',
                        border: '1px solid #E8E8E8',
                        height: '30px'
                      }}
                      onClick={() => {
                        handleRegenerateResponse();
                      }}
                    >
                      <LoopIcon
                        style={{ color: '#444444', fontSize: '20px' }}
                        id="askbotGridChildItemContainerLoopIcon"
                      />
                      <Typography
                        id="askbotGridChildItemContainerChTypo"
                        style={{
                          color: '#1D1D1D',
                          fontSize: '14px'
                        }}
                      >
                        {t('askBot.regenRespBtn')}
                      </Typography>
                    </Button>
                  </div>
                )}
                {stopResponse && (
                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    id="askbotGridChildItemContainerStpRes"
                  >
                    <Button
                      id="askbotGridChildItemContainerStpResButton"
                      style={{
                        textTransform: 'none',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '8px',
                        border: '1px solid #E8E8E8',
                        height: '30px'
                      }}
                      onClick={() => {
                        handleStopResponse();
                      }}
                    >
                      <Typography
                        id="askbotGridChildItemContainerStpResTypo"
                        style={{
                          color: '#1D1D1D',
                          fontSize: '14px'
                        }}
                      >
                        {t('askBot.stopBtn')}
                      </Typography>
                    </Button>
                  </div>
                )}

                {stopResponseInetial && (
                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    id="askbotGridChildItemContainerStpRes"
                  >
                    <Button
                      id="askbotGridChildItemContainerStpResButton"
                      style={{
                        textTransform: 'none',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '8px',
                        border: '1px solid #E8E8E8',
                        height: '30px'
                      }}
                      onClick={handleStopResponseInetial}
                    >
                      <Typography
                        id="askbotGridChildItemContainerStpResTypo"
                        style={{
                          color: '#1D1D1D',
                          fontSize: '14px'
                        }}
                      >
                        {t('askBot.stopBtn')}
                      </Typography>
                    </Button>
                  </div>
                )}

                {!matches ? (
                  <Typography
                    id="askbotGridChildItemContainerStpResChTypo"
                    style={{
                      paddingLeft: '0px',
                      fontSize: '14px',
                      color: '#000000B2',
                      display: 'grid'
                    }}
                    variant="body1"
                  >
                    <div style={{ display: 'none' }}>
                      {questionTabs === t('tab.Sector') && (
                        <i style={{ color: '#2549C9' }}>{t('askBot.sectorDisclaimer')}</i>
                      )}
                      {questionTabs === t('tab.Fund') && (
                        <i style={{ color: '#2549C9' }}>{t('askBot.fundDisclaimer')}</i>
                      )}
                    </div>
                    <i>{t('askBot.disclaimer')}</i>
                  </Typography>
                ) : null}
                <form
                  onSubmit={handleQuestionSubmit}
                  id="askbotGridFormMain"
                  className={classes.inputContainer}
                >
                  <TextField
                    id="askbotGridFormMainText"
                    fullWidth
                    variant="outlined"
                    disabled={loadingAnswerStream}
                    placeholder={t('askBot.searchBar')}
                    className={classes.root}
                    value={chatSearchInput}
                    onInput={(e) => dispatch(setChatSearchInput(e.target.value))}
                    onFocus={() => showCreditExpire()}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      },
                      endAdornment: (
                        <IconButton
                          id="askbotGridFormMainIconButton"
                          className={classes.root}
                          style={{ padding: '0px' }}
                          disabled={loadingAnswerStream}
                          onClick={(event) => {
                            if (chatSearchInput !== '') {
                              handleQuestionClick(event, chatSearchInput);
                            }
                          }}
                        >
                          {loadingAnswerStream ? <LoadingDots /> : <PostQuestion />}
                        </IconButton>
                      )
                    }}
                  />
                  <div style={{ display: 'flex', overflowY: 'auto', paddingBottom: '3px' }}>
                    <div className={classes.tabClsQuestions} id="div-question-type">
                      <div>
                        <Button
                          className={`${
                            questionTabs == t('tab.Companies') ? classes.active : classes.btnColor
                          } ${classes.tabClsBtn}`}
                          variant="text"
                          style={{
                            fontFamily: 'Satoshi',
                            fontWeight: 500,
                            height: '100%'
                          }}
                          onClick={() => {
                            dispatch(setQuestionTabs(t('tab.Companies')));
                          }}
                        >
                          <span className={classes.spanText}>{t('tab.Companies')}</span>
                        </Button>
                      </div>
                      <div>
                        <Button
                          className={`${
                            questionTabs == t('tab.People') ? classes.active : classes.btnColor
                          } ${classes.tabClsBtn}`}
                          variant="text"
                          style={{ fontFamily: 'Satoshi', fontWeight: 500, height: '100%' }}
                          onClick={() => {
                            dispatch(setQuestionTabs(t('tab.People')));
                          }}
                        >
                          <span className={classes.spanText}>{t('tab.People')}</span>
                        </Button>
                      </div>
                      {currentUserPlan && currentUserPlan.fund_access === true && (
                        <div>
                          <Button
                            className={`${
                              questionTabs == t('tab.Fund') ? classes.active : classes.btnColor
                            } ${classes.tabClsBtn}`}
                            variant="text"
                            style={{
                              fontFamily: 'Satoshi',
                              fontWeight: 500,
                              height: '100%',
                              background: !(
                                currentUserPlan && currentUserPlan.fund_access === false
                              )
                                ? 'inetial'
                                : '#949494',
                              cursor: !(currentUserPlan && currentUserPlan.fund_access === false)
                                ? 'inetial'
                                : 'not-allowed'
                            }}
                            onClick={() => {
                              if (!(currentUserPlan && currentUserPlan.fund_access === false)) {
                                dispatch(setQuestionTabs(t('tab.Fund')));
                              } else {
                                null;
                              }
                            }}
                          >
                            <span className={classes.spanText}>{t('tab.Fund')}</span>
                            <sup
                              style={{
                                margin: '-4px 0px 8px 3px',
                                fontSize: '10px',
                                ...(questionTabs !== t('tab.Fund') && {
                                  backgroundImage:
                                    'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                                  WebkitBackgroundClip: 'text',
                                  WebkitTextFillColor: 'transparent',
                                  textDecoration: 'none'
                                })
                              }}
                            >
                              Beta
                            </sup>
                          </Button>
                        </div>
                      )}
                      {screenerEnabled === 'true' ? (
                        <div>
                          <Button
                            className={`${
                              questionTabs == t('tab.Screener') ? classes.active : classes.btnColor
                            } ${classes.tabClsBtn}`}
                            variant="text"
                            style={{ fontFamily: 'Satoshi', fontWeight: 500, height: '100%' }}
                            onClick={() => {
                              dispatch(setQuestionTabs(t('tab.Screener')));
                            }}
                          >
                            <span className={classes.spanText}>{t('tab.Screener')}</span>
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            className={`${
                              questionTabs == t('tab.Screener')
                                ? classes.active
                                : classes.btnColorCommingSoon
                            } ${classes.tabClsBtn}`}
                            variant="text"
                            style={{
                              backgroundColor: '#000000 !important',
                              fontFamily: 'Satoshi',
                              fontWeight: 500,
                              paddingRight: '30px',
                              color: '#AEAEAE !important',
                              pointerEvents: 'none',
                              height: '100%'
                            }}
                            disableRipple
                          >
                            <span style={{ color: '#AEAEAE', fontWeight: 500 }}>
                              {t('tab.Screener')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <div
                              style={{
                                position: 'absolute',
                                top: '0px',
                                right: '0px',
                                transform: 'rotate(0deg)',
                                padding: '0px'
                              }}
                            >
                              <div
                                style={{
                                  opacity: 1 /* Adjust the opacity level as needed */,
                                  padding: '0px 4px',
                                  fontSize: '9px',
                                  borderRadius: '0px 4px 0px 4px',
                                  color: '#5E5E5E',
                                  fontFamily: 'Satoshi'
                                }}
                              >
                                <span style={{ paddingBottom: '1px' }}>Coming Soon</span>
                              </div>
                            </div>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                <span className={classes.availableCredit} id="askbotGridFormMainSpan">
                  {availableCredits}{' '}
                  {credit === 0 ? (
                    <Link to={'/pricing'} target={'_self'} id="askbotGridFormMainLink">
                      here
                    </Link>
                  ) : null}
                </span>

                <span className={classes.availableCredit} id="askbotGridFormMainChspan">
                  {showZeroCreditText}
                </span>
                {matches ? (
                  <Typography
                    id="askbotGridFormMainChTypo"
                    style={{
                      paddingLeft: '0px',
                      fontSize: '14px',
                      color: '#000000B2',
                      display: 'grid'
                    }}
                    variant="body1"
                  >
                    <div style={{ display: 'none' }}>
                      {questionTabs === t('tab.Sector') && (
                        <i style={{ color: '#2549C9' }}>{t('askBot.sectorDisclaimer')}</i>
                      )}
                      {questionTabs === t('tab.Fund') && (
                        <i style={{ color: '#2549C9' }}>{t('askBot.fundDisclaimer')}</i>
                      )}
                    </div>
                    <i>{t('askBot.disclaimer')}</i>
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={0} md={0.3} id="askbotGridFormMainChEmptyGrid" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const [showCompaniesTab, setShowCompaniesTab] = useState(!!matches);
  const AskBotComp = ({ customWidth, setCompanyWidth }) => {
    const isCompanyDataPopulated = useSelector(
      (state) => state.tokenReducer.isCompanyDataPopulated
    );
    const isCompanyDataVisible = useSelector((state) => state.tokenReducer.isCompanyDataVisible);
    return (
      <>
        {isCompanyDataPopulated && isCompanyDataVisible && (
          <AskBotCompany
            showCompaniesTab={showCompaniesTab}
            setShowCompaniesTab={setShowCompaniesTab}
            matches={matches}
            setValue={setValue}
            customWidth={customWidth}
            setCompanyWidth={setCompanyWidth}
            getWatchlistData={getWatchlistData}
            requestMcaInfo={requestMcaInfo}
          />
        )}
      </>
    );
  };

  const AskBotChatComp = () => {
    const customWidth = '100% - 61.8%';
    return (
      <>
        <Grid
          id="askbotMain"
          container
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            maxWidth: '1440px',
            margin: '0 auto'
          }}
        >
          <AskBotGrid />
          <AskBotComp customWidth={customWidth} setCompanyWidth={setCompanyWidth} />
        </Grid>
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [currentUserPlan, setCurrentUserPlan] = useState([]);
  const getCurrentUserPlan = async () => {
    try {
      const getCurrentUserPlanUrl = `${process.env.REACT_APP_PAYMENT_API_URL}/user/plan/authorizer`;
      const { data } = await getApi(getCurrentUserPlanUrl);
      setCurrentUserPlan(data.data);
    } catch (error) {
      setCurrentUserPlan(null);
      console.error(error);
    }
  };
  useEffect(() => {
    getCurrentUserPlan();
  }, [auth]);
  const QuestionTabDropdown = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorElPortfolio, setAnchorElPortfolio] = useState(null);

    const handleClickPortfolio = (event) => {
      setAnchorElPortfolio(event.currentTarget);
    };

    const handleClosePortfolio = () => {
      setAnchorElPortfolio(null);
    };
    const openPortfolio = Boolean(anchorElPortfolio);
    return (
      <>
        {isMobile ? (
          <Button
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '1px solid #CFCFCF',
              borderRadius: '6px',
              textTransform: 'none',
              height: '30px'
            }}
            onClick={handleClickPortfolio}
          >
            <span
              style={{
                flex: 1,
                fontFamily: 'Satoshi',
                fontSize: '16px',
                color: '#000000B2',
                fontWeight: 700,
                margin: '0px 10px'
              }}
            >
              {questionTabs}
            </span>
            {openPortfolio ? <SeeLess /> : <SeeMore />}
          </Button>
        ) : (
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '25px',
              textTransform: 'none',
              height: '28px',
              width: '28px',
              padding: '0px',
              justifyContent: 'center',
              alignContent: 'center',
              border: '1px solid #7DA3FD',
              backgroundColor: '#E3EBFD',
              color: '#0C0C0C'
            }}
            onClick={handleClickPortfolio}
          >
            {openPortfolio ? (
              <KeyboardDoubleArrowUpIcon style={{ color: '#2549C9' }} />
            ) : (
              <KeyboardDoubleArrowDownIcon style={{ color: '#2549C9' }} />
            )}
          </button>
        )}
        <Menu
          open={openPortfolio}
          anchorEl={anchorElPortfolio}
          onClose={handleClosePortfolio}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          className={classes.menuDiv}
        >
          <MenuItem
            onClick={() => {
              dispatch(setQuestionTabs(t('tab.Companies')));
              navigate('/askbyz');
              setValue(0);
            }}
            style={{
              fontFamily: 'Satoshi',
              fontWeight: 700,
              fontSize: '16px',
              color: '#171717'
            }}
          >
            <span
              style={{
                backgroundImage:
                  questionTabs === t('tab.Companies')
                    ? 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
                    : 'linear-gradient(to right, #171717 0%, #171717 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              {t('tab.Companies')}
            </span>
          </MenuItem>
          {/* <MenuItem
              // onClick={() => {
              //   dispatch(setQuestionTabs(t('tab.Sector')));
              //   navigate('/askbyz');
              //   setValue(0);
              // }}
              onClick={() => {
                if (!(currentUserPlan && currentUserPlan.sector_data_access === false)) {
                  dispatch(setQuestionTabs(t('tab.Sector')));
                  navigate('/askbyz');
                  setValue(0);
                } else {
                  null;
                }
              }}
              style={{
                fontFamily: 'Satoshi',
                fontWeight: 700,
                fontSize: '16px',
                color: '#171717',
                background: !(currentUserPlan && currentUserPlan.sector_data_access === false)
                  ? 'inetial'
                  : '#949494',
                cursor: !(currentUserPlan && currentUserPlan.sector_data_access === false)
                  ? 'inetial'
                  : 'not-allowed'
              }}
            >
              <span
                style={{
                  backgroundImage:
                    questionTabs === t('tab.Sector')
                      ? 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
                      : 'linear-gradient(to right, #171717 0%, #171717 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                {t('tab.Sector')}
              </span>
            </MenuItem> */}
          <MenuItem
            onClick={() => {
              dispatch(setQuestionTabs(t('tab.People')));
              navigate('/askbyz');
              setValue(0);
            }}
            style={{
              fontFamily: 'Satoshi',
              fontWeight: 700,
              fontSize: '16px',
              color: '#171717'
            }}
          >
            <span
              style={{
                backgroundImage:
                  questionTabs === t('tab.People')
                    ? 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
                    : 'linear-gradient(to right, #171717 0%, #171717 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              {t('tab.People')}
            </span>
          </MenuItem>
          {currentUserPlan && currentUserPlan.fund_access === true && (
            <MenuItem
              // onClick={() => {
              //   dispatch(setQuestionTabs(t('tab.Fund')));
              //   navigate('/askbyz');
              //   setValue(0);
              // }}
              onClick={() => {
                if (!(currentUserPlan && currentUserPlan.fund_access === false)) {
                  dispatch(setQuestionTabs(t('tab.Fund')));
                  navigate('/askbyz');
                  setValue(0);
                } else {
                  null;
                }
              }}
              style={{
                fontFamily: 'Satoshi',
                fontWeight: 700,
                fontSize: '16px',
                color: '#171717',
                background: !(currentUserPlan && currentUserPlan.fund_access === false)
                  ? 'inetial'
                  : '#949494',
                cursor: !(currentUserPlan && currentUserPlan.fund_access === false)
                  ? 'inetial'
                  : 'not-allowed'
              }}
            >
              <span
                style={{
                  backgroundImage:
                    questionTabs === t('tab.Fund')
                      ? 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
                      : 'linear-gradient(to right, #171717 0%, #171717 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                {t('tab.Fund')}
              </span>
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  style={{
                    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
                    opacity: 0.9 /* Adjust the opacity level as needed */,
                    padding: '0px 6px',
                    fontSize: '9px',
                    borderRadius: '0px 0px 0px 4px',
                    color: '#FFFFFF',
                    fontFamily: 'Satoshi'
                  }}
                >
                  Beta
                </div>
              </div>
            </MenuItem>
          )}
          {screenerEnabled === 'true' ? (
            <MenuItem
              onClick={() => {
                dispatch(setQuestionTabs(t('tab.Screener')));
                navigate('/askbyz');
                setValue(0);
              }}
              style={{
                fontFamily: 'Satoshi',
                fontWeight: 700,
                fontSize: '16px',
                color: '#171717'
              }}
            >
              <span
                style={{
                  backgroundImage:
                    questionTabs === t('tab.Screener')
                      ? 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
                      : 'linear-gradient(to right, #171717 0%, #171717 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                {t('tab.Screener')}
              </span>
            </MenuItem>
          ) : (
            <MenuItem
              disableRipple
              style={{
                fontFamily: 'Satoshi',
                fontWeight: 700,
                fontSize: '16px',
                color: '#171717',
                background: '#ccc',
                pointerEvents: 'none',
                paddingTop: '10px'
              }}
            >
              {t('tab.Screener')}
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  style={{
                    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
                    opacity: 0.9 /* Adjust the opacity level as needed */,
                    padding: '0px 6px',
                    fontSize: '9px',
                    borderRadius: '0px 0px 0px 4px',
                    color: '#FFFFFF',
                    fontFamily: 'Satoshi'
                  }}
                >
                  Coming Soon
                </div>
              </div>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };
  const TabChatComp = () => {
    return (
      <>
        <Grid item xs={12} style={{ maxWidth: '1440px', margin: '0 auto' }} id="tabChatCompMain">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <Tabs value={value} onChange={handleChange} id="tabChatCompMainTabs">
                <Tab
                  id="tabChatCompMainTab1"
                  value={0}
                  label="Chat"
                  onClick={() => {
                    setShowCompaniesTab(true);
                  }}
                />
                <Tab
                  id="tabChatCompMainTab2"
                  value={1}
                  label="Selected Companies"
                  onClick={() => {
                    setShowCompaniesTab(true);
                  }}
                />
              </Tabs>
            </div>
            <div style={{ paddingRight: isMobile ? '20px' : '5px', display: 'none' }}>
              <QuestionTabDropdown />
            </div>
          </div>
        </Grid>
        {value == 0 ? <AskBotGrid PaymentComponent={PaymentSuccess} /> : <AskBotComp />}
      </>
    );
  };

  return (
    <>
      <ErrorToast errorMessage={mcaErrorMessage} />
      <CreditWarning openDialog={openDialog} setOpenDialog={setOpenDialog} />
      {matches ? <TabChatComp /> : <AskBotChatComp />}
    </>
  );
};

export default AskBot;
