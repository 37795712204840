import { makeStyles } from '@material-ui/core/styles';

export const useCommonStyles = makeStyles(() => ({
  fontFamilyCls: {
    fontFamily: 'Satoshi!important'
  },
  fontFamilyBold: {
    fontFamily: 'Satoshi!important',
    fontWeight: 'bold!important'
  },
  fontFamilyBoldSize: {
    fontFamily: 'Satoshi!important',
    fontWeight: 'bold!important',
    fontSize: '16px!important'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  }
}));
