import { Paper, Tooltip, Typography, makeStyles } from '@material-ui/core';
import CustomTextField from '../../../atoms/textfield';
import { Autocomplete, IconButton } from '@mui/material';
import { Add, Close, DragIndicator } from '@material-ui/icons';
import CustomSelect from '../../../atoms/select';
import TertiaryButton from '../../../atoms/buttons/tertiaryBtn';
import { useTranslation } from 'react-i18next';
// import InputError from '../../../atoms/inputError';
import { ContentCopy, ContentPaste } from '@mui/icons-material';
import { questionTypes, questionLib } from '../../tearSheet-V1/tearSheetTemplateMocks';
import ErrorSpan from '../../../atoms/errorSpan';

const useStyles = makeStyles(() => ({
  mainDiv: {
    padding: '14px',
    position: 'relative',
    marginBottom: '10px'
  },
  sectionLevelOne: {
    display: 'flex',
    width: '100%'
  },
  closeSectionBtn: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  keyDiv: {
    width: '14%',
    margin: '18px 0px 0px'
  },
  textDiv: {
    width: '56%',
    margin: '16px 0px 0px'
  },
  keyTypography: {
    fontSize: '18px',
    marginTop: '4px'
  },
  questionsDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 0px 0px'
  },
  selectDiv: {
    width: '14%',
    marginLeft: '2%'
  },
  closeQuestionDiv: {
    '& button': {
      padding: '7px',
      marginLeft: '5px',
      margin: '16px 0px 0px'
    }
  },
  addQuestionBtn: {
    margin: '0 14% 0 auto'
  },
  dragDiv: {
    position: 'absolute',
    top: '-4px',
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  dragIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(90deg)',
    cursor: 'all-scroll !important'
  },
  questionAutocomplete: {
    '& .MuiInputBase-input': {
      height: '7px !important'
    }
  }
}));
const TemplateSectionCard = ({ obj, handleChange, isDiabledAll, errorObject }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const updateSection = (index, prop, value) => {
    const questionGroups = [...obj.question_groups];
    questionGroups[index][prop] = value;
    handleChange('question_groups', questionGroups);
  };

  const handleAddQuestion = (i) => {
    const questionGroups = [...obj.question_groups];
    questionGroups[i].questions.push({
      question: '',
      type: 'query',
      question_type: ''
    });
    handleChange('question_groups', questionGroups);
  };

  const updateQuestion = (pindex, index, prop, value) => {
    const questionGroups = [...obj.question_groups];
    questionGroups[pindex].questions[index][prop] = value;
    handleChange('question_groups', questionGroups);
  };

  const removeQuestion = (pindex, index) => {
    const questionGroups = [...obj.question_groups];
    if (questionGroups[pindex].questions.length > 1) {
      questionGroups[pindex].questions = questionGroups[pindex].questions.filter(
        (x, ind) => index !== ind
      );
      handleChange('question_groups', questionGroups);
    }
  };

  const removeSection = (i) => {
    let questionGroups = [...obj.question_groups];
    if (questionGroups.length > 1) {
      questionGroups = questionGroups.filter((x, ind) => i !== ind);
      handleChange('question_groups', questionGroups);
    }
  };

  return (
    <>
      {obj?.question_groups &&
        obj?.question_groups.length > 0 &&
        obj?.question_groups.map((section, i) => {
          const currentSection =
            (errorObject?.question_groups && errorObject?.question_groups[i]) || null;
          return (
            <Paper fullwidth key={i} className={classes.mainDiv} elevation={3}>
              <div className={classes.dragDiv}>
                <span className={classes.dragIcon}>
                  <DragIndicator />
                </span>
              </div>
              <div className={classes.closeSectionBtn}>
                <Tooltip title="Copy Section">
                  <IconButton disabled={true}>
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Paste Section">
                  <IconButton disabled>
                    <ContentPaste />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove Section">
                  <IconButton
                    disabled={isDiabledAll}
                    onClick={() => {
                      removeSection(i);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.sectionLevelOne}>
                <div className={classes.keyDiv}>
                  <Typography className={classes.keyTypography}>Heading</Typography>
                </div>
                <div className={classes.textDiv}>
                  <CustomTextField
                    disabled={isDiabledAll}
                    placeholder="Section Heading Placeholder"
                    value={section.name}
                    handleChange={(value) => {
                      updateSection(i, 'name', value);
                    }}
                  />
                  <ErrorSpan value={currentSection?.name} />
                </div>
              </div>
              {section?.questions &&
                section.questions.length > 0 &&
                section.questions.map((quest, ind) => {
                  const currentQuestion = currentSection?.questions[ind] || null;
                  return (
                    <div key={ind} className={classes.questionsDiv}>
                      <div className={classes.sectionLevelOne}>
                        <div className={classes.keyDiv}>
                          <Typography className={classes.keyTypography}>Question</Typography>
                        </div>
                        <div className={classes.textDiv}>
                          <Autocomplete
                            freeSolo
                            className={classes.questionAutocomplete}
                            value={quest?.question}
                            options={questionLib.map((option) => option.question)}
                            onInputChange={(event, value) =>
                              updateQuestion(i, ind, 'question', value)
                            }
                            renderInput={(params) => (
                              <form noValidate>
                                <CustomTextField
                                  params={params}
                                  placeholder={t('tearSheet.questionPlaceholder')}
                                  label="Byz Question"
                                  errorMessage={''}
                                />
                              </form>
                            )}
                            disabled={isDiabledAll}
                            disableClearable
                            autoHighlight
                            autoComplete
                          />
                          <ErrorSpan value={currentQuestion?.question} />
                        </div>
                        <div className={classes.selectDiv}>
                          <CustomSelect
                            value={quest?.question_type}
                            options={questionTypes}
                            handleChange={(value) => updateQuestion(i, ind, 'question_type', value)}
                            label={t('tearSheet.questionTypeLabel')}
                            placeholder={t('tearSheet.questionTypePlaceholder')}
                            errorMessage={''}
                            disabled={isDiabledAll}
                          />
                          <ErrorSpan value={currentQuestion?.question_type} />
                        </div>
                        <div className={classes.closeQuestionDiv}>
                          <IconButton
                            disabled={isDiabledAll}
                            onClick={() => {
                              removeQuestion(i, ind);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className={classes.sectionLevelOne}>
                <div className={classes.addQuestionBtn}>
                  <TertiaryButton
                    disabled={isDiabledAll}
                    handleClick={() => {
                      handleAddQuestion(i);
                    }}
                    btnText={t('tearSheet.addQuestionBtn')}
                    StartIcon={Add}
                  />
                </div>
              </div>
            </Paper>
          );
        })}
    </>
  );
};

export default TemplateSectionCard;
