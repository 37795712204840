import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { setFilter, setPreviousFilter } from '../../action';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function AppeliedFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterPreviousRedux = useSelector((state) => state.tokenReducer.filter);
  const filterInput = useSelector((state) => state.tokenReducer.filterInput);
  const [filterPreviousReduxHeadder, setFilterPreviousReduxHeadder] = useState(filterPreviousRedux);
  let updatedFilterInput;
  if (filterInput) {
    updatedFilterInput = filterInput.map((obj) => ({
      attribute: obj.field,
      fieldName: obj.fieldName
    }));
  }

  useEffect(() => {
    if (filterPreviousRedux) {
      if (filterInput) {
        const attributeToField = updatedFilterInput.reduce((obj, item) => {
          obj[item.attribute] = item.fieldName;
          return obj;
        }, {});

        const updatedFilterRedux = filterPreviousRedux.map((item) => {
          const fieldName = attributeToField[item.attribute];
          return { ...item, fieldName };
        });
        setFilterPreviousReduxHeadder(updatedFilterRedux);
      }
    } else {
      setFilterPreviousReduxHeadder(filterPreviousRedux);
    }
  }, [filterPreviousRedux]);

  const deleteChange = (value, field) => {
    const index = filterPreviousRedux.findIndex((condition) => condition.attribute === field);
    if (index === -1) {
      return;
    }

    const condition = filterPreviousRedux[index];
    const newValues = condition.values.filter((v) => v.key !== value);

    const newArray = [...filterPreviousRedux];
    if (newValues.length === 0) {
      newArray.splice(index, 1);
      dispatch(setFilter(newArray));
      dispatch(setPreviousFilter(newArray));
    } else {
      const newCondition = { ...condition, values: newValues };
      newArray[index] = newCondition;
      dispatch(setFilter(newArray));
      dispatch(setPreviousFilter(newArray));
    }
  };

  const handleClearAll = () => {
    dispatch(setFilter([]));
    dispatch(setPreviousFilter([]));
  };

  return (
    <div>
      {filterPreviousReduxHeadder.length ? (
        <>
          <div style={{ paddingTop: '10px' }}></div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography
              style={{ fontWeight: 700, fontSize: '14px', display: 'flex', alignItems: 'center' }}
            >
              {t('appliedFilter.showing')}
            </Typography>
            {filterPreviousReduxHeadder.map((filter) => (
              <div key={filter.attribute} style={{ display: 'flex', alignItems: 'center' }}>
                {filter.fieldName !== 'Name' && (
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: '14px',
                      marginRight: '10px',
                      marginLeft: '5px'
                    }}
                  >
                    {filter.fieldName}
                  </Typography>
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {filter.values.map((value) => (
                    <Chip
                      key={value.key}
                      label={value.value}
                      style={{
                        borderRadius: '2px',
                        margin: '7px 3px',
                        backgroundColor: '#E8F0FE',
                        color: '#004CFF'
                      }}
                      onDelete={() => deleteChange(value.key, filter.attribute)}
                      deleteIcon={<CloseIcon />}
                    />
                  ))}
                </div>
              </div>
            ))}
            <Button
              size="small"
              onClick={handleClearAll}
              style={{
                textTransform: 'none',
                color: '#004CFF',
                fontSize: '15px',
                backgroundColor: 'transparent',
                '&:hover': { backgroundColor: 'transparent' }
              }}
            >
              {t('appliedFilter.clearAll')}
            </Button>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default AppeliedFilters;
