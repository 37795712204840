import React, { useContext } from 'react';
import { Typography, Button, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext, AuthStatus } from '../auth/contexts/authContext';

const useStyles = makeStyles((theme) => ({
  displayColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonLink: {
    'font-family': 'Satoshi',
    'font-size': '16px',
    'font-style': 'normal',
    'font-weight': '500',
    padding: '0!important',
    'text-transform': 'none',
    '& span': {
      'align-items': 'start',
      'justify-content': 'start'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  pd10Bottom: {
    paddingBottom: '10px!important',
    marginTop: '10px'
  },
  typographyCls: {
    'font-family': 'Satoshi',
    'font-size': '22px',
    'font-style': 'normal',
    'font-weight': '700',
    'line-height': '28px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  pd5: {
    padding: '5px'
  },
  firstDivImgIcon: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center'
  },
  logo: {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'auto'
    },
    opacity: 1
  },
  displayFlex: {
    display: 'flex'
  }
}));

const FooterList = ({ footerListProps }) => {
  const classes = useStyles();
  const { Heading, children } = footerListProps;
  return (
    <div className={classes.displayColumn}>
      <Heading />
      <div className={classes.displayColumn}>
        {children &&
          children.length > 0 &&
          children.map((x, i) => {
            return (
              <Button key={i} className={classes.buttonLink} onClick={x.onClick}>
                {x.name}
              </Button>
            );
          })}
      </div>
    </div>
  );
};

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authStatus } = useContext(AuthContext);

  const CompanyListHeading = () => {
    return (
      <div className={`${classes.pd10Bottom} ${classes.displayFlex}`}>
        <Typography className={classes.typographyCls}>{t('homepage.company')}</Typography>
        <a
          className={classes.pd5}
          target="_blank"
          href="https://www.linkedin.com/company/privateblok/"
          rel="noreferrer"
        >
          <img src="/images/icons/Linkedin - Icon.svg" alt="linkedin" />
        </a>
      </div>
    );
  };

  const LegacyListHeading = () => {
    return (
      <div className={classes.pd10Bottom}>
        <Typography className={classes.typographyCls}>{t('homepage.Legal')}</Typography>
      </div>
    );
  };

  const companylistObj = {
    Heading: CompanyListHeading,
    children: [
      {
        name: t('homepage.aboutUs'),
        onClick: () => {
          window.scroll(0, 0);
          navigate('/about');
        }
      },
      {
        name: t('homepage.careers'),
        onClick: () => {
          window.open('https://www.linkedin.com/company/privateblok/jobs/', '_blank');
        }
      }
    ]
  };

  const isLoggedIn = authStatus == AuthStatus.SignedIn;

  if (isLoggedIn) {
    companylistObj.children.push({
      name: t('homepage.helpCentre'),
      onClick: () => {
        navigate('/help-center');
      }
    });
  }

  const legallistObj = {
    Heading: LegacyListHeading,
    children: [
      {
        name: t('homepage.terms'),
        onClick: () => {
          navigate('/terms-and-conditions');
          // window.open('https://public.privateblok.ai/policies/terms_of_use.html', '_blank');
        }
      },
      {
        name: t('homepage.privacy'),
        onClick: () => {
          navigate('/privacy');
          // window.open('https://public.privateblok.ai/policies/privacy_policy.html', '_blank');
        }
      }
    ]
  };
  return (
    <Grid
      container
      sx={{
        spacing: { xs: 1, sm: 1, md: 10 }
      }}
      className={classes.pd10Bottom}
    >
      <Grid item md={4} sm={5} xs={12} className={classes.firstDivImgIcon}>
        <IconButton className={classes.logo} disableTouchRipple>
          <img src="/images/PBLogo.png" alt="PrivateBlok-Logo" className="homepg-logo" />
        </IconButton>
      </Grid>
      <Grid item md={2} sm={1} xs={1}></Grid>
      <Grid item md={3} sm={3} xs={7} className={classes.pd10Bottom}>
        <FooterList footerListProps={{ ...companylistObj }} />
      </Grid>
      <Grid item md={3} sm={2} xs={3} className={classes.pd10Bottom}>
        <FooterList footerListProps={{ ...legallistObj }} />
      </Grid>
    </Grid>
  );
};
export default Footer;
