import { useNavigate } from 'react-router-dom';
import { ReactComponent as NoFilesIcon } from '../../../components/icons/NoFilesIcon.svg';
import { Typography, makeStyles } from '@material-ui/core';
import { dateFormat, convertBytes, getDisplayFileName } from '../../utils/utils';
import { ReactComponent as InfoIcon } from '../../icons/InfoIcon.svg';
import { ReactComponent as InfoIconHover } from '../../icons/InfoIconHover.svg';
import { ReactComponent as DeleteBinIcon } from '../../icons/DeleteBinIcon.svg';
import { ReactComponent as InfoIconDisabled } from '../../icons/InfoIconDisabled.svg';
import FileTags from './fileTags';
import DocDetailsPopUp from './docDetailsPopUp';
import StatusChip from './statusChip';
import { Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HoverLink from '../../atoms/hoverLink';

const useStyles = makeStyles(() => ({
  infoIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    paddingRight: '5px'
  },
  iconHover: {
    display: 'none'
  },
  infoIconDisabled: {
    cursor: 'auto'
  },
  tableCellProcessedAtStyle: {
    width: '100px !important'
  }
}));

export const NoFilesUploadedTableComp = ({ isFilterShow }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: '60px 0px'
      }}
    >
      <NoFilesIcon style={{ alignSelf: 'center' }} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {isFilterShow ? (
          <Typography
            style={{
              fontFamily: 'Satoshi',
              fontSize: '14px',
              color: '#171717',
              fontWeight: 700,
              paddingTop: '20px'
            }}
          >
            No files available matching your filter criteria.
          </Typography>
        ) : (
          <Typography
            style={{
              fontFamily: 'Satoshi',
              fontSize: '14px',
              color: '#171717',
              fontWeight: 500,
              paddingTop: '20px'
            }}
          >
            No files uploaded yet.{' '}
            <span
              style={{
                color: '#004CFF',
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
              onClick={() => {
                navigate(`/document-upload/upload`);
              }}
            >
              Upload Now
            </span>
          </Typography>
        )}
      </div>
    </div>
  );
};

export const FileNameCellComp = ({ row }) => (
  <HoverLink
    href={row?.source_link}
    target="_blank"
    displayText={getDisplayFileName(row.file_name, row.file_format)}
  />
);
export const FileStatusCellComp = ({ row }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <StatusChip
        status={row.status}
        exception={row.subdoc_ingestion_failure ? handleOpen : null}
      />
      <DocDetailsPopUp
        row={row}
        open={open}
        handleClose={handleClose}
        typeOfTable="subDocFailures"
      />
    </>
  );
};
export const FileTagsCellComp = ({
  row,
  allCompanies,
  allSectors,
  setIngestionDataTags,
  allIngestionData,
  allIngestionDataTags
}) => (
  <FileTags
    file={row}
    allCompanies={allCompanies}
    allSectors={allSectors}
    setIngestionDataTags={setIngestionDataTags}
    allIngestionDataTags={allIngestionDataTags}
    allIngestionData={allIngestionData}
  />
);
export const FileProcessedAtCellComp = ({ row }) => {
  const [open, setOpen] = useState(false);
  const iconRef = useRef(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleMouseEnter = () => {
    iconRef.current.querySelector('#hoverIcon').style.display = 'block';
    iconRef.current.querySelector('#icon').style.display = 'none';
  };
  const handleMouseLeave = () => {
    iconRef.current.querySelector('#hoverIcon').style.display = 'none';
    iconRef.current.querySelector('#icon').style.display = 'block';
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {row?.status === t('docLib.ingestedStatus') ? (
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={iconRef}>
            <Tooltip title={t('list.ingestionMetricsTooltip')} placement="top">
              <InfoIconHover
                className={`${classes.infoIcon} ${classes.iconHover}`}
                onClick={handleOpen}
                id="hoverIcon"
              />
              <InfoIcon className={classes.infoIcon} id="icon" />
            </Tooltip>
          </div>
        ) : (
          <InfoIconDisabled className={`${classes.infoIcon} ${classes.infoIconDisabled}`} />
        )}
        <div className={classes.tableCellProcessedAtStyle}>{dateFormat(row.updated_at)}</div>
      </div>
      <DocDetailsPopUp
        row={row}
        open={open}
        handleClose={handleClose}
        typeOfTable="ingestionMetrics"
      />
    </>
  );
};
export const FileSizeCellComp = ({ row }) => convertBytes(row.file_size);
export const FileFormatCellComp = ({ row }) => row.file_format;
export const FileDeleteCellComp = ({ row, setDeleteDocinfo }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('list.deleteTooltip')} placement="top">
      <DeleteBinIcon
        onClick={() => {
          setDeleteDocinfo(row);
        }}
        style={{ cursor: 'pointer' }}
      />
    </Tooltip>
  );
};
