import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  availableCredit: {
    'padding-bottom': '6px',
    'font-size': '14px',
    color: 'rgba(0, 0, 0, 0.698)',
    'text-align': 'center',
    display: 'block'
  }
}));

const Limits = ({ limitsData }) => {
  const credit =
    (limitsData?.limits &&
      limitsData?.limits.length > 0 &&
      limitsData.limits.find((x) => x.type === 'TEARSHEET_RUN')?.remaining_limits) ||
    0;

  const classes = useStyles();

  return (
    <span className={classes.availableCredit}>
      {credit === 0
        ? 'Your credits have been consumed. Please purchase additional credits to continue.'
        : `${credit} credits remaining.`}{' '}
      {credit === 0 ? (
        <Link to={'/pricing'} target={'_self'}>
          here
        </Link>
      ) : null}
    </span>
  );
};

export default Limits;
