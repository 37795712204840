import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { setPreviousFilter } from '../../action';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { Grid } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import SearchComp from '../../../atoms/searchComp';

function ExternalFilter() {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.tokenReducer.filterInput);
  const filterPreviousRedux = useSelector((state) => state.tokenReducer.previousFilter);
  const [filterCondition, setFilterCondition] = useState(filterPreviousRedux);

  const handleChange = (event, values, field) => {
    setFilterCondition((oldArray) => {
      const newArray = [...oldArray];
      const index = newArray.findIndex((item) => item.attribute === field);

      if (index !== -1) {
        if (values.length === 0) {
          // Remove the entire filter condition when all values are unselected
          newArray.splice(index, 1);
        } else {
          // Update the values for the existing filter condition
          newArray[index] = {
            attribute: field,
            operator: 'IN',
            values: values
          };
        }
      } else {
        // Add a new filter condition if it doesn't exist
        newArray.push({
          attribute: field,
          operator: 'IN',
          values: values
        });
      }

      return newArray;
    });
  };

  const deleteChange = (value, field) => {
    const index = filterCondition.findIndex((condition) => condition.attribute === field);
    if (index === -1) {
      return;
    }

    const condition = filterCondition[index];
    const newValues = condition.values.filter((v) => v.key !== value);

    if (newValues.length === 0) {
      setFilterCondition((prevCondition) => prevCondition.filter((c) => c.attribute !== field));
    } else {
      const newCondition = { ...condition, values: newValues };
      const newFilterCondition = [...filterCondition];
      newFilterCondition[index] = newCondition;
      setFilterCondition(newFilterCondition);
    }
  };

  useEffect(() => {
    dispatch(setPreviousFilter(filterCondition));
  }, [filterCondition]);

  return (
    <>
      <br />
      {filterData ? (
        <>
          {filterData.map(({ field, result, fieldName }) => {
            let matchingCondition = filterCondition.find(
              (condition) => condition.attribute === field
            );
            let selectedValues = matchingCondition ? matchingCondition.values : [];
            return (
              <>
                <br />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2}>
                    <Typography style={{ fontWeight: 550, fontSize: '15px' }}>
                      {fieldName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <SearchComp
                      placeholder={fieldName}
                      handleChange={(event, value) => handleChange(event, value, field)}
                      options={result}
                      selectedValues={selectedValues}
                      chip={true}
                      handleDeleteChip={(value) => deleteChange(value, field)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ bgcolor: '#b1c8ff' }} />
                  </Grid>
                </Grid>
                <br />
              </>
            );
          })}
        </>
      ) : (
        <>
          <br />
          <LinearProgress />
          <br />
          <br />
        </>
      )}
    </>
  );
}

export default ExternalFilter;
