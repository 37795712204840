import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  Checkbox
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import BusinessIcon from '@material-ui/icons/Business';
import { useTranslation } from 'react-i18next';
import ErrorToast from '../../atoms/errorToast';

const FavCompanyDialogPopup = ({ open, onClose, company, saveWatchlistCompany, classes }) => {
  const listEndRef = useRef(null);
  const watchlistData = company?.watchlistData;
  const { t } = useTranslation();

  const [watchlists, setWatchlists] = useState([]);
  const [isNewWatchlistActive, setNewWatchlistActive] = useState(false);
  const [newWatchlist, setNewWatchlist] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const watchlists = watchlistData?.watchlists || [];
    setWatchlists(watchlists);
  }, [watchlistData]);

  useEffect(() => {
    if (listEndRef.current) {
      listEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [watchlists]);

  const addNewWatchlist = () => {
    if (newWatchlist) {
      const isExisting = watchlists.some(
        (watchlist) => watchlist.name.toLowerCase() === newWatchlist.toLowerCase()
      );

      if (!isExisting) {
        const randomWatchlistId = uuidv4();
        const currentWatchlist = {
          watchlist_id: randomWatchlistId,
          name: newWatchlist,
          company_in_watchlist: true,
          is_new_watchlist: true
        };
        setWatchlists([...watchlists, currentWatchlist]);
        setNewWatchlist('');
        setNewWatchlistActive(false);
      } else {
        setErrorMessage('Watchlist name already exists. Please try a different name.');
      }
    }
  };

  const toggleWatchlistStatus = (comp) => {
    const newWatchlists = watchlists.map((x) => {
      if (comp.watchlist_id === x.watchlist_id) {
        return {
          ...x,
          company_in_watchlist: !x.company_in_watchlist
        };
      }
      return x;
    });
    setWatchlists(newWatchlists);
  };

  useEffect(() => {
    const errorTimer = setTimeout(() => {
      setErrorMessage(null);
    }, 5000);

    return () => {
      clearTimeout(errorTimer);
    };
  }, [errorMessage]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClick = async () => {
    setIsButtonDisabled(true);
    try {
      await saveWatchlistCompany(watchlists);
    } catch (error) {
      //error
    } finally {
      setIsButtonDisabled(false); // Re-enable the button if needed
    }
  };

  return (
    // <Dialog
    //   open={open}
    //   onClose={onClose}
    //   fullWidth={false}
    //   maxWidth={false}
    //   PaperProps={{
    //     style: {
    //       width: 450,
    //       borderRadius: 24
    //     }
    //   }}
    // >
    //   <Box width="100%">
    //     <DialogContent style={{ padding: '20px' }}>
    //       <div style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
    //         <div style={{ width: '60px', display: 'flex', justifyContent: 'center' }}>
    //           {company.logo ? (
    //             <img
    //               src={company.logo}
    //               alt="Logo"
    //               style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
    //             />
    //           ) : (
    //             <BusinessIcon style={{ maxWidth: '80%', maxHeight: '80%', objectFit: 'contain' }} />
    //           )}
    //         </div>
    //         <div
    //           style={{
    //             paddingLeft: '15px',
    //             fontWeight: '600',
    //             display: 'flex',
    //             alignItems: 'center'
    //           }}
    //         >
    //           <p style={{ fontSize: '20px', fontWeight: '600' }}>{company.name}</p>
    //         </div>
    //         <div style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }}>
    //           <CloseIcon style={{ fontSize: '34px' }} onClick={onClose} />
    //         </div>
    //       </div>
    //       <br />
    //       <div
    //         style={{
    //           display: 'flex',
    //           justifyContent: isNewWatchlistActive ? 'space-between' : 'flex-end'
    //         }}
    //       >
    //         {isNewWatchlistActive && (
    //           <TextField
    //             fullWidth
    //             variant="outlined"
    //             size="small"
    //             label="Watchlist"
    //             value={newWatchlist}
    //             onChange={(e) => setNewWatchlist(e.target.value)}
    //           />
    //         )}
    //         {isNewWatchlistActive ? (
    //           <Button
    //             style={{ background: 'linear-gradient(90deg,#06BCC1,#004CFF)', color: '#FFFFFF' }}
    //             onClick={addNewWatchlist}
    //           >
    //             <Typography>Done</Typography>
    //           </Button>
    //         ) : (
    //           <Button
    //             style={{ background: 'linear-gradient(90deg,#06BCC1,#004CFF)', color: '#FFFFFF' }}
    //             onClick={() => setNewWatchlistActive(true)}
    //           >
    //             <Typography>New Watchlist</Typography>
    //           </Button>
    //         )}
    //       </div>
    //       <div>
    //         <List>
    //           {watchlists.map((comp) => (
    //             <ListItem key={comp.watchlist_id}>
    //               <Checkbox
    //                 style={{ color: '#004CFF' }}
    //                 checked={comp.company_in_watchlist}
    //                 onChange={() => toggleWatchlistStatus(comp)}
    //               />
    //               <Typography>{comp.name}</Typography>
    //             </ListItem>
    //           ))}
    //           <div ref={listEndRef}></div>
    //         </List>
    //       </div>
    //       <div
    //         style={{
    //           paddingTop: '10px',
    //           display: 'flex',
    //           alignItems: 'flex-end',
    //           paddingRight: '12px'
    //         }}
    //       >
    //         <Button onClick={onClose}>
    //           <Typography style={{ fontSize: '14px' }}>Cancel</Typography>
    //         </Button>
    //         <div style={{ paddingLeft: '16px' }}></div>
    //         <Button
    //           style={{ background: 'linear-gradient(90deg,#06BCC1,#004CFF)', color: '#FFFFFF' }}
    //           onClick={() => saveWatchlistCompany(watchlists)}
    //         >
    //           <Typography style={{ fontSize: '14px' }}>Save</Typography>
    //         </Button>
    //       </div>
    //     </DialogContent>
    //   </Box>
    // </Dialog>
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      classes={{ paper: classes.dialogPaper }}
      fullWidth={false}
      maxWidth={false}
      PaperProps={{
        style: {
          width: 450,
          borderRadius: 24
        }
      }}
    >
      <Box width="100%">
        <DialogContent
          style={{
            paddingLeft: '20px',
            paddingTop: '30px',
            paddingRight: '20px',
            paddingBottom: '20px'
          }}
        >
          <>
            <ErrorToast errorMessage={errorMessage} />
            <div
              style={{
                display: 'flex',
                height: '60px',
                width: '100%',
                position: 'relative',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '60px'
                }}
              >
                {company.data?.base?.Logo ? (
                  <img
                    src={company.data?.base?.Logo}
                    alt="Logo"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }}
                  />
                ) : (
                  <BusinessIcon
                    style={{
                      maxWidth: '80%',
                      maxHeight: '80%',
                      objectFit: 'contain'
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  fontFamily: 'Satoshi',
                  paddingLeft: '15px',
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <p
                  style={{
                    fontFamily: 'Satoshi',
                    fontSize: '20px',
                    fontWeight: '600'
                  }}
                >
                  {company.data?.base?.Name}
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  fontSize: '20px',
                  margin: 0,
                  padding: 0,
                  cursor: 'pointer'
                }}
              >
                <CloseIcon style={{ fontSize: '34px' }} onClick={onClose} />
              </div>
            </div>
            <br />
            <div
              className={classes.btnNewWt}
              style={
                isNewWatchlistActive
                  ? { justifyContent: 'space-between' }
                  : { justifyContent: 'flex-end' }
              }
            >
              {isNewWatchlistActive && (
                <div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label={'Watchlist'}
                    value={newWatchlist}
                    onChange={(e) => {
                      setNewWatchlist(e.target.value);
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#08070d',
                        opacity: 0.8
                      }
                    }}
                  />
                </div>
              )}
              {isNewWatchlistActive ? (
                <div>
                  <Button
                    style={{
                      background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
                      color: '#FFFFFF',
                      textTransform: 'none'
                    }}
                    onClick={addNewWatchlist}
                  >
                    <Typography className={classes.btnWtp}>{t('watchlist.Done')}</Typography>
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    onClick={() => {
                      setNewWatchlistActive(true);
                    }}
                    style={{
                      background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
                      color: '#FFFFFF',
                      textTransform: 'none'
                    }}
                  >
                    <Typography className={classes.btnWtp}>
                      {t('watchlist.newWatchlist')}
                    </Typography>
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.watchlistCls}>
              <List className={classes.companyList}>
                {watchlists &&
                  watchlists.map((comp) => (
                    <ListItem key={comp.watchlist_id} className={classes.companyLi}>
                      <Checkbox
                        style={{ color: '#004CFF' }}
                        checked={comp?.company_in_watchlist}
                        onChange={() => {
                          toggleWatchlistStatus(comp);
                        }}
                      />
                      <Typography className={classes.watchlistNm}>{comp?.name}</Typography>
                    </ListItem>
                  ))}
                <div ref={listEndRef}></div>
              </List>
            </div>
            <div
              style={{
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                paddingRight: '12px'
              }}
            >
              <Button className={classes.buttonSignin} onClick={onClose}>
                <Typography
                  style={{
                    fontFamily: 'Satoshi',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    paddingLeft: '15px',
                    paddingRight: '15px'
                  }}
                >
                  {t('signIn.cancelBtn')}
                </Typography>
              </Button>
              <div style={{ paddingLeft: '16px' }}></div>
              <Button
                style={{
                  background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
                  opacity: isButtonDisabled ? 0.5 : 1,
                  color: '#FFFFFF',
                  textTransform: 'none'
                }}
                onClick={handleClick}
                disabled={isButtonDisabled}
              >
                <Typography
                  style={{
                    fontFamily: 'Satoshi',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                  }}
                >
                  {t('askBot.feedbackSave')}
                </Typography>
              </Button>
            </div>
          </>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default FavCompanyDialogPopup;
