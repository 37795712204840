import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@mui/material';
import { ReactComponent as ClockIconGradient } from '../../../components/icons/ClockIconGradient.svg';

const useStyles = makeStyles((theme) => ({
  pressReleseMainGrid: {
    padding: '14px 120px',
    maxWidth: '1440px',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      padding: '14px 120px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '14px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '14px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '14px 20px'
    }
  },
  pressReleseHeading: {
    fontFamily: 'Satoshi !important',
    fontSize: '24px !important',
    lineHeight: '32px !important',
    fontWeight: 400,
    paddingBottom: '14px'
  },
  pressReleseContentGrid: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 12px 0px rgba(6, 10, 84, 0.08)',
    borderRadius: '12px',
    padding: '32px',
    width: '100%'
  },
  pressReleseTime: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  pressLocation: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: '#171717',
    paddingLeft: '5px'
  },
  pgPressTxt: {
    fontSize: '22px !important',
    lineHeight: '28px !important',
    fontFamily: 'Satoshi',
    fontWeight: 700,
    paddingTop: '16px'
  },
  pgPressContent: {
    fontSize: '16px !important',
    lineHeight: '28px !important',
    fontFamily: 'Satoshi !important',
    fontWeight: 400,
    padding: '12px 0px',
    color: '#373737'
  },
  pgPressHeading: {
    fontSize: '18px !important',
    lineHeight: '28px !important',
    fontFamily: 'Satoshi !important',
    fontWeight: 700,
    padding: '12px 0px',
    color: '#373737'
  }
}));

function PressRelese() {
  const classes = useStyles();

  const press = {
    time: '2024-09-24 08:18:00',
    location: 'Bengaluru, India',
    title: 'AI Copilot PrivateBlok Set to Revolutionize Investment in Private Markets',
    aboutPB:
      'Founded by Dimple Sadhwani and Sachin Manchanda in 2022, PrivateBlok is a Generative AI platform that aims to increase transparency and accelerate decision-making for investors in private markets. The AI co-pilot steers investors through their pre-investment research, due-diligence and post-investment oversight, making it a must-have for sound and secure investments in private companies. A combination of data, chat and workflows, PrivateBlok provides a 360 degree lens to investors for their analysis. PrivateBlok is based on customised LLMs (Large Language Modules), other specialized machine learning models and intricate workflows, and is integrated with public and secure private data. PrivateBlok is owned and operated by BlokTrek.'
  };

  const pressContents = [
    {
      heading:
        'PrivateBlok simplifies investments for VCs, PE firms, Family Offices, Wealth Management firms and investors in start-ups and private companies',
      content:
        'Built with a vision of unlocking the hidden potential of investments in private markets, GenAI-powered SaaS platform PrivateBlok now launches in India. Set out to make the investment journey seamless and personalised for investors, PrivateBlok is geared towards professional investors including Venture Capital firms, Wealth Management firms, Private Equity firms, Family Offices, among others. PrivateBlok has leading venture capital firm Fireside Ventures and financial services company Nuvama Wealth and Investment among its initial clients.'
    },
    {
      heading:
        'A part of BlokTrek Inc., PrivateBlok is a product by seasoned financial professionals, Dimple Sadhwani and Sachin Manchanda.',
      content:
        'PrivateBlok goes beyond traditional data providers and supports investment intelligence, investor reporting and portfolio tracking workflows. Its advanced GenAI-powered platform collects vast amount of public and proprietary data into a knowledge repository, interpreting and generating insights on companies and themes. Its AI adapts to the investor’s unique viewpoint and analysis methodology through custom reports or direct conversations in its proprietary "Byz" interface. Users can quickly and reliably gain insights from extensive amounts of both internal content and external data, maintaining complete privacy.'
    },
    {
      heading:
        'Speaking on leveraging PrivateBlok’s advanced capabilities, Shuchi Pandya, Principal at Fireside Ventures said, “At Fireside Ventures, we have always explored the use of newer technologies that enhance and optimise our investments. Simultaneously we continue to scout for newer and emerging consumer brands that can be added to our portfolio. India is a very exciting market and a fast moving one and PrivateBlok’s Gen AI copilot assists us in these processes.”'
    },
    {
      heading:
        '“PrivateBlok is revolutionary and simple to use. Its advanced capabilities have been aiding in speedy and informed decision-making. We are excited to see how the product evolves. We believe it possesses the potential to drive growth in terms of investments in the private sector in India.” said Shiv Sehgal, President and Head, Nuvama Capital Markets.'
    },
    {
      content:
        'Serving as PrivateBlok’s Chief Executive Officer, Sachin Manchanda, said “We believe private markets globally are uniquely positioned for growth. Capitalising on this growth potential, we believe the launch of PrivateBlok is well timed. We aim to help maximize one’s chance of investing in a future unicorn through a transformed due diligence process that navigates vast amounts of data to generate unique insights with speed and accuracy. We are confident that the platform will serve as a co-pilot supporting investors throughout their investment journey - from sourcing and researching to monitoring, tracking and exiting.'
    },
    {
      content:
        'Speaking on leveraging AI’s capabilities, PrivateBlok’s Chief of AI, Technology and Operations, Dimple Sadhwani said, “PrivateBlok is a revolutionary GenAI co-pilot that will act as an unbiased partner to investors. We have orchestrated a truly intelligent multi-model AI leveraging the most cutting-edge capabilities in large language models. Our models understand the vertical domain of private market companies and themes, as well as respect investors’ need for privacy and differentiated outcomes. We are excited on the launch and having brought to life an offering that can unlock the market’s growth.”'
    },
    {
      content:
        'In addition to empowering the investor fraternity, PrivateBlok also plans to open up avenues for start-ups and companies looking to raise funds. It offers private companies the opportunity to share their progress and accurately shape their narrative keeping the investor community updated on all developments within the company.'
    },
    {
      content:
        'Prior to developing and launching PrivateBlok, Sachin Manchanda served as the Head of Equity Model Portfolios at Morgan Stanley until May 2022. At PrivateBlok, he leads product, sales and marketing, and finance functions. Dimple Sadhwani on the other hand is focused on developing and strengthening PrivateBlok’s AI and Tech capabilities while overseeing operations. Dimple is a Machine Learning and Quantitative Finance expert and was associated with JPMorgan Chase & Co. as Managing Director until June 2022.'
    }
  ];

  const dateFormat = (timestamp) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [datePart, timePart] = timestamp.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hour, minute] = timePart.split(':');
    const utcDateObj = new Date(Date.UTC(year, month - 1, day, hour, minute));
    const options = {
      year: 'numeric',
      month: 'short', // Use 'short' for abbreviated month names, or 'long' for full month names
      day: 'numeric',
      timeZone: userTimeZone
    };
    const formattedTime = utcDateObj.toLocaleString('en-IN', options);
    return formattedTime;
  };

  return (
    <div
      style={{
        background: '#F7F9FD'
      }}
    >
      <Grid className={classes.pressReleseMainGrid}>
        <Typography className={classes.pressReleseHeading}>
          <span
            style={{
              backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Press Release
          </span>
        </Typography>
        <Grid className={classes.pressReleseContentGrid}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ClockIconGradient style={{ paddingRight: '5px', fontSize: '30px' }} />
            <Typography className={classes.pressReleseTime}>{dateFormat(press.time)}</Typography>
            <Typography className={classes.pressLocation}>{press.location}</Typography>
          </div>
          <Typography className={classes.pgPressTxt}>
            <span
              style={{
                backgroundImage: 'linear-gradient(to right, #8F34EA 0%, #2B4BDA 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              {press.title}
            </span>
          </Typography>

          {pressContents.map((pressContent, index) => (
            <>
              <Grid key={index}>
                {pressContent?.heading && (
                  <Typography className={classes.pgPressHeading}>
                    {pressContent?.heading}
                  </Typography>
                )}
                {pressContent?.content && (
                  <Typography className={classes.pgPressContent}>
                    {pressContent?.content}
                  </Typography>
                )}
              </Grid>
            </>
          ))}
          {press.aboutPB && (
            <Grid>
              <Typography className={classes.pgPressHeading}>About PrivateBlok</Typography>
              <Typography className={classes.pgPressContent}>{press.aboutPB}</Typography>
              <Typography className={classes.pgPressHeading}>
                To know more, and schedule a demo, visit{' '}
                <a
                  href="https://privateblok.ai/home"
                  target="_blank"
                  style={{ textDecoration: 'none', color: '#004CFF' }}
                  rel="noreferrer"
                >
                  www.privateblok.ai
                </a>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default PressRelese;
