import React, { useEffect, useState, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { setFilter } from '../../action';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { Grid } from '@material-ui/core';
import './externalFiltersDesktop.css';
import SearchComp from '../../../atoms/searchComp';
import { useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    [theme.breakpoints.up('md')]: {
      flexBasis: 'calc(1 / 5 * 100%)',
      flexGrow: 1
    }
  },
  noWrap: {
    flexWrap: 'nowrap'
  }
}));

function ExternalFiltersDesktop({ handleCompanyClick }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.tokenReducer.filterInput);
  // const filterPreviousRedux = useSelector((state) => state.tokenReducer.previousFilter);
  const filterPreviousRedux = useSelector((state) => state.tokenReducer.filter);
  const [filterCondition, setFilterCondition] = useState(filterPreviousRedux);
  const tagsContainerRef = useRef(null);

  const handleChange = (event, values, field) => {
    if (field === 'names') {
      if (values && values.length > 0 && values[0].key && values[0].value) {
        handleCompanyClick(values[0].key, values[0].value);
      }
    } else {
      setFilterCondition((oldArray) => {
        const newArray = [...oldArray];
        const index = newArray.findIndex((item) => item.attribute === field);

        if (index !== -1) {
          if (values.length === 0) {
            // Remove the entire filter condition when all values are unselected
            newArray.splice(index, 1);
          } else {
            // Update the values for the existing filter condition
            newArray[index] = {
              attribute: field,
              operator: 'IN',
              values: values
            };
          }
        } else {
          // Add a new filter condition if it doesn't exist
          newArray.push({
            attribute: field,
            operator: 'IN',
            values: values
          });
        }

        return newArray;
      });
    }
  };

  useEffect(() => {
    setFilterCondition(filterPreviousRedux);
  }, [filterPreviousRedux]);

  useEffect(() => {
    // dispatch(setPreviousFilter(filterCondition));
    dispatch(setFilter(filterCondition));
    if (tagsContainerRef.current) {
      tagsContainerRef.current.scrollLeft = tagsContainerRef.current.scrollWidth;
    }
  }, [filterCondition]);

  return (
    <>
      {filterData.length > 0 ? (
        <Grid container spacing={2} className={classes.noWrap}>
          {filterData.map(({ field, result, fieldName }) => {
            let matchingCondition = filterCondition.find(
              (condition) => condition.attribute === field
            );
            let selectedValues = matchingCondition ? matchingCondition.values : [];
            const isShowCheckBox = field !== 'names';
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={field}
                className={isDesktop ? classes.item : ''}
              >
                <Typography style={{ fontWeight: 550, fontSize: '15px' }}>{fieldName}</Typography>
                <SearchComp
                  placeholder={fieldName}
                  handleChange={(event, value) => handleChange(event, value, field)}
                  options={result}
                  selectedValues={selectedValues}
                  showCheckBox={isShowCheckBox}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <>
          <br />
          <LinearProgress />
          <br />
        </>
      )}
    </>
  );
}

export default ExternalFiltersDesktop;
