import React, { useEffect, useState } from 'react';
import { postApi, getApi } from '../../services/token';
import { Grid } from '@mui/material';
import { makeStyles, Typography } from '@material-ui/core';
const url = process.env.REACT_APP_API;
const useStyles = makeStyles({
  container: {
    'margin-top': '0px!important'
  },
  flexCss: {
    display: 'flex',
    gap: '12rem'
  },
  detailHeading: {
    width: '10px'
  },
  companyList: {
    height: 'calc(100vh - 102px)',
    'overflow-y': 'scroll',
    'padding-left': '48px!important',
    'padding-right': '16px!important',
    cursor: 'pointer'
  },
  companyName: {
    background: '#bde2ee',
    height: '35px',
    'text-align': 'center',
    'padding-top': '8px',
    border: '1px solid #dcedf6'
  }
});

const Detail = () => {
  const [companies, setCompanies] = useState([]);
  const [companyDetail, setCompanyDetail] = useState(null);
  const classes = useStyles();
  const getCompanyData = async () => {
    const companyListData = await postApi(`${url}/list-companies`, {});
    if (companyListData && companyListData.data && companyListData.data.body) {
      const parsedData = JSON.parse(companyListData.data.body);
      if (parsedData && parsedData.data && Array.isArray(parsedData.data))
        setCompanies(parsedData.data);
    }
  };

  const getCompanyDetail = async (name) => {
    const encoded = name ? encodeURIComponent(name) : name;
    const compDetail = await getApi(`${url}/company/${encoded}`);
    if (compDetail && compDetail.data && compDetail.data.data)
      setCompanyDetail(compDetail?.data?.data ? compDetail.data.data : null);
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    if (companies && companies.length > 0) getCompanyDetail(companies[0]);
  }, [companies]);

  return (
    <>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={4} className={classes.companyList}>
          {companies.map((x, i) => {
            return (
              <Typography
                key={i}
                className={classes.companyName}
                onClick={() => {
                  getCompanyDetail(x);
                }}
              >
                {x}
              </Typography>
            );
          })}
        </Grid>
        <Grid item xs={8}>
          {companyDetail &&
            Object.keys(companyDetail).length > 0 &&
            Object.keys(companyDetail).map((x, i) => {
              return (
                <div key={i} className={classes.flexCss}>
                  <Typography className={classes.detailHeading}>{x}</Typography>
                  <Typography>{companyDetail[x]}</Typography>
                </div>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
};
export default Detail;
