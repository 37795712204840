export const dateFormat = (timestamp) => {
  if (!timestamp) return;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [datePart, timePart] = timestamp.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hour, minute] = timePart.split(':');
  const utcDateObj = new Date(Date.UTC(year, month - 1, day, hour, minute));
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
    hour12: true,
    timeZone: userTimeZone
  };
  const formattedTime = utcDateObj.toLocaleString('en-IN', options);
  return formattedTime;
};

export const convertBytes = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i == 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

export const getDisplayFileName = (fileName, fileFormat) => {
  return `${fileName}${fileFormat ? `.${fileFormat}` : ''}`;
};

export const routesWithoutHeaderAndFooter = ['/doc-viewer'];

export const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const getDisplayTextFromMap = (obj, path, displayMap) => {
  let displayText = getNestedValue(obj, path);
  displayText = displayMap.find((obj) => obj.key === displayText)?.value || displayText;
  return displayText;
};
