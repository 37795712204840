import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
// import { useNavigate } from 'react-router-dom';

const CustomDialog = ({ dialogProps }) => {
  const { open, handleClose, Component } = dialogProps;

  // let navigate = useNavigate();
  return (
    <div>
      <Dialog
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backdropFilter: 'blur(8px)' // Add backdrop blur effect
          }
        }}
        BackdropProps={{
          style: {
            backdropFilter: 'blur(8px)' // Add backdrop blur effect
          }
        }}
      >
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
          </div>
          <DialogContentText id="alert-dialog-description">
            <Component {...dialogProps} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CustomDialog.propTypes = {
  dialogProps: PropTypes.any
};

export default CustomDialog;
