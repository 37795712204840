import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { useValidUsername } from '../../hooks/useAuthHooks';
import { Username } from './authComponents';
import { postApi } from '../../services/token';
import { AuthContext } from './contexts/authContext';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': { cursor: 'pointer' },
    color: '#004CFF'
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  requestGrid: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start'
    }
  },
  requestPaper: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  }
}));

export default function RequestCode() {
  const { t } = useTranslation();
  const classes = useStyles();
  const url = process.env.REACT_APP_API;
  const { username, setUsername, usernameIsValid } = useValidUsername('');
  const [error, setError] = useState('');
  const [checkPass, setCheckPass] = useState(false);

  const isValid = !usernameIsValid || username.length === 0;
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmlMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const paperWidth = isDesktop ? '60%' : '100%';
  const gridHeight = isDesktop
    ? 'calc(100vh - 121px)'
    : isSmlMobile
    ? 'calc(100vh - 155px)'
    : 'calc(100vh - 121px)';

  const authContext = useContext(AuthContext);

  const sendCodeClicked = async () => {
    setCheckPass(false);
    setError(null);
    if (isValid) {
      setCheckPass(true);
      setError('Invalid username');
      return;
    }
    let body = {
      username: String(username)
    };

    const getUser = `${url}/user-status`;
    const { data } = await postApi(getUser, body);
    if (data.data.userstatus === 'UNCONFIRMED') {
      navigate('/verify', { state: { email: username } });
      await authContext.resendCode(username);
    } else if (data.data.userstatus === 'CONFIRMED') {
      try {
        await authContext.sendCode(username);
        navigate('/forgotpassword', {
          state: { email: username }
        });
      } catch (err) {
        setError(err.message);
        setCheckPass(true);
        setError('Invalid username');
      }
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        const btn = document.getElementById('sendBtn');
        btn.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        height: gridHeight
      }}
    >
      {!isMobile && ( // Conditionally render only on non-mobile screens
        <Grid item lg={5}>
          <Box
            sx={{
              background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
              borderRadius: '3px',
              opacity: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: gridHeight
            }}
          >
            <img src="/images/PBLogo_White.svg" alt="PrivateBlok-Logo" className="logo" />
          </Box>
        </Grid>
      )}
      <Grid
        xs={12}
        sm={12}
        lg={7}
        container
        className={classes.requestGrid}
        style={{ height: gridHeight }}
        item
      >
        <Paper elevation={0} className={classes.requestPaper} style={{ width: paperWidth }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Box width="100%">
              <Typography
                style={{
                  fontSize: '24px',
                  lineHeight: '70px',
                  letterSpacing: '0px',
                  color: '#08070D'
                }}
              >
                {t('requestCode.sendReset')}
              </Typography>
            </Box>
            <br />
            <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
            <br />
            <>
              {checkPass ? (
                <>
                  <Box
                    m={1}
                    style={{
                      background: '#FDF6F6',
                      borderRadius: '5px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padddingTop: '10px'
                    }}
                  >
                    <ErrorOutlineIcon
                      style={{
                        marginLeft: '13px', // Set margin left to 13px
                        marginRight: '5px', // Set margin right to 1px
                        color: '#EB5B55' // Set color of the icon to #3C962A
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#EB5B55',
                        marginRight: '15px'
                      }}
                    >
                      {error}
                    </Typography>
                  </Box>
                </>
              ) : (
                <div></div>
              )}
            </>
            <br />
            <Button
              id="sendBtn"
              color="primary"
              variant="contained"
              style={{
                width: '100%',
                background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
                height: '50px'
              }}
              onClick={sendCodeClicked}
            >
              <Typography
                style={{
                  fontSize: '18px',
                  fontWeight: 'medium',
                  color: '#FFFFFF',
                  textTransform: 'none'
                }}
              >
                {t('requestCode.sendButton')}
              </Typography>
            </Button>
            <br />
            <Button
              style={{
                width: '100%',
                color: '#0C0C0C',
                fontSize: '16px',
                textTransform: 'none'
              }}
              className={classes.button}
              onClick={() => navigate('/home')}
            >
              {t('requestCode.cancelButton')}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
