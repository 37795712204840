import React, { useRef } from 'react';
import { Box, Grid, Button, LinearProgress } from '@mui/material';
import AskBot from '../askBot/askBot';
import ExploreCompanies from '../exploreCompanies/exploreCompanies';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import History from '../history/history';
import { useDispatch, useSelector } from 'react-redux';
import { setHomeTabs, setCurrentStepNumber, endTourGuide } from '../action';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { getApi } from '../../../services/token';
import PaymentSuccess from '../validationPopover';
import { setQuestionTabs } from '../action';
// import { setCompanyDetails } from '../action';
import { useContext } from 'react';
import { AuthContext } from '../../auth/contexts/authContext';
import StepPopover from '../../tourGuide';

const pricingURL = process.env.REACT_APP_PAYMENT_API_URL;

const useStyles = makeStyles(() => ({
  active: {
    color: 'white!important',
    background: 'linear-gradient(90deg, rgb(0, 76, 255), rgb(6, 188, 193))'
  },
  mainTabBtn: {
    'text-transform': 'none!important',
    'margin-top': '6px',
    padding: '6px 26px !important',
    'border-radius': '8px',
    '& img': {
      'margin-right': '10px',
      top: '1px',
      position: 'relative'
    },
    borderRadius: '8px!important'
  },
  btnColor: {
    color: '#767676!important',
    borderRadius: '8px!important'
  },
  tabClsQuestions: {
    background: '#ffffff',
    display: 'flex',
    border: '1px #E7E7E7 solid',
    'border-radius': '12px',
    '& div': {
      padding: '2px'
    }
  },
  tabClsBtn: {
    'text-transform': 'none!important',
    'margin-top': '6px',
    color: '#767676',
    'border-radius': '8px'
  },
  stepClass: {
    '&::before': {
      border: '10px solid transparent',
      content: '""',
      position: 'absolute',
      top: '27px',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent #ffffff transparent',
      transform: 'translateX(-50%)',
      zIndex: 9999
    }
  }
}));

const screenerEnabled = process.env.REACT_APP_SCREENER_ENABLE;
const MainPage = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let activeTab = props?.currentRoute; //useSelector((state) => state.tokenReducer.hometabs);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const questionTabs = useSelector((state) => state.tokenReducer.questionTabs);
  const [currentUserPlan, setCurrentUserPlan] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const exploreAllCompanyTabRef = useRef(null);

  const storeCurrentStep = useSelector((state) => state.tokenReducer.currentStep);
  const storeShowTourGuide = useSelector((state) => state.tokenReducer.showTourGuide);
  const stepEnabled = storeCurrentStep === 6 && storeShowTourGuide;

  const handleClosePopOver = () => {
    setCurrentStep(null);
    setAnchorElPopover(null);
  };

  useEffect(() => {
    if (stepEnabled && exploreAllCompanyTabRef.current) {
      setAnchorElPopover(exploreAllCompanyTabRef.current);
    }
  }, [stepEnabled, exploreAllCompanyTabRef.current]);

  useEffect(() => {
    if (stepEnabled) {
      stepOperation(storeCurrentStep);
    }
  }, [stepEnabled, storeCurrentStep, navigate]);

  useEffect(() => {
    if (storeCurrentStep === 4 && storeShowTourGuide) {
      navigate('/home');
    }
  }, [storeCurrentStep, storeShowTourGuide, navigate]);

  const handleSkip = () => {
    dispatch(endTourGuide());
  };

  const onNextPrevClick = (currStep) => {
    dispatch(setCurrentStepNumber(currStep));
  };

  const stepOperation = (currentStep) => {
    if (currentStep === 6) {
      setCurrentStep({
        step: currentStep,
        totalSteps: 6,
        paraHeading: 'Explore Companies',
        para: 'Browse and navigate across all available private companies on the platform. Narrow down to the subset of your interest using the filters. In case you don’t find a particular private company, you may place a request for it to be added to the platform. We will add it on a best effort basis.',
        note: 'For more guidance visit the Help Center to understand how to use PrivateBlok and learn about its features.',
        onClickPrev: () => {
          onNextPrevClick(currentStep - 1);
        },
        nextBtnText: 'Go to Help Center',
        onclickNext: () => {
          handleClosePopOver();
          navigate('/help-center', {
            state: { isStepFinished: true }
          });
        }
      });
    }
  };

  const isMobileLandscape =
    useMediaQuery('(max-width: 900px) and (orientation:landscape)') && activeTab == t('tab.AskBot');
  const getUserRemainingCredits = async () => {
    const createPlanApiUrl = `${pricingURL}/user/plan/authorizer`;
    const { data } = await getApi(createPlanApiUrl);
    return data?.data;
  };
  const [credits, setCreditsMainPg] = useState(null);

  // const companyDetails = useSelector((state) => state.tokenReducer.companyDetails);
  const auth = useContext(AuthContext);

  // useEffect(() => {
  //   if (companyDetails.length !== 0) {
  //     dispatch(setCompanyDetails([]));
  //   }
  // }, [questionTabs]);

  const getCurrentUserPlan = async () => {
    try {
      const getCurrentUserPlanUrl = `${process.env.REACT_APP_PAYMENT_API_URL}/user/plan/authorizer`;
      const { data } = await getApi(getCurrentUserPlanUrl);
      setCurrentUserPlan(data.data);
    } catch (error) {
      setCurrentUserPlan(null);
      console.error(error);
    }
  };

  const mainTabsData = [
    {
      text: t('tab.AskBot'),
      path: '/askbyz',
      icon: 'images/headerTabs/askbot_'
    },
    {
      text: t('tab.ExploreAllCompanies'),
      path: '/explore-all-companies',
      icon: 'images/headerTabs/exploreAllCompanies_',
      tabRef: exploreAllCompanyTabRef,
      tourTab: true
    },
    {
      text: t('tab.History'),
      path: '/history',
      icon: 'images/headerTabs/History_'
    }
  ];

  useEffect(() => {
    getCurrentUserPlan();
  }, [auth]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        height:
          activeTab == t('tab.ExploreAllCompanies') || isMobileLandscape
            ? null
            : 'calc(100vh - 85px)',
        // minHeight: isMobileLandscape ? 'calc(100vh - 85px)' : null,
        backgroundColor: '#F7F9FD'
      }}
    >
      {loading && <LinearProgress />}
      {!matches && (
        <div>
          <PaymentSuccess
            condition={[t('payment.successStatus')]}
            message={`Thanks for purchasing. You now have access to premium data and ${credits} credits`}
          />
        </div>
      )}
      {!matches && (
        <Grid
          container
          justifyContent="center"
          sx={{ height: '70px', display: 'flex', alignContent: 'center' }}
        >
          <Grid
            item
            xs={11}
            style={{
              maxWidth: '1440px',
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div className={classes.tabClsQuestions}>
              {mainTabsData.map((tab, index) => (
                <>
                  <div key={index}>
                    <Button
                      className={`${activeTab == tab.text ? classes.active : classes.btnColor} ${
                        classes.mainTabBtn
                      } ${stepEnabled && tab.tourTab ? classes.stepClass : ''}`}
                      variant="text"
                      style={{ fontFamily: 'Satoshi', fontWeight: 'bold' }}
                      onClick={() => {
                        dispatch(setHomeTabs(tab.text));
                        navigate(tab.path);
                      }}
                      ref={tab.tabRef}
                    >
                      <span>
                        <img
                          src={`${tab.icon}${activeTab == tab.text ? 'white' : 'Grey'}.svg `}
                          alt={tab.text}
                        />
                        {tab.text}
                      </span>
                    </Button>
                  </div>
                  {tab.tourTab && currentStep && stepEnabled && (
                    <StepPopover
                      customStyle={{ top: '10px' }}
                      value={anchorElPopover}
                      currentStep={currentStep}
                      onClose={handleClosePopOver}
                      skip={handleSkip}
                    />
                  )}
                </>
              ))}
            </div>
            <div
              style={{ display: 'none' }}
              className={classes.tabClsQuestions}
              id="div-question-type"
            >
              <div>
                <Button
                  className={`${
                    questionTabs == t('tab.Companies') ? classes.active : classes.btnColor
                  } ${classes.tabClsBtn}`}
                  variant="text"
                  style={{ fontFamily: 'Satoshi', fontWeight: 'bold' }}
                  onClick={() => {
                    dispatch(setQuestionTabs(t('tab.Companies')));
                    navigate('/askbyz');
                  }}
                >
                  <span>{t('tab.Companies')}</span>
                </Button>
              </div>
              {/* <div>
                <Button
                  className={`${
                    questionTabs == t('tab.Sector') ? classes.active : classes.btnColor
                  } ${classes.tabClsBtn}`}
                  variant="text"
                  style={{
                    fontFamily: 'Satoshi',
                    fontWeight: 'bold',
                    background: !(currentUserPlan && currentUserPlan.sector_data_access === false)
                      ? 'inetial'
                      : '#949494',
                    cursor: !(currentUserPlan && currentUserPlan.sector_data_access === false)
                      ? 'inetial'
                      : 'not-allowed'
                  }}
                  // onClick={() => {
                  //   dispatch(setQuestionTabs(t('tab.Sector')));
                  //   navigate('/askbyz');
                  // }}
                  onClick={() => {
                    if (!(currentUserPlan && currentUserPlan.sector_data_access === false)) {
                      dispatch(setQuestionTabs(t('tab.Sector')));
                      navigate('/askbyz');
                    } else {
                      null;
                    }
                  }}
                >
                  <span>{t('tab.Sector')}</span>
                </Button>
              </div> */}
              <div>
                <Button
                  className={`${
                    questionTabs == t('tab.People') ? classes.active : classes.btnColor
                  } ${classes.tabClsBtn}`}
                  variant="text"
                  style={{ fontFamily: 'Satoshi', fontWeight: 'bold' }}
                  onClick={() => {
                    dispatch(setQuestionTabs(t('tab.People')));
                    navigate('/askbyz');
                  }}
                >
                  <span>{t('tab.People')}</span>
                </Button>
              </div>
              {currentUserPlan && currentUserPlan.fund_access === true && (
                <div>
                  <Button
                    className={`${
                      questionTabs == t('tab.Fund') ? classes.active : classes.btnColor
                    } ${classes.tabClsBtn}`}
                    variant="text"
                    style={{
                      fontFamily: 'Satoshi',
                      fontWeight: 'bold',
                      background: !(currentUserPlan && currentUserPlan.fund_access === false)
                        ? 'inetial'
                        : '#949494',
                      cursor: !(currentUserPlan && currentUserPlan.fund_access === false)
                        ? 'inetial'
                        : 'not-allowed'
                    }}
                    // onClick={() => {
                    //   dispatch(setQuestionTabs(t('tab.Fund')));
                    //   navigate('/askbyz');
                    // }}
                    onClick={() => {
                      if (!(currentUserPlan && currentUserPlan.fund_access === false)) {
                        dispatch(setQuestionTabs(t('tab.Fund')));
                        navigate('/askbyz');
                      } else {
                        null;
                      }
                    }}
                  >
                    <span>{t('tab.Fund')}</span>
                    <sup
                      style={{
                        margin: '-4px 0px 8px 3px',
                        fontSize: '8.5px',
                        ...(questionTabs !== t('tab.Fund') && {
                          backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          textDecoration: 'none'
                        })
                      }}
                    >
                      Beta
                    </sup>
                  </Button>
                </div>
              )}
              {screenerEnabled === 'true' ? (
                <div>
                  <Button
                    className={`${
                      questionTabs == t('tab.Screener') ? classes.active : classes.btnColor
                    } ${classes.tabClsBtn}`}
                    variant="text"
                    style={{ fontFamily: 'Satoshi', fontWeight: 'bold' }}
                    onClick={() => {
                      dispatch(setQuestionTabs(t('tab.Screener')));
                      navigate('/askbyz');
                    }}
                  >
                    <span>{t('tab.Screener')}</span>
                  </Button>
                </div>
              ) : (
                <div style={{ padding: '0px' }}>
                  <Button
                    className={`${
                      questionTabs == t('tab.Screener') ? classes.active : classes.btnColor
                    } ${classes.tabClsBtn}`}
                    variant="text"
                    style={{
                      fontFamily: 'Satoshi',
                      fontWeight: 'bold',
                      paddingRight: '30px',
                      color: '#000000 !important',
                      background: '#cccccc',
                      pointerEvents: 'none',
                      height: '100%'
                    }}
                    disableRipple
                  >
                    <span>{t('tab.Screener')}</span>
                    <div
                      style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        transform: 'rotate(0deg)',
                        padding: '0px'
                      }}
                    >
                      <div
                        style={{
                          background: 'linear-gradient(to right, #06BCC1, #004CFF)',
                          opacity: 0.9 /* Adjust the opacity level as needed */,
                          padding: '0px 4px',
                          fontSize: '8px',
                          borderRadius: '0px 4px 0px 4px',
                          color: '#FFFFFF',
                          fontFamily: 'Satoshi'
                        }}
                      >
                        Coming Soon
                      </div>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center">
        <Grid
          item
          xs={11.8}
          sm={11.8}
          md={11}
          sx={
            activeTab == t('tab.ExploreAllCompanies')
              ? null
              : isMobileLandscape
              ? { minHeight: 'calc(100vh - 145px)' }
              : matches
              ? { height: 'calc(100vh - 145px)' }
              : { height: 'calc(100vh - 185px)', display: 'flex' }
          }
        >
          {activeTab == t('tab.History') ? <History matches={matches} /> : null}
          {activeTab == t('tab.AskBot') ? (
            <AskBot
              setLoadingCompanySelection={setLoading}
              matches={matches}
              getUserRemainingCredits={getUserRemainingCredits}
              setCreditsMainPg={setCreditsMainPg}
            />
          ) : null}
          {activeTab == t('tab.ExploreAllCompanies') ? (
            <ExploreCompanies getUserRemainingCredits={getUserRemainingCredits} />
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ height: '30px' }} />
    </Box>
  );
};

export default MainPage;
MainPage.propTypes = {
  currentRoute: PropTypes.string
};
