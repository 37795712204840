import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import './signUp.css';
import PhoneInput from 'react-phone-input-2';
import {
  useValidEmail,
  useValidPassword,
  useValidName,
  useValidCode,
  useValidInviteCode
} from '../../../hooks/useAuthHooks';
import { Email, Password, Name, Code, InviteCode } from '../authComponents';
import { LinearProgress, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { AuthContext } from '../contexts/authContext';
import Dialog from './trialAgreementDialog';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WaitList from '../waitlist/waitList';
import { postApi } from '../../../services/token';

const url = process.env.REACT_APP_PAYMENT_API_URL;
const waitList = process.env.REACT_APP_ENABLE_WAITLIST;
const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': { cursor: 'pointer' },
    color: '#004CFF'
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  signupGrid: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start'
    }
  },
  signupPaper: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  disclaimerButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    fontSize: '13px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    }
  }
}));
const SignUp = () => {
  const location = useLocation();
  const locationState = location?.state;
  const route = location?.pathname === '/signupfortrial' ? 1 : 0; // 1 -> trial; 0 -> signup
  const { t } = useTranslation();
  const classes = useStyles();
  const { email, setEmail, emailIsValid } = useValidEmail('');
  const { name, setName, nameIsValid } = useValidName('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const { inviteCode, setInviteCode, inviteCodeIsValid } = useValidInviteCode('');
  const [error, setError] = useState('');
  const [created, setCreated] = useState(false);
  const [termsAndConditionCheckBox, setTermsAndConditionCheckBox] = useState(false);
  const [phone_number, setPhone_number] = useState('');
  const [open, setOpen] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const [checkPass, setCheckPass] = useState(false);
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid
  } = useValidPassword('');

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !nameIsValid ||
    name.length === 0 ||
    !inviteCodeIsValid ||
    inviteCode.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !termsAndConditionCheckBox;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const signUpClicked = () => {
    setCheckPass(false);
    setError(null);
    if (phone_number === '') {
      setError(t('signUp.phoneNumberErrMessage'));
      setCheckPass(true);
    } else if (password !== passwordConfirm) {
      setError(t('signUp.passwordMessage'));
      setCheckPass(true);
    } else {
      if (route) handleClickOpen();
      else handleTrialSigned(false);
    }
  };

  const handleTrialSigned = async (agreementSigned) => {
    handleClose();
    setSignUpLoading(true);
    const trial_agreement_accepted = agreementSigned;
    try {
      await authContext.signUpWithEmail(
        name,
        email,
        password,
        termsAndConditionCheckBox,
        phone_number,
        inviteCode,
        trial_agreement_accepted
      );
      setCreated(true);
    } catch (err) {
      if (err instanceof Error) {
        if (err.code === 'UsernameExistsException') {
          setCheckPass(true);
          setError('An account with the given email already exists');
        } else if (err.code === 'UserLambdaValidationException') {
          setCheckPass(true);
          if (err?.message.includes('Phone number'))
            setError('An account with the given number already exists');
          else setError('Invalid invite code');
        } else if (err.message === 'Invalid phone number format.') {
          setCheckPass(true);
          setError('Invalid phone number format.');
        }
      }
    }
    setSignUpLoading(false);
  };

  const signUpClickedInvalid = async () => {
    setCheckPass(false);
    setError(null);
    if (!inviteCodeIsValid || inviteCode.length === 0) {
      setError(t('signUp.iviteCodeRequired'));
      setCheckPass(true);
      return;
    } else if (!nameIsValid || name.length === 0) {
      setError(t('signUp.invalidName'));
      setCheckPass(true);
      return;
    } else if (!emailIsValid || email.length === 0) {
      setError(t('signUp.invalidEmail'));
      setCheckPass(true);
      return;
    } else if (password !== passwordConfirm) {
      setError(t('signUp.passwordMessage'));
      setCheckPass(true);
    } else if (password.match(/^\s|\s$/)) {
      setError(t('signUp.invalidPasswordBlankSpace'));
      setCheckPass(true);
      return;
    } else if (!passwordIsValid || password.length === 0) {
      setError(t('signUp.invalidPassword'));
      setCheckPass(true);
      return;
    } else if (!termsAndConditionCheckBox) {
      setError(t('signUp.invalidTerms'));
      setCheckPass(true);
      return;
    }
  };

  const redirectToPaymentPage = async (obj) => {
    const createPlanApiUrl = `${url}/user/plan`;
    const body = { plan_id: obj.id };
    const { data } = await postApi(createPlanApiUrl, body);
    if (data && data.data && data.data.transaction_url)
      window.location.href = data.data.transaction_url;
  };

  const sendClicked = async () => {
    setCheckPass(false);
    setError(null);
    try {
      await authContext.verifyCode(email, code);
      // if (locationState?.cost === 'Basic' || locationState?.cost === 'Basic Plus') {
      if (locationState?.plan_name === 'Basic Plan') {
        // sign in user
        await authContext.signInWithEmail(email, password);
        redirectToPaymentPage(locationState);
      } else {
        navigate('/signin', {
          state: { message: t('signUp.successStatus') }
        });
      }
    } catch (err) {
      setCheckPass(true);
      setError('Invalid Code');
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmlMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const paperWidth = isDesktop ? '60%' : '100%';
  const gridHeight = isDesktop
    ? 'calc(100vh - 121px)'
    : isSmlMobile
    ? 'calc(100vh - 155px)'
    : 'calc(100vh - 121px)';
  const handleClose = () => {
    setOpen(false);
  };
  const dialogProps = { open, handleClose, handleTrialSigned };

  const handlePhoneNumberChange = (value) => {
    const formattedPhoneNumber = `+${value}`;
    setPhone_number(formattedPhoneNumber);
  };

  const signUp = (
    <>
      <Dialog dialogProps={dialogProps} />
      <Box width="100%">
        <Typography
          style={{ fontSize: '24px', lineHeight: '70px', letterSpacing: '0px', color: '#08070D' }}
        >
          {route ? t('trialSignUp.heading') : t('signUp.signUpHeading')}
        </Typography>
      </Box>
      <Box width="100%" marginY={1}>
        <InviteCode inviteCodeIsValid={inviteCodeIsValid} setInviteCode={setInviteCode} />
      </Box>
      <Box width="100%" marginY={1}>
        <Name nameIsValid={nameIsValid} setName={setName} />
      </Box>
      <Box width="100%" marginY={1}>
        <PhoneInput
          inputStyle={{
            height: '56px',
            width: '100%'
          }}
          country={'in'}
          onlyCountries={['in']}
          countryCodeEditable={false}
          value={phone_number}
          onChange={handlePhoneNumberChange}
        />
      </Box>
      <Box width="100%" marginY={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="100%" marginY={1}>
        <Password
          label={t('signUp.pwdLabel')}
          password={password}
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
          validate={true}
        />
      </Box>
      <Box width="100%" marginY={1}>
        <Password
          label={t('signUp.cnfPwdLabel')}
          password={passwordConfirm}
          passwordIsValid={passwordConfirmIsValid}
          setPassword={setPasswordConfirm}
          validate={false}
        />
      </Box>
      <>
        {checkPass ? (
          <>
            <br />
            <Box
              marginY={1}
              style={{
                background: '#FDF6F6',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ErrorOutlineIcon
                style={{
                  marginLeft: '13px', // Set margin left to 13px
                  marginRight: '5px', // Set margin right to 1px
                  color: '#EB5B55' // Set color of the icon to #3C962A
                }}
              />
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#EB5B55',
                  marginRight: '15px'
                }}
              >
                {error}
              </Typography>
            </Box>
          </>
        ) : (
          <div></div>
        )}
      </>
      <Box width="100%" marginY={1}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAndConditionCheckBox}
                onChange={() => {
                  setTermsAndConditionCheckBox(!termsAndConditionCheckBox);
                }}
                size="small"
                style={{
                  color: '#004CFF'
                }}
              />
            }
            label={
              <>
                <div
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <Typography
                    style={{ fontFamily: 'Satoshi', display: 'flex' }}
                    className={classes.disclaimerButton}
                  >
                    {t('signUp.disclaimer')}
                    <Typography
                      style={{
                        color: '#004CFF',
                        textTransform: 'none',
                        paddingLeft: '3px',
                        paddingRight: '3px'
                      }}
                      className={classes.disclaimerButton}
                      onClick={() => {
                        navigate('/terms-and-conditions');
                        // window.open(
                        //   'https://public.privateblok.ai/policies/terms_of_use.html',
                        //   '_blank'
                        // );
                        // setTermsAndCondition(!termsAndCondition);
                      }}
                    >
                      {t('signUp.disclaimerT&C')}
                    </Typography>{' '}
                    <Typography
                      style={{ fontFamily: 'Satoshi' }}
                      className={classes.disclaimerButton}
                    >
                      {t('signIn.disclaimerAnd')}{' '}
                    </Typography>
                    <Typography
                      style={{
                        color: '#004CFF',
                        textTransform: 'none',
                        paddingLeft: '3px',
                        paddingRight: '3px'
                      }}
                      className={classes.disclaimerButton}
                      onClick={() => {
                        navigate('/privacy');
                        // window.open(
                        //   'https://public.privateblok.ai/policies/privacy_policy.html',
                        //   '_blank'
                        // );
                        // setTermsAndCondition(!termsAndCondition);
                      }}
                    >
                      {t('signUp.disclaimerPrivacy')}
                    </Typography>
                  </Typography>
                </div>
              </>
            }
          />
        </FormGroup>
      </Box>
      {isValid || signUpLoading ? ( // If all the fields are not filled
        <Tooltip title={t('signUp.invalidButtonTooltip')} placement="top">
          <Button
            id="validSignupBtn"
            color="primary"
            variant="contained"
            disabled={!termsAndConditionCheckBox}
            style={{
              width: '100%',
              background: termsAndConditionCheckBox
                ? 'linear-gradient(90deg,#06BCC1,#004CFF)'
                : '#ccc',
              height: '50px'
            }}
            onClick={signUpClickedInvalid}
          >
            <Typography
              style={{
                fontSize: '18px',
                fontWeight: 'medium',
                color: '#FFFFFF',
                textTransform: 'none'
              }}
            >
              {route ? t('trialSignUp.signUpBtn') : t('signUp.signUpButton')}
            </Typography>
          </Button>
        </Tooltip>
      ) : (
        <Button
          id="signupBtn"
          disabled={isValid}
          color="primary"
          variant="contained"
          style={{
            width: '100%',
            background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
            height: '50px'
          }}
          onClick={signUpClicked}
        >
          <Typography
            style={{
              fontSize: '18px',
              fontWeight: 'medium',
              color: '#FFFFFF',
              textTransform: 'none'
            }}
          >
            {route ? t('trialSignUp.signUpBtn') : t('signUp.signUpButton')}
          </Typography>
        </Button>
      )}
      {signUpLoading && (
        <>
          <br />
          <LinearProgress style={{ width: '100%' }} />
        </>
      )}
      <Box>
        <br />
        <Typography style={{ fontSize: 16, display: 'flex' }}>
          {t('signUp.signInText')}
          <Typography
            style={{
              color: '#004CFF',
              fontSize: '16px',
              textTransform: 'none',
              paddingLeft: '3px',
              paddingRight: '3px',
              cursor: 'pointer'
            }}
            className={classes.button}
            onClick={() => navigate('/signin')}
          >
            {t('signUp.signInBtn')}
          </Typography>
        </Typography>
      </Box>
      <Button
        style={{
          width: '100%',
          color: '#0C0C0C',
          fontSize: '16px',
          textTransform: 'none'
        }}
        className={classes.button}
        onClick={() => navigate('/home')}
      >
        {t('signUp.cancelButton')}
      </Button>
    </>
  );

  const { code, setCode, codeIsValid } = useValidCode('');
  const accountCreated = (
    <>
      <Box width="100%">
        <Typography
          style={{ fontSize: '24px', lineHeight: '70px', letterSpacing: '0px', color: '#08070D' }}
        >
          {t('signUp.verifyMessage')}
        </Typography>
        <Typography style={{ fontSize: '16px', letterSpacing: '0px', color: '#0C0C0C' }}>
          {t('signUp.createdMessage')} <strong>{phone_number}</strong>
        </Typography>
        <br />
        <Code codeIsValid={codeIsValid} setCode={setCode} />
        <>
          {checkPass ? (
            <>
              <Box
                marginY={1}
                style={{
                  background: '#FDF6F6',
                  borderRadius: '5px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padddingTop: '10px'
                }}
              >
                <ErrorOutlineIcon
                  style={{
                    marginLeft: '13px', // Set margin left to 13px
                    marginRight: '5px', // Set margin right to 1px
                    color: '#EB5B55' // Set color of the icon to #3C962A
                  }}
                />
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#EB5B55',
                    marginRight: '15px'
                  }}
                >
                  {error}
                </Typography>
              </Box>
            </>
          ) : (
            <div></div>
          )}
        </>
        <br />
        <Button
          color="primary"
          id="verifyBtnId"
          variant="contained"
          style={{
            width: '100%',
            background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
            height: '50px'
          }}
          onClick={sendClicked}
        >
          <Typography
            style={{
              fontSize: '18px',
              fontWeight: 'medium',
              color: '#FFFFFF',
              textTransform: 'none'
            }}
          >
            {t('signUp.sendCodeBtn')}
          </Typography>
        </Button>
        <br />
        <Button
          style={{
            width: '100%',
            color: '#0C0C0C',
            fontSize: '16px',
            textTransform: 'none'
          }}
          className={classes.button}
          onClick={() => navigate('/home')}
        >
          {t('signUp.cancelButton')}
        </Button>
      </Box>
    </>
  );

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (created) {
          const btn = document.getElementById('verifyBtnId');
          btn.click();
        } else {
          const btn = document.getElementById(isValid ? 'validSignupBtn' : 'signupBtn');
          btn.click();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isValid, created]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      style={{
        display: 'flex'
      }}
    >
      {!isMobile && ( // Conditionally render only on non-mobile screens
        <Grid item lg={5}>
          <Box
            sx={{
              background: 'linear-gradient(90deg,#06BCC1,#004CFF)',
              borderRadius: '3px',
              opacity: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <img src="/images/PBLogo_White.svg" alt="PrivateBlok-Logo" className="logo" />
          </Box>
        </Grid>
      )}
      <Grid
        xs={12}
        sm={12}
        lg={7}
        container
        className={classes.signupGrid}
        style={{ flex: '1', minHeight: gridHeight }}
        item
      >
        <Paper elevation={0} className={classes.signupPaper} style={{ width: paperWidth }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {waitList === 'true' ? <WaitList /> : !created ? signUp : accountCreated}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SignUp;
