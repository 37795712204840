import { TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ReactComponent as SearchIcon } from '../../../components/icons/SearchIcon.svg';
import StaticChip from '../staticChip';
import { getDisplayTextFromMap, getNestedValue } from '../../utils/utils';
import EntityTypeFilterTabs from './entityTypeFilterTabs';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '10px !important',
      fontFamily: 'Satoshi !important'
    }
  },
  searchIcon: {
    marginRight: '4px',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  optionTypo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '4px 0px'
  }
}));

const SingleSearch = ({
  options = [],
  value = null,
  handleChange = () => {},
  searchIcon = true,
  placeholder = '',
  label = '',
  optionFormat = {
    key: 'key',
    value: 'value',
    chipValue: 'type'
  },
  chipValueMap = [],
  setFilterEntityType = () => {},
  filterEntityType = 'all',
  filterTabsMap = []
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      options={options}
      className={classes.root}
      getOptionLabel={(option) => getNestedValue(option, optionFormat.value) || ''}
      value={options.find((option) => getNestedValue(option, optionFormat.key) === value) || null}
      renderInput={(params) => (
        <form noValidate>
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: searchIcon && (
                <>
                  <SearchIcon className={classes.searchIcon} />
                  {params.InputProps.startAdornment}
                </>
              )
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel
              }
            }}
            autoComplete="off"
          />
        </form>
      )}
      renderOption={(option) => {
        return (
          <>
            <Typography className={classes.optionTypo}>
              {getNestedValue(option, optionFormat.value)}{' '}
            </Typography>
            <StaticChip
              displayText={getDisplayTextFromMap(option, optionFormat.chipValue, chipValueMap)}
            />
          </>
        );
      }}
      ListboxComponent={(props) => (
        <EntityTypeFilterTabs
          tabsOptions={filterTabsMap}
          props={props}
          setFilterTab={setFilterEntityType}
          selectedTab={filterEntityType}
        />
      )}
      onChange={(event, value) => handleChange(event, value)}
      autoHighlight
    />
  );
};

export default SingleSearch;
