import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  para: {
    color: '#171717',
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      lineHeight: '24px',
      'padding-top': '10px'
    }
  },
  upperCase: {
    textTransform: 'uppercase!important'
  }
}));

const para = ({ children, gutterBottom = false, upperCase = false }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      gutterBottom={gutterBottom}
      className={`${classes.para} ${upperCase ? classes.upperCase : ''}`}
    >
      {children}
    </Typography>
  );
};
export default para;
