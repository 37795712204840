import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import { Skeleton, Card } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { formatedTime } from '../utils/formatedTime';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Chip } from '@mui/material';
import CustomTextField from '../../atoms/textfield';
import { ReactComponent as ClockIconGradient } from '../../../components/icons/ClockIconGradient.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  table: {
    borderCollapse: 'collapse'
  },
  th: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF'
  },
  td: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    backgroundColor: '#FFFFFF'
  },
  alternateCell: {
    backgroundColor: '#f6f7fe'
  },
  historyGrid: {
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  timeText: {
    fontSize: '14px',
    paddingLeft: '32px',
    paddingBottom: '2px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingBottom: '6px',
      fontSize: '12px'
    }
  },
  botHistoryText: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  markdownTxt: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  questionText: {
    margin: 0,
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  historyAccordianTime: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  historyAccordianTxt: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  accordionSpace: {
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '7px'
    }
  },
  byzClickable: {
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none',
    margin: '0px 4px'
  },
  pagination: {
    '@media (max-width: 320px)': {
      '& button': {
        minWidth: '20px'
      }
    }
  },
  heading: {
    fontSize: '22px',
    padding: '10px 0px 10px 0px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      paddingLeft: '2px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  content: {
    justifyContent: 'center'
  },
  addWatchlistParent: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      'justify-content': 'space-between'
    }
  },
  skelHeading: {
    width: '40rem'
  },
  autocomplete: {
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    },
    width: '400px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F7F9FD',
      padding: '1px',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: '08'
    }
  },
  news_Icon: {
    padding: 0,
    height: '13px',
    width: '13px'
  },
  clockImg: {
    top: '3px',
    position: 'relative',
    width: '18px',
    height: '18px'
  },
  gradientColor: {
    'background-clip': 'text !important',
    'background-image':
      'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) !important',
    '-webkit-text-fill-color': 'transparent !important'
  },
  compHeading: {
    'font-family': 'Satoshi!important',
    'font-size': '16px!important',
    'font-style': 'normal!important',
    'font-weight': '700!important',
    'line-height': '28px!important'
  },
  liCls: {
    marginTop: '0!important',
    '& li': {
      fontFamily: 'Satoshi !important',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '22px !important'
    }
  },
  txtField: {
    [theme.breakpoints.up('xs')]: {
      '& input': {
        width: '28rem!important'
      }
    }
  },
  typoSummary: {
    color: '#1D1D1D!important',
    fontWeight: '600!important'
  },
  accSummaryCls: {
    backgroundColor: '#FAFAFA!important',
    borderRadius: '8px!important',
    border: '1px solid #E8E8E8!important',
    margin: '0!important',
    'box-shadow': '0px 6px 8px 0px rgba(0, 0, 0, 0.06)!important'
  },
  spanSummTitle: {
    fontWeight: '700!important',
    'font-family': 'Satoshi!important',
    'font-size': '16px!important',
    'font-style': 'normal!important',
    'line-height': '28px!important'
  },
  pd140: {
    padding: '14px 0 14px 0'
  },
  typoGradient: {
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)!important',
    WebkitBackgroundClip: 'text!important',
    WebkitTextFillColor: 'transparent!important'
  },
  accSummContent: {
    margin: '0!important',
    padding: '16px 0!important'
  },
  accSummExpanded: {
    'min-height': '0!important'
  },
  readMore: {
    borderRadius: '4px !important',
    // border: `1px solid var(--PBgradient, #004EFE) !important`,
    background: `var(--PBgradient, linear-gradient(90deg, #004EFE -1.88%, #06BAC2 99.73%)) !important`,
    display: 'flex !important',
    height: '34px !important',
    padding: '10px 24px !important',
    alignItems: 'center !important',
    gap: '6px !important',
    '& p': {
      color: '#FFF !important',
      fontFamily: 'Satoshi !important',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '500!important',
      lineHeight: 'normal !important',
      textTransform: 'none !important'
    }
  }
}));

const Summary = ({
  showSeeMore,
  summaryData,
  loading,
  summaryTitle,
  page,
  setPage,
  isSectorShown,
  isSector,
  watchlists,
  themeOptions,
  setThemes,
  themes
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const { t } = useTranslation();
  const isWatchlistExists = watchlists && watchlists.length > 0;
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  const showCompanyNews = false;
  useEffect(() => {
    if (summaryData && summaryData.length) {
      const allIndices = summaryData.map((_, index) => index);
      setExpandedAccordions(allIndices);
    }
  }, [summaryData]);

  const isAccordionExpanded = (accordionId) => {
    return expandedAccordions.includes(accordionId);
  };
  const toggleAccordion = (accordionId) => {
    if (isAccordionExpanded(accordionId)) {
      setExpandedAccordions(expandedAccordions.filter((id) => id !== accordionId));
    } else {
      setExpandedAccordions([...expandedAccordions, accordionId]);
    }
  };

  // const handleChange = (event, value) => {
  //   setThemes(value.map((item) => item.key));
  // };

  const handleChange = (event, value) => {
    if (value) {
      setThemes(value.map((item) => item.key));
    } else {
      setThemes([]);
    }
  };

  const GetKeyPointWithNewsLink = ({ keyPointsData }) => {
    const { news_link_info } = keyPointsData || {};
    const altText = news_link_info?.display_text ? news_link_info.display_text : 'here';
    console.log(news_link_info);
    return (
      <li>
        {keyPointsData?.keypoint}
        {'  '}
        {news_link_info && news_link_info.isActive && (
          <a href={news_link_info.srcLink} target="_blank" rel="noopener noreferrer">
            <img className={classes.news_Icon} src="/images/news/news-link.svg" alt={altText} />
          </a>
        )}
        {news_link_info && news_link_info.is_active && (
          <a href={news_link_info.src_link} target="_blank" rel="noopener noreferrer">
            <img className={classes.news_Icon} src="/images/news/news-link.svg" alt={altText} />
          </a>
        )}
      </li>
    );
  };
  // const handleDeleteTheme = (themeKey) => {
  //   setThemes(themes.filter((theme) => theme !== themeKey));
  // };
  return (
    <>
      {matches ? <div style={{ height: '20px' }} /> : null}
      <Grid
        container
        style={{
          'font-family': 'Satoshi',
          // height: '100vh',
          'overflow-y': 'auto',
          backgroundColor: '#FFFFFF',
          borderRadius: '24px',
          maxWidth: '1440px',
          margin: '0 auto'
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            // height: historyHeight,
            overflowY: 'auto'
          }}
          className={classes.historyGrid}
        >
          <div className={classes.addWatchlistParent}>
            <div>
              <Typography className={classes.heading}>
                <span className={classes.typoGradient}>Live Tracker</span>
              </Typography>
            </div>
            <div className={`${classes.heading} ${classes.txtField}`}>
              <CustomTextField
                value={''}
                handleChange={() => {}}
                placeholder={'Search'}
                disabled={true}
              />
            </div>
          </div>
          <div className={classes.addWatchlistParent}>
            <div className={classes.pd140}>
              {loading && (
                <Typography className={classes.skelHeading}>
                  <Skeleton animation="wave" height={10} width="20%" style={{ margin: 20 }} />
                </Typography>
              )}
              {/* {Array.isArray(watchlists) && !isWatchlistExists && !loading && (
                <Typography className={classes.heading}>
                  <span
                    style={{
                      color: '#1D1D1D'
                    }}
                  >
                    <>{t('portfolio.validationMsg')}</>
                  </span>
                </Typography>
              )} */}
              <Typography>
                {!loading && <span className={classes.spanSummTitle}>{summaryTitle}</span>}
              </Typography>
            </div>
            <div className={classes.heading}>
              <Autocomplete
                disableCloseOnSelect
                multiple
                options={themeOptions}
                className={classes.autocomplete}
                getOptionLabel={(option) => option.value || ''}
                getOptionSelected={(option, value) => option.key === value.key}
                onChange={(event, value) => handleChange(event, value)}
                renderOption={(option, { selected }) => (
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={selected || themes.includes(option.key)} />
                    }
                    label={option.value}
                  />
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder={' Select Category'} />
                )}
              />
              <div
                style={{
                  marginTop: '10px',
                  // display: 'flex',
                  alignItems: 'center',
                  display: 'none'
                }}
              >
                {themes.length > 0 && themes[0] != '' > 0 && (
                  <div style={{ paddingRight: '5px', fontSize: '18px' }}>Themes :</div>
                )}
                {themes.length > 0 &&
                  themes[0] != '' &&
                  themes.map((theme) => (
                    <Chip
                      key={theme}
                      label={themeOptions.find((option) => option.key === theme)?.value} // Access the corresponding value from themeOptions
                      // onDelete={() => handleDeleteTheme(theme)}
                      color="primary"
                      style={{ marginRight: '5px', marginBottom: '5px' }}
                    />
                  ))}
              </div>
              <Button
                style={{ textTransform: 'none', display: 'none' }}
                onClick={() => {
                  navigate('/watchlist-company', { state: { createWatchlist: true } });
                }}
              >
                <Typography style={{ fontFamily: 'Satoshi', fontWeight: 'bold' }}>
                  {t('portfolio.Createwatchlist')}
                </Typography>
              </Button>
            </div>
          </div>

          {loading && (
            <>
              {Array.from({ length: 10 }).map((_, index) => (
                <div key={index}>
                  <Card
                    sx={{
                      maxWidth: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: '#FAFAFA',
                      borderRadius: '8px',
                      border: '1px solid #E8E8E8'
                    }}
                    elevation={0}
                  >
                    <Skeleton animation="wave" height={10} width="20%" style={{ margin: 20 }} />
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="70%"
                      style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                    />
                  </Card>
                  <br />
                </div>
              ))}
            </>
          )}
          <div>
            {!isSectorShown && isSector ? (
              <div>
                <Accordion expanded={false} className={classes.accSummaryCls} elevation={0}>
                  <AccordionSummary
                    classes={{
                      content: classes.accSummContent,
                      expanded: classes.accSummExpanded
                    }}
                    expandIcon={<SeeMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        color: '#1D1D1D',
                        fontWeight: 600
                      }}
                      className={classes.historyAccordianTime}
                    >
                      Stay tuned for updates! We're currently working on generating summaries for
                      the Watchlist / Sector
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ overflowX: 'auto' }}>
                    <div>
                      <Typography
                        style={{
                          color: '#1D1D1D',
                          fontWeight: 600
                        }}
                        className={classes.historyAccordianTime}
                      >
                        Coming Soon
                      </Typography>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className={classes.accordionSpace}></div>
              </div>
            ) : (
              <>
                {summaryData.map((smData, index) => {
                  const {
                    start_date_of_summary,
                    date,
                    summary,
                    company_with_news,
                    header,
                    paragraph
                  } = smData;
                  return (
                    <div key={index} id={index}>
                      <Accordion
                        // expanded={isAccordionExpanded(index)}
                        expanded={isAccordionExpanded(index)}
                        onChange={() => toggleAccordion(index)}
                        className={classes.accSummaryCls}
                        elevation={0}
                      >
                        <AccordionSummary
                          classes={{
                            content: classes.accSummContent,
                            expanded: classes.accSummExpanded
                          }}
                          expandIcon={<SeeMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ 'min-height': 'auto!important' }}
                        >
                          {start_date_of_summary ? (
                            <Typography
                              className={`${classes.historyAccordianTime} ${classes.gradientColor}`}
                            >
                              <ClockIconGradient className={classes.clockImg} />
                              <span>
                                {` ${formatedTime(start_date_of_summary)} to ${formatedTime(
                                  date
                                )} `}
                              </span>
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                color: '#1D1D1D',
                                fontWeight: 600
                              }}
                              className={classes.historyAccordianTime}
                            >
                              {summary?.paragraph ? (
                                summary?.paragraph
                              ) : (
                                <>
                                  {isWatchlistExists ? (
                                    <>{`${header} - Highlights from `}</>
                                  ) : (
                                    <>{t('portfolio.validationMsg')}</>
                                  )}
                                </>
                              )}
                            </Typography>
                          )}
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ overflowX: 'auto', padding: '0px 16px 16px' }}
                          id={index}
                        >
                          {!summary && paragraph ? <div>{paragraph}</div> : null}
                          <div>
                            {summary && summary.length > 0 && Array.isArray(summary) ? (
                              <>
                                {summary.map((x, i) => {
                                  return (
                                    <Fragment key={`${x}-${i}`}>
                                      <Typography
                                        className={`${classes.historyAccordianTime} ${classes.typoSummary}`}
                                      >
                                        {x?.entity_name}
                                      </Typography>
                                      <ul key={i} className={classes.liCls}>
                                        {x?.theme_points &&
                                          x?.theme_points.length > 0 &&
                                          x?.theme_points.map((x, index) => {
                                            return (
                                              <GetKeyPointWithNewsLink
                                                key={index}
                                                keyPointsData={x}
                                              />
                                            );
                                          })}
                                      </ul>
                                    </Fragment>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {summary &&
                                  Object.keys(summary) &&
                                  Object.keys(summary).length > 0 &&
                                  Object.keys(summary).map((x, i) => {
                                    return (
                                      <Fragment key={`${x}-${i}`}>
                                        <Typography
                                          className={`${classes.historyAccordianTime} ${classes.typoSummary}`}
                                        >
                                          {summary[x]?.name}
                                        </Typography>
                                        <ul key={i}>
                                          {summary[x]?.theme_points &&
                                            summary[x]?.theme_points.length > 0 &&
                                            summary[x]?.theme_points.map((x, index) => {
                                              return (
                                                <GetKeyPointWithNewsLink
                                                  key={index}
                                                  keyPointsData={x}
                                                />
                                              );
                                            })}
                                        </ul>
                                      </Fragment>
                                    );
                                  })}
                              </>
                            )}
                          </div>
                          {showCompanyNews && company_with_news && company_with_news.length > 0 && (
                            <div>
                              <Typography
                                style={{
                                  color: '#1D1D1D',
                                  fontWeight: 600
                                }}
                                className={classes.historyAccordianTime}
                              >
                                {t('tab.Companies')}
                              </Typography>
                              <Typography
                                style={{
                                  color: '#1D1D1D',
                                  fontWeight: 500,
                                  paddingTop: '5px'
                                }}
                              >
                                {company_with_news && company_with_news.join(', ')}
                              </Typography>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                      <div className={classes.accordionSpace}></div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {showSeeMore ? (
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}
            >
              <Button
                className={classes.readMore}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                <Typography>{t('history.seeMoreSessionDetials')}</Typography>
                {/* <SeeMore style={{ paddingLeft: '5px' }} /> */}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {matches ? <div style={{ height: '10px' }} /> : null}
    </>
  );
};

export default Summary;
