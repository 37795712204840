import axios from 'axios';

export const axiosInterceptor = (auth) => {
  axios.interceptors.request.use(
    async (config) => {
      const session = await auth.getSession();
      const token = session?.idToken?.jwtToken;
      const skipAuth = config?.headers?.skipAuth;
      if (!skipAuth && token) {
        config.headers['Authorization'] = token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
