import { Box, Slide, Snackbar, Typography, makeStyles } from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#F3FFF4',
    padding: '5px 10px',
    margin: '8px',
    borderLeft: '3px solid #22A428',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.10)'
  },
  icon: {
    marginRight: '8px',
    color: '#22A428'
  },
  typo: {
    fontSize: '13px',
    color: '#171717',
    fontWeight: '500'
  }
}));

const SuccessToast = ({ successMessage }) => {
  const classes = useStyles();
  return (
    successMessage && (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successMessage}
        TransitionComponent={Slide}
        TransitionProps={{
          direction: 'down'
        }}
        autoHideDuration={6000}
        style={{ marginTop: '65px' }}
      >
        <Box className={classes.box}>
          <CheckCircleOutlined className={classes.icon} />
          <Typography className={classes.typo}>{successMessage}</Typography>
        </Box>
      </Snackbar>
    )
  );
};

export default SuccessToast;
