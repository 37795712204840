import { Checkbox, makeStyles, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Chip } from '@mui/material';
import CustomTextField from '../textfield';
import { getNestedValue } from '../../utils/utils';
import StaticChip from '../staticChip';
import EntityTypeFilterTabs from './entityTypeFilterTabs';

const useStyles = makeStyles(() => ({
  autoCompletRoot: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '7px !important'
    }
  },
  pd4: {
    padding: '4px 0 4px 0'
  },
  optionMainDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0px'
  },
  optionTypo: {
    fontSize: '14px',
    fontWeight: 400
  }
}));
const MultiSearch = ({
  className = '',
  options = [],
  selectedValues = [],
  placeholder = '',
  handleChange = () => {},
  chip = null, // flag to show selected value as chips in the search bar itself
  handleDeleteChip = () => {},
  clearable = false,
  optionFormat = { key: 'key', value: 'value', chipValue: 'chipValue' }, //model for objects in options to avoid loop to make it compatible to key and value
  errorMessage = '',
  disabled = null,
  showCheckBox = true,
  chipValueMap = [],
  filterTabsMap = [],
  setFilterEntityType = () => {},
  filterEntityType = 'all'
}) => {
  const classes = useStyles();
  const getChipDisplayText = (option) => {
    let chipValue = getNestedValue(option, optionFormat.chipValue);
    chipValue = chipValueMap.find((obj) => obj.key === chipValue)?.value || chipValue;
    return chipValue;
  };
  const filteredOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        (getNestedValue(option, 'entity_type') === filterEntityType ||
          filterEntityType === 'all') &&
        getNestedValue(option, 'entity_name')?.toLowerCase().includes(inputValue?.toLowerCase())
    );
  };
  return (
    <>
      {selectedValues && (
        <form noValidate className={className} onSubmit={(e) => e.preventDefault()}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={options}
            className={classes.autoCompletRoot}
            getOptionLabel={(result) => getNestedValue(result, optionFormat.value) || ''}
            filterOptions={filteredOptions}
            value={selectedValues
              .map((selected) =>
                options.find(
                  (option) =>
                    getNestedValue(selected, optionFormat.key) ===
                    getNestedValue(option, optionFormat.key)
                )
              )
              .filter(Boolean)}
            renderTags={(selectedValues) => {
              return chip
                ? selectedValues.map((value) => (
                    <Chip
                      key={getNestedValue(value, optionFormat.key)}
                      label={getNestedValue(value, optionFormat.value)}
                      style={{
                        borderRadius: '2px',
                        margin: '2px 3px',
                        color: '#004CFF',
                        backgroundColor: '#E0E5F7'
                      }}
                      onDelete={() => handleDeleteChip(getNestedValue(value, optionFormat.key))}
                    />
                  ))
                : null;
            }}
            renderOption={(result) => (
              <div className={classes.optionMainDiv}>
                {selectedValues && showCheckBox && (
                  <Checkbox
                    style={{ color: '#004CFF', marginRight: '10px', width: '7px', height: '7px' }}
                    checked={
                      selectedValues.find(
                        (obj) =>
                          getNestedValue(obj, optionFormat.key) ===
                          getNestedValue(result, optionFormat.key)
                      )
                        ? true
                        : false
                    }
                  />
                )}
                <Typography className={`${classes.optionTypo} ${showCheckBox ? {} : classes.pd4}`}>
                  {getNestedValue(result, optionFormat.value)}
                </Typography>
                <StaticChip displayText={getChipDisplayText(result)} />
              </div>
            )}
            renderInput={(params) => (
              <CustomTextField
                params={params}
                placeholder={placeholder}
                errorMessage={errorMessage}
              />
            )}
            ListboxComponent={(props) => (
              <EntityTypeFilterTabs
                tabsOptions={filterTabsMap}
                props={props}
                setFilterTab={setFilterEntityType}
                selectedTab={filterEntityType}
              />
            )}
            onChange={(event, value) => {
              handleChange(event, value);
            }}
            disabled={disabled}
            disableClearable={clearable}
            autoHighlight
          />
        </form>
      )}
    </>
  );
};

export default MultiSearch;
