import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  errorColor: {
    color: 'red'
  }
}));

const ErrorSpan = ({ value }) => {
  const classes = useStyles();
  return <>{value && <span className={classes.errorColor}>{value}</span>}</>;
};

export default ErrorSpan;
