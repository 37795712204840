import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import './loadingPopover.css';

const useStyles = makeStyles({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  }
});

const LoadingDots = () => {
  return (
    <div className="loading-dots-popover">
      <div className="loading-dot-pop"></div>
      <div className="loading-dot-pop"></div>
      <div className="loading-dot-pop"></div>
    </div>
  );
};

const LoadingPopover = ({ open }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      <DialogContent
        style={{
          minWidth: '220px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <LoadingDots />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingPopover;
