import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  availableCredit: {
    'padding-bottom': '6px',
    'font-size': '14px',
    color: 'rgba(0, 0, 0, 0.698)',
    'text-align': 'center',
    display: 'block'
  }
}));

const RemainingCredits = ({
  className = '',
  credit,
  noCreditText = 'Your credits have been consumed. Please purchase additional credits to continue.',
  hereRoute = '/pricing',
  target = '_self'
}) => {
  const classes = useStyles();

  return (
    <span className={className ? className : classes.availableCredit}>
      {credit === 0 ? noCreditText : `${credit} credits remaining.`}{' '}
      {credit === 0 ? (
        <Link to={hereRoute} target={target}>
          here
        </Link>
      ) : null}
    </span>
  );
};

export default RemainingCredits;
