import { Typography, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  parentLabelLogoDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  },
  orgLogo: {
    maxWidth: '160px',
    paddingLeft: '20px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '130px',
      paddingLeft: '7px'
    }
  },
  pbLogoPowered: {
    maxWidth: '120px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '101px'
    }
  },
  pbLogoPoweredDiv: {
    paddingLeft: '24px'
  },
  poweredText: {
    fontSize: '11px',
    textAlign: 'justify',
    margin: '5px 0px -5px',
    color: '#373737',
    [theme.breakpoints.down('xs')]: {
      margin: '9px 0px -7px',
      fontSize: '8px'
    }
  }
}));

const WhiteLabelLogo = ({ whiteLabelDetails }) => {
  const classes = useStyles();
  const memoisedComponent = useMemo(() => {
    return (
      <div className={classes.parentLabelLogoDiv}>
        <img src={whiteLabelDetails?.org_logo_url} alt="Org-Logo" className={classes.orgLogo} />
        <div className={classes.pbLogoPoweredDiv}>
          <Typography className={classes.poweredText}>Powered by</Typography>
          <img src="/images/PBLogo.png" alt="PrivateBlok-Logo" className={classes.pbLogoPowered} />
        </div>
      </div>
    );
  }, [whiteLabelDetails]);
  return memoisedComponent;
};

export default WhiteLabelLogo;
