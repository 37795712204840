import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import React from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { ReactComponent as PostQuestion } from '../../../../icons/LogoMark.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setChatSearchInputDE } from '../../../action';
import LoadingDots from '../../../../atoms/loadingDots';
import NoDocTooltip from '../../noDocTooltip';

const Input = ({ handleQuestionClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedDocExp = useSelector((state) => state.tokenReducer.selectedDocExp);
  const sources =
    selectedDocExp && selectedDocExp.length > 0 ? `${selectedDocExp.length} Sources` : '';
  const dispatch = useDispatch();

  const loadingAnswerStreamDE = useSelector((state) => state.tokenReducer.loadingAnswerStreamDE);
  const chatsearchInputDE = useSelector((state) => state.tokenReducer.chatsearchInputDE);

  const handleChange = (event) => {
    dispatch(setChatSearchInputDE(event.target.value));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleQuestionClick(event, chatsearchInputDE);
    }
  };

  const isDocIds = selectedDocExp && selectedDocExp.length > 0;
  return (
    <div className={classes.inputContainer}>
      {sources && <div className={classes.sourcesText}>{sources}</div>}
      <NoDocTooltip>
        <TextField
          value={chatsearchInputDE}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          fullWidth
          disabled={!isDocIds}
          variant="outlined"
          placeholder={t('askBot.searchBar')}
          className={classes.textField}
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ padding: '0px' }}
                onClick={(event) => {
                  if (chatsearchInputDE) {
                    handleQuestionClick(event, chatsearchInputDE);
                  }
                }}
              >
                {loadingAnswerStreamDE ? <LoadingDots /> : <PostQuestion />}
              </IconButton>
            )
          }}
        />
      </NoDocTooltip>
    </div>
  );
};

export default Input;
