import React from 'react';

import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Eye } from '../../components/icons/Eye.svg';
import { ReactComponent as EyeCrossed } from '../../components/icons/EyeCrossed.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  }
});

export const Name = ({ nameIsValid, setName }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={nameIsValid ? 'Name' : 'Minimum 2 characters'}
      error={!nameIsValid}
      onChange={(evt) => {
        setName(evt.target.value);
      }}
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline
        }
      }}
      InputLabelProps={{
        style: {
          color: '#08070d',
          opacity: 0.8
        }
      }}
    />
  );
};

Name.propTypes = {
  nameIsValid: PropTypes.any,
  setName: PropTypes.any
};

export const Email = ({ emailIsValid, setEmail }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={emailIsValid ? 'Email' : 'Invalid Email'}
      error={!emailIsValid}
      onChange={(evt) => {
        setEmail(evt.target.value);
      }}
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline
        }
      }}
      InputLabelProps={{
        style: {
          color: '#08070d',
          opacity: 0.8
        }
      }}
    />
  );
};

Email.propTypes = {
  emailIsValid: PropTypes.any,
  setEmail: PropTypes.any
};

export const Password = ({ label, passwordIsValid, setPassword, password, validate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const specialCharacterFormat = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; // eslint-disable-line
  const mixedcaseCharacter = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
  const checkContainsNumbers = () => {
    return !/\d/.test(password);
  };
  const checkContainsSpecialCharacter = () => {
    return !specialCharacterFormat.test(password);
  };

  const checkCharCount = () => {
    return password.length < 8;
  };

  const checkContainsMixedcase = () => {
    return !mixedcaseCharacter.test(password);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const validateInput = (validationFunc, errorMessage, successMessage) => {
    const isValid = validationFunc();
    if (!isValid) {
      return (
        <Box
          m={0.4}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <CheckCircleOutlineIcon
            style={{
              marginRight: '2px',
              color: '#3C962A',
              backgroundColor: '#C7F1BF',
              borderRadius: '50%'
            }}
          />
          <Typography
            style={{
              fontSize: '13px',
              color: '#206113',
              opacity: 1
            }}
          >
            {successMessage}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box
          m={0.4}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ErrorOutlineIcon
            style={{
              marginRight: '2px',
              color: '#EB5B55',
              backgroundColor: '#FDF6F6',
              borderRadius: '50%'
            }}
          />
          <Typography
            style={{
              fontSize: '13px',
              color: '#EB5B55'
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
      );
    }
  };
  return (
    <>
      {!validate ? (
        <TextField
          fullWidth
          // type="password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          label={label}
          onChange={(evt) => setPassword(evt.target.value)}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline
            },
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <Eye /> : <EyeCrossed />}
              </IconButton>
            )
          }}
          InputLabelProps={{
            style: {
              color: '#08070d',
              opacity: 0.8
            }
          }}
          id="signin-password"
        />
      ) : (
        <>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            label={password ? label : passwordIsValid ? label : 'Minimum 8 characters'}
            error={!passwordIsValid}
            onChange={(evt) => setPassword(evt.target.value)}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline
              },
              endAdornment: (
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <Eye /> : <EyeCrossed />}
                </IconButton>
              )
            }}
            InputLabelProps={{
              style: {
                color: '#08070d',
                opacity: 0.8
              }
            }}
            id="signin-password"
          />
          {password && (
            <>
              <Grid container>
                <Grid item xs={5} sm={5} lg={5}>
                  {validateInput(checkCharCount, t('password.validMin8'), t('password.validMin8'))}
                </Grid>
                <Grid item xs={7} sm={7} lg={7}>
                  {validateInput(
                    checkContainsSpecialCharacter,
                    t('password.validSplCh'),
                    t('password.validSplCh')
                  )}
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '4px' }}>
                <Grid item xs={5} sm={5} lg={5}>
                  {validateInput(
                    checkContainsNumbers,
                    t('password.valid1Num'),
                    t('password.valid1Num')
                  )}
                </Grid>
                <Grid item xs={7} sm={7} lg={7}>
                  {validateInput(
                    checkContainsMixedcase,
                    t('password.validMixCase'),
                    t('password.validMixCase')
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

Password.propTypes = {
  label: PropTypes.any,
  passwordIsValid: PropTypes.any,
  setPassword: PropTypes.any,
  password: PropTypes.any,
  validate: PropTypes.any
};

export const Username = ({ usernameIsValid, setUsername }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={usernameIsValid ? 'Username' : 'Invalid Username'}
      error={!usernameIsValid}
      onChange={(evt) => {
        setUsername(evt.target.value);
      }}
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline
        }
      }}
      InputLabelProps={{
        style: {
          color: '#08070d',
          opacity: 0.8
        }
      }}
      id="signin-username"
    />
  );
};

Username.propTypes = {
  usernameIsValid: PropTypes.any,
  setUsername: PropTypes.any
};

export const Code = ({ codeIsValid, setCode }) => {
  const classes = useStyles();
  return (
    <form noValidate>
      <TextField
        fullWidth
        variant="outlined"
        label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
        error={!codeIsValid}
        onChange={(evt) => {
          setCode(evt.target.value);
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline
          }
        }}
        InputLabelProps={{
          style: {
            color: '#08070d',
            opacity: 0.8
          }
        }}
      />
    </form>
  );
};

Code.propTypes = {
  emailIsValid: PropTypes.any,
  setEmail: PropTypes.any,
  label: PropTypes.any,
  passwordIsValid: PropTypes.any,
  setPassword: PropTypes.any,
  usernameIsValid: PropTypes.any,
  setUsername: PropTypes.any,
  codeIsValid: PropTypes.any,
  setCode: PropTypes.any
};

export const InviteCode = ({ inviteCodeIsValid, setInviteCode }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={inviteCodeIsValid ? 'Invite Code' : 'Minimum 2 characters'}
      error={!inviteCodeIsValid}
      onChange={(evt) => {
        setInviteCode(evt.target.value);
      }}
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline
        }
      }}
      InputLabelProps={{
        style: {
          color: '#08070d',
          opacity: 0.8
        }
      }}
    />
  );
};

InviteCode.propTypes = {
  inviteCodeIsValid: PropTypes.any,
  setInviteCode: PropTypes.any
};
