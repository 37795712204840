import { Paper } from '@mui/material';
import TearSheetHeader from './tearSheetHeader';
import TearSheetContent from './tearSheetContent';
import { makeStyles } from '@material-ui/core';
import { postApi } from '../../../../services/token';
import { updateParamsInURL, getFirstProperty, hideShowMessage } from '../../../../helpers';

const useStyles = makeStyles(() => ({
  tearSheet: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '16px !important',
    padding: '16px'
  }
}));

const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;

const TearSheet = ({
  tearSheetData = {},
  getSummaryData = () => {},
  // entity_type = 'company',
  setLoading,
  navigate,
  location,
  handleReportDeleteOpen,
  setErrorMessage,
  getLimits
}) => {
  const classes = useStyles();
  const entity_id = tearSheetData?.metadata?.entity_id;
  // const getTearsheetLatestPayload = () => {
  //   const payload = {
  //     report_name: tearSheetData.report_name,
  //     entity_list: [
  //       {
  //         entity_type: entity_type,
  //         entity_id: tearSheetData.metadata.entity_id
  //       }
  //     ],
  //     question_groups: tearSheetData.question_groups
  //   };
  //   return payload;
  // };

  const triggerLatest = async () => {
    const currReportID = tearSheetData.metadata.report_id;
    if (currReportID) {
      try {
        setLoading(true);
        const { data } = await postApi(`${COMPANY_API_URL}/v2/report/${currReportID}/refresh`, {});
        setLoading(false);
        if (data?.data?.success) {
          const reportId =
            (data?.data?.report_id &&
              data?.data?.report_id.length > 0 &&
              data?.data?.report_id[0]) ||
            '';
          const addParamObj = reportId
            ? { success: 'create', report_id: reportId }
            : { success: 'create' };

          await getSummaryData({ entity_id }, reportId);
          await getLimits();

          updateParamsInURL({
            navigate,
            location,
            addParams: addParamObj,
            removeParams: ['action', 'entity_id']
          });
        } else {
          hideShowMessage(
            getFirstProperty(data?.data?.errors) ||
              'Request for latest report is failed. Please check the data input and try again',
            setErrorMessage
          );
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  // const triggerLatestOld = async () => {
  //   try {
  //     setLoading(true);
  //     const payload = getTearsheetLatestPayload();
  //     const { data } = await postApi(`${COMPANY_API_URL}/v2/report`, payload);
  //     const reportId =
  //       (data?.data?.report_id && data?.data?.report_id.length > 0 && data?.data?.report_id[0]) ||
  //       '';
  //     const addParamObj = reportId
  //       ? { success: 'create', report_id: reportId }
  //       : { success: 'create' };

  //     await getSummaryData({ entity_id }, reportId);
  //     updateParamsInURL({
  //       navigate,
  //       location,
  //       addParams: addParamObj,
  //       removeParams: ['action', 'entity_id']
  //     });
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const tearSheetHeaderProps = {
    tearSheetData: tearSheetData,
    triggerLatest,
    handleReportDeleteOpen
  };
  return (
    <Paper className={classes.tearSheet}>
      {tearSheetData && (
        <>
          <TearSheetHeader {...tearSheetHeaderProps} />
          {tearSheetData?.response && Object.keys(tearSheetData?.response).length > 0 && (
            <TearSheetContent tearSheetData={tearSheetData} />
          )}
        </>
      )}
    </Paper>
  );
};

export default TearSheet;
