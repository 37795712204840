import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DocExplorerFiles from './Files';
import DocExplorerChat from './Chat';

const useStyles = makeStyles((theme) => ({
  mainGridContainer: {
    padding: '20px 120px 10px 120px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 80px'
    }
  }
}));

const DocExplorer = () => {
  const classes = useStyles();

  return (
    <>
      <div style={{ minHeight: 'calc(100vh - 120px)' }}>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <Grid container className={classes.mainGridContainer}>
            <Grid xs={8.25}>
              <DocExplorerChat />
            </Grid>
            <Grid xs={3.75}>
              <DocExplorerFiles />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default DocExplorer;
