import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import SocialMedia from './socialMedia';

const StringAccordian = ({ title, strAccordianData, currentTabsData, classes }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleAccordionToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div style={{ paddingTop: '15px' }}>
      <Accordion
        defaultExpanded={true}
        style={{
          backgroundColor: '#FAFAFA',
          borderRadius: '8px',
          border: '1px solid #E8E8E8'
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<SeeMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ height: '56px', minHeight: '56px', display: 'flex' }}
          onClick={handleAccordionToggle}
        >
          <Typography className={`${classes.fontSize14} ${classes.fontWeight500}`}>
            {title}
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <Typography
              style={{
                fontSize: '12px',
                color: '#1D1D1D',
                fontWeight: 500,
                paddingRight: '5px',
                paddingTop: '1.5px'
              }}
            >
              {isExpanded ? 'Show' : 'Hide'}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: '0px' }}>
          <Typography className={classes.fontSize12}>{strAccordianData}</Typography>
          {'Social Media' in currentTabsData ? (
            <>
              <SocialMedia
                socialIconsData={currentTabsData['Social Media'].bt_value}
                classes={classes}
              />
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default StringAccordian;
