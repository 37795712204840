// import { useState, useEffect } from 'react';
import CustomDialog from '../customDialog';
import { Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Message = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box width="100%">
        <Typography
          style={{ fontSize: '24px', lineHeight: '70px', letterSpacing: '0px', color: '#08070D' }}
        >
          {t('credit.upgrade')}
        </Typography>
      </Box>
      <Box width="100%" marginY={1}>
        {t('credit.zeroCreditMessage')}
      </Box>
      <br />
      <Button
        id="signInBtn"
        color="primary"
        variant="contained"
        onClick={() => {
          navigate('/pricing');
        }}
        style={{
          width: '100%',
          background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
          height: '50px'
        }}
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 'medium',
            color: '#FFFFFF',
            textTransform: 'none'
          }}
        >
          Buy
        </Typography>
      </Button>
    </>
  );
};

const CreditWarning = ({ openDialog, setOpenDialog }) => {
  return (
    <>
      <CustomDialog
        id="customDialogMain"
        dialogProps={{
          open: openDialog,
          handleClose: () => {
            setOpenDialog(false);
          },
          Component: Message
        }}
      />
      {/* {openDialog !== 'dsds' ? null : (
        <CustomDialog
          id="customDialogMain"
          dialogProps={{
            open: !openDialog,
            handleClose: () => {},
            Component: Message,
            isDialog: true
          }}
        />
      )} */}
    </>
  );
};

export default CreditWarning;
