import React, { useEffect } from 'react';
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../atoms/header';
import SubHeader from '../atoms/subHeader';
import Para from '../atoms/paragraph';
import CustomList from '../atoms/customList';
import FooterMenu from '../footer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  headingColor: {
    'background-image': 'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%)',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  header: {
    marginBottom: theme.spacing(0.8),
    fontFamily: 'Satoshi',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '38px'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: '16px 24px 24px 24px',
    textAlign: 'justify',
    color: theme.palette.text.secondary,
    borderRadius: '16px!important',
    [theme.breakpoints.only('xs')]: {
      padding: '20px'
    }
  },
  customContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '87rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0!important'
    }
  },
  containerDiv: {
    padding: '20px 32px 20px 32px',
    background: '#F7F9FD',
    [theme.breakpoints.only('xs')]: {
      padding: '20px'
    }
  },
  upperCase: {
    textTransform: 'uppercase!important'
  },
  subHeading: {
    color: '#171717',
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    padding: '25px 0 5px 0'
  },
  para: {
    color: '#171717',
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    'padding-top': '10px'
  },
  fw600: {
    fontWeight: 600
  },
  listItem: {
    '& li': {
      padding: '0!important',
      '& span': {
        color: 'var(--Neutral-Colors-Color-800, var(--grey7, #171717))',
        fontFeatureSettings: "'clig' 0, 'liga' 0",
        fontFamily: 'Satoshi',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px'
      }
    }
  }
}));

const Privacy = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll(0, 0);
  });

  const personalInfoItems = [
    '<span style="font-style: italic;">Account Information</span>: When you create an account with us, we will collect information associated with your account, including your name, contact information, account credentials, payment card information, and transaction history, (collectively, “Account Information”).',
    '<span style="font-style: italic;">User Content</span>: When you use our Services, we collect Personal Information that is included in the input, file uploads, or feedback that you provide to our Services (“Content”). ',
    '<span style="font-style: italic;">Communication Information</span>: If you communicate with us, we collect your name, contact information, and the contents of any messages you send (“Communication Information”).',
    '<span style="font-style: italic;">Social Media Information</span>: We have pages on social media sites like Instagram, Facebook, Medium, Twitter, YouTube and LinkedIn. When you interact with our social media pages, we will collect Personal Information that you elect to provide to us, such as your contact details (collectively, “Social Information”). In addition, the companies that host our social media pages may provide us with aggregate information and analytics about our social media activity.'
  ];

  const personalInfoReceiveItems = [
    '<span style="font-style: italic;">Log Data</span>: Information that your browser automatically sends when you use our Services. Log data includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interact with our website.',
    '<span style="font-style: italic;">Usage Data</span>: We may automatically collect information about your use of the Services, such as the types of content that you view or engage with, the features you use and the actions you take, as well as your time zone, country, the dates and times of access, user agent and version, type of computer or mobile device, and your computer connection.',
    '<span style="font-style: italic;">Device Information</span>: Includes name of the device, operating system, device identifiers, and browser you are using. Information collected may depend on the type of device you use and its settings.',
    '<span style="font-style: italic;">Cookies</span>: We use cookies to operate and administer our Services and improve your experience. A “cookie” is a piece of information sent to your browser by a website you visit. You can set your browser to accept all cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide each time whether to accept it. However, refusing a cookie may in some cases preclude you from using, or negatively affect the display or function of, a website or certain areas or features of a website. For more details on cookies, please visit our Cookie Policy.',
    '<span style="font-style: italic;">Analytics</span>: We may use a variety of online analytics products that use cookies to help us analyze how users use our Services and enhance your experience when you use the Services.',
    '<span style="font-style: italic;">Location Data</span>: We may use and store information about your location if you give us permission to do so. We use this data to provide you with features of our Service, and to further improve and customize our Service. If you wish to not share your location with us, can you disable the location setting on your device when you use our Service at any time'
  ];

  const infoCollectedItems = [
    'to provide and maintain our Service;',
    'to notify you about changes to our Service;',
    'to allow you to participate in interactive features of our Service when you choose to do so;',
    'to provide customer support;',
    'To prevent fraud, criminal activity, or misuses of our Services, and to protect the security of our IT systems, architecture, and networks;',
    'to gather analysis or valuable information so that we can improve our Service;',
    'to monitor the usage of our Service;',
    'to detect, prevent and address technical issues;',
    'to fulfil any other purpose for which you provide it;',
    'to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;',
    'to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;',
    'to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;',
    'in any other way we may describe when you provide the information;',
    'for any other purpose with your consent or in accordance with applicable law.'
  ];

  const disclosurePersonalInfoItems = [
    '<strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.',
    '<strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.',
    '<strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.',
    '<strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).',
    '<strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved. <br/> More specifically, we may need to process your data or share your personal information in the following situations:',
    '<strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.',
    '<strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services or promotions.'
  ];

  const upRemovingInfoItems = [
    '<strong>If you wish to correct, update, or delete inaccuracies in personal information stored or processed by Us or access such information, you can email a request regarding the same.</strong>',
    '<strong>To make a request to have personal information maintained by us accessed or updated please email <a href="mailto:info@privateblok.ai">info@privateblok.ai</a>. Requests to access, change, or remove your information will be handled within thirty (30) days.</strong>',
    '<strong>We will retain personal information that we store and process for as long as needed to provide the services to you. We will retain and use this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</strong>',
    '<strong>If You have provided us with information in connection with your use of our Services, we will update or delete this information upon your request, provided that, notwithstanding such request, this information may be retained for as long as you maintain Your use of our Services, or as needed to provide you with our Services, comply with our legal obligations, resolve disputes and enforce our agreements.</strong>'
  ];

  const thirdPartyItems = [
    'Our Services contains links to various third party and other sites having their own terms and conditions and privacy Policy. We may use remarketing services to advertise on third-party websites to you after you visited our Service.',
    'You are cautioned to read the terms and conditions of such third party websites and/or privacy policies before using such websites. You acknowledge that we have no control over such third party’s site and We shall not be responsible or liable to claim arising out of such third party site, in relation to any content, products or services made available on such a website.',
    'You are requested to visit Privacy Policy of such websites before submitting any response.'
  ];

  const retainItems = [
    'We will retain your Personal Data only for as long as is necessary for the purposes set out in this Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations in accordance with applicable law.',
    'We may disclose your personal information in response to summons or subpoenas, court orders, or other legal process, or to establish or exercise our legal rights or defend against legal claims. We may also share such information if we believe it is necessary to do so, in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Services, or as otherwise required by law.',
    'We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a short period, except when this data is used to strengthen the security or to improve the functionality of our Service, or in the event we are legally obligated to retain this data for longer time periods.'
  ];

  const rightsItems = [
    'The right to request a copy of your personal data which we hold about you.',
    'The right to request for any correction to any personal data if it is found to be inaccurate or out of date.',
    'The right to withdraw Your consent to the processing at any time.',
    'The right to object to the processing of personal information.',
    'The right to obtain information as to whether personal data are transferred to a third country or to an international organization.'
  ];

  const dataSecurityItems = [
    'Your privacy is very important to the company and we are committed to protecting your personal information from unauthorized access, use or disclosure. We protect the data collected from you through both online and offline mode. We, in order to ensure security and confidentiality of your personal information, have proportionate and appropriate physical, electronic and managerial security measures and practices in place. We protect your information from unauthorized access, alteration, disclosure, loss, misuse, or destruction.',
    'The security of your data is important to us but remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.',
    'We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once it is received.',
    'Further, you shall be responsible for providing accurate contact information to the Company at all times. You shall indemnify and hold the Company harmless against any and all claims made in relation to inaccurate or wrong information provided by you to the Company.'
  ];

  const transferDataItems = [
    'Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.',
    'If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.',
    'Your consent to this Policy followed by your submission of such information represents your consent to transfer such information. The Company will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.'
  ];

  const dataProcessorItems = [
    'We may employ third-party companies and individuals to facilitate our Service and provide Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and such Parties are bound by obligations of confidentiality and are obligated not to disclose or use it for any other purpose.',
    'We may use third-party Service Providers to monitor and analyze Your use of our Service.',
    'We may use third-party Service Providers to automate the development process of our Service.'
  ];

  return (
    <>
      <div className={classes.containerDiv}>
        <Container className={classes.customContainer} maxWidth="md">
          <Paper className={classes.paper}>
            <Header>Privacy Policy</Header>
            <div>
              <Para>
                We at BlokTrek (together with our affiliates, “BlokTrek”, “PrivateBlok”, “we”,
                “our”, “Company” or “us”) respect your privacy and are strongly committed to keeping
                secure any information we obtain from you or about you. This Privacy Policy
                describes our practices with respect to Personal Information (any personally
                identifiable information that We may collect from You and can be used to identify,
                locate, or contact you. Such information includes but not limited to, your name,
                email address, postal address, and phone numbers) we collect from or about you when
                you use our website, applications, and services (collectively, “Services”).
              </Para>
              <Para>
                BlokTrek provides a platform for private companies globally, and most of the
                information available on our site is collected from publicly available sources on
                the internet.
              </Para>
              <Para>
                The purpose of this privacy policy is to explain to you in the clearest way possible
                what information we collect, how we use it, and what rights you have in relation to
                it. You are advised to read and understand the privacy policy carefully since by
                accessing the Website, you are bound by the terms and conditions of the below
                mentioned privacy policy and consent to the collection, storage, and use of
                information pertaining to you as specified in this policy. If there are any terms in
                this privacy policy that you do not agree with, please discontinue use of our
                Services immediately.
              </Para>
            </div>

            <div>
              <SubHeader>INFORMATION COLLECTION</SubHeader>
              <Para>
                <span className={classes.fw600}>Personal Information You Provide : </span> We
                collect Personal Information if you create an account to use our Services or
                communicate with us as follows:
              </Para>
              <CustomList items={personalInfoItems} isNumberedList={false} isContainHtml={true} />
              <Para>
                <span className={classes.fw600}>
                  Personal Information We Receive Automatically from your use of the services:{' '}
                </span>
              </Para>
              <Para>
                When you visit, use, or interact with the Services, we receive the following
                information about your visit, use, or interactions (“Technical Information”):
              </Para>
              <CustomList
                items={personalInfoReceiveItems}
                isNumberedList={false}
                isContainHtml={true}
              />
            </div>

            <div>
              <SubHeader>USE OF INFORMATION COLLECTED</SubHeader>
              <Para>
                We will not use Your data in a manner materially different from that stated in this
                Policy without notifying you. The Company uses the collected data for various
                purposes including but not limited to:
              </Para>
              <CustomList items={infoCollectedItems} isNumberedList={false} />
              <Para>
                <span className={classes.fw600}>Aggregated or De-Identified Information. </span> We
                may aggregate or de-identify Personal Information so that it may no longer be used
                to identify you and use such information to analyze the effectiveness of our
                Services, to improve and add features to our Services, to conduct research and for
                other similar purposes. In addition, from time to time, we may analyze the general
                behavior and characteristics of users of our Services and share aggregated
                information like general user statistics with third parties, publish such aggregated
                information or make such aggregated information generally available. We may collect
                aggregated information through the Services, through cookies, and through other
                means described in this Privacy Policy. We will maintain and use de-identified
                information in anonymous or de-identified form and we will not attempt to reidentify
                the information, unless required by law.
              </Para>
            </div>

            <div>
              <SubHeader>DISCLOSURE OF PERSONAL INFORMATION</SubHeader>
              <Para>
                We may process or share your data that we hold based on the following legal basis:
              </Para>
              <CustomList
                items={disclosurePersonalInfoItems}
                isNumberedList={false}
                isContainHtml={true}
              />
            </div>

            <div>
              <SubHeader>CORRECTING, UPDATING AND REMOVING YOUR INFORMATION</SubHeader>
              <CustomList items={upRemovingInfoItems} isNumberedList={false} isContainHtml={true} />
            </div>

            <div>
              <SubHeader>THIRD PARTY WEBSITE</SubHeader>
              <CustomList items={thirdPartyItems} isNumberedList={false} />
            </div>

            <div>
              <SubHeader>HOW LONG WE RETAIN</SubHeader>
              <CustomList items={retainItems} isNumberedList={false} />
            </div>

            <div>
              <SubHeader>YOUR RIGHTS</SubHeader>
              <Para>
                Unless subject to an exemption, you have the following rights with respect to your
                personal data:
              </Para>
              <CustomList items={rightsItems} isNumberedList={false} />
            </div>

            <div>
              <SubHeader>DATA SECURITY</SubHeader>
              <CustomList items={dataSecurityItems} isNumberedList={false} />
            </div>

            <div>
              <SubHeader>CHILDREN’S PRIVACY</SubHeader>
              <Para>
                Our Services are not intended for use by minors under the age of 18 (“Child” or
                “Children”, or minimum age required in your country. We do not knowingly collect
                Personal Data from Children under 18. If you become aware that a Child has provided
                us with Personal Data, please contact us at{' '}
                <a href="mailto:info@privateblok.ai">info@privateblok.ai</a>. If we become aware
                that we have collected Personal Data from Children without verification of parental
                consent, we take steps to remove such information from our Servers.
              </Para>
            </div>

            <div>
              <SubHeader>TRANSFER OF DATA</SubHeader>
              <CustomList items={transferDataItems} isNumberedList={false} />
            </div>

            <div>
              <SubHeader>DATA PROCESSORS AND SERVICE PROVIDERS</SubHeader>
              <CustomList items={dataProcessorItems} isNumberedList={false} />
            </div>

            <div>
              <SubHeader>DISCLOSURE OF INFORMATION UNDER LAW</SubHeader>
              <Para>
                The information / data collected from you may be shared or disclosed without
                obtaining your prior written consent if such disclosure is mandated under the
                applicable law.
              </Para>
            </div>

            <div>
              <SubHeader>JURISDICTION</SubHeader>
              <Para>
                The terms stated here shall be governed and interpreted by and construed in
                accordance with the laws of India and the courts having ordinary original
                jurisdiction at Bangalore, India shall have exclusive jurisdiction.
              </Para>
            </div>

            <div>
              <SubHeader>UPDATES TO THE POLICY</SubHeader>
              <Para>
                We may update this privacy policy from time to time. The updated version will be
                indicated by an updated "Revised" date and the updated version will be effective as
                soon as it is accessible. If we make material changes to this privacy policy, we may
                notify you either by prominently posting a notice of such changes or by directly
                sending you a notification. We encourage you to review this privacy policy
                frequently to be informed of how we are protecting your information. If you continue
                the use of the services and the Website it shall be deemed as your acceptance over
                the updated version of the policy.
              </Para>
            </div>

            <div>
              <SubHeader>QUERIES</SubHeader>
              <Para>
                For any queries related to Privacy Policy, please write to us at: <br />
                <a href="mailto:info@privateblok.ai">info@privateblok.ai</a>
              </Para>
            </div>
          </Paper>
        </Container>
      </div>
      <FooterMenu />
    </>
  );
};

export default Privacy;
