import { Button, Typography, makeStyles } from '@material-ui/core';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none !important',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.25px',
      right: '1.25px',
      top: '1.25px',
      bottom: '1.25px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    },
    '&:disabled': {
      background: '#797F8D'
    }
  },
  startIcon: {
    marginRight: '-4px',
    fontSize: '17px !important',
    color: '#08070D'
  },
  typo: {
    fontSize: '14px',
    color: '#08070D'
  }
}));

const SecondaryButton = ({
  handleClick = () => {},
  StartIcon = null,
  btnText = '',
  styleClass = {},
  toolTip = '',
  disabled = null
}) => {
  const classes = useStyles();
  return (
    <Tooltip disableHoverListener={!toolTip} title={toolTip}>
      <Button
        type="submit"
        variant="outlined"
        className={`${classes.root} ${styleClass}`}
        onClick={handleClick}
        startIcon={StartIcon ? <StartIcon className={classes.startIcon} /> : null}
        disabled={disabled}
      >
        <Typography className={classes.typo}>{btnText}</Typography>
      </Button>
    </Tooltip>
  );
};

export default SecondaryButton;
