import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getApi } from '../../../services/token';
import StatusChip from './statusChip';
import { dateFormat, getDisplayFileName } from '../../utils/utils';
import { Typography, makeStyles } from '@material-ui/core';
import TableComp from './table';
import { ReactComponent as ExceptionIcon } from '../../icons/ExceptionsIcon.svg';
import {
  ingestionMetricsDisplayNames,
  subDocFailureColumHeaders
} from '../../../constants/docLib/docDetailsPopUp';
import HoverLink from '../../atoms/hoverLink';
import { subDocFailureTable } from '../../../constants/strings/docLib';

const useStyles = makeStyles({
  fileName: {
    fontFamily: 'Satoshi',
    fontWeight: '700 !important',
    fontSize: '16px'
  },
  docDetials: {
    fontSize: '14px !important',
    display: 'flex',
    fontFamily: 'Satoshi',
    fontWeight: '700 !important'
  },
  horizontalLine: {
    margin: '4px 0px 8px 0px',
    border: '0px',
    backgroundColor: '#E6E8ED',
    height: '1px'
  },
  boxExceptionMessage: {
    display: 'flex',
    marginTop: '7px',
    alignItems: 'center'
  },
  exceptionIcon: {
    width: '16px',
    height: '16px',
    marginRight: '5px'
  },
  exceptionMessage: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#C44545'
  }
});

const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;

const DocDetailsPopUp = ({ row, handleClose, open, typeOfTable = 'subDocFailures' }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleGetFileInfo = async () => {
    try {
      const response = await getApi(`${ingestionUrl}/v1/document/${row.doc_id}`);
      let res = response?.data?.data;
      if (typeOfTable === 'subDocFailures') {
        let filteredData = res?.sub_doc_details;
        setData(filteredData.filter((subDoc) => subDoc.status === 'FAILED'));
      } else {
        let ingestionMetrics = res?.ingestion_metrics;
        setData(Object.entries(ingestionMetrics));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      handleGetFileInfo();
    }
  }, [open]);

  const subDocRowComps = [
    {
      comp: ({ row }) => (
        <HoverLink
          href={row?.source_link}
          target="_blank"
          displayText={getDisplayFileName(row.file_name, row.file_format)}
        />
      )
    },
    {
      comp: ({ row }) => <StatusChip status={row.status} />
    },
    {
      comp: ({ row }) => dateFormat(row.updated_at)
    }
  ];
  const subDocFailureLoadingState = loading
    ? {
        colCnt: 3,
        rowCnt: 4
      }
    : null;
  const subDocFailureTableProps = {
    columnHeaders: subDocFailureColumHeaders(),
    loading: subDocFailureLoadingState,
    tableData: data,
    tableRowComps: subDocRowComps
  };

  const ingestionMetricsLoadingState = loading
    ? {
        colCnt: 2,
        rowCnt: 4
      }
    : null;
  const getIngestionMetricDisplayName = (key) => {
    return ingestionMetricsDisplayNames.find((item) => item.key === key)?.displayName || key;
  };
  const ingestionMetricsTableProps = {
    loading: ingestionMetricsLoadingState,
    tableData: data,
    tableRowComps: [
      {
        comp: ({ row }) => getIngestionMetricDisplayName(row[0])
      },
      {
        comp: ({ row }) => row[1]
      }
    ]
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={handleClose}>
      <Box p={0} width="100%">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 16px 0px 16px',
            alignItems: 'center'
          }}
        >
          <Typography
            className={classes.fileName}
            style={{ fontFamily: 'Satoshi', file_size: '16px', fontWeight: 700 }}
          >
            {row.file_name}.{row.file_format}
          </Typography>
          <IconButton
            className={classes.closeBtn}
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </div>
        <hr className={classes.horizontalLine} />
        <DialogContent style={{ padding: '0px' }}>
          <>
            <Box
              style={{
                padding: '0px 16px 16px',
                borderRadius: '5px'
              }}
            >
              {((data && data.length > 0) || loading) &&
                (typeOfTable === subDocFailureTable ? (
                  <>
                    <Typography style={{ padding: '5px 0px' }} className={classes.docDetials}>
                      Components Details
                    </Typography>
                    <TableComp {...subDocFailureTableProps} />
                    <Box className={classes.boxExceptionMessage}>
                      <ExceptionIcon className={classes.exceptionIcon} />
                      <Typography className={classes.exceptionMessage}>
                        {t('list.subDocFailureMessage')}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography style={{ padding: '5px 0px' }} className={classes.docDetials}>
                      Document Details
                    </Typography>
                    <TableComp {...ingestionMetricsTableProps} />
                  </>
                ))}
            </Box>
          </>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DocDetailsPopUp;
