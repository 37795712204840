import { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxHeight: {
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  }
}));

const validationPopover = ({ condition, message, isFailed }) => {
  const searchParams = new URLSearchParams(document.location.search);
  const [paymentStatus, setPaymentStatus] = useState(searchParams.get('payment_status'));
  const classes = useStyles();

  useEffect(() => {
    if (paymentStatus) {
      localStorage.setItem('messageShown', 'true');

      setTimeout(() => {
        setPaymentStatus('');
      }, 100000);
    }
  }, [paymentStatus]);

  const hasMessageBeenShown = localStorage.getItem('messageShown');

  return (
    <>
      {paymentStatus && condition.includes(paymentStatus) && hasMessageBeenShown ? (
        <Box
          id="paymentMessage"
          style={{
            background: isFailed ? '#f7b6b6' : '#C7F1BF',
            borderRadius: '5px',
            opacity: 1,
            display: 'flex', // Set display to flex
            alignItems: 'center', // Vertically center align the content
            paddingRight: '10px'
          }}
          className={classes.boxHeight}
        >
          <CheckCircleOutlineIcon
            style={{
              marginLeft: '13px', // Set margin left to 13px
              marginRight: '2px', // Set margin right to 1px
              color: isFailed ? '#ef0f0f' : '#3C962A' // Set color of the icon to #3C962A
            }}
          />
          <Typography
            style={{
              fontSize: '14px',
              color: isFailed ? '#ef0f0f' : '#3C962A',
              opacity: 1,
              fontFamily: 'Satoshi',
              fontWeight: 'normal'
            }}
          >
            {message}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default validationPopover;
