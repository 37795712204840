import { Box, Typography, makeStyles } from '@material-ui/core';
import { formatedTime } from '../../utils/formatedTime';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Info } from '@material-ui/icons';
import { ReactComponent as RestorePage } from '../../../icons/refreshReport.svg';
import { ReactComponent as PrintReport } from '../../../icons/printReport.svg';
import { ReactComponent as DeleteBinIcon } from '../../../icons/DeleteBinIcon.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { updateParamsInURL } from '../../../../helpers';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tearSheetHeader: {
    display: 'flex',
    position: 'relative'
  },
  printButton: {
    display: 'flex',
    position: 'absolute',
    right: '20px',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      right: '30px'
    }
  },
  '@media print': {
    printButton: {
      visibility: 'hidden'
    }
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  infoDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    // background: '#cccccc',
    // borderRadius: '5px',
    marginRight: '15px',
    'border-left': '3px solid var(--SystemColor-blue400, #3473EE)',
    background: 'var(--SystemColor-blue100, #F4F8FF)',
    'box-shadow': '0px 0px 8px 0px rgba(0, 0, 0, 0.10)'
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '21px',
    color: '#3473EE'
  },
  infoTypo: {
    color: '#1d1d1d'
  },
  reportNameCls: {
    'text-transform': 'capitalize',
    'font-family': 'Satoshi',
    'font-size': '24px',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': '32px',
    marginTop: 0
  }
}));

const Message = ({ classes, msg }) => {
  return (
    <Box className={classes.infoBox}>
      <div className={classes.infoDiv}>
        <Info className={classes.infoIcon} />
        <Typography className={classes.infoTypo}>{msg}</Typography>
      </div>
    </Box>
  );
};
const TearSheetHeader = ({ tearSheetData, triggerLatest, handleReportDeleteOpen }) => {
  const { generated_from_latest_template, report_name } = tearSheetData || {};
  const metaData = tearSheetData?.metadata;
  const tearSheetStatus = metaData?.report_status;
  const shareReport = metaData?.share_report;
  const classes = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleEdit = () => {
    updateParamsInURL({
      navigate,
      location,
      addParams: { action: 'edit', entity_id: metaData?.entity_id }
    });
  };

  const handleDelete = () => {
    handleReportDeleteOpen(metaData?.entity_id, metaData?.report_id, report_name);
  };

  const showLoader =
    tearSheetStatus === t('tearSheet.inProgress') || tearSheetStatus === t('tearSheet.inQueue');

  return (
    <>
      <div className={classes.tearSheetHeader}>
        {metaData?.logo && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '60px'
            }}
          >
            <img
              src={metaData?.logo}
              alt="Logo"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
            {/* (
            <Business
              style={{
                maxWidth: '80%',
                maxHeight: '80%',
                objectFit: 'contain'
              }}
            />
          ) */}
          </div>
        )}
        <div
          style={{
            fontFamily: 'Satoshi',
            paddingLeft: '15px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <p
            style={{
              fontFamily: 'Satoshi',
              fontSize: '20px',
              fontWeight: '600'
            }}
          >
            {metaData?.entity_name}
          </p>
        </div>
        <>
          {metaData?.updated_at && (
            <div
              style={{
                fontFamily: 'Satoshi',
                paddingLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p
                style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  fontStyle: 'italic'
                }}
              >
                ({formatedTime(metaData?.updated_at, true)})
              </p>
            </div>
          )}
          <div className={classes.printButton}>
            {showLoader ? (
              <>
                <Message classes={classes} msg={t('tearSheet.InProg')} />
                <IconButton>
                  <Tooltip title={t('tearSheet.inProcessToolTip')}>
                    <CircularProgress size={24} />
                  </Tooltip>
                </IconButton>
              </>
            ) : (
              <>
                {generated_from_latest_template === false && (
                  <Message classes={classes} msg={t('tearSheet.notLatest')} />
                )}
                {shareReport && <Message classes={classes} msg={t('tearSheet.nonEditable')} />}
                {!shareReport && (
                  <IconButton onClick={triggerLatest}>
                    <Tooltip title={t('tearSheet.RefreshToolTip')}>
                      <RestorePage />
                    </Tooltip>
                  </IconButton>
                )}
              </>
            )}
            {!showLoader && (
              <>
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
                {!shareReport && (
                  <IconButton onClick={handleDelete}>
                    <DeleteBinIcon />
                  </IconButton>
                )}
                <IconButton style={{ marginLeft: '10px' }} onClick={() => window.print()}>
                  <PrintReport />
                </IconButton>
              </>
            )}
          </div>
        </>
      </div>
      <p className={classes.reportNameCls}>{report_name}</p>
    </>
  );
};

export default TearSheetHeader;
