import { Button, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as EditTags } from '../../../components/icons/EditTags.svg';
import { Box, Checkbox, Chip, Dialog, DialogContent, IconButton } from '@mui/material';
import SearchComp from '../../atoms/searchComp';
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import { putApi } from '../../../services/token';
import { tagTypes } from '../../../constants/docLib/fileTags';

const useStyles = makeStyles({
  chipsContainer: {
    display: 'flex',
    padding: '1px 2px'
  },
  tagChip: {
    fontSize: '12px !important',
    fontFamily: 'Satoshi !important',
    margin: '0px 1.5px !important',
    border: 'none !important',
    '& span': {
      border: '1px solid rgba(204, 219, 255, 1) !important',
      padding: '5px !important',
      backgroundColor: 'rgba(204, 219, 255, 1) !important',
      'border-radius': '4px !important'
    }
  },
  saveButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px'
  },
  uploadCheckBoxTest: {
    fontFamily: 'Satoshi',
    fontSize: '0.875rem!important',
    fontWeight: '700',
    lineHeight: '18.9px',
    textAlign: 'left'
  },
  cancleButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #797F8D, #797F8D)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#FFFFFF',
      zIndex: -1,
      transition: '200ms'
    }
  }
});

const FileTags = ({
  file,
  allCompanies,
  allSectors,
  setIngestionDataTags,
  allIngestionDataTags,
  allIngestionData
}) => {
  const FileTagsEditPopup = ({
    file,
    allCompanies,
    allSectors,
    setIngestionDataTags,
    allIngestionDataTags,
    allIngestionData
  }) => {
    file = allIngestionDataTags.find((item) => item.doc_id === file.doc_id);
    const companyIDs =
      Object.keys(file.company_details)?.map((id) => ({
        key: id,
        value: file.company_details[id]
      })) ?? [];
    const sectorIDs =
      Object.keys(file.sector_details)?.map((id) => ({
        key: id,
        value: file.sector_details[id]
      })) ?? [];
    const classes = useStyles();

    const handleCheckboxChange = (docId, associationType, file) => {
      const updatedFile = { ...file };
      const index = updatedFile.association_types.indexOf(associationType);

      if (index !== -1) {
        updatedFile.association_types = [];
      } else {
        updatedFile.association_types = [associationType];
      }

      setIngestionDataTags((prevData) => {
        const index = prevData.findIndex((item) => item.doc_id === docId);
        if (index !== -1) {
          const updatedData = [...prevData];
          updatedData[index] = updatedFile;
          return updatedData;
        }
        return prevData;
      });
    };

    const handleDeleteCompanyChip = (value, docId, file) => {
      const updatedValues = { ...file.company_details };
      delete updatedValues[value];
      const updatedFile = { ...file, company_details: updatedValues };
      updateFileData(docId, updatedFile);
    };

    const handleDeleteSectorChip = (value, docId, file) => {
      const updatedValues = { ...file.sector_details };
      delete updatedValues[value];
      const updatedFile = { ...file, sector_details: updatedValues };
      updateFileData(docId, updatedFile);
    };

    const updateFileData = (docId, updatedFile) => {
      setIngestionDataTags((prevData) => {
        const index = prevData.findIndex((item) => item.doc_id === docId);
        if (index !== -1) {
          const updatedData = [...prevData];
          updatedData[index] = updatedFile;
          return updatedData;
        }
        return prevData;
      });
    };

    const handleSectorChange = (event, value, docId, file) => {
      const sectorDetails = value.reduce((acc, item) => {
        acc[item.key] = { name: item.value };
        return acc;
      }, {});

      const updatedFile = { ...file, sector_details: sectorDetails };

      setIngestionDataTags((prevData) => {
        const index = prevData.findIndex((item) => item.doc_id === docId);
        if (index !== -1) {
          const updatedData = [...prevData];
          updatedData[index] = updatedFile;
          return updatedData;
        }
        return prevData;
      });
    };

    const handleCompanyChange = (event, value, docId, file) => {
      const companyDetails = value.reduce((acc, item) => {
        acc[item.key] = { name: item.value };
        return acc;
      }, {});

      // Updating the file with the new company details
      const updatedFile = { ...file, company_details: companyDetails };

      // Updating the ingestion data
      setIngestionDataTags((prevData) => {
        const index = prevData.findIndex((item) => item.doc_id === docId);
        if (index !== -1) {
          const updatedData = [...prevData];
          updatedData[index] = updatedFile;
          return updatedData;
        }
        return prevData;
      });
    };

    const searchCompProps = () => ({
      options: allCompanies,
      selectedValues: companyIDs,
      placeholder: 'Select Company',
      chip: true,
      handleChange: (event, value) => handleCompanyChange(event, value, file.doc_id, file),
      handleDeleteChip: (value) => handleDeleteCompanyChip(value, file.doc_id, file)
    });

    const searchSectorProps = () => ({
      options: allSectors,
      selectedValues: sectorIDs,
      placeholder: 'Select Sector',
      chip: true,
      handleChange: (event, value) => handleSectorChange(event, value, file.doc_id, file),
      handleDeleteChip: (value) => handleDeleteSectorChip(value, file.doc_id, file)
    });

    const validateFileTags = (file) => {
      const validateFile = allIngestionDataTags.find((item) => item.doc_id === file.doc_id);

      if (validateFile.association_types.length === 0) {
        return 'Associate the file to one of the tags.';
      }

      if (validateFile.association_types.includes('miscellaneous')) {
        return null;
      } else if (validateFile.association_types.includes('company')) {
        if (Object.keys(validateFile.company_details).length === 0) {
          return 'Need to select company tags';
        }
      } else if (validateFile.association_types.includes('sector')) {
        if (Object.keys(file.sector_details).length === 0) {
          return 'Need to select sector tags';
        }
      }

      return null;
    };

    const createPayload = () => {
      const newFile = allIngestionDataTags.find((item) => item.doc_id === file.doc_id);
      if (!newFile) {
        return null;
      }

      const payload = {
        association_types: newFile.association_types
      };

      if (newFile.association_types.includes('company')) {
        payload.association_details = {
          cin_list: Object.keys(newFile.company_details),
          sector_list: []
        };
      } else if (newFile.association_types.includes('sector')) {
        payload.association_details = {
          cin_list: [],
          sector_list: Object.keys(newFile.sector_details)
        };
      } else {
        payload.association_details = {
          cin_list: [],
          sector_list: []
        };
      }

      return payload;
    };

    const handleUpdateTags = async (file) => {
      try {
        // setIsLoading(true);
        const payload = createPayload();
        const url = process.env.REACT_APP_INGESTION_API_URL;
        const response = await putApi(`${url}/v1/document/${file.doc_id}`, payload);
        if (response) {
          setOpenEditTagsPopup(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <>
        <div
          style={{
            background: '#F7F7FC',
            display: 'flex',
            width: '100%',
            padding: '5px 0px 10px 5px'
          }}
        >
          <div style={{ width: '40%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                style={{ color: '#004CFF' }}
                value={'company'}
                checked={!!file.association_types?.includes('company')}
                onChange={() => handleCheckboxChange(file.doc_id, 'company', file)}
              />
              <Typography className={classes.uploadCheckBoxTest}>Companies</Typography>
            </div>
            <div
              style={{
                display: file.association_types.includes('company') ? 'grid' : 'none',
                backgroundColor: '#FFFFFF'
              }}
            >
              <SearchComp {...searchCompProps(file)} />
            </div>
          </div>
          <div style={{ width: '40%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                style={{ color: '#004CFF' }}
                value={'sector'}
                checked={!!file.association_types?.includes('sector')}
                onChange={() => handleCheckboxChange(file.doc_id, 'sector', file)}
              />
              <Typography className={classes.uploadCheckBoxTest}>Sector</Typography>
            </div>
            <div
              style={{
                display: file.association_types.includes('sector') ? 'grid' : 'none',
                backgroundColor: '#FFFFFF'
              }}
            >
              <SearchComp {...searchSectorProps(file)} />
            </div>
          </div>
          <div style={{ width: '20%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                style={{ color: '#004CFF' }}
                value={'miscellaneous'}
                checked={!!file.association_types?.includes('miscellaneous')}
                onChange={() => handleCheckboxChange(file.doc_id, 'miscellaneous', file)}
              />
              <Typography className={classes.uploadCheckBoxTest}>Miscellaneous</Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            background: '#F7F7FC',
            width: '100%'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            <Button
              className={classes.cancleButton}
              style={{ width: '80px', height: '30px' }}
              onClick={() => {
                setIngestionDataTags(allIngestionData);
                setOpenEditTagsPopup(false);
              }}
            >
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#08070D',
                  textTransform: 'none',
                  fontFamily: 'Satoshi'
                }}
              >
                Cancel
              </Typography>
            </Button>
            <div style={{ paddingLeft: '20px' }}></div>
            <Button
              className={classes.saveButton}
              onClick={() => {
                const validationResult = validateFileTags(file);
                // Check the result and take appropriate action
                if (validationResult === null) {
                  handleUpdateTags(file);
                } else {
                  console.error(validationResult);
                }
              }}
              style={{ width: '80px', height: '30px' }}
            >
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#FFFFFF',
                  textTransform: 'none',
                  fontFamily: 'Satoshi'
                }}
              >
                Save
              </Typography>
            </Button>
          </div>
        </div>
      </>
    );
  };

  const TagsCell = ({ tagsProps }) => {
    // const { label, chips, setOpenCompanyFilter } = tagsProps;
    const { chips } = tagsProps;
    const chipsData = chips ? Object.values(chips) : [];
    const classes = useStyles();
    return (
      <div style={{ maxWidth: '265px', overflowY: 'auto' }}>
        <div className={classes.chipsContainer}>
          {chipsData.length > 0 &&
            chipsData.map((x, i) => {
              const chipLabel = x.name ?? 'Default Label';
              return (
                <Chip key={i} className={classes.tagChip} label={chipLabel} variant="outlined" />
              );
            })}
          <div
            style={{
              // display: 'flex',
              display: 'none',
              alignContent: 'center',
              alignItems: 'center',
              paddingRight: '10px'
            }}
          >
            <EditTags
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenEditTagsPopup(false);
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  const classes = useStyles();
  const [openEditTagsPopup, setOpenEditTagsPopup] = useState(false);

  if (file.association_types.includes('sector')) {
    file.association_types = file.association_types.map((at) =>
      at === 'sector' ? 'miscellaneous' : at
    );
  }

  return (
    <>
      <Dialog open={openEditTagsPopup} fullWidth={true} maxWidth="lg">
        <Box p={0} width="100%">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px 24px 0px 24px'
            }}
          >
            <div>
              <Typography style={{ fontFamily: 'Satoshi', file_size: '16px', fontWeight: 700 }}>
                Update Tags : {file.file_name}.{file.file_format}
              </Typography>
            </div>
            <div>
              <IconButton
                className={classes.closeBtn}
                onClick={() => {
                  setOpenEditTagsPopup(false);
                  setIngestionDataTags(allIngestionData);
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
          <br />
          <DialogContent>
            <>
              <Box
                style={{
                  background: '#FDF6F6',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <FileTagsEditPopup
                  file={file}
                  allCompanies={allCompanies}
                  allSectors={allSectors}
                  setIngestionDataTags={setIngestionDataTags}
                  allIngestionDataTags={allIngestionDataTags}
                  allIngestionData={allIngestionData}
                />
              </Box>
              <br />
            </>
          </DialogContent>
        </Box>
      </Dialog>
      <div
        style={{
          background: '#F7F7FC',
          display: 'flex',
          width: '700px',
          borderRadius: '4px'
        }}
      >
        {tagTypes.map((tagType) => (
          <div style={{ width: '265px' }} key={tagType?.key}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                style={{ color: '#004CFF' }}
                value={tagType?.key}
                checked={file.association_types?.includes(tagType?.key)}
                onChange={() => setOpenEditTagsPopup(false)}
              />
              <Typography className={classes.uploadCheckBoxTest}>{tagType?.displayKey}</Typography>
            </div>
            {tagType?.hasTagsCell && (
              <div
                style={{
                  display: file.association_types?.includes(tagType?.key) ? 'grid' : 'none',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #D9DDE4',
                  borderRadius: '4px',
                  margin: '0px 9px 9px'
                }}
              >
                {/* <SearchComp {...searchCompProps(file)} /> */}
                <TagsCell
                  tagsProps={{
                    label: tagType.displayKey,
                    chips: file.company_details,
                    setOpenCompanyFilter: false
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default FileTags;
