export const limitsTypeToDisplayMap = [
  {
    key: 'INGESTION',
    value: 'Document Storage'
  },
  {
    key: 'TEARSHEET_RUN',
    value: 'Tearsheets'
  },
  {
    key: 'QNA',
    value: 'Ask Byz'
  },
  {
    key: 'MCA',
    value: 'MCA Refreshes'
  }
];
