import { useTranslationHook } from '../../hooks/useTranslationHook';

export const ingestionMetricsDisplayNames = [
  {
    key: 'tables_extracted',
    displayName: 'Number of tables extracted'
  },
  {
    key: 'tables_ingested',
    displayName: 'Number of tables ingested'
  },
  {
    key: 'ingestion_time',
    displayName: 'Time taken for upload (in seconds)'
  },
  {
    key: 'total_pages_count',
    displayName: 'Number of pages in the document'
  },
  {
    key: 'images_extracted',
    displayName: 'Number of images extracted'
  },
  {
    key: 'images_ingested',
    displayName: 'Number of images ingested'
  }
];

export const subDocFailureColumHeaders = () => {
  const { t } = useTranslationHook();
  return [
    {
      displayName: t('list.components')
    },
    { displayName: t('list.status'), key: 'status' },
    { displayName: t('list.timestamp'), key: 'updated_at' }
  ];
};
