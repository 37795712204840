import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Tooltip, IconButton } from '@material-ui/core';
import { formatedTime } from '../utils/formatedTime';
import { ReactComponent as MCADataRequest } from '../../../components/icons/MCADataRequest.svg';
import { ReactComponent as MCADataRequestHovered } from '../../../components/icons/MCADataRequestHovered.svg';
import { ReactComponent as MCADataDisabled } from '../../../components/icons/MCADataDisabled.svg';
import { ReactComponent as MCADataRefresh } from '../../../components/icons/MCADataRefresh.svg';
import { ReactComponent as MCADataRefreshHovered } from '../../../components/icons/MCADataRefreshHovered.svg';
import { ReactComponent as MCADataRefreshDisabled } from '../../../components/icons/MCADataRefreshDisabled.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '12px',
    borderColor: '#E3EBFD !important'
  },
  questionText: {
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    color: '#767676',
    padding: '12px 10px',
    textAlign: 'left'
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiInputBase-input': {
      height: '11px !important',
      backgroundColor: '#F0F0F0 !important',
      borderRadius: '12px 0 0 12px'
    },
    padding: '10px !important',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      color: '#000000',
      fontWeight: 600,
      background: 'none'
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#4D4D4D',
      opacity: 1
    },
    '& .MuiOutlinedInput-adornedEnd': {
      backgroundColor: '#F0F0F0',
      borderRadius: '12px 12px 12px 12px'
    }
  },
  buttonSaveFeedback: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px',
    textTransform: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  cancleButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px'
  },
  likeBtn: {
    '&:hover': {
      backgroundColor: '#F7F9FD'
    }
  },
  flexCss: {
    display: 'flex',
    gap: '12rem'
  },
  detailHeading: {
    width: '10px'
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontWeight: 500,
      fontSize: '12px'
    },
    '&::placeholder': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  popoverPaper: {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px #0000000D'
  },
  headerCompany: {
    display: 'flex',
    gap: '4px'
  },
  headerCompanyTab: {
    display: 'flex',
    backgroundColor: '#F7F9FD'
  },
  firstDiv: {
    background: '#EDEBEB',
    // height: '66px',
    // width: '88px',
    width: '72px',
    height: '66px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '8px',
    border: '1px solid #E8E8E8'
  },
  secondDiv: {
    background: '#FFFFFF',
    // height: '66px',
    // width: '88px',
    width: '72px',
    height: '66px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '8px',
    border: '1px solid #E8E8E8'
  },
  compareData: {
    color: '#004CFF',
    'font-size': '14px',
    float: 'right',
    'text-decoration': 'none',
    paddingRight: '5px'
  },
  companyLogo: {
    marginRight: '6px',
    display: 'flex'
  },
  companyHeading: {
    fontSize: '36px'
  },
  mainDiv: {
    padding: '4px',
    background: '#FFFFFF'
  },
  topLevelGrid: {
    overflowY: 'auto',
    height: '104.5%',
    [theme.breakpoints.down('sm')]: {
      height: '97%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  },
  fontSize14: {
    fontSize: '14px!important'
  },
  fontSize12: {
    fontSize: '12px!important'
  },
  fontWeight500: {
    'font-weight': '501!important'
  },
  displayInline: {
    display: 'flex',
    'padding-top': '14px!important'
  },
  iconsMainDiv: {
    [theme.breakpoints.down('sm')]: {
      'margin-right': '3%'
    },
    [theme.breakpoints.up('sm')]: {
      'margin-right': '4%'
    }
  },
  iconParent: {
    display: 'inline-block',
    'font-size': '18px',
    'line-height': '30px',
    width: '30px',
    height: '30px',
    'border-radius': '36px',
    'background-color': '#EFF3FC',
    color: '#fff',
    margin: '0 3px 3px 0'
  },
  socialMediaIcon: {
    [theme.breakpoints.up('sm')]: {
      padding: '7px',
      width: '50%',
      height: '45%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: '45%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '45%'
    }
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: theme.spacing(2)
  },
  mapKey: {
    display: 'inline-block',
    minWidth: '100px',
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  },
  mapValue: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  },
  closeIcon: {
    borderRadius: '50%',
    transition: 'color 0.3s ease',
    boxSizing: 'border-box',
    backgroundColor: '#CDCDCD',
    '&:hover': {
      color: '#EB5B55',
      backgroundColor: '#FFFFFF'
    }
  },
  iconCircle: {
    color: 'white'
  },
  closeIconMain: {
    borderRadius: '50%',
    transition: 'color 0.3s ease',
    boxSizing: 'border-box',
    backgroundColor: '#E3EBFD',
    '&:hover': {
      color: '#EB5B55',
      backgroundColor: '#FFFFFF'
    }
  },
  iconCircleMain: {
    color: '#489ECD'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogPaper: {
    margin: 0,
    width: '100%'
  },
  buttonSignin: {
    marginLeft: 'auto',
    textTransform: 'none',
    position: 'relative',
    zIndex: 1,
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  showOnDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none!important'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none!important'
    }
  },
  compIconTab: {
    'max-width': '36px',
    'max-height': '36px',
    'object-fit': 'contain',
    'font-size': '16px'
  },
  backArrow: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    width: '75px'
  },
  wordbreak: {
    [theme.breakpoints.down('xs')]: {
      'word-break': 'break-all'
    }
  },
  companyList: {
    height: '300px',
    overflow: 'auto',
    margin: '2px 0px !important'
  },
  watchlistCls: {
    'border-radius': '8px',
    border: '1px solid rgb(232, 232, 232)'
  },
  companyLi: {
    paddingLeft: '0px !important',
    display: 'flex !important',
    '&:hover': {
      borderRadius: '3px',
      opacity: '0.9',
      background: 'rgb(248, 250, 253) !important'
    }
  },
  removeLink: {
    fontWeight: 'lighter',
    fontSize: '13px',
    cursor: 'pointer',
    color: 'blue',
    margin: '0px 10px',
    '&:hover': {
      color: theme.palette.error.main,
      textDecoration: 'underline'
    }
  },
  watchlistNm: {
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#000000B2'
  },
  btnNewWt: {
    display: 'flex',
    border: '1px solid rgb(232, 232, 232)',
    'border-radius': '8px',
    padding: '20px 10px',
    marginBottom: '10px'
  },
  btnWtp: {
    fontFamily: 'Satoshi',
    fontWeight: 'normal',
    fontSize: '14px',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  companyName: {
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  tooltipStyle: {
    padding: '16px !important',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    }
  },
  iconButton: {
    cursor: 'pointer'
  },
  defaultIcon: {
    display: 'inline'
  },
  hoverIcon: {
    display: 'none'
  },
  iconButtonHover: {
    '&:hover $defaultIcon': {
      display: 'none'
    },
    '&:hover $hoverIcon': {
      display: 'inline'
    }
  },
  newCard: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #F0F0F0',
    borderRadius: '10px',
    boxShadow: '0px 2px 4px 0px #0000000D'
  },
  newsCardContent: {
    flexGrow: '8!important',
    width: '80% !important',
    padding: '7px !important'
  },
  newsDateTime: {
    fontFamily: 'Satoshi !important',
    fontWeight: 'bold !important',
    fontSize: '12px !important'
  },
  newsTitle: {
    fontFamily: 'Satoshi !important',
    fontWeight: 'bold !important',
    fontSize: '12px !important'
  },
  newsSource: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    color: '#666666 !important',
    fontWeight: '500 !important'
  },
  newsCardMedia: {
    flexGrow: '2 !important',
    width: '20% !important',
    borderRadius: '7px !important',
    height: '60px !important',
    objectFit: 'cover !important'
  },
  newsSeeMore: {
    fontFamily: 'Satoshi !important',
    fontWeight: 'bold !important',
    fontSize: '12px !important'
  }
}));

const MCAIconComp = ({ requestMcaInfo, cin }) => {
  const classes = useStyles();
  const allMcaData = useSelector((state) => state.tokenReducer.mcaData);
  const mcaData = allMcaData[cin];
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  let IconComponent;
  let tooltipTitle;

  if (loading) {
    IconComponent = CircularProgress;
    tooltipTitle = 'Loading...';
  } else if (mcaData?.is_mca_applicable) {
    if (mcaData.is_mca_latest) {
      IconComponent = MCADataRefreshDisabled;
      tooltipTitle = `Recently updated MCA. Last Updated ${formatedTime(
        mcaData.last_mca_refresh_at
      )}`;
    } else if (mcaData.mca_request_placed) {
      IconComponent = MCADataDisabled;
      tooltipTitle = `MCA Data Fetch in Progress. ${
        mcaData.last_mca_refresh_at !== null
          ? `Last Updated ${formatedTime(mcaData.last_mca_refresh_at)}`
          : ''
      }`;
    } else if (mcaData.mca_request_placed === false && mcaData.last_mca_refresh_at === null) {
      IconComponent = hovered ? MCADataRequestHovered : MCADataRequest;
      tooltipTitle = 'Request MCA Data';
    } else if (!mcaData.mca_request_placed && !mcaData.is_mca_latest) {
      IconComponent = hovered ? MCADataRefreshHovered : MCADataRefresh;
      tooltipTitle = `Request Latest MCA. Last Updated ${formatedTime(
        mcaData.last_mca_refresh_at
      )}`;
    }
  } else {
    tooltipTitle = null;
  }

  return (
    <>
      {tooltipTitle !== null && (
        <Tooltip className={classes.tooltipStyle} title={tooltipTitle}>
          <IconButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={async () => {
              if (!mcaData.mca_request_placed) {
                setLoading(true);
                await requestMcaInfo(cin);
                setLoading(false);
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : <IconComponent />}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default React.memo(MCAIconComp);
