import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  buttonSaveFeedback: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px',
    textTransform: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  cancleButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px'
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontWeight: 500,
      fontSize: '12px'
    },
    '&::placeholder': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  popoverPaper: {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px #0000000D'
  },
  table: {
    borderCollapse: 'collapse'
  },
  th: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF'
  },
  td: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    backgroundColor: '#FFFFFF'
  },
  alternateCell: {
    backgroundColor: '#f6f7fe'
  },
  timeText: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  answerText: {
    fontSize: '14px',
    'font-family': 'Satoshi',
    'font-style': 'normal',
    'font-weight': '500',
    'line-height': '22px',
    // paddingLeft: '36.2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
      // paddingLeft: '5px'
    }
  },
  botText: {
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  iconPostQuestion: {
    display: 'flex',
    alignContent: 'center'
  },
  paragraph: {
    margin: '0!important'
  }
}));

function BotComponent({ messageData, showMore }) {
  const dispatch = useDispatch();
  console.log(dispatch);
  const loading = useSelector((state) => state.tokenReducer.loadingDE);
  const loadingAnswerStream = useSelector((state) => state.tokenReducer.loadingAnswerStreamDE);
  const allMessages = useSelector((state) => state.tokenReducer.allMessagesDE);
  const classes = useStyles();

  const lastMessageId = Object.keys(allMessages)[Object.keys(allMessages).length - 1];
  const isLastBotMessage = messageData.messageId === lastMessageId;

  let formattedData = '';

  try {
    formattedData = JSON.stringify(JSON.parse(messageData.answer), null, 2);
  } catch (error) {
    formattedData = messageData.answer;
  }

  let alternate = false;

  const renderTd = (props) => {
    const { children } = props;
    alternate = !alternate;
    const className = alternate ? classes.alternateCell : '';
    return <td className={`${classes.td} ${className}`}>{children}</td>;
  };

  const renderLink = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  const renderP = ({ children }) => {
    return <p className={classes.paragraph}>{children}</p>;
  };

  const renderImage = ({ src, alt }) => (
    <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />
  );
  return (
    <>
      <div id="botComponentMain">
        <div className={classes.botText}>
          <div id="askbyz-answer">
            <Typography
              id="askbyzAnswerBotComponentTypo"
              style={{
                color: '#1D1D1D',
                fontWeight: 500
              }}
              className={classes.answerText}
            >
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  table: ({ children }) => <table className={classes.table}>{children}</table>,
                  th: ({ children }) => <th className={classes.th}>{children}</th>,
                  td: renderTd,
                  a: renderLink,
                  img: renderImage,
                  p: renderP
                }}
              >
                {formattedData}
              </ReactMarkdown>
              {!loading && loadingAnswerStream && isLastBotMessage && (
                <span className="blinkingCursor" />
              )}
            </Typography>
          </div>
        </div>
        {messageData.showMore && (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }} id="BotComponentShowMore">
              <Button
                id="BotComponentShowMoreButton"
                style={{
                  textTransform: 'none',
                  backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  border: '1px solid #E8E8E8',
                  height: '30px'
                }}
                onClick={() => {
                  showMore(messageData.messageId);
                }}
              >
                <Typography
                  id="BotComponentShowMoreTypo"
                  style={{
                    color: '#1D1D1D',
                    fontSize: '14px'
                  }}
                >
                  Show More
                </Typography>
                <ExpandMoreIcon
                  id="BotComponentExpandMoreIcon"
                  style={{ color: '#444444', fontSize: '20px' }}
                />
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

BotComponent.propTypes = {
  messageData: PropTypes.any
};

export default BotComponent;
