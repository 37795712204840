import { Typography, makeStyles } from '@material-ui/core';
// import { PlayArrow } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../atoms/buttons/button';
import SecondaryButton from '../../../atoms/buttons/secondaryBtn';

const useStyles = makeStyles((theme) => ({
  reqBtn: {
    padding: '6px 14px',
    margin: '10px 0px 0px',
    width: 'auto',
    '& p': {
      fontSize: '16px',
      [theme.breakpoints.down('xs')]: {
        width: 'max-content',
        fontSize: '14px',
        lineHeight: '1.25'
      }
    }
  },
  box: {
    padding: '22px 24px 24px',
    border: '1px solid #E4EBFC',
    background: '#F7F9FD',
    borderRadius: '10px',
    maxWidth: '500px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 14px 14px'
    }
  },
  typo: {
    padding: '0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
      lineHeight: '1.25',
      fontSize: '14px'
    }
  }
}));

const TearSheetRequestComp = ({ handleTrigger, status, errorMessage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const processingBtnProps = {
    btnText: t('tearSheet.reqInProcess'),
    disabled: true,
    styleClass: classes.reqBtn
  };
  const reqBtnProps = {
    handleClick: handleTrigger,
    btnText: t('tearSheet.requestBtn'),
    styleClass: classes.reqBtn
  };
  return (
    <Box>
      <Box textAlign="center" className={classes.box}>
        <Typography className={classes.typo}>
          {errorMessage
            ? errorMessage
            : status === t('tearSheet.inProgress') || status === t('tearSheet.inQueue')
            ? t('tearSheet.inProgressMessage')
            : t('tearSheet.notAvailableMessage')}
        </Typography>
        {status === t('tearSheet.inProgress') || status === t('tearSheet.inQueue') ? (
          <SecondaryButton {...processingBtnProps} />
        ) : (
          <CustomButton {...reqBtnProps} />
        )}
      </Box>
    </Box>
  );
};

export default TearSheetRequestComp;
