import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { getApi, deleteApi, putApi } from '../../../services/token';
import { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import Companies from './companies';
import AddWatchlistPopOver from './addWatchlistPopUp';
import { CheckCircleOutline } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import DeleteWatchlistPopOver from './deleteWcPopUp';
import { IconButton, Button, makeStyles } from '@material-ui/core';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import EditDeleteMenu from './editDeleteWatchlist';
import ErrorToast from '../../atoms/errorToast';
import SuccessToast from '../../atoms/successToast';

const useStyles = makeStyles((theme) => ({
  logo: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    opacity: 1
  },
  logo1: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    opacity: 1,
    padding: '0px'
  },
  listSidebar: {
    height: '100vh',
    overflow: 'auto'
  },
  exploreCompGrid: {
    paddingLeft: '50px',
    paddingRight: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  drawer: {
    width: '100%',
    background: '#F8F9FD'
  },
  materialInput: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    width: '100%',
    padding: '10px 12px',
    fontSize: '1rem',
    lineHeight: '1',
    color: '#08070d',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.light}`
    }
  },
  displayFlex: {
    display: 'flex'
  },
  iconPd: {
    padding: '6px 5px 0 0'
  }
}));

const InputComponent = ({ currentValue, updateWatchlistData }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (currentValue) setValue(currentValue);
  }, [currentValue]);

  return (
    <>
      <input
        type="text"
        value={value}
        onChange={(e) => {
          e.stopPropagation();
          setValue(e.target.value);
        }}
        className={classes.materialInput}
      />
      <CheckIcon
        style={{ color: 'gray', opacity: 0.8 }}
        onClick={(e) => {
          e.stopPropagation();
          updateWatchlistData(value);
        }}
      />
    </>
  );
};

const WatchList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const createWatchlistFlag = location?.state?.createWatchlist || false;
  const [watchlists, setWatchlists] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const companyUrl = process.env.REACT_APP_API;
  const [openDeleteWCPopUp, setOpenDeleteWCPopUp] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [currentWatchlist, setCurrentWatchlist] = useState(null);
  const [currentWatchlistValue, setCurrentWatchlistValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get('action');
  const watchlistId = queryParams.get('watchlist-id');

  const getWatchlists = async (isUpdateWatchlist = false) => {
    try {
      const response = await getApi(`${companyUrl}/v1/user/watchlist`);
      setWatchlists(response.data.data === null ? [] : response.data.data);

      if (!isUpdateWatchlist) setSelectedItem(response.data.data[0].watchlist_id);
    } catch (err) {
      console.err(err);
    }
  };

  const getAddedWatchlists = async (data) => {
    try {
      const response = await getApi(`${companyUrl}/v1/user/watchlist`);
      setWatchlists(response.data.data === null ? [] : response.data.data);
      setSelectedItem(data);
    } catch (err) {
      console.err(err);
    }
  };

  const createWatchlist = () => {
    setOpenPopUp(true);
  };

  useEffect(() => {
    getWatchlists();
  }, []);

  useEffect(() => {
    if (action && watchlistId && watchlists && watchlists.length > 0) {
      const currentWatchlist = watchlists.find((x) => x.watchlist_id === watchlistId);
      if (currentWatchlist) {
        const currWtList = currentWatchlist.watchlist_id;
        setSelectedItem(currWtList);
        if (action === 'edit') {
          setCurrentWatchlist(currWtList);
          setCurrentWatchlistValue(currentWatchlist.name);
        } else if (action === 'delete') {
          setOpenDeleteWCPopUp(true);
        }
      }
    }
  }, [action, watchlistId, watchlists]);

  const [openPopUp, setOpenPopUp] = useState(createWatchlistFlag);
  const [successMessage, setSuccessMessage] = useState('');
  const handleClosePopUp = () => {
    setOpenPopUp(false);
  };

  // const receiveDataFromChild = (data) => {
  //   setSelectedItem(data);
  // };

  const handleCompaniesAdded = async (data) => {
    await getAddedWatchlists(data);
    setSuccessMessage(t('Watchlist.AddedSuccess'));
    setTimeout(() => setSuccessMessage(''), 5000);
  };
  const handleCloseDeleteWCPopUp = () => {
    setOpenDeleteWCPopUp(false);
  };
  const [sidebarDrawer, setSidebarDrawer] = useState(false);

  const updateWatchlist = async (watchlistName) => {
    if (currentWatchlist && currentWatchlistValue && watchlistName) {
      let currentWatchlistData = watchlists.find((x) => x.watchlist_id === currentWatchlist);
      currentWatchlistData.update_name = watchlistName;
      await putApi(`${companyUrl}/v1/user/watchlist/${currentWatchlist}`, currentWatchlistData);
    }
  };

  const hideShowErrorMessage = (msg) => {
    if (msg) {
      setErrorMessage(msg);
      setTimeout(() => {
        setErrorMessage('');
      }, 10000);
    }
  };

  const updateWatchlistName = async (watchlistName) => {
    try {
      await updateWatchlist(watchlistName);
      await getWatchlists(true);
      setCurrentWatchlist(null);
    } catch (error) {
      const msg = error?.response?.data?.error?.message;
      hideShowErrorMessage(msg);
    }
  };

  const updateWatchlistData = (watchlistName) => {
    updateWatchlistName(watchlistName);
    navigate(`/watchlist-company`);
  };

  const SideBarSectorList = () => {
    return (
      <>
        <AddWatchlistPopOver
          openState={openPopUp}
          handleClose={handleClosePopUp}
          watchlistedCompanies={[]}
          trigger={handleCompaniesAdded}
        />
        <DeleteWatchlistPopOver
          openState={openDeleteWCPopUp}
          handleClose={handleCloseDeleteWCPopUp}
          watchlistName={watchlists.find((item) => item.watchlist_id === selectedItem)?.name}
          trigger={deleteWatchlist}
        />
        <List disablePadding className={classes.listSidebar}>
          <ListItem disablePadding>
            <Accordion
              style={{
                width: '100%'
              }}
              elevation={0}
              expanded={true}
            >
              <AccordionSummary
                style={{
                  height: '56px',
                  minHeight: '56px',
                  display: 'flex',
                  padding: '0px',
                  background: 'hsla(218, 89%, 51%, 0.09)'
                }}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    fontFamily: 'Satoshi',
                    color: '#000000',
                    paddingLeft: '10px'
                  }}
                >
                  Watchlists
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0px' }}>
                {watchlists.length !== 0
                  ? watchlists.map(({ watchlist_id, name, is_default }) => (
                      <>
                        {currentWatchlist === watchlist_id ? (
                          <ListItemButton
                            selected={false}
                            style={{
                              border: '1px solid #E4EBFC',
                              borderTop: 'none',
                              borderRight: 'none',
                              background: 'hsla(218, 89%, 51%, 0.09)'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                            >
                              <InputComponent
                                currentValue={currentWatchlistValue}
                                updateWatchlistData={updateWatchlistData}
                              />
                            </div>
                          </ListItemButton>
                        ) : (
                          <ListItemButton
                            key={watchlist_id}
                            selected={selectedItem === watchlist_id}
                            onClick={() => {
                              if (selectedItem !== watchlist_id) {
                                setSelectedItem(watchlist_id);
                              }
                            }}
                            style={{
                              border: '1px solid #E4EBFC',
                              borderTop: 'none',
                              borderRight: 'none',
                              background:
                                selectedItem === name
                                  ? 'hsla(218, 89%, 51%, 0.32)'
                                  : 'hsla(218, 89%, 51%, 0.09)'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: selectedItem === watchlist_id ? '600' : '400',
                                  fontFamily: 'Satoshi',
                                  color: '#000000'
                                }}
                              >
                                {name}
                              </Typography>
                              {watchlist_id === selectedItem && !is_default === true ? (
                                <EditDeleteMenu
                                  watchlist_id={watchlist_id}
                                  name={name}
                                  setOpenDeleteWCPopUp={setOpenDeleteWCPopUp}
                                  setCurrentWatchlist={setCurrentWatchlist}
                                  setCurrentWatchlistValue={setCurrentWatchlistValue}
                                  classes={classes}
                                  t={t}
                                />
                              ) : null}
                            </div>
                          </ListItemButton>
                        )}
                      </>
                    ))
                  : null}
              </AccordionDetails>
            </Accordion>
          </ListItem>
        </List>
      </>
    );
  };

  const handleWatchlistDeletion = (deletedWatchlistId) => {
    const updatedWatchlists = watchlists.filter(
      (watchlist) => watchlist.watchlist_id !== deletedWatchlistId
    );
    const deletedWatchlistIndex = watchlists.findIndex(
      (watchlist) => watchlist.watchlist_id === deletedWatchlistId
    );
    let newSelectedItem = null;
    if (watchlists.length > 1) {
      if (deletedWatchlistIndex === 0) {
        newSelectedItem = watchlists[1].watchlist_id;
      } else if (deletedWatchlistIndex === watchlists.length - 1) {
        newSelectedItem = watchlists[deletedWatchlistIndex - 1].watchlist_id;
      } else {
        newSelectedItem = watchlists[deletedWatchlistIndex + 1].watchlist_id;
      }
    }

    setSelectedItem(newSelectedItem);
    setWatchlists(updatedWatchlists);
  };

  const deleteWatchlist = async () => {
    try {
      await deleteApi(`${companyUrl}/v1/user/watchlist/${selectedItem}`, null, {});
      handleWatchlistDeletion(selectedItem);
      navigate(`/watchlist-company`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseMobileSB = () => {
    setSidebarDrawer(false);
  };
  const handleOpenMobileSB = () => {
    setSidebarDrawer(true);
  };
  return (
    <>
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      <div style={{ display: 'flex' }}>
        {isMobile ? (
          <div>
            <div style={{ display: 'flex', paddingTop: '30px', paddingLeft: '10px' }}>
              <IconButton className={classes.logo1}>
                <KeyboardArrowRightIcon sx={{ color: '#000000' }} onClick={handleOpenMobileSB} />
              </IconButton>
            </div>
            <Drawer
              anchor="right"
              open={sidebarDrawer}
              classes={{
                paper: classes.drawer
              }}
              PaperProps={{
                style: {
                  backgroundColor: '#F8F9FD'
                }
              }}
            >
              <div style={{ display: 'flex', padding: '20px', justifyContent: 'space-between' }}>
                <div>
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      fontFamily: 'Satoshi',
                      color: '#000000',
                      cursor: 'pointer'
                    }}
                  >
                    Watchlist
                  </Typography>
                </div>
                <div>
                  <IconButton className={classes.logo1}>
                    <CloseIcon sx={{ color: '#000000' }} onClick={handleCloseMobileSB} />
                  </IconButton>
                </div>
              </div>
              <SideBarSectorList />
            </Drawer>
          </div>
        ) : (
          <Box
            sx={{
              minHeight: 'calc(100vh - 119.5px)',
              width: '230px',
              borderRight: '1px solid #E4EBFC',
              borderTop: '1px solid #E4EBFC',
              backgroundColor: '#FFFFFF'
            }}
          >
            <SideBarSectorList />
          </Box>
        )}
        {successMessage && (
          <Box
            className={classes.successBox}
            style={{
              display: 'none'
            }}
          >
            <CheckCircleOutline
              style={{
                marginRight: '2px',
                color: '#3C962A',
                backgroundColor: '#C7F1BF',
                borderRadius: '50%'
              }}
            />
            <Typography
              style={{
                fontSize: '13px',
                color: '#206113',
                opacity: 1
              }}
            >
              {successMessage}
            </Typography>
          </Box>
        )}
        {watchlists.length !== 0 ? (
          <Grid
            container
            style={{
              backgroundColor: '#FFFFFF',
              maxWidth: '1440px',
              margin: '0 auto'
            }}
          >
            <Grid container>
              <Grid item xs={12} className={classes.exploreCompGrid}>
                {matches ? <div style={{ height: '20px' }} /> : null}
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      createWatchlist();
                    }}
                    style={{
                      margin: '10px',
                      border: 'none',
                      textTransform: 'none',
                      height: '33px',
                      backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        fontFamily: 'Satoshi',
                        color: '#FFFFFF'
                      }}
                    >
                      {t('watchlist.Create')}
                    </Typography>
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Companies
              watchlist={watchlists.find((x) => x.watchlist_id === selectedItem)}
              selectedItem={selectedItem}
              handleWatchlistDeletion={handleWatchlistDeletion}
            />
          </Grid>
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '50px' }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                createWatchlist();
              }}
              style={{
                margin: '10px',
                border: 'none',
                textTransform: 'none',
                height: '33px',
                backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
              }}
            >
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  fontFamily: 'Satoshi',
                  color: '#FFFFFF'
                }}
              >
                {t('watchlist.Create')}
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default WatchList;
