import { Checkbox, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Chip } from '@mui/material';
import CustomTextField from './textfield';

const useStyles = makeStyles(() => ({
  autoCompletRoot: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '7px !important'
    }
  },
  pd4: {
    padding: '4px 0 4px 0'
  }
}));
const SearchComp = ({
  className = '',
  options = [],
  selectedValues = [],
  placeholder = '',
  handleChange = () => {},
  chip = null, // flag to show selected value as chips in the search bar itself
  handleDeleteChip = () => {},
  clearable = false,
  optionFormat = { key: 'key', value: 'value' }, //model for objects in options to avoid loop to make it compatible to key and value
  errorMessage = '',
  disabled = null,
  showCheckBox = true
}) => {
  const classes = useStyles();
  return (
    <>
      {selectedValues && (
        <form noValidate className={className}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={options}
            className={classes.autoCompletRoot}
            getOptionLabel={(result) => result?.[optionFormat.value] || ''}
            value={selectedValues.map((selected) =>
              options.find((option) => selected?.[optionFormat.key] === option?.[optionFormat.key])
            )}
            renderTags={(selectedValues) => {
              return chip
                ? selectedValues.map((value) => (
                    <Chip
                      key={value?.[optionFormat.key]}
                      label={value?.[optionFormat.value]}
                      style={{
                        borderRadius: '2px',
                        margin: '2px 3px',
                        color: '#004CFF',
                        backgroundColor: '#E0E5F7'
                      }}
                      onDelete={() => handleDeleteChip(value?.[optionFormat.key])}
                    />
                  ))
                : null;
            }}
            renderOption={(result) => (
              <>
                {selectedValues && showCheckBox && (
                  <Checkbox
                    style={{ color: '#004CFF', marginRight: 8 }}
                    checked={
                      selectedValues.find(
                        (obj) => obj?.[optionFormat.key] === result?.[optionFormat.key]
                      )
                        ? true
                        : false
                    }
                  />
                )}
                {showCheckBox ? (
                  result?.[optionFormat.value]
                ) : (
                  <span className={classes.pd4}>{result?.[optionFormat.value]}</span>
                )}
              </>
            )}
            renderInput={(params) => (
              <CustomTextField
                params={params}
                placeholder={placeholder}
                errorMessage={errorMessage}
              />
            )}
            onChange={(event, value) => handleChange(event, value)}
            disabled={disabled}
            disableClearable={clearable}
            autoHighlight
          />
        </form>
      )}
    </>
  );
};

export default SearchComp;
