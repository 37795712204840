import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import { ReactComponent as SeeLess } from '../../../components/icons/SeeLess.svg';
import PropTypes from 'prop-types';

function Row(props) {
  const { row, isFyTable, isFyMap, classes, truncateStr } = props;
  const [open, setOpen] = React.useState(false);
  let children = [];
  let childTitle = row?.column_name;
  const isCollapsable = isFyTable || isFyMap;
  if (isCollapsable) {
    Object.keys(row).forEach((x) => {
      if (Array.isArray(row[x])) {
        children = row[x];
      } else if (typeof row[x] === 'object') {
        children = row[x];
      }
    });
  }
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {isCollapsable ? (
          <TableCell style={{ borderBottom: 'none' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <SeeLess /> : <SeeMore />}
            </IconButton>
          </TableCell>
        ) : null}
        {row &&
          Object.keys(row).length > 0 &&
          Object.keys(row)
            .filter((x) => typeof row[x] != 'object')
            .map((x, i) => {
              if (i == 0)
                return (
                  <TableCell
                    style={{ borderBottom: 'none' }}
                    title={row[x]}
                    className={classes.fontSize12}
                    component="th"
                    scope="row"
                    key={i}
                  >
                    {/* {truncateStr(row[x], 38)} */}
                    <Typography
                      style={{ fontFamily: 'Satoshi', fontSize: '12px', fontWeight: 600 }}
                    >
                      {row[x]}
                    </Typography>
                  </TableCell>
                );

              return (
                <TableCell
                  style={{ borderBottom: 'none' }}
                  title={row[x]}
                  className={classes.fontSize12}
                  key={i}
                >
                  {/* {truncateStr(row[x], 38)} */}
                  <Typography style={{ fontFamily: 'Satoshi', fontSize: '12px', fontWeight: 600 }}>
                    {row[x]}
                  </Typography>
                </TableCell>
              );
            })}
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.fontSize12}
          style={{ borderBottom: 'none', paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {isCollapsable ? (
              <Box sx={{ margin: 1 }}>
                <Typography
                  className={classes.fontSize12}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {childTitle}
                </Typography>
                <Table size="small" aria-label="purchases">
                  {isFyTable ? (
                    <>
                      <TableHead>
                        <TableRow>
                          {children.length > 0 &&
                            Object.keys(children[0]).length > 0 &&
                            Object.keys(children[0]).map((x, i) => {
                              if (i < 2)
                                return (
                                  <TableCell
                                    style={{ borderBottom: 'none' }}
                                    title={x}
                                    className={classes.fontSize12}
                                    key={i}
                                  >
                                    {truncateStr(x, 38)}
                                  </TableCell>
                                );

                              return (
                                <TableCell
                                  style={{ borderBottom: 'none' }}
                                  title={x}
                                  className={classes.fontSize12}
                                  key={i}
                                >
                                  {truncateStr(x, 38)}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {children.length > 0 &&
                          children.map((childRow, index) => {
                            return (
                              <TableRow key={index}>
                                {Object.keys(childRow).length > 0 &&
                                  Object.keys(childRow).map((x, i) => {
                                    if (i == 0)
                                      return (
                                        <TableCell
                                          style={{ borderBottom: 'none' }}
                                          className={classes.fontSize12}
                                          component="th"
                                          scope="row"
                                          key={i}
                                          title={childRow[x]}
                                        >
                                          {truncateStr(childRow[x], 38)}
                                        </TableCell>
                                      );

                                    if (i < 2)
                                      return (
                                        <TableCell
                                          style={{ borderBottom: 'none' }}
                                          title={childRow[x]}
                                          className={classes.fontSize12}
                                          key={i}
                                        >
                                          {truncateStr(childRow[x], 38)}
                                        </TableCell>
                                      );

                                    return (
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                        className={classes.fontSize12}
                                        key={i}
                                        title={childRow[x]}
                                      >
                                        {truncateStr(childRow[x], 38)}
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </>
                  ) : (
                    <>
                      {children &&
                        Object.keys(children).length > 0 &&
                        Object.keys(children).map((x, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell
                                style={{ borderBottom: 'none' }}
                                title={x}
                                className={classes.fontSize12}
                              >
                                {/* {truncateStr(x, 25)} */}
                                <Typography
                                  style={{
                                    fontFamily: 'Satoshi',
                                    fontSize: '12px'
                                  }}
                                >
                                  {x}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{ borderBottom: 'none' }}
                                title={x}
                                className={classes.fontSize12}
                              >
                                {/* {truncateStr(children[x], 25)} */}
                                {children[x] ? (
                                  <Typography
                                    style={{
                                      fontFamily: 'Satoshi',
                                      fontSize: '12px'
                                    }}
                                  >
                                    {children[x]}
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      fontFamily: 'Satoshi',
                                      fontSize: '12px'
                                    }}
                                  >
                                    -
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  )}
                </Table>
              </Box>
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ config }) {
  const { isFyTable, tableAccordianData, isFyMap, classes, truncateStr } = config;
  const data =
    tableAccordianData &&
    Object.keys(tableAccordianData).length > 0 &&
    Object.keys(tableAccordianData).map((x) => {
      return {
        column_name: x,
        children: tableAccordianData[x]
      };
    });
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      style={{
        borderRadius: '8px',
        border: '1px solid #E8E8E8'
      }}
    >
      <Table aria-label="collapsible table">
        <TableBody>
          {data.length > 0 &&
            data.map((row, i) => (
              <Row
                key={i}
                row={row}
                isFyTable={isFyTable}
                isFyMap={isFyMap}
                classes={classes}
                truncateStr={truncateStr}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  config: PropTypes.any
};
